.events-browse__list {
    background: $light-gray-color;
    margin-left: toRem(-15);
    margin-right: toRem(-15);
    padding-left: toRem(15);
    padding-right: toRem(15);
    overflow: hidden;

    .search-result__info {
        margin: 0;
        background: $white;
        box-shadow: 0 toRem(3) toRem(3) rgba(0, 0, 0, 0.07);
        margin-left: toRem(-16);
        margin-right: toRem(-16);
        padding-left: toRem(15);
        padding-right: toRem(15);
        padding-bottom: toRem(20);
    }

    .sort-by-caption {
        display: inline-block;
        font-weight: 600;
    }

    .search__sort {
        @include font-size(14);
        font-style: normal;
    }

    .search__item--event {
        width: 100%;
        background: $white;
        margin-bottom: toRem(14);
    }

    .left-bordered-label {
        border-color: $black;
    }

    &-label {
        display: table-cell;
        vertical-align: middle;
        width: 33%;

        @include font-size(14);
        font-weight: 600;

        &s {
            margin: toRem(33) 0 toRem(13);
            @extend .clearfix;
        }
    }
    &-info-labels {
        display: table;
        width: 100%;

        .events-browse__list-label {
            padding: 0 toRem(10);
        }
    }
    .pagination {
        margin-top: toRem(20);
    }
}

.events-results {
    .search__item--event {
        width: 100%;
    }
}
