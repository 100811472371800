.collection-item {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    margin-bottom: toRem(30);
    min-height: toRem(141);
    position: relative;
    cursor: pointer;
    @extend .card--rounded;
    @extend .card--bordered;
    @include media('<=tablet') {
        height: auto;
        border-radius: toRem(22);
    }
    &__right {
        padding: toRem(13) toRem(15);
        flex: 1;
    }
    &__title {
        @include font-size(14);
    }
    &__more {
        float: right;
        color: $blue-color;
        @include font-size(11);
        position: absolute;
        bottom: toRem(10);
        right: toRem(20);
        .icon-Arrow-Stroke {
            @include font-size(8);
        }
        .collection-grid & {
            @include media('<=tablet') {
                position: static;
                float: none;
            }
        }
    }
    &__brief {
        @include font-size(11);
        color: $primary-color;
        overflow: hidden;
    }
    &__image {
        position: relative;
        .slideShow & {
            max-width: toRem(121);
        }
        @include media('<tablet') {
            width: 100%;
            img {
                width: 100%;
            }
        }
        img {
            display: block;
        }
    }
    &__badge {
        position: absolute;
        bottom: toRem(4);
        max-width: toRem(45);
        left: toRem(4);

        @include media('<tablet') {
            min-width: 30%;
        }
    }
}
