.search-page {
    &__title {
        color: $text-onDark;
        @include font-size(56);
        margin-left: toRem(15);

        @include media('<desktop') {
            padding-top: toRem(10);
            @include font-size(40);
        }
        @include media('<tablet') {
            padding-top: toRem(10);
            @include font-size(28);
            text-align: center;
            margin: 0;
        }
    }

    &__upcoming-events {
        padding: toRem(25) 0;
        margin-top: toRem(30);
        margin-left: toRem(30);
        @include media('<phone') {
            margin-left: 0;
        }

        .owl-carousel {
            padding: 0;
        }

        .slideShow {
            margin: 0 toRem(40);
            @include media('<phone') {
                margin: 0 toRem(15);
            }

            .owl-carousel {
                .owl-dots {
                    @include media('<desktop') {
                        width: toRem(100);
                    }
                }

                .owl-dot {
                    span {
                        padding: toRem(5);
                    }

                    &.active {
                        span {
                            background-color: $blue-color;
                            border-color: $blue-color;
                        }
                    }
                }
            }
        }

        .slide-item {
            margin: 0 toRem(10);
        }

        .owl-nav {
            i,
            .owl-nav-active i {
                color: lighten($primary-lighten-color, 10%) !important;
            }

            .owl-prev {
                margin-left: toRem(-40);
                @include media('<phone') {
                    margin-left: toRem(-25);
                }
            }

            .owl-next {
                margin-right: toRem(-40);
                @include media('<phone') {
                    margin-right: toRem(-25);
                }
            }

            .owl-carousel .owl-nav [class*='owl-']:hover {
                background: transparent !important;
            }
        }
    }

    .search-titles {
        margin-top: toRem(25);

        &.other-results {
            padding-bottom: toRem(18);
            margin-bottom: toRem(15);
            border-bottom: solid toRem(1) $secondary-color;
        }
    }

    .publications-wrapper {
        @include media('>=tablet') {
            .owl-carousel {
                &.slide-removal {
                    margin-left: toRem(15);
                }
            }
        }
    }
}

.saveToBinderHeader {
    font-size: toRem(16);
    font-weight: 600;
}

.saveToBinders-list > span {
    font-size: toRem(16);
    font-weight: 600;
    margin-bottom: toRem(15);
    display: block;
}

.facet__title {
    @media screen and (max-height: toRem(1200)) {
        max-width: toRem(150);
    }
}
