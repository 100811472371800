$facebookColor: rgb(59, 89, 152);
$twitterColor: rgb(29, 161, 242);
$linkedinColor: rgb(0, 119, 181);
$googleColor: rgb(220, 78, 65);
$emailColor: rgb(132, 132, 132);
$redditColor: rgb(255, 87, 0);
$xColor: rgb(26, 26, 26);

.pill-share__content {
    [class*='a2a_button'] {
        border-radius: toRem(2);

        @include media('>=tablet') {
            padding: 0 toRem(8);
            min-width: toRem(130);
        }

        span {
            vertical-align: middle;
        }

        &:after {
            @include font-size(14);
            color: $white;
            padding: toRem(11) 0;
            @include media('>=tablet') {
                padding-left: toRem(5);
            }
        }
    }

    .share__inline-links {
        .a2a {
            line-height: unset !important;
            display: inline-block;
            & li {
                margin: toRem(8);
            }
            &_svg {
                margin-right: 0;
            }
        }
    }

    .a2a_button_ {
        &facebook {
            background: $facebookColor;
        }
        &twitter {
            background: $twitterColor;
        }
        &linkedin {
            background: $linkedinColor;
        }
        &google_plusone_share {
            background: $googleColor;
        }
        &email {
            background: $emailColor;
        }
        &reddit {
            background: $redditColor;
        }
        &x {
            background: $xColor;
        }
    }

    .copy {
        &__text {
            @extend .info--text;
            font-weight: normal;
            border-bottom: toRem(1) solid $secondary-border-color;
            padding-bottom: toRem(10);
            color: $primary-lighten-color;
            overflow-wrap: anywhere;
        }
        &__btn {
            i {
                color: $primary-lighten-color;
            }
        }
    }
}

.a2a_button_twitter {
    .icon-x {
        font-weight: 600;
    }
}
