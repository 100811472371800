#manageAlert {
    p {
        @include font-size(15);
        line-height: toRem(25);
        font-weight: 300;
    }

    .email {
        display: block;
        color: $blue-color;
        font-weight: 600;
    }

    .btn {
        color: $white;
        margin-left: 0;

        &:hover {
            color: $white;
            text-decoration: none;
        }
    }

    .link {
        @extend .link;
    }
}
