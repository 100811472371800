.event {
    &__header {
        .event__code,
        .event__title {
            margin-bottom: toRem(10);
            display: block;
        }

        .event__title {
            @include font-size(18);
            font-style: italic;
            color: $blue-color;
        }

        .event__code {
            font-weight: 600;
            line-height: 1.125rem;
            @include font-size(15);
        }
    }

    &__content {
        @include font-size(14);
        color: $primary-lighten-color;
        font-style: italic;
        font-weight: 600;

        .info {
            padding-bottom: toRem(10);

            i {
                padding-right: toRem(10);
                @include font-size(16);
                min-width: toRem(25);
                text-align: center;
                display: inline-block;
            }

            &.calender {
                i {
                    color: $primary-lighten-color;
                    .active.row--event & {
                        color: $blue-color;
                    }
                }
            }
            &.map {
                i {
                    color: $primary-lighten-color;
                    .active.row--event & {
                        color: $dark-pink-color;
                    }
                }
            }
        }
    }

    .colored-block & {
        &:not(.event--inline-info) {
            .event {
                &__header {
                    background-color: $blue-color;
                    color: $text-onDark;
                    padding: toRem(14) 0;

                    .event__code {
                        border-left: toRem(8) solid;
                        padding-left: toRem(8);
                        @include font-size(18);
                        font-style: italic;
                        margin-bottom: 0;
                    }

                    .event__title {
                        padding: 0 toRem(16);
                        margin-bottom: 0;
                        color: $white;
                    }
                }
                &__content {
                    position: relative;
                    padding: toRem(16);
                    padding-bottom: 0;
                    background-color: $white;
                }
            }
        }

        .colored-block__dash {
            background-color: $primary-lighten-color;
        }
    }

    .map-popup & {
        padding: toRem(16);

        .event__code,
        .event__title,
        .event__content,
        .website i {
            @include font-size(12);
        }

        .event__title {
            color: $primary-color;
        }

        .event__content {
            font-weight: 300;
        }
        .event__code {
            font-style: italic;
        }

        .website {
            color: $blue-color;
            font-style: normal;
            font-weight: 500;
            @include font-size(13);

            margin: 0 toRem(-16);
            border-top: toRem(1) solid $secondary-shadow-color;
            padding: toRem(16);
            padding-bottom: 0;
        }

        .disabled {
            opacity: 1;
        }
    }
}
