.footer {
    color: $text-onDark;
    position: relative;
    @include font-size(14);
    line-height: 1.563rem;
    font-weight: 300;

    @include media('<=tablet') {
        line-height: 1.25rem;
    }

    a,
    i {
        opacity: 0.8;
        &:hover,
        &:focus {
            opacity: 1;
        }
    }
    .back-to-top {
        i {
            background: $primary-color;
        }
    }

    &__copyright-wrapper {
        @include font-size(12);
        line-height: 0.938rem;

        @include media('>desktop') {
            width: 69%;
        }

        a {
            opacity: 1;
        }
    }

    &__logos-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include media('>desktop') {
            width: 31%;
            padding: 0;
        }
        @include media('<tablet') {
            display: block;
            margin-top: toRem(20);
            padding-top: toRem(15);
            border-top: toRem(1) solid $secondary-border-color;
        }
    }
    &__logo1 {
        width: toRem(170);
        margin-right: toRem(25);
        display: inline-block;
        vertical-align: super;
        @include media('<large') {
            width: toRem(140);
            margin-right: toRem(12);
        }
        @include media('<phone') {
            width: toRem(140);
            margin-right: toRem(5);
        }
    }
    &__logo2 {
        width: toRem(140);
        display: inline-block;
    }

    .logos {
        a {
            display: inline-block;
        }
    }

    &-bottom {
        background-color: $default-bg-color;
        color: $text-onLight;
        @include font-size(14);
        padding: toRem(15) 0;
        border-top: toRem(1) solid $primary-border-color;

        .rlist--inline {
            margin-top: toRem(2);
            @include media('<tablet') {
                margin-top: toRem(15);
            }
            li:not(:first-child) {
                padding-left: toRem(8);
                margin-left: toRem(8);
                border-left: toRem(1) solid $text-onLight;
            }
        }
    }
    &-top {
        background-color: $footer-bg-color;
        background-image: url('../../../images/footer-decoration1.png'), url('../../../images/footer-decoration2.png');
        background-position: right bottom, left top;
        background-repeat: no-repeat, no-repeat;
        @include media('<desktop') {
            background-size: 50%;
        }
        @include media('<tablet') {
            background-size: 80%;
        }
        @include media('<phone') {
            background-size: 100%;
        }

        padding: toRem(32) 0 toRem(50);

        @include media('<tablet') {
            padding-bottom: toRem(20);
        }

        .sitemap {
            display: flex;

            @include media('<tablet') {
                display: block;
            }

            &__column {
                &:not(:first-child) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-left: toRem(1) solid $primary-border-color;
                    @include media('<tablet') {
                        border-left: none;
                        display: block;

                        &:nth-child(3) {
                            clear: left;
                        }

                        &:nth-child(3),
                        &:nth-child(4) {
                            padding-top: toRem(20);
                            margin-top: toRem(20);
                            border-top: toRem(1) solid $primary-border-color;
                        }
                    }
                }
            }

            .rlist {
                li {
                    padding-bottom: toRem(2);

                    @include media('<=tablet') {
                        padding-bottom: toRem(8);
                    }
                }
            }

            h6 {
                padding: toRem(4) 0 toRem(5);
                font-family: $default-font-family;
                margin-bottom: toRem(18);
                line-height: 1.125rem;
                opacity: 0.87;
            }
        }
    }
}

.social-media__connect {
    margin-top: toRem(15);

    i {
        margin-right: toRem(20);
    }
}
