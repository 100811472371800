$default-font-family: 'Merriweather Sans', sans-serif;
$secondary-font-family: 'Source Sans Pro', sans-serif;
$tertiary-font-family: 'Merriweather', serif;
$quaternary-font-family: 'Open Sans', sans-serif;
$arial-font-family: 'Arial,Helvetica,sans-serif';

$default-font-size: toRem(16);
// 16px
$h1-font-size: toRem(26);
// 26px
$h2-font-size: toRem(22);
// 22px
$h3-font-size: toRem(20);
// 20px
$h4-font-size: toRem(18);
// 18px
$h5-font-size: toRem(16);
// 16px
$h6-font-size: toRem(14);
// 14px
$icon-font-size: toRem(18);
// 18px
$small-font-size: toRem(12);
// 12px
$gutter-size: toRem(30);

$gutter-extra-size: toRem(60);

//z-index (create a mixin)
$index-minus50: -50;

$index-minus49: -49;

$index-minus3: -3;

$index-minus49: -49;

$index-minus50: -50;

$index-minus3: -3;

$index-minus: -1;

$index-0: 0;

$index-1: 1;

$index-2: 2;

$index-4: 4;

$index-5: 5;

$index-6: 6;

$index-7: 7;

$index-8: 8;

$index-9: 9;

$index-10: 10;

$index-11: 11;

$index-15: 15;

$index-999: 999;

$index-9010: 9010;

$index-9020: 9020;

$index-10000: 10000;

$index-10001: 10001;

$index-9999999999: 9999999999;

$theme-map: null;
