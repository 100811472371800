.csl {
    &-wrapper {
        .input-group {
            select {
                -webkit-appearance: none;
                appearance: none;
                background-image: url(../../../images/down-arrow.png);
                background-repeat: no-repeat;
                background-position: right 0.75rem center;
                padding-right: toRem(28);
                padding-left: toRem(12);
                cursor: pointer;

                &::-ms-expand {
                    display: none;
                }
            }
        }

        button {
            @extend .btn;
        }
    }
}

#exportCitation {
    .tab {
        .tab__nav {
            li {
                margin-bottom: 0;
                border: toRem(1) solid $secondary-bg-color;

                a {
                    padding: toRem(6) toRem(16);
                    border: none;
                    outline: none;
                    font-weight: 600;
                    text-decoration: none;
                }

                &.active {
                    background-color: $black;

                    a {
                        color: $white;
                    }
                }

                &:first-child {
                    border-top-left-radius: toRem(20);
                    border-bottom-left-radius: toRem(20);
                }

                &:last-child {
                    border-top-right-radius: toRem(20);
                    border-bottom-right-radius: toRem(20);
                }
            }
        }

        &__content {
            .tab__pane {
                padding-top: 0;
            }
        }
    }

    .all-results-tab-container {
        padding-top: toRem(20);
        border-top: solid toRem(1) $secondary-bg-color;

        .desc-text {
            line-height: toRem(24);

            .bold {
                margin-bottom: toRem(5);
            }
        }

        .downloadBtn {
            margin-top: toRem(20);
            padding: toRem(8) toRem(16);
            border-radius: toRem(4);
            text-decoration: none;
            outline: none;

            i.icon-Icon_Download {
                color: $primary-lighten-color;
                padding-right: toRem(10);
            }

            i.icon-export {
                padding-right: 0;
                padding-left: toRem(10);
                color: $secondary-color;
            }
        }
    }
}

.searchCiteExport-popup {
    position: fixed;
    bottom: toRem(20);
    left: toRem(20);
    max-width: toRem(600);
    background: $white;
    border-radius: toRem(6);
    z-index: $index-15;
    overflow: hidden;
    border: toRem(1) solid $secondary-lighten-color;

    @include media('<tablet') {
        width: 100%;
        padding: 0;
        left: 0;
        bottom: 0;
    }

    &__body {
        padding: toRem(16) toRem(50);
        font-size: toRem(12);
        color: $primary-color;
        padding-top: 0;

        .csl-all-response {
            display: none;
        }

        @include media('<tablet') {
            padding: toRem(11) toRem(15);
            padding-top: 0;
        }
    }

    &__header {
        background: $secondary-lighten-color;
        .icon-cancel-bold {
            padding: toRem(10);
            font-size: toRem(10);

            &:hover {
                cursor: pointer;
            }
        }

        .icon-info {
            display: inline-block;
            vertical-align: middle;
            width: toRem(40);
            border-right: toRem(1) solid $white;
            padding: toRem(13) 0;
            text-align: center;
            margin-right: toRem(10);
            min-height: toRem(45);
        }
    }

    &__title {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        font-weight: 600;
    }

    &__close {
        @include font-size(14);
    }
}
