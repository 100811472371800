.advanced_search_container {
    background: $white;

    .left-bordered-title {
        margin: toRem(30) 0;
        @include media('<phone') {
            margin-top: toRem(15);
        }
    }
}
