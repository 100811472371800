.contrib-metrics {
    @include media('<tablet') {
        &--inline {
            .contrib-metrics__multi-items {
                .box-item {
                    &__content {
                        flex-direction: row;

                        + .box-item__count {
                            text-align: right;
                        }
                    }

                    &__title-holder {
                        margin-top: 0;
                    }
                }
            }
            .box-item__title {
                max-width: toRem(160);
            }
        }
    }

    &__multi-items {
        .box-item__title-holder {
            padding-right: toRem(4);
        }
    }
}

@include media('>=tablet', '<desktop') {
    .contrib-metrics__multi-items {
        .box-item {
            .content--empty {
                padding-top: 0;

                img {
                    width: toRem(68);
                    height: toRem(68);
                }

                p {
                    margin: toRem(6) 0 0 0;
                }
            }
        }
    }
}
