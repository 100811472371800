.full-text-wrapper {
    div.accordion-tabbed__tab {
        .left-bordered-title {
            margin: toRem(20) 0;

            &:after {
                font-style: normal;
                @include font-size(10);
                color: $blue-color;
            }
        }

        @include media('<phone') {
            border-top: solid toRem(1) $secondary-border-color;
        }
    }
}
