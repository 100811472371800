.editable {
    &__text,
    &__toggle {
        display: inline;
    }

    &__toggle {
        color: $primary-lighten-color;
        @include font-size(13);
        margin-left: toRem(10);
        cursor: pointer;
    }

    &__wrapper {
        .input-group {
            margin: 0;

            &-addon {
                width: toRem(130);
                padding: 0;
            }

            &__field-container--right-icon {
                width: 100%;
            }
        }

        .btn {
            background: none;
            color: $primary-lighten-color;
            @extend .separator-left;
            padding: 0.615rem;
            border-radius: 0;
            margin: 0;

            i {
                padding-right: 0;
            }
        }
    }

    &--default {
        .editable__toggle,
        .editable__text {
            display: none;
        }
    }
}
