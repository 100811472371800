:root {
    --primary-color: #333;
    --light-color: #79c6e2;
    --lighter-color: #a4d4e6;
    --diff-color: #b0ba31;
    --logo-color: #1975ae;
    --badge-color: #f0f0f0;
    --nav-background-color: #d9d9d9;
    --banner-color: #4975bb;
    --nav-font-color: #333;
    --dropdown-font-color: #333;
    --dropdown-border-color: #333;
    --dropdown-background-color: #d9d9d9;
    --proceeding-background: #fff;
    --banner: url('/specs/products/acm/releasedAssets/images/journal-banner-inner--default-colors.svg');
}

.banner {
    background-color: var(--primary-color);
    &.conference-landing {
        background-color: var(--proceeding-background);
    }
    &__no-sub {
        --primary-color: #4975bb;
    }
    &__holder {
        &-imgs-container {
            height: toRem(175);
            position: absolute;
            width: 100%;
            left: 0;
            overflow: hidden;
            top: 0;
        }

        &-img {
            position: absolute;
            top: toRem(-15);

            @media screen and (max-width: toRem(1600)) {
                height: 100%;
                top: 0;
            }

            @media screen and (min-width: toRem(1600)) and (max-width: toRem(1750)) {
                top: 0;
            }

            &--mobile {
                position: absolute;
                top: 0;
            }
        }
    }
}

.header .sub-nav {
    background-color: var(--nav-background-color);

    .dropdown__menu {
        .sub-nav__item {
            &:hover {
                color: $white;
            }

            color: var(--dropdown-font-color);
            border-color: var(--dropdown-border-color);
        }
    }

    &__holder {
        background-color: var(--nav-background-color);

        .menu-parent .sub-items-list {
            .dropdown__menu {
                background-color: var(--dropdown-background-color);
            }
        }
    }

    &__item {
        color: var(--nav-font-color);

        .icon-section_arrow_d {
            color: var(--nav-font-color);
        }

        &:hover {
            color: $white;

            .icon-section_arrow_d {
                color: $white;
            }
        }

        &.active-item:hover {
            color: $black;
        }
    }

    &__toggle {
        color: var(--nav-font-color);
    }
}

.colored-block {
    &__dash {
        background-color: var(--primary-color);
    }
}

.loi-page,
.toc {
    .border-title {
        border-color: var(--primary-color);
    }

    .colored-block__title {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            &:not(.left-bordered-title) {
                border-color: var(--primary-color);
            }
        }
    }

    .left-bordered-title:before {
        background-color: var(--primary-color);
    }

    .row--event--with-label {
        .event__label {
            background-color: var(--primary-color);

            .arrow-right,
            .arrow-bottom {
                fill: var(--primary-color);
            }
        }
    }

    .loi {
        .issues-list .loi__issue {
            border-color: var(--nav-background-color);
        }
    }

    .badge-rounded-edgs {
        background-color: var(--badge-color);
        color: var(--nav-font-color);
    }

    .award-winners {
        .award-winners__prize {
            border-left-color: var(--primary-color);
        }
    }

    .left-bordered-title--white {
        border-color: $white;
    }
    .browse-journals-meta {
        &__logo-image {
            background-color: var(--logo-color);
        }
    }
}

.acmbooks {
    --primary-color: #18699b;
    --nav-background-color: #18699b;
    --nav-font-color: #fff;

    .colored-block__title {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            &:not(.left-bordered-title) {
                border-color: var(--primary-color);
            }
        }
    }

    .left-bordered-title:before {
        background-color: var(--primary-color);
    }
}

.tocl,
.taslp {
    @extend .dark-theme;
}

.header {
    @if (variable-exists(--banner-sm)) {
        --banner-sm: var(--banner-sm);
    } @else {
        --banner-sm: var(--banner);
    }
}
