.pill-bibliometrics__content {
    .metrics__list {
        margin-top: toRem(20);
    }
}

.article-metric {
    border-radius: 100%;
    border: toRem(5) solid #eee;
    width: toRem(164);
    height: toRem(164);
    vertical-align: middle;
    padding: toRem(22) toRem(30);
    font-weight: 400;

    @include media('<tablet') {
        @include font-size(12);
        width: toRem(100);
        height: toRem(100);
        padding: toRem(10) toRem(5);
    }

    .metric-value {
        float: none;
        font-weight: 600;
        height: toRem(40);
        padding-bottom: toRem(4);
        font-size: 1.25rem;
        @include media('<tablet') {
            height: toRem(30);
        }
    }

    &.citation {
        border-color: $blue-color;
        .metric-value {
            color: $blue-color;
        }
    }

    &.download {
        border-color: $light-purple-color;
        .metric-value {
            color: $light-purple-color;
        }
    }

    &s {
        li {
            vertical-align: top;
            text-align: center;

            ~ li {
                margin-left: toRem(35);
            }

            a {
                color: $blue-color;
                @include font-size(13);
                text-decoration: underline;
                display: inline-block;
                margin-top: toRem(10);
            }
        }
    }
}
