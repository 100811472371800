.tab {
    &__nav {
        a {
            display: block;
            padding: toRem(8);
            border-bottom: toRem(1) solid $tab-nav-border-color;
            line-height: unset;
        }
    }

    &--res {
        .dropBlock {
            li {
                a,
                button {
                    font-size: toRem(14);
                    padding: toRem(11);
                }
            }
        }
    }

    .dropBlock {
        > a {
            background: $primary-lighten-color;
            color: $white;
            text-align: center;
            padding: toRem(13);
        }

        i {
            color: $white;
            position: static;
            padding-left: toRem(10);
            font-size: toRem(7);
        }
    }
}

.tabbed-content {
    .tab {
        &__nav {
            border-bottom: toRem(1) solid $secondary-color;
            padding-inline: toRem(16);

            &__item {
                margin-bottom: 0;

                &__link {
                    background-color: unset;
                    border-bottom: 0;
                    border: none;
                    color: $primary-color;
                    cursor: pointer;
                    font-style: italic;
                    margin-right: toRem(20);
                    padding: 0;
                    @include font-size(18);

                    @include media('<1200px') {
                        font-size: toRem(16);
                        margin-right: toRem(10);
                    }
                }

                &.active {
                    .tab__nav__item__link {
                        border-bottom: toRem(2) solid $primary-color;
                        font-weight: 600;
                    }
                }
            }

            &.gutterless {
                padding: 0;
            }
        }
    }

    &--proceedings-tabs {
        .tab {
            &__nav {
                border: none;
                padding: 0 0 toRem(10) 0;

                &__item {
                    &__link {
                        font-style: normal;
                    }
                }

                .tab__nav__item.active .tab__nav__item__link {
                    border-width: toRem(4);
                }
            }
        }
    }
}

.tabs-with-icons {
    .tab {
        &__nav {
            &__item {
                &__link {
                    background: $grey-lighten-20;
                    border-radius: toRem(30);
                    border: 0;
                    padding: toRem(9) toRem(13);
                    transition: background-color 0.4s;
                    @include font-size(12);

                    @include media('>=tablet') {
                        margin-right: toRem(6);
                    }

                    @include media('<tablet') {
                        padding: toRem(9) toRem(9);
                    }

                    i {
                        display: inline-block;
                        vertical-align: middle;
                        color: $blue-color;
                        margin-right: toRem(8);

                        @include media('<tablet') {
                            margin-right: toRem(4);
                        }
                    }

                    .icon-bookmark1 {
                        background: $blue-color;
                        border-radius: toRem(3);
                        height: toRem(18);
                        overflow: hidden;
                        position: relative;
                        width: toRem(16);

                        &:before {
                            position: absolute;
                            top: toRem(2);
                            left: toRem(1);
                            font-size: toRem(9);
                            color: $white;
                        }
                    }

                    span {
                        vertical-align: middle;
                    }

                    &:hover {
                        background: $grey;
                    }
                }
            }

            .active {
                .tab__nav__item__link {
                    background: $blue-lighten-10;
                    color: $white;

                    i {
                        color: $white;
                    }
                }

                .icon-bookmark1 {
                    background: $white;

                    &:before {
                        color: $blue-color;
                    }
                }
            }
        }
    }
}

.acm-browse-tabs {
    .tab__nav {
        margin-bottom: 0;

        &:before {
            content: 'View:';
            font-weight: 600;
            font-size: toRem(14);
            border-left: toRem(8) solid;
            padding-left: toRem(8);
            display: inline-block;
            vertical-align: middle;
            margin-right: toRem(10);

            @include media('<tablet') {
                display: block;
                margin-bottom: toRem(20);
            }
        }
    }
}
