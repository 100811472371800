h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: toRem(15);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
    font-weight: 600;
}

h1 {
    font-size: $h1-font-size;
}

h2 {
    font-size: $h2-font-size;
}

h3 {
    font-size: $h3-font-size;
}

h4 {
    font-size: $h4-font-size;
}

h5 {
    font-size: $h5-font-size;
}

h6 {
    font-size: $h6-font-size;
}

.left-bordered {
    &-title {
        padding-left: toRem(14);
        line-height: 1.125rem;
        @include font-size(18);
        font-weight: 600;
        font-style: italic;
        position: relative;

        &.accordion-tabbed__control {
            [data-accordion-vport]:not(.accordion-vport--res) & {
                // increasing specificity
                padding: 0 0 0 toRem(14);
            }
            padding: 0 0 0 toRem(14);
            font-weight: 600;
            @include font-size(18);
            margin-top: toRem(10);
        }

        &.long {
            padding-top: toRem(30);
            &:before {
                height: toRem(48);
            }
        }

        &:before {
            content: '';
            width: toRem(8);
            height: toRem(18);
            background: $black;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            .conference-landing & {
                background: $conference-primary-color;
            }
            .green-theme & {
                background: $dark-green-color;
            }
        }
        &--white {
            color: $white;
            &:before {
                background: $white;
            }
        }
        &.custom-border-title {
            font-size: $h2-font-size;
            line-height: toRem(28);
            display: flex;
            &:before {
                margin-top: toRem(5);
            }
            @media only screen and (min-device-width: toRem(532)) {
                display: inline;
            }
        }
    }
}

.small {
    @include font-size(14);
    font-weight: 300;
    font-style: italic;
}

.h1-styling {
    margin-top: 0;
    margin-bottom: toRem(15);
    font-weight: 600;
    display: block;
}

.widget-title {
    @extend .container;
    padding: 0;
    margin: toRem(30) auto;

    &.border-title {
        margin: toRem(30) auto;
    }

    &.left-bordered-title {
        font-size: $h2-font-size;
        line-height: toRem(28);
        padding-left: toRem(14);
        &:before {
            margin-top: toRem(5);
        }
    }
}

.section {
    > .widget-title {
        @include media('>=desktop') {
            max-width: toRem(1280);
        }

        @include media('<desktop') {
            max-width: 92%;
        }
    }
}

.tabs__header {
    font-weight: 600;
}
