.header {
    &--global-header {
        background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.17) 58.1%, rgba(0, 0, 0, 0) 100%);

        .header {
            &__fixed-items,
            &--second-row.container {
                background-color: transparent;
            }

            &--second-row {
                .responsive-menu-container,
                .main-nav,
                .quick-search--input,
                .quick-search__button,
                .quick-menu__item {
                    background: transparent;
                }

                .quick-search {
                    &__input {
                        border: none;
                    }

                    &--form {
                        background: rgba(255, 255, 255, 0.38);

                        @include media('<tablet') {
                            background: $black;
                        }
                    }
                }
            }
        }

        .global-header-container {
            z-index: $index-10;
            width: 100%;
            height: toRem(124);
            position: absolute;

            @include media('<tablet') {
                height: toRem(64);
            }
        }
    }

    &__fixed-items {
        background: inherit;
        z-index: $index-11;
        width: 100%;
        height: toRem(124);
        background-color: $primary-bg-color;
        top: 0;

        @include media('<tablet') {
            height: toRem(64);
        }

        &.high-zIndex {
            z-index: $index-9999999999;
        }
    }

    @include media('<tablet') {
        .institution-wrapper {
            display: none;
        }

        .show-on-mobile {
            display: none;
        }

        .quick-search-mobile {
            border-bottom: toRem(1) solid $primary-border-color;
            padding: 0 toRem(15);

            .input-group {
                margin: 0;
            }

            .quick-search {
                &--input {
                    background-color: transparent;
                    width: calc(100% - #{toRem(40)});
                }

                &__button {
                    background: $primary-bg-color;
                    border: none;
                    position: absolute;
                    @include font-size(22);
                    padding: toRem(16) toRem(14) toRem(34);
                    right: 0;
                    top: 0;
                }

                &__input {
                    border-color: transparent;
                    height: toRem(60);
                    @include font-size(16);
                    padding: toRem(25) 0;
                    border-radius: 0;
                    -webkit-appearance: none;

                    &::-webkit-input-placeholder {
                        @include font-size(16);
                    }
                }
            }
        }
    }

    &--first-row {
        //padding: 0 toRem(15);
        height: toRem(64);

        @include media('>=tablet') {
            height: toRem(62);
        }

        &.container {
            max-width: 100%;
            width: 100%;
        }

        .pull-left {
            .logos-list {
                margin: 0;
                padding: 0;
            }
            @include media('<tablet') {
                width: 75%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    &--second-row {
        @include media('>=large') {
            padding-left: 0;
        }

        .responsive-menu-container {
            @include media('>=tablet') {
                min-height: toRem(61);
            }

            @include media('<large') {
                margin-left: toRem(-15);
            }

            @include media('<tablet') {
                height: 0;

                .quick-menu__item {
                    display: none;
                }

                .quick-search,
                .advanced-search-link {
                    display: none;
                }
            }
        }

        //padding: 0 toRem(15);
        border-top: toRem(1) solid $primary-border-color;

        &.container {
            max-width: 100%;
            width: 100%;
            background: $text-onLight;
        }

        .register-link {
            font-weight: 600;
        }

        .quick-search {
            &--input {
                background-color: darken($primary-lighten-color, 8);
                border-top-left-radius: toRem(2);
                border-bottom-left-radius: toRem(2);
            }

            &__button,
            &__icon,
            &__input {
                color: $text-onDark;
            }

            &__advancedHeader {
                margin-left: toRem(16);
                color: $secondary-color;
                @include font-size(12);
                vertical-align: text-top;

                &:hover {
                    color: $secondary-color;
                    text-decoration: underline;
                }
            }

            &__clearfix {
                display: inline-block;
            }
        }
    }

    &__logo1 {
        display: inline-block;
        width: toRem(145);
        border-right: toRem(1) solid $primary-border-color;
        padding: toRem(14) toRem(15) toRem(13) 0;
        margin-left: 0;
        margin-right: toRem(10);
        vertical-align: middle;
        max-height: toRem(62);

        a {
            display: block;
        }

        @include media('<large') {
            margin-left: 0;
        }

        @include media('<desktop') {
            width: toRem(110);
            padding: toRem(22) toRem(15) toRem(13) 0;
        }

        @include media('<tablet') {
            border-right: none;
            margin-left: 36%;
            width: toRem(150);
            padding: toRem(15) toRem(15) toRem(10) 0;
        }
    }

    &__logo2 {
        display: inline-block;
        width: toRem(120);
        vertical-align: middle;

        a {
            display: block;
        }

        @include media('<tablet') {
            display: none;
        }
    }

    &__quick-menu {
        display: inline-block;
        @include font-size(12);

        > .rlist--inline > li {
            padding: toRem(23) 0 toRem(22) toRem(12);
            margin-left: toRem(12);
            border-left: toRem(1) solid $primary-border-color;
            position: relative;

            @include media('<tablet') {
                position: static;
                margin: 0;
                //padding: toRem(18) 0 toRem(15) toRem(15);
                padding: toRem(14) 0 toRem(10) toRem(15);
                min-height: toRem(64);
            }

            &.login-list {
                @include media('<tablet') {
                    display: flex;
                    padding-top: toRem(10);
                }
            }
        }

        .btn {
            color: lighten($blue-color, 25%);
        }

        .advanced-search-link {
            @include media('<=phone') {
                display: none;
            }
        }

        .loginBar {
            .login-link {
                @include media('<tablet') {
                    a {
                        font-size: 0;

                        &:before {
                            font-size: toRem(27);
                            content: '\e95c';
                            font-family: icomoon, sans-serif !important;
                        }
                    }
                }
            }

            .register-link {
                @include media('<tablet') {
                    display: none;
                }
            }
        }

        .rlist--inline {
            .login-list {
                @media only screen and (min-device-width: toRem(768)) {
                    padding-top: toRem(20);
                    padding-bottom: toRem(17);
                }
            }
        }
    }

    .hide-when-dots-shows {
        .menu-section {
            @media screen and (min-height: toRem(901)) {
                display: none;
            }

            @include media('<tablet') {
                display: inline-block;
            }
        }
    }
    &.jds {
        .banner__holder {
            @include media('>=desktop') {
                margin-top: toRem(55);
            }
        }
    }
}

.simple-header {
    height: auto;

    &-banner {
        padding-top: toRem(62);
    }
}

.author-picture {
    + .icon-person {
        display: none;
    }
}

.profile-text {
    min-height: toRem(22);
}

@media only screen and (min-device-width: toRem(768)) {
    .login-list {
        .login-link {
            padding-bottom: toRem(26);
        }
    }
}

.login-list {
    display: inline-flex;

    .loginBar {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.on-login-page {
    .login-link {
        a {
            color: $active-tab-onblack;
            border-bottom: 1px solid $active-tab-onblack;
            padding-bottom: toRem(5);
        }
    }
}
