.acceptance-rates {
    &__heading {
        color: $blue-gray-color;

        & > div {
            @include font-size(12);
            font-weight: 600;

            &:not(:last-child) {
                margin-bottom: toRem(7);
            }
        }
    }

    &__table {
        margin-top: toRem(36);

        tr {
            font-weight: 600;
            @include font-size(14);

            @include media('<phone') {
                @include font-size(12);
            }
        }

        @include media('<tablet') {
            td:not(:first-of-type),
            th:not(:first-of-type) {
                text-align: right;
            }
        }
    }

    &__table-container {
        @include media('<phone') {
            overflow: auto;
        }
    }

    &__section {
        &.accordion-tabbed__tab {
            .acceptance-rates__heading {
                &-overall {
                    margin-top: toRem(12);
                }
            }
        }
    }
}
