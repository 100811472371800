[class*='simple-tooltip'] {
    position: relative;
    display: inline-block;
}

[class*='simple-tooltip__']:hover {
    z-index: $index-6;
    cursor: pointer;
}

.simple-tooltip {
    &-text {
        background: $tertiary-bg-color;
        padding: toRem(10) toRem(15);
        color: $white;
        border-radius: toRem(3);
        position: absolute;
        @include font-size(14);
        line-height: toRem(20);
        z-index: $index-9020;
        max-width: toRem(300);

        &-top-arrow {
            width: 0;
            height: 0;
            border: toRem(10) solid $tertiary-bg-color;
            position: absolute;
            transform: rotate(45deg);
        }

        &.bottom {
            .simple-tooltip-text-top-arrow {
                top: toRem(-3);
                left: calc(50% - #{toRem(10)});
                z-index: $index-minus;
            }
        }

        &.top {
            .simple-tooltip-text-top-arrow {
                bottom: toRem(-3);
                left: calc(50% - #{toRem(10)});
                z-index: $index-minus;
            }
        }

        &.right {
            .simple-tooltip-text-top-arrow {
                left: toRem(-3);
                top: calc(50% - #{toRem(10)});
                z-index: $index-minus;
            }
        }

        &.left {
            .simple-tooltip-text-top-arrow {
                right: toRem(-3);
                top: calc(50% - #{toRem(10)});
                z-index: $index-minus;
            }
        }

        &--wide {
            max-width: toRem(345);
            @include font-size(13);

            @include media('<phone') {
                width: 85%;
                //left: 0!important;
                max-width: 100%;
                //margin: 0 toRem(30);
            }
        }
    }
}
