.box-item {
    @extend %multi-search-alt;
    display: flex;
    flex-direction: column;
    height: 100%;

    .contribs-grid & {
        @include media('>tablet') {
            height: toRem(146);
        }
        .content--empty {
            padding-top: toRem(15);
        }
    }
    @include media('<tablet') {
        text-align: center;
        .contrib-metrics--inline & {
            text-align: left;
        }
    }
    &__img-holder {
        width: toRem(60);
        height: toRem(50);
        padding-right: toRem(10);
        img {
            width: toRem(50);
            height: toRem(50);
            @extend .img-rounded;
        }

        ~ .box-item__title-holder {
            width: calc(100% - #{toRem(60)});
        }
    }
    &__title {
        @include font-size(14);
        font-weight: 400;
        color: $black;
        margin: 0;

        a {
            display: block;
            width: 100%;
        }

        &-holder {
            flex: 1;
            @include media('>=tablet') {
                padding-right: toRem(20);
            }
            @include media('<=tablet') {
                margin-top: toRem(10);
            }
        }
    }
    &__meta {
        @include font-size(14);
        color: $primary-lighten-color;
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @include media('<tablet') {
            flex-direction: column;
        }
    }
    &__count {
        @include media('>=tablet') {
            padding-left: toRem(60);
        }
        @include font-size(14);
        color: $primary-lighten-color;
    }
    .creative-work__meta {
        @include font-size(14);
        color: $primary-lighten-color;
    }
    .more-link {
        margin-top: 0;
        padding-top: toRem(6);
        @include font-size(13);
        min-height: toRem(24);
    }
    .top-rated-text {
        font-weight: 600;
        color: $top-rated-color;
        &.light {
            color: $top-rated-light-color;
        }
        &.purple {
            color: $light-purple-color;
        }
    }
    &__item {
        border-bottom: toRem(1) solid $secondary-color;
        padding-bottom: toRem(10);
        margin-bottom: toRem(10);
        min-height: toRem(82);
        flex: 1;
        &.borderless {
            border: none;
        }
        .contrib-metrics--inline & {
            border-bottom: toRem(1) solid $secondary-color;
            min-height: 0;
            padding-bottom: toRem(14);
            margin-bottom: toRem(14);
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            &.borderless {
                border: none;
            }
            .creative-work {
                display: inline-block;
                border: none;
                margin: 0;
                padding: 0;
            }
            .title {
                color: $black;
                padding-right: toRem(20);
            }
            .box-item__count {
                padding: 0;
                color: $black;
                flex-grow: 1;
                text-align: right;
            }
            .box-item__content + .box-item__count {
                @include media('<tablet') {
                    text-align: center;
                    position: static;
                }
            }
            .contrib__count-label {
                display: none;
            }
        }
    }
}
