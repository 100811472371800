.binder__share-invitation {
    margin: toRem(30) 0;
    max-width: toRem(956);

    .actions {
        float: right;
        font-weight: 600;
        a ~ a {
            border-left: toRem(1) solid $dark-info-bg-color;
            margin-left: toRem(10);
            padding-left: toRem(10);
        }

        i {
            @include font-size(10);
        }
    }

    &__msg {
        @include media('<desktop') {
            width: calc(100% - #{toRem(26)});
            display: inline-block;
            vertical-align: middle;
        }
    }

    tr {
        font-weight: 400;
        @include font-size(12);

        .actions {
            a ~ a {
                border-color: $secondary-color;
            }
        }
    }

    td {
        font-weight: 300;
        padding: toRem(7);
    }

    #shareBindersInvitationDetails {
        border-top: toRem(1) solid $dark-info-bg-color;
        margin-top: toRem(8);
        margin-left: toRem(-16);
        margin-right: toRem(-16);
        margin-bottom: toRem(-7);
        background: $white;
        border-bottom-left-radius: toRem(25);
        border-bottom-right-radius: toRem(25);
        padding: toRem(10) toRem(20);
        color: $primary-color;
    }

    @include media('<desktop') {
        .table-responsive {
            margin-bottom: 0;
        }

        .expandable {
            th {
                display: none;
            }

            .table__control__button {
                i {
                    display: block;
                    @include font-size(15);
                    color: darken($secondary-color, 20%);
                }

                .icon-minus-light {
                    display: none;
                }
            }

            tr:not(.hidden),
            td {
                display: block;
            }

            tr {
                padding: 0;

                &.js--expanded {
                    padding-bottom: toRem(20);
                }
            }

            td {
                &:first-child {
                    @include font-size(14);
                    font-weight: 600;
                }
                &:not(:first-child):before {
                    content: attr(data-thead);
                    display: block;
                    color: $primary-color;
                    float: none;
                    font-weight: 600;
                    margin: 0;
                }
            }

            .actions {
                float: none;
                margin-top: toRem(14);

                a {
                    padding: toRem(7) toRem(4);
                    border: toRem(1) solid $primary-color;
                    width: calc(50% - #{toRem(5)});
                    display: inline-block;
                    text-align: center;
                }
            }
        }

        .table-striped tbody tr {
            border-bottom: toRem(1) solid $secondary-color;

            &:nth-of-type(odd) {
                background-color: $white;
            }

            &:last-child {
                border-bottom: none;
            }
        }

        .to_hide {
            display: none !important;
        }

        .js--expanded {
            .to_hide {
                display: block !important;
            }

            .table__control__button {
                .icon-plus-light {
                    display: none;
                }

                .icon-minus-light {
                    display: block;
                }
            }
        }
    }
}
