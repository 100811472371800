// todo: move to variables file
$login-errorMessage-color: red;

.login-form {
    &__small-desc {
        margin-bottom: toRem(12);
        font-weight: 300;
    }

    .form-control {
        border-radius: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    .errorMsg {
        display: block;
        margin-top: toRem(15);
        color: $login-errorMessage-color;
    }
}

.institutions {
    .page__header,
    h5 {
        @include font-size(13);
    }

    .tab__content {
        margin-top: toRem(15);

        &.tab__content {
            margin-bottom: toRem(20);
            padding: 0;
            border: 0;
        }

        ul {
            max-height: toRem(197);
            overflow-y: auto;
            list-style-type: none;
            padding-left: 0;

            li {
                margin-bottom: 0;
                border: solid toRem(1) $secondary-color;
                padding: toRem(10);
                border-bottom: 0;
                @include font-size(13);

                &:last-child {
                    border-bottom: solid toRem(1) $secondary-color;
                }
            }
        }
    }

    .dropBlock {
        .dropBlock__link {
            background: $white;
            border: solid toRem(1) $secondary-color;
            display: table;
            width: 100%;
            padding: 0;
            height: toRem(40);

            span {
                color: $primary-color;
                display: table-cell;
                text-align: left;
                padding: 0 toRem(15);
                vertical-align: middle;
            }

            i {
                color: $primary-lighten-color;
                @extend .separator-left;
                text-align: center;
                width: toRem(40);
                vertical-align: middle;
                height: 100%;
                display: table-cell;
                padding: 0;
                @include font-size(8);
            }

            &.js--open {
                i {
                    background: $light-gray-color;

                    &:before {
                        content: '\e60c';
                    }
                }
            }
        }
    }

    .dropblock--tab {
        display: none;

        &.js--open {
            display: inline-block;
            margin-bottom: toRem(15);
        }

        .institutions-list {
            max-height: toRem(240);
            overflow-y: auto;
            padding: 0;
        }

        li {
            width: 100%;
            margin-bottom: toRem(2);
            text-align: left;
            padding: 0 toRem(8);

            a {
                border: 0;
                padding: toRem(5) toRem(15);
                @include font-size(12);
            }

            &:last-child {
                border: 0;
            }

            .search-input {
                background: $light-gray-color;
                margin: toRem(12) 0;
                font-style: italic;
            }

            i.icon-Icon_Search {
                color: $primary-color;
                @include font-size(25);
                font-weight: 300;
                margin: 0;
                padding: 0;
                position: absolute;
                top: toRem(18);
                right: toRem(12);

                &:before {
                    color: $primary-color;
                }
            }

            &.placeholder_title {
                display: none;
            }
        }

        .search-box-li {
            border-bottom: solid toRem(1) $secondary-color;
            &.active {
                display: block;
            }
        }

        .search-no-results {
            display: none;

            a {
                pointer-events: none;
            }
        }
    }

    .dropBlock__link {
        span {
            font-weight: 300;
            @include font-size(13);
        }
    }

    .tab__nav {
        &.dropblock--tab {
            border: solid toRem(1) $secondary-color;
            box-shadow: none;
            border-top: 0;
        }
    }

    .page__header {
        @include font-size(12);
        font-weight: 600;
        margin-bottom: toRem(6);
    }
}
