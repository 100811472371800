.sushi-table {
    &__label {
        color: $primary-color;
        @include font-size(14);
    }

    &__value {
        font-weight: 300;
        @include font-size(14);
        color: $primary-color;
    }

    tr {
        .table-responsive & {
            padding: 0;
        }

        &:nth-child(odd) {
            background: $light-gray-color;
        }

        @extend .responsive-table__row;
    }

    td {
        .profile-main-container & {
            border: none;

            &:before {
                content: '';
            }

            @include media('<tablet') {
                padding: toRem(11);
            }
        }
    }
}

.usage-reports {
    &__delivery-list {
        padding: 0;
        padding-left: toRem(18);

        .section__header {
            .profile-main-container & {
                @include font-size(16);
                margin-top: 0;
            }
        }
    }
}

#reports,
#formats,
#recipients {
    .label-txt {
        &:before {
            top: 0;
            margin-top: 0;
        }
    }
}

.account-tab {
    .tab__pane {
        form {
            input[type='submit'] {
                cursor: pointer;
                background: $white;

                &:hover {
                    background: $secondary-lighten-color;
                }
            }
        }

        #institutionUsageReport {
            display: none;
        }

        #institutionUsageReportCounter5 div + p {
            display: none;
        }

        .button-styling {
            padding: toRem(10) toRem(8);
            border: toRem(1) solid #ccc;
            background: inherit;
            width: 100%;
            @include font-size(15);
            margin-bottom: toRem(20);
            line-height: 1rem;
            color: $accountTab-button;
            display: block;
            text-align: center;
            cursor: pointer;

            &:hover {
                background: $secondary-lighten-color;
            }
        }

        .insightsCounter4 p {
            text-align: center;
        }
    }
}

#institutionUsageReport {
    td {
        & + td {
            font-weight: 600;
        }
    }
}
