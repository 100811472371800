.entitlements-wrapper {
    background: $white;

    form {
        background: $light-gray-color;
        position: relative;
        padding: toRem(12);
        margin-top: toRem(10);
        border-bottom: solid toRem(2) $secondary-color;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .input-group {
            margin: 0;
            width: 82%;
            display: inline-block;
            vertical-align: middle;
            @include media('<=large') {
                width: 77%;
            }
            @include media('<=tablet') {
                width: 100%;
            }
            input {
                border: solid toRem(1) $secondary-color;
                border-radius: toRem(2);
                background: $white;
                padding: toRem(20) toRem(40) toRem(20) toRem(15);
            }
        }

        .account__sort {
            width: 100%;
            .left-side-label {
                text-align: left;
                width: 100%;

                label {
                    color: $primary-lighten-color;
                    font-weight: normal;
                    @include font-size(12);
                    margin-left: toRem(2);
                    &::before {
                        display: inline-block;
                        content: '\e968';
                        font-family: icomoon, sans-serif;
                        color: $primary-color;
                        @include font-size(18);
                        vertical-align: middle;
                        margin-right: toRem(5);
                        margin-left: toRem(5);
                    }
                }

                select {
                    border: 0;
                    padding: 0;
                    margin: toRem(12) 0;
                    max-width: toRem(74);
                    @include font-size(12);
                    color: $primary-color;
                    padding-right: toRem(28);
                    line-height: toRem(25);
                    @include media('<=phone') {
                        padding-left: toRem(5);
                    }
                }
            }
        }

        .quick-search__button {
            background: transparent;
            position: absolute;
            top: toRem(20);
            font-weight: 600;
            @include font-size(22);
            right: toRem(180);
            @include media('<=large') {
                right: toRem(165);
            }
            @include media('<=tablet') {
                left: auto;
                right: toRem(20);
            }
        }

        .border-bottom {
            margin-left: auto;
            padding: 0;
            border: 0;
            display: inline-block;
            vertical-align: middle;
        }
    }

    h3 {
        font-size: toRem(14);
        padding: toRem(20);
        font-weight: 400;
    }

    @include media('<tablet') {
        .table-responsive {
            td {
                display: flex !important;
                align-items: center;

                a,
                p {
                    margin-left: auto;
                }

                &:before {
                    margin-right: toRem(20);
                }
            }
        }
    }

    #accessEntitlementsDownloadInfo {
        a {
            text-decoration: underline;
            font-weight: 600;
        }
    }
}
