.header-breadcrumb {
    margin-top: toRem(13);
}

.article {
    &__breadcrumbs {
        font-style: italic;
        color: $primary-lighten-color;
        @include media('<tablet') {
            display: none;
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                display: inline;

                &:before {
                    display: inline-block;
                    content: '> ';
                    position: relative;
                    left: 0;
                    margin: toRem(5);
                }

                &:last-child {
                    a {
                        color: $text-onLight;
                    }
                }

                &:first-child {
                    &:before {
                        content: '';
                        margin: 0;
                    }
                }
            }
        }
    }

    &__tocHeading {
        @include font-size(14);
        font-weight: 500;
    }
}

.breadcrumb-hidden {
    height: 0;
    margin: 0;
    visibility: hidden;
}

.enable-mobile__breadcrumbs {
    @include media('<tablet') {
        .article {
            &__breadcrumbs {
                border-bottom: toRem(1) solid $secondary-color;
                padding: toRem(10) 0;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                /* autoprefixer: off */
                -webkit-box-orient: vertical;
                /* autoprefixer: on */

                .article__tocHeading {
                    @include font-size(20);

                    &:first-child {
                        display: none;
                    }

                    &:nth-child(2) {
                        color: $primary-lighten-color;

                        &:before {
                            display: none;
                        }
                    }

                    &:last-child {
                        &:before {
                            color: $primary-lighten-color;
                        }
                    }
                }
            }
        }
    }
}
