.issue-item {
    @include font-size(14);
    margin-top: toRem(15);
    @include media('<phone') {
        margin-top: toRem(5);
    }
    padding: toRem(15);
    box-shadow: 0 toRem(5) toRem(8) rgba($black, 0.1);
    background: $white;
    word-break: break-word;

    &--list {
        box-shadow: none;
        margin-top: 0;
        padding-top: 0;
        padding-left: 0;
        @include media('<desktop') {
            padding: 0;
            border-top: toRem(1) solid $secondary-border-color;
            padding-top: toRem(15);
        }

        .issue-item {
            &__citation {
                width: 100%;
            }

            &__content {
                width: 100%;
            }
        }
    }

    @include media('<large') {
        .get-access {
            padding: toRem(7);
        }
    }

    @include media('<tablet') {
        padding: toRem(15) toRem(10);

        .get-access {
            margin: 0 0 0 toRem(3);
        }

        .loa {
            @include font-size(14);
        }
    }

    &-container {
        @include media('<tablet') {
            display: block;
            margin: toRem(10) 0;
            box-shadow: 0 0 toRem(2) toRem(2) rgba($black, 0.1);
        }
    }

    &-checkbox-container {
        position: relative;
        top: toRem(25);

        @include media('<tablet') {
            display: none;
        }

        + .issue-item {
            margin-left: toRem(30);
            @include media('<tablet') {
                margin-left: 0;
            }
        }
    }

    &__title {
        color: $blue-color;
        font-weight: 500;
        font-family: $tertiary-font-family;
        margin-bottom: toRem(10);
        &-suffix {
            color: $primary-color;
        }
        @include media('<tablet') {
            @include font-size(14);
        }
    }

    &__abstract {
        margin-bottom: toRem(10);
        @include media('<tablet') {
            @include font-size(12);
        }
    }

    &__citation {
        @include media('>=desktop') {
            width: toRem(140);
            display: inline-block;
            margin-bottom: 0;
        }
        margin-bottom: toRem(15);
        vertical-align: top;
        @include font-size(12);
        text-transform: uppercase;

        @include media('<desktop') {
            margin-bottom: toRem(8);
            display: flex;
            flex-direction: column;
        }

        .toc-heading {
            font-weight: 600;
        }

        @include media('<desktop') {
            .comma-before {
                &:before {
                    content: ',';
                    padding-right: toRem(2);
                }
            }
        }

        .issue-heading {
            margin-right: toRem(10);
            font-weight: 600;
        }

        .publisher-badge {
            margin-top: toRem(8);

            img {
                padding-left: toRem(2);

                @include media('<desktop') {
                    padding-left: toRem(4);
                    padding-right: toRem(7);
                }
            }
        }

        @media only screen and (min-device-width: toRem(768)) and (max-device-width: toRem(1024)) and (orientation: landscape) {
            width: 100%;
            display: block;
            margin-bottom: toRem(22);
            > div {
                display: inline-block;
            }
            .publisher-badge {
                float: right;
                margin-top: 0;
                img {
                    padding-left: toRem(4);
                    padding-right: toRem(5);
                }
            }
            .issue-heading {
                + .publisher-badge {
                    margin-top: toRem(-7);
                }
            }
        }
    }

    &__content {
        @include media('>=desktop') {
            display: inline-block;
            width: calc(100% - #{toRem(140)});
            float: right;
        }
        @media only screen and (min-device-width: toRem(768)) and (max-device-width: toRem(1024)) and (orientation: landscape) {
            display: block;
            width: 100%;
            float: none;
        }
    }

    &__cover-image {
        max-width: toRem(100);
        margin-right: toRem(25);
        display: inline-block;
        vertical-align: top;
        width: 100%;

        + .issue-item__content-right {
            display: inline-block;
            width: calc(100% - #{toRem(130)});
            @include media('<desktop') {
                width: 100%;
            }
            @media only screen and (min-device-width: toRem(768)) and (max-device-width: toRem(1024)) and (orientation: landscape) {
                display: block;
                width: 100%;
            }
        }

        @include media('<desktop') {
            display: none;
        }

        @media only screen and (min-device-width: toRem(768)) and (max-device-width: toRem(1024)) and (orientation: landscape) {
            display: none;
        }

        img {
            border: toRem(1) solid $secondary-lighten-color;
        }
    }

    &__detail {
        color: $tertiary-bg-color;
        margin: toRem(10) 0;

        span {
            @include media('<tablet', '>=phone') {
                display: inline-block;
            }
        }

        .video {
            &__image {
                position: relative;
                overflow: hidden;

                @include media('>=large') {
                    display: inline-block;
                    width: toRem(250);
                }

                img {
                    @include media('<phone') {
                        width: 100%;
                    }
                }

                .flowplayer {
                    z-index: $index-1;
                }

                .overlay-video__trigger {
                    left: 0;
                    right: 0;

                    i {
                        @include font-size(40);
                    }
                }
                + .video__details {
                    @include media('>=large') {
                        display: inline-block;
                        width: calc(100% - #{toRem(270)});
                        float: right;
                    }
                }
                .cloudflare-stream-container {
                    .cloudflare-stream-player {
                        height: toRem(140);
                    }
                }
            }

            &__details {
                &-title {
                    color: $text-onLight;

                    .bold {
                        padding-right: toRem(3);
                    }
                }

                &-abstract {
                    margin-bottom: toRem(20);
                    color: $primary-color;
                }

                .issue-item__title {
                    @include media('<large') {
                        margin-top: toRem(15);
                    }
                }
            }
        }

        .label {
            color: $black;
            display: inline-block;
            @include font-size(14);
            margin-bottom: 0;
            margin-right: toRem(6);
        }
    }

    &__footer {
        @include media('<tablet') {
            position: relative;
            padding-top: toRem(10);
            border-top: toRem(1) solid $secondary-border-color;
        }

        &-links {
            @include media('<phone') {
                position: relative;
            }
            .rlist--inline {
                display: inline-block;

                li {
                    vertical-align: middle;
                    position: relative;
                    @include media('<phone') {
                        position: static;
                    }
                    .sfxLink {
                        @include media('<desktop') {
                            display: block;
                        }
                        img {
                            vertical-align: middle;
                        }
                    }
                }

                &.dot-separator {
                    &:before {
                        color: $tertiary-bg-color;
                        @include media('<tablet') {
                            padding: 0;
                        }
                    }

                    &.left {
                        &:before {
                            //@include media('>=large') {
                            display: none;
                            //}
                        }
                    }
                }
            }

            &-video {
                .addtional-material {
                    color: $blue-color;
                    margin-bottom: toRem(15);
                    @include media('>=large') {
                        margin-left: toRem(180);
                        display: inline-block;
                    }

                    i {
                        padding-right: toRem(5);
                    }
                }

                .rlist--inline {
                    @include media('>=large') {
                        float: right;
                    }

                    li {
                        &:not(:first-child) {
                            margin-left: toRem(5);
                        }
                    }

                    .btn {
                        @include font-size(18);
                        padding: toRem(6) toRem(10);

                        i {
                            padding: 0;
                        }
                    }
                }

                .btn {
                    &.disabled {
                        background: $primary-lighten-color;
                    }
                }
            }

            .more-formats {
                box-shadow: 0 0 toRem(2) toRem(2) rgba($black, 0.1);
                background-color: $white;
                width: toRem(220);
                padding: toRem(10);

                li {
                    padding: toRem(5) 0;

                    i {
                        margin-right: toRem(8);
                        color: $primary-lighten-color;
                        vertical-align: middle;
                        &.icon-eReader {
                            @include font-size(17);
                        }
                    }
                }

                @include media('<1370px') {
                    position: absolute;
                    left: auto;
                    right: toRem(-86);
                }
            }
        }

        &-info {
            > ul {
                > li {
                    @include media('>phone') {
                        border-right: toRem(1) solid $secondary-border-color;
                        margin-right: toRem(7);
                    }
                    @include media('>=tablet', '<desktop') {
                        margin-right: toRem(5);
                    }

                    vertical-align: text-top;

                    &:last-child {
                        border: 0;
                        margin-right: 0;
                        @include media('>=tablet', '<desktop') {
                            //margin-right: toRem(0);
                        }
                    }
                }
            }

            .tooltip {
                span {
                    @include media('<large') {
                        display: block;
                    }
                }

                &:hover,
                &:focus,
                &.show {
                    font-weight: 600;

                    .tooltip__body,
                    & ~ .tooltip__body {
                        line-height: 1;
                        top: 100%;
                        &.footer-attach-holder {
                            @include media('<phone') {
                                top: toRem(30);
                            }
                        }

                        @include font-size(12);

                        .left-bordered-title:before {
                            background: $tertiary-bg-color;
                        }

                        .bottom-bordered-title {
                            border-bottom: toRem(1) solid $secondary-border-color;
                            padding-bottom: toRem(10);
                        }

                        .citation,
                        .metric {
                            border-top: toRem(1) solid $secondary-border-color;
                            padding: toRem(10);
                        }

                        .info {
                            /* !important to override Axel style */
                            padding: toRem(8) toRem(10) !important;
                            font-weight: normal;
                        }

                        .bold {
                            padding-left: toRem(2);
                            display: inline;
                        }

                        .more-link {
                            a {
                                i {
                                    vertical-align: middle;
                                }
                            }
                        }
                        a {
                            text-decoration: none;
                        }
                    }
                    & ~ .tooltip__body {
                        top: unset;
                        left: unset;
                    }
                }
            }

            .tooltip,
            .tooltip__body {
                .left-bordered-title {
                    color: $tertiary-bg-color;
                    border-color: $tertiary-bg-color;
                    border-width: toRem(4);
                    @include font-size(12);
                    margin: toRem(10) 0;
                }

                .sub-material-icon {
                    .icon-attach_file {
                        @include font-size(21);
                        color: $primary-lighten-color;
                        position: relative;
                    }

                    span {
                        position: absolute;
                        top: toRem(-10);
                        right: 0;
                        background: $blue-color;
                        color: $white;
                        border-radius: 50%;
                        padding: toRem(2) toRem(6);
                        text-align: center;
                        @include font-size(12);
                        opacity: 0.7;
                    }
                }
                .arrow {
                    left: toRem(1);

                    &.arrow--left {
                        left: toRem(4);
                    }
                }
            }

            .rlist--inline {
                cursor: default;

                &.padding-0 {
                    li {
                        i {
                            @include media('<phone') {
                                padding-right: 0;
                            }
                        }
                    }
                }
            }

            li {
                &:not(:last-child) {
                    padding-right: toRem(6);

                    @include media('<large') {
                        border-right: 0;
                    }
                    @include media('>=tablet', '<desktop') {
                        padding-right: toRem(5);
                    }

                    @include media('<tablet') {
                        padding-right: toRem(5);
                    }
                }

                i {
                    padding-right: toRem(6);
                    vertical-align: sub;
                }

                @include media('<tablet') {
                    text-align: center;
                }
            }

            .citation {
                color: $blue-color;
            }

            .metric {
                color: $purple-color;
            }

            .accordion {
                .icon-format_color_text {
                    @include font-size(19);
                    font-weight: normal;
                    padding-right: toRem(6);
                    margin-left: 0;
                }
                .icon-section_arrow_d {
                    vertical-align: middle;
                }
            }
        }

        @include media('<large') {
            border-top: none;
            &-wrapper {
                position: relative;
            }
            &-info {
                width: 100%;
                border-bottom: toRem(1) solid $secondary-border-color;
                margin-bottom: toRem(10);

                .attach-holder {
                    float: right;
                    border-left: toRem(1) solid $secondary-border-color;
                    margin-top: toRem(5);
                    margin-bottom: toRem(5);

                    .tooltip {
                        .tooltip__body {
                            left: auto;
                            right: 0;
                        }

                        .arrow {
                            left: auto;
                            right: toRem(16);
                        }
                    }
                }
            }
            &-links {
                min-height: toRem(38);
                width: 100%;
                text-align: right;

                .modal__dialog {
                    text-align: left;
                }
            }
            .metric-holder {
                position: absolute;
                bottom: 0;
            }
        }

        .accordion {
            em {
                @extend .highlight;
            }
        }
    }

    .badges {
        margin-top: toRem(8);

        @media only screen and (max-device-width: toRem(1024)) {
            margin-top: 0;
            position: absolute;
            right: toRem(20);
        }
    }

    .publisher-badge {
        + .badges {
            margin-top: 0;
            @include media('<desktop') {
                margin-top: toRem(-20);
            }
        }
    }

    .img-badget {
        margin-left: 0;

        img {
            width: toRem(24);
        }

        @media only screen and (max-device-width: toRem(1024)) {
            display: inline-block;
            padding-right: toRem(5);
        }
    }

    &__chapter-label {
        background: $primary-lighten-color;
        color: $white;
        padding: toRem(3) toRem(6);
        border-radius: toRem(24);
        margin-right: toRem(10);
    }

    &--stack {
        margin: 0;
        padding: toRem(10) 0;
        @include media('<tablet') {
            border-top: toRem(1) solid $secondary-border-color;
        }

        .issue-heading {
            display: inline;
        }

        .issue-item {
            &__citation {
                width: auto;
                display: block;
            }

            &__content {
                float: none;
                width: 100%;
                display: block;
            }
        }
    }

    .search__item & {
        .single_highlight_class {
            @extend .highlight;
        }

        [class*='-text'] {
            margin-bottom: 1rem;
            margin-top: 1rem;
        }
    }

    .accordion__content {
        display: none;
        margin-bottom: toRem(15);
    }

    &__also-published {
        background: $light-gray-color;
        padding: toRem(15);
        margin-bottom: toRem(20);

        h6 {
            margin-bottom: toRem(6);
        }

        span {
            color: $primary-lighten-color;
        }
    }

    .authorizerBtnUl {
        &:after {
            content: '•';
            padding-right: toRem(5);
            padding-left: toRem(5);
            font-weight: 600;
            @include font-size(19);
            vertical-align: middle;
            color: $tertiary-bg-color;
        }
        @include media('<phone') {
            display: none;
        }

        @include media('<large') {
            .auth-span {
                display: none;
            }
        }

        @include media('>=tablet', '<large') {
            i {
                padding: 0;
            }
        }
        @include media('<tablet') {
            i {
                padding-left: toRem(5);
            }
        }
    }

    .authorizerBtn {
        vertical-align: middle;
        span,
        i {
            color: $primary-color;
        }
    }

    &[data-rel='videos'] {
        @media only screen and (min-device-width: toRem(1025)) {
            .issue-item__citation {
                width: toRem(80);
            }
        }

        @include media('>=desktop') {
            .issue-item__content {
                width: calc(100% - #{toRem(80)});
            }
        }
    }
    .issue-item__citation {
        .publisher-badge {
            @include media('<desktop') {
                float: right;
                margin-top: toRem(-45);
            }
        }

        @include media('<desktop') {
            .issue-heading {
                + .publisher-badge {
                    margin-top: toRem(-16);
                }
            }
        }

        @media only screen and (min-device-width: toRem(992)) and (max-device-width: toRem(1024)) and (orientation: landscape) {
            .issue-heading {
                display: block;
            }

            .bookPubDate ~ .search-badges:not(.clonedBadges) {
                margin-top: toRem(-23);
            }
        }

        @media only screen and (max-device-width: toRem(991)) {
            .search-badges {
                &.hidden-sm.hidden-xs {
                    float: right;
                }
            }
            .access-icon ~ .search-badges:not(.clonedBadges) {
                margin-top: toRem(-37);
            }
        }
        @media only screen and (max-device-width: toRem(1024)) and (orientation: landscape) {
            .search-badges {
                &.hidden-sm.hidden-xs {
                    float: right;
                }
            }
        }

        @include media('<desktop') {
            .search-badges {
                display: block;
                margin-top: toRem(-22);
                padding-bottom: toRem(18);

                &.hidden-sm.hidden-xs {
                    display: none;
                }

                .publisher-badge {
                    margin-top: toRem(-19);
                }
            }

            .access-icon ~ .clonedBadges {
                margin-top: toRem(-37);
                padding-bottom: toRem(34);
            }
        }
        & > .publisher-badge {
            @include media('<desktop') {
                margin-top: 0;
            }
        }
        .access-icon {
            display: block;
        }
    }
}

.item-results {
    &__buttons {
        display: inline-block;
        visibility: hidden;
        padding: toRem(6) 0;

        @include media('>=tablet', '<large') {
            display: none;
        }

        .btn {
            margin-left: 0;
            color: $secondary-text-color;
            //padding: toRem(8);

            @include media('>=tablet', '<large') {
                width: toRem(32);
                height: toRem(32);
                padding: 0;
                text-align: center;
                padding-top: toRem(6);
            }

            span {
                color: $primary-color;
                margin-left: toRem(10);

                @include media('>=tablet', '<large') {
                    display: none;
                }
            }

            i {
                vertical-align: middle;
                @include media('>=tablet', '<large') {
                    padding: 0;
                }
            }

            ~ .btn {
                margin-left: toRem(8);
            }

            @include media('<tablet') {
                @include font-size(13);

                span {
                    margin-left: toRem(2);
                }

                i {
                    padding: 0;
                }

                ~ .btn {
                    margin-left: toRem(3);
                }
            }
        }

        &.visible {
            @include media('>=tablet', '<large') {
                display: inline-block;
            }
        }
    }

    &__checkbox {
        display: inline-block;
        vertical-align: middle;
    }

    &__msg {
        display: inline-block;
        color: $primary-color;
    }

    &__authorizerbtn {
        padding: toRem(12) 0 toRem(4) 0;
        text-align: right;

        @include media('<tablet') {
            display: none;
        }

        .authorizerBtnBatch {
            margin-right: toRem(10);
        }
    }
}

.description-holder {
    .icon-Icon_About-Article.icon-Icon_About-Article {
        @include font-size(16);
        font-weight: 400;
    }

    i {
        .issue-item__footer & {
            vertical-align: middle;
        }
    }

    .accordion__control {
        margin: 0;
    }
}

.creationDate-holder {
    @include font-size(13);
    color: $primary-lighten-color;
}

.citation {
    .tooltip {
        &:hover,
        &:focus {
            .tooltip__body {
                top: toRem(30);

                @include media('<large') {
                    top: toRem(50);
                }
                @include media('<tablet') {
                    top: toRem(30);
                }
            }
        }
    }
}

@-moz-document url-prefix() {
    .search-result {
        .truncate_container {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;

            [class*='-text'] {
                margin: 0;
            }

            .truncate-text-css {
                display: block;
            }
        }
    }
}

.bookPubDate,
.journalDate,
.ppubDate {
    @include font-size(12);
    color: $note-text;
    font-weight: 400;
    text-transform: capitalize;
}

.custom-dot {
    padding-right: toRem(5);
    padding-left: toRem(5);
    font-weight: 600;
    font-size: 1.188rem;
    vertical-align: middle;
}

.tooltip__body {
    .info {
        display: block;
    }
}
.custom-publishedIn {
    .dot-separator:first-of-type {
        &:before {
            content: '';
            padding: 0;
            margin: 0;
        }
    }
}
