.article__references {
    margin: toRem(25) 0 toRem(45);

    .colored-block__title {
        margin-bottom: toRem(15);
    }
}

.references {
    &__list {
        @include font-size(14);
        font-weight: 300;
        line-height: toRem(20);
        color: $black;
        position: relative;
        > li {
            counter-increment: item;
            margin-top: toRem(8);
            @extend .separated-block--dashed--bottom;

            .article__references & {
                text-align: left;
            }

            .references__note {
                pub-id {
                    display: inline-block;
                }
            }
        }
        li:not(.references__item) {
            a {
                display: flex;
                align-items: flex-start;
                .references__acm_badge {
                    margin-top: toRem(3);
                    width: toRem(20);
                    height: toRem(20);
                }
                > div {
                    margin-left: toRem(4);
                }
            }
        }
        .sfxLink {
            @extend .references__suffix;
        }
    }

    &__numeric {
        > li {
            &:before {
                font-weight: 600;
                text-align: left;
                content: counter(item) '.';
                padding-right: toRem(3);
                float: left;
            }
        }
    }

    &__item {
        .label {
            display: inline-block;
            color: $black;
            margin: 0;
            font-weight: 600;
            text-align: left;
            padding-right: toRem(3);
            @include font-size(14);
        }
    }

    &__acm_badge {
        vertical-align: bottom;
        margin: 0 toRem(3);
    }

    &__go-to-ref {
        margin-top: toRem(3);
        color: $blue-color;
    }
    &__note {
        .prefixLetter {
            background: $highlight-color;
            border-radius: 50%;
            width: toRem(20);
            height: toRem(20);
            display: inline-block;
            text-align: center;
            font-weight: 600;
            margin-right: toRem(3);
            vertical-align: middle;
            sup {
                top: toRem(-1);
            }
        }
    }

    &__source {
        font-style: italic;
    }

    &__article-title {
        font-family: $tertiary-font-family;
    }

    &__suffix {
        color: $blue-color;
        text-decoration: underline;
        display: inline-block;
        line-height: 0.875rem;
        font-weight: 400;
        vertical-align: middle;
        padding: 0 toRem(5);
        &:not(:last-child) {
            padding-right: toRem(10);
            border-right: toRem(1) solid $secondary-color;
            margin-right: toRem(10);

            //@include media('<=phone') {
            //    margin-bottom: toRem(15);
            //    padding-right: toRem(15);
            //    margin-right: toRem(15);
            //}
        }

        .icon-subdirectory_arrow_left {
            @include font-size(18);
            vertical-align: middle;
            line-height: 0.875rem;
        }
    }
    &__btn-holder {
        margin-top: toRem(32);
    }

    &__links {
        margin-top: toRem(5);

        .references__go-to-ref {
            padding-right: toRem(6);
            i {
                margin-right: toRem(5);
                vertical-align: middle;
            }

            + .references__go-to-ref {
                border-left: solid toRem(1) $secondary-color;
                padding-left: toRem(6);
            }
        }

        .references__link-dropdown {
            display: inline-block;

            .reference-dropdownList {
                background-color: $white;
                padding: toRem(16);

                li {
                    padding-bottom: toRem(10);

                    &:last-child {
                        padding-bottom: 0;
                    }

                    a {
                        color: $blue-color;
                        i {
                            margin-right: toRem(5);
                            vertical-align: middle;
                        }
                    }
                }
            }

            .references-drop-btn {
                background-color: $white;
                color: $primary-color;
                border-radius: toRem(40);
                border-color: $secondary-color;
                padding: toRem(8) toRem(17);

                &.js--open {
                    background-color: $light-gray-color;

                    i.icon-arrow-down-bold {
                        &:before {
                            transform: rotate(180deg);
                            transition: 0.3s;
                        }
                    }
                }

                i.icon-arrow-down-bold {
                    color: $primary-color;
                    @include font-size(8);
                    padding: 0;
                    margin-left: toRem(6);
                    font-weight: 600;
                    vertical-align: middle;

                    &:before {
                        transition: 0.3s;
                    }
                }
            }
        }
    }
}

.pill-references__content {
    .references {
        &__links {
            .references__link-dropdown {
                .reference-dropdownList {
                    display: none !important;
                    box-shadow: 0 toRem(2) toRem(10) rgba(82, 82, 82, 0.43);
                    position: absolute;

                    &.js--open {
                        display: block !important;
                    }

                    li {
                        a {
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Springer Feed styles start */

.springer {
    .references {
        &__source {
            &::after {
                content: '\00a0';
            }
        }
        &__article-title {
            &::after {
                content: '.';
                margin-right: toRem(5);
            }
        }
        &__year {
            &::before {
                content: '(';
            }
            &::after {
                content: ')';
                margin-right: toRem(5);
            }
        }

        &__volume {
            font-weight: 500;
            + *:not(.references__issue) {
                &::before {
                    content: ', ';
                }
            }
        }

        &__issue {
            &::before {
                content: '(';
            }
            &::after {
                content: '),';
                margin-right: toRem(5);
            }
        }

        &__pub-id {
            &::before {
                content: ' ';
            }
            &.bibcode,
            &.amsid {
                display: none;
            }
        }

        &__fpage {
            margin-right: toRem(5);
        }

        &__lpage {
            &::before {
                content: '-';
                margin-right: toRem(5);
            }
            &::after {
                content: '.';
                margin-right: toRem(5);
            }
        }

        &__authors {
            .references__name {
                &:not(:last-child) {
                    &:after {
                        content: ', ';
                    }
                }
                &:last-child {
                    &:after {
                        content: ': ';
                    }
                }
            }
        }
    }

    .comment {
        &::before {
            content: ' ';
        }
    }

    .NLM_publisher-loc {
        &:after {
            content: ', ';
        }
    }
}

/* Springer Feed styles ends */
