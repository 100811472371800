[class^='badge-'] {
    background: $secondary-bg-color;
    display: inline-block;
    font-size: toRem(14);
    margin: 0 toRem(2) toRem(4);
    padding: toRem(5) toRem(10);

    &.red {
        @include badge-colors($dark-red-color, $text-onDark);
    }

    &.blue {
        @include badge-colors($dim-blue-color, $text-onDark);
    }

    &.purple {
        @include badge-colors($light-purple-color, $text-onDark);
    }
}

.badge {
    &-rounded-edgs {
        border-radius: toRem(4);
        font-size: toRem(12);
    }
}
