.binder {
    &__quick-search {
        padding: toRem(10) toRem(16);
        @include media('>=tablet') {
            display: flex;
            align-items: center;
        }

        label {
            color: $primary-color;
            margin: 0;
            @include font-size(14);
            margin-right: toRem(10);

            @include media('<tablet') {
                margin-bottom: toRem(10);
            }
        }

        .quickSearchFormContainer {
            flex-grow: 1;
        }

        .quick-search {
            padding: 0;

            &__input {
                height: toRem(40);
                border: toRem(1) solid $secondary-shadow-color;
                @include font-size(14);

                &::-webkit-input-placeholder {
                    //chrome
                    color: $primary-color;
                    @include font-size(14);
                    font-style: normal;
                }
                &::-moz-placeholder {
                    //firefox
                    color: $primary-color;
                    @include font-size(14);
                    font-style: normal;
                }
                &:-ms-input-placeholder {
                    //ie
                    color: $primary-color;
                    @include font-size(14);
                    font-style: normal;
                }
            }

            &__button {
                @include font-size(20);
                top: toRem(4);
            }
        }
    }
}
