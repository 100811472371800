//to be remove css if the auto hide bar disbale on mobile view
.secondary-bar--sticky,
.auto-hide-bar {
    position: fixed;
    width: 100%;
    transition: transform 0.5s;
}

.loi__banner--sticky {
    @include media('<tablet') {
        top: toRem(64) !important;
    }
}
