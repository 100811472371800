// todo: move to variables file
$index-9: 9;

.looker {
    &__filters {
        position: fixed;
        width: 100%;
        z-index: $index-9;

        select {
            background-color: $white;
        }

        .input-group {
            width: 100%;
        }

        &--institution {
            &-wrapper {
                display: grid;
                overflow: hidden;
            }

            &__footer {
                position: absolute;
                bottom: toRem(8);
                display: none;
                width: 100%;
            }

            &__cancel {
                margin-left: auto;
            }
        }

        .tablet-submit-container {
            display: none;
            text-align: right;

            @include media('<desktop') {
                display: block;
            }
        }
    }

    &__reports {
        padding-top: toRem(127);
        @include media('<tablet') {
            padding-top: toRem(212);
        }
    }

    .embed {
        position: absolute;
        height: 100%;
        width: 100%;
        border: none;
    }

    .box {
        display: none;
    }

    .overview {
        display: block;
    }

    .jcf-select.jcf-compact-multiple {
        max-width: 100%;
        background-color: $white;
    }

    .input-group .jcf-select {
        background-color: $white;
        //background-image: url(/specs/products/acm/releasedAssets/images/down-arrow.png), url(/specs/products/acm/releasedAssets/images/input-line.png);
        //background-position: right toRem(12) center, right toRem(38.4) center;
        //padding-right: toRem(38);
        //background-size: auto;
        overflow: hidden;

        .jcf-select-opener {
            position: absolute;
            top: toRem(2);
            right: 0;

            &:before {
                padding-top: 0;
                content: '';
                background-color: $white;
                background-image: url(../../../images/down-arrow.png), url(../../../images/input-line.png);
                background-position: right toRem(12) center, right toRem(38.4) center;
                padding-right: toRem(38);
                background-size: auto;
                background-repeat: no-repeat;
                height: toRem(32);
                width: toRem(40);
                display: block;
            }
        }
    }
    &.is--loading:after {
        content: '';
        background: #ffffffb8;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url(../../../images/loader.gif);
        background-repeat: no-repeat;
        background-position: center center;
    }

    &.scrolled {
        .looker__filters {
            top: toRem(50);
        }
    }
}

.jcf-list .jcf-list-content,
.jcf-scrollable-wrapper {
    width: 100% !important;
}

.jcf-select-looker__filters--institution {
    &.jcf-select {
        display: inline-table;
        padding: 0;

        .jcf-select-text {
            padding: toRem(9) toRem(8) toRem(11);
        }

        &.jcf-drop-active {
            padding-bottom: toRem(50);
            position: absolute;
            overflow: hidden;

            .looker__filters--institution__footer {
                display: flex;
            }
        }
    }

    &.jcf-disabled {
        &.jcf-select {
            .jcf-select-opener:before {
                background-image: none;
            }
        }
    }

    &.jcf-select-drop {
        position: static;
        width: auto !important;
    }

    .jcf-select-drop-content {
        border: toRem(1) solid #ccc;
        border-right: 0;
        border-left: 0;
    }

    .alert-msg--warning {
        margin: 0;
        width: calc(100% - #{toRem(160)});
    }

    .looker__filters--institution__submit {
        @include media('<desktop') {
            display: none;
        }
    }
}
