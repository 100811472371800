.saveToBinders-list {
    background: $white;
    padding: toRem(20);
    min-width: toRem(240);
    //display: none;
    padding-bottom: 0;

    ul {
        max-height: toRem(210);
    }

    .input-group {
        margin-bottom: toRem(17);
        margin-right: toRem(5);

        .label-txt {
            @include font-size(14);
            font-weight: 400;
            display: flex;
        }
    }

    &__binder-name {
        width: calc(100% - #{toRem(50)});
        flex-grow: 1;
    }

    .binder__create {
        @include font-size(14);
        border-top: toRem(1) solid $secondary-border-color;
        margin: 0 toRem(-20);
        padding: toRem(14) toRem(20) 0;
        display: block;
        background: $light-gray-color;
        padding-bottom: toRem(12);
        min-height: toRem(46);

        a {
            display: block;

            i {
                color: $primary-lighten-color;
                margin-right: toRem(10);
                vertical-align: middle;
            }
        }

        &-btns-container {
            width: 100%;
        }

        &-cancel {
            border: none;
            background: none;
            padding-top: toRem(11);

            &:hover {
                background: transparent;
            }
        }

        &-submit {
            width: toRem(130);
        }

        &-mini-form {
            display: none;
        }

        [data-action] {
            font-size: inherit;
        }
    }

    .modal & {
        padding: 0;
        height: toRem(330);

        .binder-list-alerts {
            min-height: toRem(30);
        }

        h5 {
            @include font-size(22);
            text-align: left;
            font-weight: 600;
            margin-bottom: 0;
        }
    }

    .sb-content {
        max-height: toRem(210) !important;
        word-break: break-word;
    }
}

#saveToBinders-popup {
    .modal__dialog {
        padding-bottom: 0;
    }

    .modal__header {
        z-index: $index-2;
        position: absolute;
        right: toRem(35);
        top: toRem(17);
    }

    .binder__create {
        margin: 0 toRem(-35);
        padding: toRem(20) toRem(35) 0;
        padding-bottom: toRem(20);
        min-height: toRem(60);
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .saveToBinders-list__container {
        max-height: toRem(200);
    }
}

.saveToBinders {
    + [data-db-target-of] {
        top: toRem(35);
        left: 0;
        right: 0;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: $index-10;
        @include media('<=1450px') {
            left: auto;
            right: 0;
            transform: none;
        }

        @include media('<phone') {
            left: 50%;
            margin-left: toRem(-120);
        }

        &.js--open {
            display: flex;
            box-shadow: none;

            .saveToBinders-list {
                box-shadow: 0 toRem(2) toRem(10) rgba(82, 82, 82, 0.43);
            }
        }
    }

    .icon-add-folder {
        font-size: toRem(14);
    }
}
