.linkResolverWidget {
    margin-bottom: toRem(60);

    &__disable-form {
        float: right;
    }

    &__update-btn {
        float: right;
    }
}

.link_resolver_form {
    input {
        margin-bottom: toRem(10);
    }

    fieldset {
        margin-bottom: toRem(30);
    }

    .error_msg {
        margin: 0;
        font-size: toRem(12);
    }

    .jcf-file {
        @include media('>=phone') {
            margin-left: toRem(30);
        }
    }

    .urlText-input {
        @include media('>=phone') {
            margin-left: toRem(25);
            margin-top: toRem(12);
        }
    }

    .label-txt {
        &:before {
            margin: toRem(0);
            margin-right: toRem(8);
        }
    }

    .upload-image-note {
        font-weight: 200;
        @include font-size(14);
        margin-top: toRem(30);
    }

    .uploadImageButton {
        border-bottom: solid toRem(1) $secondary-color;
        padding-bottom: toRem(20);

        .label {
            margin-bottom: toRem(20);
        }
    }

    .label {
        label {
            @include font-size(18);
        }
    }

    .imagebutton-input {
        .error_msg {
            margin-left: toRem(28);
            padding-top: toRem(5);
        }
    }
}

.jcf-file {
    .jcf-upload-button {
        border-left: toRem(1) solid rgba(217, 217, 217, 0.56);
    }
}
