.institution {
    padding: 0;
    color: $text-onDark;
    border-right: none;
    @include font-size(10);
    @include media('>=desktop') {
        border-left: toRem(1) solid $primary-border-color;
        padding: toRem(15) 0 toRem(11) toRem(12);
    }

    > a {
        display: inline-block;
    }
    &__name,
    &-info-wrapper {
        display: inline-block;
        font-weight: 500;
        padding-left: toRem(5);
        @include media('<large') {
            padding-left: 0;
            vertical-align: middle;
        }
    }
    &-info-wrapper {
        @include media('>=large') {
            padding-top: toRem(10);
            padding-bottom: toRem(10);
        }
    }
    &__name {
        @include media('<large') {
            display: block;
            clear: both;
            max-width: toRem(200);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__image {
        display: inline-block;
        vertical-align: middle;
        max-height: toRem(35);
        padding-left: toRem(8);
        float: right;
        @include media('<large') {
            max-width: toRem(60);
            + .institution-info-wrapper .institution__name {
                max-width: toRem(140);
            }
        }
    }
    &-wrapper:not(:empty) {
        display: inline-block;
        @include media('<desktop') {
            max-width: toRem(200);
        }
    }
}
