.remote_institutional_access {
    .institution_part {
        vertical-align: middle;
        width: calc(100% - #{toRem(40)});
    }
    .institution_name {
        font-weight: normal;
        @include font-size(14);
    }
    .renew_part {
        width: auto;
        vertical-align: middle;
        @include media('<tablet') {
            width: 100%;
        }
        .renew_message {
            top: 90%;
            left: auto;
            right: 0;
        }
        .btn {
            width: toRem(139);
            margin: 0;
            text-align: center;
            left: auto;
            @include media('<tablet') {
                width: 100%;
            }
        }
        .renew_verification_message:empty {
            display: none;
        }
    }
    .icons_part {
        width: toRem(30);
        text-align: center;
        vertical-align: middle;
    }
    .add_affiliation {
        width: 100%;
        margin-top: toRem(20);
    }
    a {
        &.btn[title]:hover::after {
            content: attr(title);
            position: absolute;
            top: toRem(40);
            right: 0;
            background-color: $white;
            color: $institutional-affs-red;
            width: 18.75rem;
            padding: 1rem;
            border: 0.063rem solid $black;
            border-radius: 0.375rem;
            z-index: $index-1;
        }
    }
}
