.figure-viewer {
    background: rgba(0, 0, 0, 0.9);
    .media-item {
        iframe {
            height: 90%;
            position: absolute;
            left: 0;
        }
    }
    .flowplayer {
        width: 90%;
        max-height: 100%;
    }
    &__reg {
        &__top {
            background: linear-gradient(
                180deg,
                rgba(8, 0, 0, 0.62) 0%,
                rgba(8, 0, 0, 0.07) 73.63%,
                rgba(8, 0, 0, 0) 104.12%
            );
            background-color: $black;
            display: flex;
            flex-flow: row wrap;
        }
    }
    &__cent {
        &__right {
            padding: 0;
            background: $white;
            position: fixed;
            color: $black;
        }
    }
    &__ctrl {
        display: inline-block;
        vertical-align: middle;
        float: none;
        margin: 0;
        @include font-size(20);
        padding-left: toRem(20);
        background: inherit;
        text-shadow: none;
        &__browse {
            @include font-size(20);
            @include media('<phone') {
                margin-right: toRem(8);
            }
        }
        &__return {
            position: absolute;
            z-index: $index-2;
            right: 0;
            top: toRem(21);
            margin-right: toRem(20);
            .icon-cancel-bold {
                color: $black;
            }
        }
        &__prev {
            transform: rotate(180deg);
            font-weight: 600;
            @include font-size(15);
            @include media('>=tablet') {
                left: 0;
            }
            @include media('<=large') {
                padding: toRem(5);
                @include font-size(12);
            }
            @include media('<tablet') {
                display: none;
            }
        }
        &__next {
            font-weight: 600;
            @include font-size(15);
            @include media('>=tablet') {
                right: 0;
            }
            @include media('<=large') {
                padding: toRem(5);
                @include font-size(12);
            }
            @include media('<tablet') {
                display: none;
            }
        }
    }
    &__caption-header {
        padding: toRem(21);
        border: toRem(1) solid $primary-lighten-bg-color;
        border-top: 0;
        position: relative;
    }
    &__close-caption {
        background: inherit;
        padding: 0;
        .icon-cancel-bold {
            color: $primary-lighten-color;
            padding-left: toRem(22);
        }
        &:before {
            content: '';
            border-left: toRem(1) solid $primary-lighten-bg-color;
            top: 0;
            position: absolute;
            height: 100%;
            padding-right: toRem(20);
        }
    }
    &__caption-wrapper {
        padding: toRem(30) toRem(20);
        .figure-viewer__ctrl__caption {
            background: inherit;
            color: $primary-lighten-bg-color;
        }

        .media-caption {
            overflow: auto;
            max-height: 78vh;
        }
    }
    &__title {
        margin: 0;
        @include font-size(17);
    }
    &__caption-label {
        display: inline;
    }
    &__hold {
        &__list {
            background: $white;
            position: fixed;
            top: 0;
            height: 100%;
            margin: 0;
            padding: 0;
            display: block;
            right: 100%;
            transition: right 0.25s ease-in-out;
            width: 100%;
            z-index: $index-1;
            .article-section__table {
                display: none;
            }
            &.active {
                right: 0;
            }
            .colored-block {
                width: 25%;
                margin: 0;
                padding: 0;
                background: $white;
                border: toRem(1) solid #ddd;
                max-height: toRem(300);
                height: toRem(300);
                overflow: hidden;
                @include media('<=tablet') {
                    width: 100%;
                    border-bottom: toRem(1) solid $interactions-primary-color;
                    margin-bottom: toRem(20);
                    padding-bottom: toRem(20);
                }
                &__control {
                    display: none;
                }
                &__content {
                    background: $white;
                    height: 100%;
                }

                &.extra-table-title {
                    position: relative;
                    max-height: toRem(325);
                    height: toRem(325);

                    .article__table-viewer-opener {
                        height: 77%;
                    }
                    .table-footer-title {
                        position: absolute;
                        bottom: toRem(3);
                        width: 100%;
                        text-align: center;
                        z-index: $index-1;
                        .title {
                            color: $primary-color;
                            @include font-size(14);
                        }
                    }
                }
            }
            .flex-grid {
                .media-caption,
                .article-section__table-footnotes {
                    display: none;
                }
            }
        }
        &__fig {
            .show-more-items__btn-holder {
                &.table-type {
                    margin: auto;
                    min-width: 80%;
                }
            }
        }
    }
    &__browse-title {
        color: $interactions-primary-color;
        text-align: center;
        display: block;
        padding-top: toRem(20);
        text-transform: capitalize;
    }

    &__top {
        &__right {
            flex: 1 auto;
            order: 3;
            float: none;
            text-align: right;

            @include media('<phone') {
                a.figure-viewer__ctrl {
                    &:first-child {
                        padding-left: 0;
                    }
                }
            }
        }
        &__left {
            flex: 1 auto;
            order: 1;
            float: none;
        }
        &__center {
            order: 2;
            text-align: center;
            flex-grow: 1;
            @include media('<=tablet') {
                @include font-size(16);
            }
            @include media('<tablet') {
                display: none;
            }
        }
    }
    .icon-Arrow-Stroke {
        font-size: inherit;
    }
    .article-section__table {
        max-width: 80%;
        margin: 0 auto;
        img {
            display: block;
        }
    }
    .article__table-viewer-opener {
        display: block;
        position: absolute;
        width: 85%;
        background: #ebebeb;
        z-index: $index-1;
        left: 7%;
        height: 85%;
        top: 7%;
        .icon-table-2 {
            font-size: toRem(50);
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            position: absolute;
            width: 100%;
            color: $secondary-color;
        }
    }
    .table-opener-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        transition: all 0.5s;
        &:hover {
            background: $black;
            opacity: 0.6;
            .icon-table-2 {
                opacity: 0.6;
            }
            .view-table {
                top: 50%;
            }
        }
        .view-table {
            color: $white;
            border: toRem(1) solid;
            padding: toRem(6) toRem(35);
            position: absolute;
            top: 120%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.5s;
        }
    }

    .flowPlayer {
        &.is-paused {
            .fp-ui {
                background-image: url(../../../images/play_white@x2.png);
            }
        }
    }
    &--white {
        background: $white;
        [class*='__ctrl__'] {
            color: $black;
        }
        .figure-viewer {
            &__top {
                &__center {
                    color: $primary-color;
                    min-height: toRem(25);
                }
            }
            &__reg {
                &__top {
                    background: $white;
                    border-bottom: toRem(1) solid $secondary-border-color;
                    margin-bottom: toRem(50);
                }
            }
        }
        .holder {
            display: block;
            overflow-x: auto;
            max-height: calc(100vh - #{toRem(200)});
        }
    }

    .zoomSetting {
        display: none;

        #figure-viewer__zoom-range {
            box-shadow: none;
        }

        &.zoomSlider {
            margin-right: toRem(8);
        }
    }

    /* the range fix needs to be moved to the ux3 level, this is temporary */

    input[type='range'] {
        appearance: none;
        width: 100%;
        margin: 0;
        background: transparent;
        border: 0;
    }

    input[type='range']::-webkit-slider-runnable-track {
        width: 100%;
        height: toRem(2.3);
        cursor: pointer;
        box-shadow: 0 0 0 $dark-grey, 0 0 0 $darker-grey;
        background: $grey;
        border-radius: toRem(0.4);
        border: 0 solid $darker-grey;
    }

    input[type='range']::-webkit-slider-thumb {
        box-shadow: toRem(1) toRem(1) toRem(1) $dark-grey, 0 0 toRem(1) $darker-grey;
        border: toRem(1) solid $white;
        height: toRem(20);
        width: toRem(20);
        border-radius: toRem(250);
        background: $dark-grey;
        cursor: pointer;
        appearance: none;
        margin-top: toRem(-8.85);
    }

    input[type='range']:focus::-webkit-slider-runnable-track {
        background: $light-grey;
    }

    input[type='range']::-moz-range-track {
        width: 100%;
        height: toRem(2.3);
        cursor: pointer;
        box-shadow: 0 0 0 $dark-grey, 0 0 0 $darker-grey;
        background: $grey;
        border-radius: toRem(0.4);
        border: 0 solid $darker-grey;
    }

    input[type='range']::-moz-range-thumb {
        box-shadow: toRem(1) toRem(1) toRem(1) $dark-grey, 0 0 toRem(1) $darker-grey;
        border: toRem(1) solid $white;
        height: toRem(20);
        width: toRem(20);
        border-radius: toRem(250);
        background: $dark-grey;
        cursor: pointer;
    }

    input[type='range']::-ms-track {
        width: 100%;
        height: toRem(2.3);
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        border-width: toRem(6) 0;
        color: transparent;
    }

    input[type='range']::-ms-fill-lower {
        border: 0 solid $darker-grey;
        box-shadow: 0 0 0 $dark-grey, 0 0 0 $darker-grey;
        background: $grey;
        border-radius: toRem(0.8);
    }

    input[type='range']::-ms-fill-upper {
        border: 0 solid $darker-grey;
        box-shadow: 0 0 0 $dark-grey, 0 0 0 $darker-grey;
        background: $grey;
        border-radius: toRem(0.8);
    }

    input[type='range']::-ms-thumb {
        box-shadow: toRem(1) toRem(1) toRem(1) $dark-grey, 0 0 toRem(1) $darker-grey;
        height: toRem(12);
        width: toRem(12);
        border-radius: 50%;
        background: $dark-grey;
        border: toRem(1) solid $white;
        cursor: pointer;
    }

    input[type='range']:focus::-ms-fill-lower {
        background: $grey;
    }

    input[type='range']:focus::-ms-fill-upper {
        background: $light-grey;
    }
}
.flex-grid {
    // done by me (ramarat) faced an issue on github and for some reason it add with the name "rahhal"
    .show-more-items {
        display: none;
    }
}

figure {
    &.inline-fig {
        &:hover {
            cursor: auto;
        }
    }
}
