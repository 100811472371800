.tooltip {
    outline: none;

    @include media('<tablet') {
        z-index: $index-1;
    }

    &:hover,
    &:focus,
    &.show {
        .tooltip__body,
        & ~ .tooltip__body {
            border: none;
            top: toRem(25);
            left: 0;
            box-shadow: 0 0 toRem(2) toRem(2) rgba($black, 0.1);
            opacity: 1;
            visibility: visible;

            @include media('<large') {
                top: toRem(45);
            }
        }
    }

    .tooltip__body {
        opacity: 0.1;
        outline: none;
        z-index: $index-5;
    }

    ul.rlist--inline {
        outline: none;
    }
}

.tooltip,
.tooltip__body {
    .arrow {
        left: 50%;
        top: calc((0.5rem + #{toRem(8)}) * -1);
        width: 0.5rem;
        height: 0.5rem;
        margin: 0.5rem 0;
        position: absolute;
        display: block;

        &::after,
        &::before {
            border-width: 0 0.5rem 0.5rem;
            position: absolute;
            display: block;
            content: '';
            border-color: transparent;
            border-style: solid;
        }

        &::after {
            top: toRem(1);
            border-bottom-color: $white;
        }

        &::before {
            top: 0;
            border-bottom-color: rgba($black, 0.1);
        }
    }
}
