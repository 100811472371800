.edit-profile {
    &__text {
        font-weight: 300;
        color: $primary-color;
    }
    &__btn {
        margin-top: toRem(10);
        .icon-export {
            padding-left: toRem(12);
            opacity: 0.8;
        }
    }
}
