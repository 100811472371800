.table-of-content {
    .abstractSection {
        font-family: $tertiary-font-family;
        @include font-size(17);
        line-height: toRem(34);
        font-weight: 300;
        @extend .clearfix;

        p {
            margin-top: 0;
        }

        @include media('>tablet') {
            margin-left: toRem(14);
        }
    }

    .issue-item {
        margin-top: 0;

        &-container:not(:last-of-type) {
            margin-bottom: toRem(16);
        }

        &-checkbox-container {
            top: toRem(16);
        }
    }

    .authors-accordion {
        .accordion-tabbed {
            &__tab {
                border: none;
                padding: 0;
            }

            &__control {
                padding: 0;
                padding-bottom: toRem(10);
                margin-top: 0;
                @extend .separated-block--dashed--bottom;

                &:after {
                    line-height: toRem(50);
                }
            }

            &__content {
                @include media('>=phone') {
                    padding-left: 0;
                }

                .separated-block--dashed--top {
                    background-image: none;
                    padding: 0;
                }
            }
        }

        .auth-metrics {
            margin-bottom: toRem(30);
        }
    }

    .show-more-items__btn-holder {
        @include media('<=tablet') {
            &:after {
                display: none;
            }
        }
    }

    &__options {
        padding: toRem(11) 0;
        border-top: toRem(1) solid $secondary-border-color;
        border-bottom: toRem(1) solid $secondary-border-color;
        margin-bottom: toRem(10);
        @include media('<tablet') {
            display: none;
        }

        ~ .issue-item-container {
            margin-top: toRem(24);
        }

        &.sticky_checkboxes {
            position: sticky;
            background: $white;
            transition: top 0.5s;
            z-index: $index-5;
            top: toRem(52);
        }
    }

    .accordion-tabbed__control {
        @include font-size(18);
        font-weight: 600;
        margin-top: toRem(10);

        &:after {
            color: $blue-color;
            font-style: normal;
            @include font-size(9);
            position: absolute;
            right: 0;
            top: 0;
            float: unset;
        }

        &.section__title {
            display: inline-block;
            margin-bottom: toRem(15);
            margin-top: 0;
        }
    }

    .accordion-tabbed {
        .toc__section {
            margin-bottom: 0;
            padding-top: toRem(14);

            .section-checkbox {
                @include media('<tablet') {
                    display: none;
                }
            }
        }

        .accordion-tabbed__content {
            padding-bottom: 0;
            margin-bottom: toRem(30);

            .table-of-content-wrapper {
                padding-bottom: 0;
            }
        }
    }

    .logo {
        background-color: $secondary-lighten-bg-color;
    }

    &--lazy {
        .issue-item {
            &-checkbox-container {
                &.section-checkbox {
                    display: inline-block;
                    position: static;
                    vertical-align: top;
                }
                + .section__title {
                    max-width: calc(100% - #{toRem(28)});
                    padding-right: 0.875rem;
                }
            }
        }
    }

    .chapter-heading {
        border-top: toRem(1) solid $secondary-color;
        padding: toRem(10) 0;

        &__label {
            background: $primary-lighten-color;
            color: $white;
            padding: toRem(7) toRem(13);
            border-radius: toRem(24);
            margin-right: toRem(10);
            display: inline-block;
            vertical-align: middle;
            min-width: toRem(92);
            min-height: toRem(31);
            text-align: center;
            @include font-size(12);

            &-empty {
                background: $secondary-lighten-color;
            }
        }

        &__title {
            display: inline-block;
            max-width: calc(100% - #{toRem(110)});
            vertical-align: middle;
        }
    }

    .chapter-heading--part {
        .chapter-heading {
            &__label {
                min-width: toRem(70);
                background: transparent;
                border: toRem(1) solid;
                color: $primary-lighten-color;
                min-height: toRem(24);
                padding: toRem(5) toRem(13);
                @include media('>phone') {
                    margin-left: toRem(19);
                }
            }
        }
    }

    .checkbox--primary {
        margin-bottom: 0;
    }

    .loadingProceedings {
        @include font-size(30);
        text-align: center;
        margin: toRem(10) 0;
    }

    .proceedingsLazyLoad {
        .showMoreProceedings,
        .showAllProceedings {
            display: block;
            margin: toRem(12) auto;
            width: toRem(240);
        }
    }
}

div[class^='col-'] {
    > .table-of-content-wrapper {
        padding-bottom: toRem(30);
    }
}

.issue-downloads {
    display: flex;

    @include media('>tablet') {
        max-width: 90%;
    }
    flex-wrap: wrap;

    &__item {
        flex: 0 50%;
        flex-grow: 1;
        @include media('<=tablet') {
            flex: 0 100%;
        }

        margin-bottom: toRem(15);

        &__details {
            width: calc(100% - #{toRem(65)});
            display: inline-block;
            vertical-align: top;
        }
    }

    i {
        padding-left: toRem(8);
        padding-top: toRem(3);
        color: $secondary-text-color;
        vertical-align: middle;
    }

    .badge-rounded-edgs {
        height: toRem(19);
        padding: toRem(2) toRem(6);
        margin-right: toRem(10);
        text-align: center;

        @include media('<tablet') {
            margin-left: 0;
        }
    }

    a {
        margin-bottom: toRem(5);
        display: flex;
        margin-right: toRem(20);
        align-items: start;

        @include media('<tablet') {
            padding-right: toRem(20);
        }

        &:hover {
            i {
                color: $primary-color;
            }
        }
    }

    .row {
        margin-left: 0;
    }

    .col-md-6 {
        @include media('<tablet') {
            padding: 0;
        }

        @include media('>=tablet') {
            &:nth-child(3n) {
                clear: left;
            }
        }
    }
}

.accordion-tabbed__tab {
    &.js--open {
        .accordion-tabbed__content {
            &.issue-downloads {
                display: flex;
            }
        }
    }
}
