.full-text-collection {
    @include media('>=phone') {
        padding-top: toRem(25);
    }

    &__list {
        padding: toRem(12);

        @include media('<phone') {
            padding: 0;
        }

        li {
            margin-bottom: toRem(16);
            padding-bottom: toRem(24);
            border-bottom: toRem(1) solid $secondary-color;

            @include media('<phone') {
                &:first-child {
                    padding-top: toRem(15);
                }
                &:last-child {
                    border: none;
                }
            }
        }
    }

    &__title {
        @include font-size(16);

        .sub_title {
            color: $primary-lighten-color;
            font-weight: 200;
            @include font-size(14);
            margin-left: toRem(6);
        }
    }

    &__info {
        padding: toRem(10) 0;
        @include font-size(14);

        .info_item {
            display: inline-block;
            margin-right: toRem(20);
        }

        .space {
            @include font-size(14);
        }
    }

    &__volume {
        color: $blue-color;
        border-left: solid toRem(2) $secondary-color;
        padding-left: toRem(8);
        line-height: toRem(16);
    }

    .left-bordered-title {
        margin: toRem(20) 0;
        @include media('<phone') {
            padding-right: toRem(35);
        }

        &:after {
            font-style: normal;
            @include font-size(10);
            color: $blue-color;
            @include media('<phone') {
                position: absolute;
                right: 0;
            }
        }
    }

    ul.accordion-tabbed.rlist {
        @include media('>=desktop') {
            width: 70%;
        }
    }

    li.accordion-tabbed__tab {
        @include media('>=phone') {
            padding-bottom: toRem(10);
        }

        @include media('<phone') {
            border-top: solid toRem(1) $secondary-border-color;

            &:last-child {
                border-bottom: solid toRem(1) $secondary-border-color;
            }
        }
    }
}
