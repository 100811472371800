.binder {
    &__options {
        .item-results__msg {
            color: $primary-text-color;
            display: table-cell;
        }
    }

    &__list {
        @include font-size(14);

        tr {
            font-weight: 400;
            border-bottom: toRem(1) solid $secondary-color;
        }

        tbody {
            color: $primary-lighten-color;
        }

        &__title {
            display: inline-block;
            word-break: break-word;

            @include media('<phone') {
                max-width: toRem(173);
            }

            &__name {
                margin: 0;
                font-weight: 400;
            }

            &__td {
                display: flex;
                align-items: center;

                @include media('<desktop') {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        .icon-folder,
        .icon-share-folder {
            @include font-size(28);
            color: $lighter-blue-color;
            margin-right: toRem(14);

            &.default--binder {
                color: $primary-lighten-color;
            }
        }

        .addedRow {
            background: lighten($success-bg-color, 5%);
        }

        .tools {
            text-align: right;
            width: toRem(50);
            @include media('>=desktop') {
                width: toRem(180);
            }
        }

        i.icon-delete {
            @include font-size(18);
            line-height: toRem(14);
        }

        i.icon-cancel-bold {
            @include font-size(12);
            font-weight: 600;
        }

        .btn--icon {
            &.disable {
                color: $secondary-color;
                cursor: not-allowed;

                span {
                    color: $secondary-color;
                }

                &:hover {
                    @include media('>=desktop') {
                        background: $secondary-lighten-color;
                    }
                }
            }
        }

        td {
            &[class*='hidden-'] {
                @include media('>=desktop') {
                    display: table-cell;
                }
            }
        }

        [data-db-target-for] {
            @include media('>=desktop') {
                display: none;
            }
        }

        .dropBlock__holder {
            @include media('>=desktop') {
                display: inline-block;
                float: left;
                position: static;
                margin: 0;
                padding: 0;
                box-shadow: none;
                background: none;
            }

            right: toRem(5);
            min-width: 0;
            margin-top: toRem(10);

            @include media('<desktop') {
                width: toRem(170);
            }

            &:after,
            &:before {
                content: '';
                width: 0;
                height: 0;
                border: toRem(5) solid transparent;
                border-bottom-color: $white;
                position: absolute;
                right: toRem(27);
                bottom: 100%;
            }

            &:before {
                border: toRem(7) solid transparent;
                border-bottom-color: $secondary-lighten-color;
                right: toRem(25);
            }

            li {
                @include media('<desktop') {
                    display: block;
                    ~ li {
                        margin-top: toRem(18);
                    }
                }
            }

            @include media('<desktop') {
                i {
                    margin-right: toRem(10);
                    width: toRem(20);
                    display: inline-block;
                }

                .btn--icon {
                    margin: 0;
                    background: none;
                    line-height: toRem(14);
                    width: auto;
                    height: auto;
                }
            }

            span {
                @include media('>=desktop') {
                    visibility: hidden;
                    position: absolute;
                    left: -999em;
                }
                @include font-size(14);
                color: $primary-color;
            }
        }

        &__description {
            max-width: toRem(500);
            word-break: break-word;

            @include media('<large') {
                max-width: toRem(300);
            }
        }

        &__creation,
        &__description {
            @include media('<desktop') {
                display: none;
            }
        }

        &__no-description {
            color: $secondary-color;
            font-style: italic;
        }

        &__creator {
            color: $primary-lighten-color;
            @include media('>=desktop') {
                display: block;
            }

            @include media('<desktop') {
                padding-left: toRem(5);
                margin-left: toRem(5);
                border-left: toRem(1) solid $secondary-color;
                line-height: toRem(14);
                display: inline-block;
            }
        }
    }

    &s-definition {
        margin-top: toRem(21);
        padding-bottom: toRem(20);
        padding-left: toRem(14);
        border-bottom: toRem(1) solid $secondary-color;

        ul {
            @include font-size(14);
            padding-left: toRem(17);

            li {
                margin-bottom: toRem(10);
                color: $secondary-color;

                p {
                    color: $primary-color;
                }
            }
        }
    }
    &__issues {
        &__info {
            .icon-info {
                font-size: toRem(18);
                position: relative;
                top: toRem(4);
                left: toRem(2);
            }
        }
    }
}

[id*='removeBinder'] {
    p {
        @include font-size(18);
        font-weight: 300;
    }
}

.ux-modal-container .modal__dialog.small {
    min-height: 0;
}

.sort-default {
    &:after {
        content: '\e92c';
        margin-left: toRem(5);
        @include font-size(11);
    }
}

.table-striped--hover {
    .pagination {
        margin-bottom: toRem(50);

        li {
            padding: 0;

            a {
                padding: 0 toRem(10);
            }
        }
    }
}

[data-db-target-for*='binder-list-tools'] {
    padding: toRem(20);
}
