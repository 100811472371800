.row--event {
    .left--block {
        @include media('>desktop') {
            width: toRem(105);
            min-height: toRem(105);
            margin-right: toRem(16);
        }

        .event {
            &__code {
                background: $secondary-lighten-color;
                color: $primary-color;
                word-break: break-word;

                @at-root {
                    .active#{&} {
                        background: $blue-color;
                        color: $white;
                    }
                }

                text-align: center;
                padding: toRem(5);
                margin: 0;

                @include media('>desktop') {
                    min-height: toRem(46);
                    display: table;
                    width: 100%;

                    &--text {
                        vertical-align: middle;
                        display: table-cell;
                        word-break: break-word;
                    }
                }
            }

            &__sopnsers {
                border: toRem(2) solid $secondary-lighten-color;
                text-align: center;
                padding: toRem(5);
                @include font-size(11);
                color: $blue-color;

                @include media('>desktop') {
                    min-height: toRem(47);
                    border-top: none;
                    width: 100%;
                }

                .label {
                    margin: 0;
                    color: $primary-color;
                    @include font-size(11);
                    line-height: toRem(19);
                }

                @include media('>desktop') {
                    a {
                        display: block;
                    }
                    .removed-items-count {
                        margin: 0;
                    }
                }
                @include media('<=desktop') {
                    border-left: none;

                    .trunc-done {
                        li {
                            display: inline-block;
                            margin: 0 toRem(2);
                        }

                        .label {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    @include media('>desktop') {
        .right--block,
        .left--block {
            float: left;
        }

        .right--block {
            width: calc(100% - #{toRem(121)});
        }
    }

    @include media('<=desktop') {
        .left--block {
            display: table;
            width: 100%;
            min-height: toRem(39);
        }
        .event {
            &__code,
            &__sopnsers {
                display: table-cell;
                width: 50%;
                vertical-align: middle;
            }
        }
        .right--block {
            border-top: toRem(1) solid $secondary-lighten-color;
            padding: toRem(16);
            margin: 0 toRem(-16);
            margin-top: toRem(16);
            padding-bottom: 0;
        }
    }

    .col-md-5 {
        padding: toRem(16);

        .search__item--event & {
            border-right: toRem(1) solid $secondary-lighten-color;
        }
    }

    .conference--link {
        @include font-size(13);
        color: $blue-color;
    }

    .event {
        &__title {
            font-style: normal;
            color: $primary-color;
            @include font-size(17);
        }

        &__content {
            padding: toRem(16) 0;

            .search__item--event & {
                border-bottom: toRem(1) solid $secondary-lighten-color;
                min-height: toRem(72);
                font-weight: 500;
            }

            @include media('>desktop') {
                display: table;
                width: 100%;
            }

            @include media('<=desktop') {
                &:first-of-type {
                    margin: 0 toRem(-16);
                    padding: toRem(16);
                    border-top: toRem(1) solid $secondary-lighten-color;
                }
            }

            .info {
                @include media('>desktop') {
                    display: table-cell;
                    padding: 0 toRem(10);
                    vertical-align: middle;
                    width: 33%;

                    i {
                        .pill-information__content & {
                            float: left;
                            position: static;
                        }
                    }

                    > span {
                        display: inline-block;
                        width: calc(100% - #{toRem(36)});
                        vertical-align: top;

                        .pill-information__content & {
                            float: left;
                            width: calc(100% - #{toRem(30)});
                            padding: 0;
                        }
                    }
                }

                .disabled {
                    opacity: 1;
                }
                &.map {
                    span {
                        width: auto;
                    }
                }
            }

            .website {
                color: $blue-color;
                font-style: normal;

                i {
                    padding-left: toRem(10);
                    position: static;
                    @include font-size(12);
                }

                @include media('<=desktop') {
                    padding: 0;
                    a {
                        display: block;
                        @at-root :not(.active)#{&} {
                            margin: 0 toRem(-16);
                            border-top: toRem(1) solid $secondary-lighten-color;
                            padding: toRem(16);
                            padding-bottom: 0;
                        }

                        @at-root .active#{&} {
                            padding: toRem(10);
                            text-align: center;
                            border-radius: toRem(2);
                            background: $secondary-lighten-color;
                        }
                    }
                }
            }
        }

        &__proceedings {
            padding: toRem(16) 0;
            color: $primary-lighten-color;
            font-weight: 300;

            i {
                padding: 0 toRem(10);
                vertical-align: middle;
                @include font-size(17);
                position: absolute;

                &.active {
                    color: $blue-color;
                }
            }

            ul,
            span {
                padding-left: toRem(36);
            }

            li {
                margin-bottom: toRem(10);

                @include media('<=desktop') {
                    display: block;
                }

                a {
                    text-decoration: underline;
                }
            }

            a {
                @include media('<=desktop') {
                    display: block;
                }
            }

            button {
                &.removed-items-count {
                    text-align: center;
                    padding: toRem(9);
                }
            }

            @include media('<desktop') {
                .truncate-list {
                    position: relative;
                    padding-bottom: toRem(48);

                    li {
                        &.count-list {
                            display: block;
                            background-color: $white;
                            width: calc(100% - #{toRem(36)});
                            padding-top: toRem(5);
                            position: absolute;
                            bottom: 0;
                        }
                    }
                }
            }

            @include media('>=desktop') {
                ul:not(:has(.count-list)) {
                    li:first-of-type {
                        padding-right: toRem(50);
                    }
                }
            }
        }
    }

    &--with-label {
        .row--event {
            background: $light-gray-color;
            margin-bottom: toRem(26);
            width: 100%;
            @include media('>desktop') {
                display: table;
            }

            .left--block {
                @include media('>desktop') {
                    width: toRem(95);
                    min-height: 0;
                }

                @include media('<=desktop') {
                    margin-top: toRem(20);
                }

                .event__code {
                    min-height: toRem(46);
                    @include font-size(12);
                }
            }
        }

        .event {
            &__label,
            &__header,
            &__content,
            &__info {
                @include media('>desktop') {
                    display: table-cell;
                    width: auto;
                    vertical-align: middle;
                }
            }

            &__label {
                position: relative;
                background: $blue-color;
                color: $white;
                @include font-size(20);
                font-weight: 600;

                @include media('>desktop') {
                    max-width: toRem(174);
                    padding-left: toRem(30);
                    @include font-size(24);
                }

                @include media('<=desktop') {
                    text-align: center;
                    padding: toRem(12);
                    border-top-left-radius: toRem(2);
                    border-top-right-radius: toRem(2);
                }

                .conference-landing & {
                    background: $conference-primary-color;
                }

                svg {
                    fill: $blue-color;
                    position: absolute;

                    .conference-landing & {
                        fill: $conference-primary-color;
                    }

                    &.arrow-right {
                        top: 0;
                        left: 100%;

                        @include media('<=desktop') {
                            display: none;
                        }
                    }

                    &.arrow-bottom {
                        display: none;
                        top: 96%;
                        left: 50%;
                        margin-left: toRem(-15);

                        @include media('<=desktop') {
                            display: block;
                        }
                    }
                }
            }

            &__header {
                @include media('>desktop') {
                    padding-left: toRem(100);
                    max-width: toRem(450);
                }

                @include media('<=desktop') {
                    border-bottom: toRem(1) solid $secondary-lighten-color;
                    padding: 0 toRem(16);
                }
            }

            &__content {
                font-weight: 500;
                border-left: toRem(1) solid $secondary-lighten-color;

                @include media('<=desktop') {
                    padding: toRem(10) 0 0;
                }

                .info {
                    padding: 0 toRem(16);
                }
            }

            &__info {
                padding-top: toRem(16);
                padding-bottom: toRem(16);
            }
        }
    }

    &.event {
        &--inline-info {
            .event__content {
                @include media('>=desktop') {
                    padding: 0;
                }

                .info {
                    display: block;
                    width: auto;
                    padding-bottom: toRem(10);
                    padding-left: 0;

                    a {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .upcoming-events-table & {
        display: flex;
        margin: 0;
        background: transparent;
        @include media('<desktop') {
            flex-direction: column;
        }

        .event {
            &__header {
                padding-left: 0;
            }

            &__content {
                border: none;
                margin: 0;

                .info {
                    padding: 0;
                    display: flex;
                }
            }
        }

        .sponsors-list {
            @include media('<desktop') {
                border-bottom: toRem(1) solid;
                border-top: toRem(1) solid;
                border-color: $secondary-lighten-color;
                padding: toRem(12) 0;
            }

            &__item {
                padding-right: toRem(5);
            }
        }
    }
}

.search__item--event {
    display: inline-block;
    width: 100%;
}

.events-separator {
    .event {
        &:not(:last-child) {
            border-bottom: toRem(1) solid rgba(0, 0, 0, 0.12);
            margin-bottom: toRem(11);
            padding-bottom: toRem(11);
        }
    }
}

.events-table-container {
    margin-bottom: toRem(50);

    .more-link {
        margin: 0;
        @include media('>phone') {
            border: 0;
        }
        padding: toRem(20) toRem(15);
    }
}

.conferences-home {
    @include media('<phone') {
        padding: 0;

        .conferences-home__item {
            padding: toRem(10) toRem(15);
            box-shadow: 0 toRem(4) toRem(2) rgba($black, 0.1);
        }
    }

    .row--event {
        .right--block {
            border-top: none;
            padding: 0;
            margin: 0;
        }

        .event {
            &__content {
                &:first-of-type {
                    margin: 0;
                    padding: 0;
                    border-top: none;
                }
            }
        }

        @include media('<phone') {
            .right--block {
                padding-top: toRem(16);
            }
        }

        @include media('>phone') {
            .right--block,
            .left--block {
                float: left;
            }

            .event {
                &__code {
                    min-height: toRem(46);
                    display: table;
                    width: 100%;
                    &--text {
                        vertical-align: middle;
                        display: table-cell;
                    }
                }

                &__sopnsers {
                    .trunc-done {
                        .label {
                            display: block;
                        }
                    }
                }
            }
        }

        @include media('>=tablet', '<desktop') {
            .left--block {
                display: block;
                width: toRem(90);
                min-height: toRem(105);
                margin-right: toRem(16);
            }

            .right--block {
                width: calc(100% - #{toRem(106)});
            }
        }
    }
}

.link.goto-sig {
    display: inline-block;
}
