.conferences-do-meta {
    .slideShow {
        min-height: 0;
    }
    .slide-item {
        text-align: center;

        span {
            display: block;
            @include font-size(11);
            font-weight: 300;
            margin: toRem(9) 0;
        }

        .icon-export {
            color: $blue-color;
            opacity: 0;
            transition: opacity 0.6s;
            padding-left: toRem(2);
        }
        .sponser-item {
            &:hover {
                .icon-export {
                    opacity: 1;
                }
            }
        }
    }

    .owl-carousel {
        .owl-item img {
            display: inline-block;
            object-fit: fill;
        }

        .owl-nav {
            margin: 0;

            [class*='owl-'] {
                padding: 0;
                margin: 0;
                top: 30%;

                &:hover {
                    color: $primary-lighten-color;
                    background: none !important;
                }

                i {
                    @include font-size(20);
                }
            }
            .owl-nav-active.owl-nav-active i,
            [class*='owl-'] {
                color: $primary-lighten-color;
            }
        }

        .owl-dots.owl-dots .owl-dot.active span {
            background-color: $blue-color;
            border-color: $blue-color;
        }
    }
    .more-link {
        @include media('>phone') {
            border: none;
            margin-top: 0;
            padding-top: 0;
        }
    }
}
