.proceedings-meta,
.proceedings-meta-light {
    .colored-block__content {
        align-items: center;
    }

    .quick-search {
        padding: 0;

        &-alt {
            .quick-search {
                &__input {
                    border: toRem(1) solid $secondary-lighten-color;
                    @include font-size(14);
                    padding-top: toRem(13);
                    padding-bottom: toRem(13);
                    max-height: toRem(52);
                    &::-webkit-input-placeholder {
                        //chrome
                        @include font-size(14);
                    }
                    &::-moz-placeholder {
                        //firefox
                        @include font-size(14);
                    }
                    &:-ms-input-placeholder {
                        //ie
                        @include font-size(14);
                    }
                }

                &__button {
                    @include font-size(21);
                    padding: toRem(21) toRem(12) toRem(31);
                    position: static;
                }
            }
        }
        &__clear--button {
            display: inline-block;
            .icon-cancel-bold {
                background: transparent;
                padding: toRem(21) toRem(12) toRem(12);
                color: $black;
                font-weight: 900;

                &:before {
                    transition: transform 0.25s ease-in-out;
                    display: inline-block;
                }

                &:hover {
                    &:before {
                        transform: scale(1.2);
                    }
                }
            }
        }
    }
}

.conferences-do-meta,
.proceedings-meta {
    .truncate-text {
        @include font-size(15);
        font-weight: 300;
        line-height: toRem(26);
    }

    h6 {
        @include media('<desktop') {
            border-top: toRem(1) solid $secondary-lighten-color;
            padding-top: toRem(21);
        }
    }

    .item-meta__info {
        @include media('>=desktop') {
            border-right: toRem(1) solid $secondary-lighten-color;
        }
    }
}
