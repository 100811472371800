.recommended-articles {
    display: none;

    #pb-editor & {
        display: block;
        position: relative;
        margin: 0 auto;
    }

    position: fixed;
    width: toRem(444);
    right: 0;
    bottom: 0;
    margin: toRem(18);
    z-index: $index-9010;
    border-radius: toRem(2);
    box-shadow: 0 0.125rem 0.375rem rgba($black, 0.16);

    .pb-dropzone {
        display: inline-block;

        #pb-editor & {
            display: block;
        }
    }

    @include media('>=576px', '<1024px') {
        width: 46%;
    }

    @include media('<576px') {
        margin: 0;
        width: 100%;
    }

    &__title {
        background-color: $secondary-lighten-color;
        padding: toRem(15) toRem(12);
        border-top: solid toRem(8) $blue-color;
        font-style: italic;

        h4 {
            margin: 0;
            display: inline-block;
            vertical-align: bottom;

            @include media('<tablet') {
                @include font-size(16);
            }
        }

        span {
            margin-left: toRem(8);

            i {
                color: $blue-color;
                @include font-size(18);
            }
        }

        .icon-cancel-bold {
            color: $primary-lighten-color;
            @include font-size(13);
            float: right;
            cursor: pointer;
            margin-top: toRem(4);
        }
    }

    &__content {
        padding: toRem(20) toRem(12);
        border-radius: toRem(2);

        @include media('<tablet') {
            max-height: initial;
        }

        &::-webkit-scrollbar {
            width: toRem(4);
        }

        &::-webkit-scrollbar-track {
            background: $secondary-lighten-color;
        }

        &::-webkit-scrollbar-thumb {
            background: $blue-color;
            border-radius: toRem(4);
        }

        .creative-work {
            padding-bottom: toRem(10);

            .creative-work__title {
                font-size: toRem(13);
                margin: 0;
                font-style: italic;
                color: $recommendation-title;
                padding: 0;
                line-height: toRem(24);
            }

            &__sub-title {
                @include font-size(13);
            }

            .description {
                @include font-size(13);
                font-weight: 200;
                margin: toRem(10) 0 toRem(5) 0;

                @include media('<576px') {
                    display: none;
                }
            }

            .issue-item__abstract {
                margin: 0;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                /* autoprefixer: off */
                -webkit-box-orient: block-axis;
                /* autoprefixer: on */
                overflow: hidden;
                max-height: toRem(35);
                line-height: 1.4;
            }

            .readMore {
                text-align: right;

                a {
                    text-decoration: underline;
                    color: $blue-color;
                    @include font-size(13);
                }
            }
            a {
                text-decoration: none;
            }
        }

        .grid-item {
            @include media('<1024px') {
                .creative-work {
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border: 0;
                }
            }

            &:last-child {
                .creative-work {
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border: 0;
                }
            }
        }
    }
}

.SG-demo-mode {
    .recommended-articles {
        display: block;
        position: static;
    }
}
