.main-nav {
    &.menu--res {
        position: static;
        background: $primary-bg-color;
        .menubar {
            display: none;
        }

        .register-link {
            @include media('<tablet') {
                .btn {
                    text-align: left;
                    background: transparent;
                    color: $blue-color;
                }
            }
        }

        a {
            &[data-toggle='nav'] {
                top: 0;
                color: $text-onDark;
                left: 0;
                padding: toRem(13) toRem(40) 0 toRem(20);
                border-right: toRem(1) solid $primary-border-color;
                height: toRem(64);
                width: toRem(50);
                outline: none;
                &.js--open {
                    background: $primary-bg-color;
                }
            }
        }
        ul {
            margin: 0;
            a {
                font-weight: 500;
                border-bottom: toRem(1) solid $primary-border-color;
                padding: toRem(15);
                margin: 0;
            }
        }

        .drawer__nav {
            border-bottom: none;
        }

        .dropdown {
            &__menu {
                margin: 0;
                padding: 0;
                display: none;
                transform: none;
                -webkit-transform: none;
                position: static;
                background-color: $tertiary-bg-color;
                a {
                    margin: 0;
                }
            }

            &__toggle {
                &:hover,
                &:focus {
                    i {
                        transform: rotate(0deg);
                    }
                }
                &.selected {
                    background-color: $primary-lighten-bg-color;
                    i {
                        transform: rotate(180deg);
                    }
                }
                i {
                    @include font-size(8);
                }
            }
        }
        .icon-Icon_Search:before {
            content: '\e60e';
        }
    }

    .menubar {
        > .menu-item,
        > .menu-parent {
            @include media('>=tablet') {
                margin: 0 toRem(15);

                &:hover:after {
                    transform: scaleX(1);
                }
                &:after {
                    display: block;
                    content: '';
                    border-bottom: solid toRem(4) white;
                    transform: scaleX(0);
                    transition: transform 350ms ease-in-out;
                }
            }
        }
        &__extra-mob-links {
            display: none;
            @include media('<tablet') {
                display: block;
            }
        }
        .menu-item {
            &.mobile-menu-item {
                display: none;
                @include media('<tablet') {
                    display: block;
                }
            }
        }
    }

    a {
        padding: toRem(25) 0 toRem(20);
        @include font-size(14);
        font-weight: 500;
        text-transform: capitalize;
    }

    .dropdown__menu {
        a {
            border-bottom: toRem(1) solid $primary-border-color;
            padding-left: toRem(15);
        }
    }
}

.lock-screen {
    &[data-active='menu'] {
        transform: none;

        .menu--res {
            #menubar {
                padding: 0;
                display: block;
                border-top: toRem(1) solid $primary-border-color;
                position: absolute;
                background: $primary-bg-color;
                top: toRem(64);
                left: 0;
                right: 0;
                height: calc(100vh - #{toRem(64)});
                overflow-y: auto;
                z-index: $index-10;
            }
        }
    }
}

.drawer__icons {
    &-box {
        position: relative;
        width: toRem(20);
        height: toRem(35);
        display: block;
        -webkit-perspective: toRem(40);
        perspective: toRem(40);
    }

    &-controls {
        transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
            background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
            -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        top: 50%;
        position: absolute;
        width: toRem(20);
        height: toRem(2);
        display: block;
        background-color: $text-onDark;
        border-radius: toRem(1);
        .js--open & {
            background-color: transparent;
            -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
        }

        &:before,
        &:after {
            position: absolute;
            width: toRem(20);
            height: toRem(2);
            display: block;
            background-color: $text-onDark;
            border-radius: toRem(1);
            transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
            content: ' ';
        }

        &:after {
            bottom: toRem(-6);

            .js--open & {
                transform: translate3d(0, toRem(-6), 0) rotate(-45deg);
            }
        }

        &:before {
            top: toRem(-6);
            .js--open & {
                transform: translate3d(0, toRem(6), 0) rotate(45deg);
            }
        }
    }
}
