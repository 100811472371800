.registration-container {
    &__sub-title {
        margin-bottom: toRem(10);
        @include font-size(22);
    }

    &__small-desc {
        margin-bottom: toRem(12);
        font-weight: 300;

        &.right-side-desc {
            margin-bottom: toRem(20);
            @include font-size(14);
        }
    }

    &__bottom-desc {
        display: flex;
        margin-top: toRem(20);

        i {
            color: $primary-lighten-color;
            margin-right: toRem(5);
            vertical-align: sub;
            @include font-size(22);
        }
    }
}
