.autoComplete {
    min-width: toRem(330);
    max-height: toRem(330);

    &__category {
        display: none;
    }

    &__link--grid {
        display: block;
        padding: toRem(10) toRem(12);
    }

    &__item {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        border-bottom: toRem(1) solid $secondary-lighten-color;
    }

    &__tag {
        font-size: toRem(14);

        &:hover {
            border: toRem(1) solid $primary-border-color;
        }
    }

    &::-webkit-scrollbar {
        width: toRem(5);
    }

    &::-webkit-scrollbar-track {
        background: $white;
        border-radius: toRem(4);
    }

    &::-webkit-scrollbar-thumb {
        background: $secondary-shadow-color;
        border-radius: toRem(4);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $lighter-dark-color;
    }

    .single_highlight_class {
        background-color: $highlight-color-new;
        color: $green-color;
        font-weight: 700;
    }
}
