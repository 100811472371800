.sticko {
    &__child {
        overflow-x: hidden;
        .w-slide__content & {
            height: auto !important;
        }
        .js--sticko & {
            max-height: calc(100vh - #{toRem(58)});

            @include media('<desktop') {
                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                    width: toRem(3);
                    height: toRem(3);
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: toRem(4);
                    background-color: $secondary-color;
                }
            }
        }
    }
    &-transition-top {
        transition: top 0.3s;
    }
}
