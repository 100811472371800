.subject-browse {
    $this: &;

    background: $white;
    border-radius: toRem(5);
    box-shadow: 0 toRem(2) toRem(3) rgba(0, 0, 0, 0.1);
    padding: toRem(10);
    margin: toRem(40) 0;

    &__grid-item {
        display: flex;
        flex-wrap: wrap;
        li {
            width: 33%;
            padding: 0 toRem(20);
            display: inline-block;
            @include media('>=desktop', '<large') {
                width: 32.8%;
            }
            @include media('<desktop') {
                width: 49%;
                &:empty {
                    display: none;
                }
            }
            @include media('<phone') {
                width: 100%;
            }
        }

        li:last-child {
            .subject-browse__item {
                border: none;
            }
        }
    }

    &__item {
        position: relative;
        display: flex;
        align-items: center;
        border-bottom: toRem(1) solid $secondary-color;
        padding: toRem(15) toRem(42) toRem(15) 0;
        height: 100%;
        flex-direction: column;
        justify-content: center;

        &:hover {
            #{$this} {
                &__icon-right {
                    color: $primary-lighten-color;
                    right: 0;
                }
                &__meta {
                    color: $primary-lighten-color;
                }
            }
        }
    }

    &__title {
        margin: 0;
        font-weight: normal;
        width: 100%;
        @include font-size(14);
    }

    &__icon-right {
        position: absolute;
        top: 50%;
        color: $secondary-color;
        right: toRem(6);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        -webkit-transition: right 0.25s ease-in-out, color 0.25s ease-in-out;
        -o-transition: right 0.25s ease-in-out, color 0.25s ease-in-out;
        transition: right 0.25s ease-in-out, color 0.25s ease-in-out;

        i {
            font-weight: normal;
            @include font-size(11);
        }
    }

    &__meta {
        color: $secondary-color;
        @include font-size(12);
        width: 100%;
        display: block;
        -webkit-transition: color 0.25s ease-in-out;
        -o-transition: color 0.25s ease-in-out;
        transition: color 0.25s ease-in-out;
    }

    &--without-shadow {
        .subject-browse {
            border-radius: 0;
            box-shadow: none;
            padding: 0;
            margin: 0;
        }
    }
}
