.profile-main-container {
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(224, 224, 224, 0.46);
        width: toRem(1);
        height: 99%;
    }
    padding-top: toRem(14);
    padding-bottom: toRem(30);
    select {
        -webkit-appearance: none;
        appearance: none;
        background-image: url(../../../images/down-arrow.png);
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        padding-right: toRem(28);
        cursor: pointer;
        &::-ms-expand {
            display: none;
        }
    }
    .sortby__dropdown {
        label {
            font-weight: 300;
            color: $primary-lighten-color;
            @include font-size(12);
            display: inline-block;
            &:before {
                display: inline-block;
                content: '\e968';
                font-family: icomoon, sans-serif;
                color: $primary-color;
                @include font-size(18);
                vertical-align: middle;
                margin-right: toRem(5);
                margin-left: toRem(5);
            }
        }
        select {
            display: inline-block;
            max-width: toRem(130);
            @include font-size(12);
            font-weight: normal;
            border: 0;
        }
    }
    .table-responsive {
        @extend .user-profile-table;
    }
    [class*='alert-msg'],
    .notification {
        margin-bottom: toRem(20);
    }
    .section__header {
        border: none;
        @include font-size(20);
        margin-top: toRem(30);
        &.no-margin {
            margin: 0;
        }
    }
    .radio--secondary {
        @extend .radio--primary;
        .label-txt {
            &:before {
                content: ' ' !important;
            }
        }
    }
    .profile-submit-holder {
        border-top: toRem(1) solid $secondary-color;
        padding-top: toRem(16);
        &--right {
            text-align: right;
        }
    }
    .profile-form-tip {
        font-weight: 300;
        @include font-size(12);
        color: $primary-color;
    }
    .iconed-notice {
        clear: both;
        &__icon {
            display: inline-block;
            @include font-size(18);
            color: $primary-lighten-color;
            padding-right: toRem(10);
        }
        &__note {
            display: inline-block;
            width: calc(100% - #{toRem(30)});
            vertical-align: text-top;
        }
    }
    .downloadHoldings {
        @extend .btn;
        @extend .blue;
        @extend .stretched;
        @extend .big;
    }
}

.profile-title {
    margin-top: toRem(10);
    margin-bottom: toRem(30);
}

.account-tab {
    margin-top: 0;
    .tab__nav {
        &:not(.js--open) {
            button,
            a {
                @include font-size(15);
                font-weight: 400;
                border: toRem(1) solid $secondary-color;
                background: $default-bg-color;
                color: $primary-text-color;
                padding: toRem(15) toRem(8);
                width: 100%;
            }
            .active {
                button,
                a {
                    background: $primary-lighten-color;
                    color: $default-bg-color;
                    border-color: $secondary-color;
                    border-right: 0;
                    border-left: 0;
                }
            }
        }
    }
    &__nav:not(.js--open) {
        .active a {
            border-right: 0;
            border-left: 0;
            background: $primary-lighten-color;
            //font-weight: 600;
            color: $white;
            border-color: $secondary-color;
        }
        a {
            border: toRem(1) solid $secondary-color;
            background: $white;
            color: $primary-color;
            font-weight: normal;
            width: 100%;
            @include font-size(15);
        }
    }
    th {
        color: $primary-color;
    }
}
