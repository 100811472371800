/* merriweather-sans-300 - latin */
@font-face {
    font-family: 'Merriweather Sans';
    font-style: normal;
    font-weight: 300;
    src: url('./merriweather-sans-v11-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Merriweather Sans Light'), local('MerriweatherSans-Light'),
    url('./merriweather-sans-v11-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./merriweather-sans-v11-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./merriweather-sans-v11-latin-300.woff') format('woff'), /* Modern Browsers */
    url('./merriweather-sans-v11-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./merriweather-sans-v11-latin-300.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
}
/* merriweather-sans-300italic - latin */
@font-face {
    font-family: 'Merriweather Sans';
    font-style: italic;
    font-weight: 300;
    src: url('./merriweather-sans-v11-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local('Merriweather Sans Light Italic'), local('MerriweatherSans-LightItalic'),
    url('./merriweather-sans-v11-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./merriweather-sans-v11-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./merriweather-sans-v11-latin-300italic.woff') format('woff'), /* Modern Browsers */
    url('./merriweather-sans-v11-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./merriweather-sans-v11-latin-300italic.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
}
/* merriweather-sans-regular - latin */
@font-face {
    font-family: 'Merriweather Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./merriweather-sans-v11-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Merriweather Sans'), local('MerriweatherSans-Regular'),
    url('./merriweather-sans-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./merriweather-sans-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('./merriweather-sans-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('./merriweather-sans-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./merriweather-sans-v11-latin-regular.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
}
/* merriweather-sans-italic - latin */
@font-face {
    font-family: 'Merriweather Sans';
    font-style: italic;
    font-weight: 400;
    src: url('./merriweather-sans-v11-latin-italic.eot'); /* IE9 Compat Modes */
    src: local('Merriweather Sans Italic'), local('MerriweatherSans-Italic'),
    url('./merriweather-sans-v11-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./merriweather-sans-v11-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./merriweather-sans-v11-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('./merriweather-sans-v11-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./merriweather-sans-v11-latin-italic.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
}
/* merriweather-sans-700 - latin */
@font-face {
    font-family: 'Merriweather Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./merriweather-sans-v11-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Merriweather Sans Bold'), local('MerriweatherSans-Bold'),
    url('./merriweather-sans-v11-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./merriweather-sans-v11-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./merriweather-sans-v11-latin-700.woff') format('woff'), /* Modern Browsers */
    url('./merriweather-sans-v11-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./merriweather-sans-v11-latin-700.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
}
/* merriweather-sans-700italic - latin */
@font-face {
    font-family: 'Merriweather Sans';
    font-style: italic;
    font-weight: 600;
    src: url('./merriweather-sans-v11-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local('Merriweather Sans Bold Italic'), local('MerriweatherSans-BoldItalic'),
    url('./merriweather-sans-v11-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./merriweather-sans-v11-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./merriweather-sans-v11-latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('./merriweather-sans-v11-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./merriweather-sans-v11-latin-700italic.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
}