.browse-journals-meta {
    .truncate-text {
        color: $primary-lighten-color;
        @include font-size(15);
    }

    &__logo {
        text-align: center;

        img {
            width: toRem(80);

            @include media('<=tablet') {
                width: toRem(62);
                vertical-align: middle;
                padding-right: toRem(10);
            }
        }

        &-image {
            width: toRem(85);
            height: toRem(85);
            border-radius: 50%;
            margin: toRem(10) auto;
            display: flex;
            padding: toRem(5);

            @include media('<=tablet') {
                img {
                    padding-right: 0;
                    width: 100%;
                }
            }
        }

        .title {
            @include font-size(14);
            font-weight: 600;
            color: $blue-color;
            text-transform: uppercase;
            @include media('>tablet') {
                margin-top: toRem(7);
            }

            @include media('<=tablet') {
                display: inline-block;
            }
        }
        .bordered-link {
            display: inline-block;
            @include font-size(13);
            border-top: toRem(1) solid $secondary-border-color;
            padding-top: toRem(5);
            margin-top: toRem(5);
            [class*='simple-tooltip__'] {
                margin-left: toRem(6);
            }
        }

        a:focus {
            text-decoration: underline;
            color: $blue-color;
        }
    }
}
