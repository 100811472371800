.institutionListBlock {
    background: $light-gray-color;
    border: toRem(1) solid $secondary-color;
    padding: toRem(20) toRem(29);
    margin-bottom: toRem(30);
    &__label {
        @include font-size(20);
    }
    &__list {
        width: 100%;
        background-color: $white;
        -webkit-appearance: none;
        appearance: none;
        background-image: url(../../../images/down-arrow.png);
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        padding-right: toRem(28);
        cursor: pointer;
        &::-ms-expand {
            display: none;
        }
    }
}
