.saml__consent {
    &__message {
        margin-bottom: toRem(10);
        a {
            color: $blue-color;
        }
    }

    &__yes,
    &__no {
        @extend .btn;
        @extend .stretched;
        width: auto;
    }

    &__yes {
        @extend .blue;
    }

    &__no {
        @extend .transparent;
    }
}
