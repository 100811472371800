.counter-list {
    counter-reset: section;

    .multi-search__item {
        @include font-size(14);

        .multi-search & {
            position: relative;
            padding-bottom: toRem(16);
            border-bottom: toRem(1) solid $secondary-color;
            margin-bottom: toRem(16);

            &.multi-search__item {
                // increasing specificity
                padding-left: toRem(44);
            }
        }
        .flex-grid & {
            float: none;
        }

        &:before,
        &--title {
            font-family: $tertiary-font-family;
        }
        &:before {
            counter-increment: section;
            content: counter(section);
            display: inline-block;
            color: $highlight-color-darker;
            @include font-size(36);
            opacity: 0.6;
            vertical-align: top;
            position: absolute;
            margin-left: toRem(-44);
            line-height: toRem(75);
        }

        &--date,
        &--downloaded,
        &--citeded {
            margin: 0;
        }
        &--date {
            color: $primary-lighten-color;
            line-height: toRem(22);
        }

        &--title {
            @include font-size(14);
            @include media('>=tablet') {
                min-height: toRem(57);
            }
        }
        &--downloaded,
        &--serialName,
        &--citeded {
            font-style: italic;
            display: none;
        }
        &--downloaded,
        &--citeded {
            margin-top: auto;
            font-weight: 300;
        }

        &--serialName {
            color: $primary-lighten-color;

            .displaySerialName & {
                display: -webkit-box;
                min-height: toRem(38);
            }
        }

        &--downloaded {
            .displayDownload & {
                display: block;
            }
        }

        &--citeded {
            .displayCited & {
                display: block;
            }
        }
        &:last-child {
            flex-grow: 1;
        }
        .teaser--counter {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }

    .loa button:not(.removed-items-count) {
        text-decoration: none;
    }

    .colored-block & {
        margin-top: toRem(20);
    }
}
