.merge-request-form {
    margin-top: toRem(40);

    .reproducible {
        @extend .clearfix;
        margin-bottom: toRem(20);

        &-set {
            @include media('>=tablet') {
                &:not(:first-child) {
                    border-top: toRem(1) solid $secondary-color;
                    padding-top: toRem(20);
                }

                &:last-child {
                    border-bottom: toRem(1) solid $secondary-color;
                    padding-bottom: toRem(20);
                }
            }

            @include media('<tablet') {
                margin: 0 toRem(-15) toRem(25);
                padding: 0 toRem(15);
                border-bottom: toRem(2) solid $secondary-color;
            }
        }

        @include media('<tablet') {
            &:not(.repeated) + .repeated {
                border-top: toRem(1) solid $secondary-color;
                padding-top: toRem(20);
            }
        }

        input {
            width: 100%;
            background: $white;
        }

        .input-group__actions {
            a {
                width: 40%;
                font-size: toRem(21);
                display: inline-block;
                text-align: center;

                ~ a {
                    border-left: toRem(1) solid $secondary-color;
                }
            }
        }

        .add-ctrl-field {
            color: $blue-color;
        }

        .remove-confirmation {
            color: $primary-lighten-color;
        }

        @include media('>=tablet') {
            @at-root .merge-request-form__header,
                & {
                display: flex;
                //align-items: center;
            }
            .input-group {
                &__field-container {
                    width: 45%;
                    padding-right: toRem(60);
                }

                &__actions {
                    width: 10%;
                    text-align: right;
                    margin-left: auto;
                    margin-top: toRem(6);
                }
                &__right-icon {
                    right: toRem(70);
                }
            }
        }

        @include media('<tablet') {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: relative;

            .is-accessible {
                position: static;
            }

            .input-group {
                &__field-container {
                    width: 100%;
                    margin-bottom: toRem(10);
                    order: 2;

                    &.exclude {
                        order: 1;
                    }

                    &:first-child {
                        width: 80%;
                    }
                }

                &__actions {
                    width: 20%;
                    position: absolute;
                    bottom: toRem(14);
                    right: 0;
                    order: 3;

                    [class*='ctrl-field'] {
                        width: 50%;
                    }
                }
            }
        }
    }

    @at-root .split-request-form__header,
        &__header {
        @include media('>=tablet') {
            margin-bottom: toRem(20);
            padding-bottom: toRem(15);
            border-bottom: toRem(4) solid $secondary-color;

            label {
                width: 45%;
                @include font-size(14);
            }
        }

        @include media('<tablet') {
            display: none;
        }
    }

    @include media('<tablet') {
        .merge-request-action {
            .btn {
                padding-left: toRem(20);
                padding-right: toRem(20);
            }
        }

        .field-error-icon {
            top: toRem(32);
        }
    }

    .error-submit-msg {
        display: none;
        margin: toRem(8) toRem(6);
        color: $error-text-color;
        @include font-size(13);
        font-weight: 300;
        font-style: italic;
    }

    .cyclic-redirections-detected {
        input {
            border-color: $error-text-color;
        }
    }
}
