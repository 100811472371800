.artifacts-badges {
    margin: toRem(16) 0;
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    &__title {
        flex: 1;
        padding-left: toRem(10);
        color: $primary-color;
        @include font-size(12);
    }
    &__item {
        border: toRem(1) solid;
        border-left: 0;
        border-color: $secondary-border-color;
        padding: toRem(15) 0;
        &:last-child {
            border-right: 0;
        }
        @include media('<=tablet') {
            border-right: 0;
        }
    }
    &__img {
        img {
            max-height: toRem(40);
        }
    }
}
.artifacts-tools {
    .btn {
        color: $secondary-text-color;
    }
}
.asset-item {
    margin-bottom: toRem(20);
    &__download {
        margin-top: toRem(10);
    }
}
.artifact-body {
    @include media('<tablet') {
        margin-top: toRem(20);
    }
}
.artifact-item {
    border: toRem(1) solid $secondary-color;
    border-radius: toRem(10);
    display: block;
    overflow: hidden;
    margin-bottom: toRem(30);
    height: calc(100% - #{toRem(30)});
    background-color: $light-gray-color;
    &__header {
        display: flex;
        align-items: center;
        padding: toRem(16) toRem(24);
        border-bottom: toRem(1) solid $secondary-color;
        background: $white;
    }
    &__number {
        padding-left: toRem(14);
        font-weight: 600;
        @include font-size(22);
    }
    &__name {
        color: $primary-lighten-color;
        padding-left: toRem(10);
    }
    &__arrow {
        flex-grow: 1;
        color: $secondary-color;
        @include font-size(25);
        text-align: right;
    }
    &__body {
        padding: toRem(16) toRem(24);
    }
}
.artifact-meta {
    margin-top: toRem(20);
    margin-bottom: toRem(30);
}

.section--gray {
    .issue-item__detail {
        li {
            display: inline;

            &.label {
                display: inline;
            }
        }
    }
}

.artifacts {
    .truncate-list[aria-label='authors'] {
        li {
            &:nth-last-child(2) {
                > span {
                    display: none;
                }
            }
        }
    }
}

.relatedDo {
    font-size: toRem(14);
    line-height: 1.5;
    a {
        color: $primary-lighten-color;

        &:hover {
            color: $blue-color;
        }
    }
    .label {
        font-weight: 600;
    }
    .dot-separator:before {
        font-size: toRem(16);
        color: $black;
    }
}
