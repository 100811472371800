.sidebar-section {
    position: sticky;
    transition: top 0.3s;
    top: toRem(30);
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        transition: none;
        position: static;
    }

    .sections-box {
        max-height: calc(100vh - #{toRem(58)});
        overflow-x: hidden;
        overflow-y: auto;
        word-break: break-word;
    }
}

.sections-block {
    border-top: toRem(1) solid $secondary-border-color;
    color: $dim-blue-color;

    li {
        padding: toRem(8) 0;
        padding-left: toRem(16);
        position: relative;

        &:not(:last-child) {
            border-bottom: toRem(1) solid $secondary-border-color;
        }

        a {
            &.active {
                border: none;
                padding: 0;
                color: $black;
                font-weight: normal;
                font-style: normal;
                &:before {
                    color: $black;
                    border-color: $black;
                    border-width: toRem(8);
                    content: ' ';
                    position: absolute;
                    left: 0;
                    width: toRem(8);
                    height: toRem(16);
                    background: $black;
                    top: toRem(9);
                }
            }
        }
    }
}

.sections-box {
    .logo {
        background-color: $secondary-lighten-color;
        text-align: center;
        img {
            width: toRem(110);
            padding: toRem(5) 0;
        }
    }

    &.colored-block {
        .vol-issue {
            @include font-size(18);
            font-style: italic;
            font-weight: 600;
        }
    }
    .parent-item {
        margin-bottom: toRem(15);
    }

    .sb-scrollbar-container {
        &:not(.hide-side-bar) {
            right: 0;
        }
    }
}

.article__sections {
    @include font-size(14);

    .parent-item {
        font-style: italic;
        font-weight: 600;
        padding: toRem(10) toRem(15) 0;
        line-height: toRem(20);

        .truncate-text-css {
            display: -webkit-box;
        }

        &__title {
            margin-bottom: toRem(5);
            @include font-size(14);
        }
    }

    .vol-issue,
    .coverDate,
    .pageRange {
        display: block;
    }

    .vol-issue,
    .coverDate,
    .parent-item__subtitle,
    .pageRange {
        color: $tertiary-bg-color;
    }

    .coverDate,
    .pageRange {
        font-weight: 300;
        margin-top: toRem(5);
    }

    .sections-box {
        margin: toRem(3);
        border-radius: toRem(3);
        box-shadow: 0 toRem(2) toRem(6) rgba(0, 0, 0, 0.16);
        background: $white;
    }

    .sections-block {
        margin: 0;
        padding: toRem(10) 0;
    }

    li {
        font-style: italic;
        font-weight: 300;
        color: $tertiary-bg-color;

        &:not(:last-child) {
            border: none;
        }

        &.sb-list {
            padding: 0;
            display: contents;
        }
    }

    .logo {
        background-color: $light-gray-color;
    }

    @include media('>=tablet', '<1200px') {
        .logo,
        .content-navigation .control,
        .journal-name {
            display: none;
        }
    }
}

.comment-count {
    background: #75b6de;
    color: $white;
    border-radius: 100%;
    display: inline-block;
    min-width: toRem(18);
    height: toRem(18);
    text-align: center;
    vertical-align: middle;
    @include font-size(10);
    line-height: toRem(15);
    border: toRem(1) solid $secondary-bg-color;
    margin-left: toRem(5);
}
.sections-navigator {
    height: auto;
    &.custom-scrollbar {
        @include media('>phone') {
            max-height: toRem(350);
        }
    }
    li {
        list-style: none;
    }
    .sb-content {
        @include media('<tablet') {
            max-height: inherit !important;
        }
        li {
            a {
                span.label {
                    display: inline;
                    @include font-size(14);
                    margin-right: toRem(2);
                }

                &.active {
                    span.label {
                        color: $black;
                    }
                }
            }
        }
    }
    &--special-items {
        li {
            &:first-child {
                padding-top: toRem(18);
                &:before {
                    content: ' ';
                    display: block;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    top: toRem(-11);
                    height: toRem(17);
                    background: linear-gradient(
                        to bottom,
                        rgba(255, 255, 255, 0.02) 0%,
                        rgba(239, 239, 239, 0.24) 38%,
                        rgba(223, 223, 223, 0.47) 75%,
                        rgba(215, 215, 215, 0.52) 94%,
                        rgba(212, 212, 212, 0.26) 100%
                    );
                }
            }
        }
    }
}

.parentAccordion {
    &.accordion__control {
        width: auto;
        font-size: 0.5rem;
        margin-bottom: toRem(16);
        margin-top: toRem(7);
    }
    & + .accordion__content {
        background-color: $white;
        padding: 0;
        margin-top: toRem(16);
        li {
            padding: 0;
            margin-bottom: toRem(16);
            a {
                display: inline-block;
                &.active {
                    &:before {
                        left: toRem(-16);
                        top: 0;
                    }
                }
            }
        }
    }
    &.js--open {
        transform: rotate(180deg);
    }
}

.hasNestedMenu {
    a {
        display: inline-block;
        max-width: 87%;
    }
    .accordion__content {
        clear: none;
    }
}
