// todo: move to variables file
$pending-icon: #e4941c;
$index-10002: 10002;
$index-10001: 10001;

#shareBinder {
    .modal__dialog {
        max-width: toRem(716);

        @include media('<desktop') {
            padding: toRem(20);
        }
    }

    .icon-folder {
        color: $lighter-blue-color;
        margin-right: toRem(10);
        @include font-size(15);
        line-height: toRem(22);
    }

    .card--dark {
        font-style: italic;
        line-height: toRem(22);

        p,
        h6 {
            @include font-size(12);
            margin: 0;
        }
    }

    .shared-email-section {
        margin: toRem(23) 0 toRem(36);

        .input-group {
            margin: 0;
            flex-grow: 1;
        }

        .sharedEmailSubmit {
            &[disabled] {
                background: $secondary-color;
            }

            color: $white;

            @include media('<desktop') {
                margin: 0;
                margin-top: toRem(10);
                width: 100%;
            }
        }

        .flex-container {
            @include media('<desktop') {
                display: block;
            }
        }
    }

    .info--text {
        font-style: normal;
    }

    table {
        @include font-size(12);

        td {
            font-weight: 300;
        }

        .icon-checkmark,
        .icon-pending,
        .icon-cancel-bold {
            font-weight: 600;
            vertical-align: middle;
            margin-right: toRem(10);
        }

        .icon-pending {
            color: $pending-icon;
        }

        .icon-checkmark {
            color: $green-color;
        }

        .icon-cancel-bold {
            color: $primary-lighten-color;
            border-left: toRem(1) solid $secondary-color;
            padding: toRem(4) 0;
            padding-left: toRem(10);
        }

        .status {
            .icon-cancel-bold {
                color: $error-text-color;
                padding: 0;
                border: 0;
            }
        }
    }

    .sb-container,
    .sb-content {
        max-height: toRem(242) !important;
    }

    .sb-content {
        padding-bottom: toRem(24);
    }
    .sb-container {
        border-bottom: toRem(1) solid $secondary-color;
        margin-bottom: toRem(30);
    }

    .sb-container-noscroll .sb-scrollbar-container {
        right: 0;
    }

    @include media('<phone') {
        .sb-scrollbar-container {
            display: none;
        }
        .sb-content {
            width: 100%;
            padding-right: 0;
        }
    }

    th {
        padding-bottom: toRem(8);
        position: sticky;
        top: 0;
        background: $white;
        border-bottom: toRem(1) solid $secondary-color;
    }

    tbody {
        border-top: toRem(16) solid $white;
    }

    .replied-date,
    .invite-date,
    .status span {
        @include media('<desktop') {
            display: none;
        }
    }

    .sharedEmail {
        &::-webkit-input-placeholder {
            //chrome
            font-style: italic;
            font-weight: 300;
        }

        &::-moz-placeholder {
            //firefox
            font-style: italic;
            font-weight: 300;
        }

        &:-ms-input-placeholder {
            //ie
            font-style: italic;
            font-weight: 300;
        }
    }
}

#unshareBinder {
    z-index: $index-10002;
    ~ .modal-backdrop.in {
        z-index: $index-10001;
    }
}
