.conferences-banner {
    padding: toRem(24) toRem(30);
    background: #ddd;

    &__title {
        color: $white;
    }
    &__abstract {
        @include font-size(14);
        font-style: italic;
    }
    &__blockquote {
        @include font-size(25);
        color: $white;
        border-left: toRem(1) solid;
        margin-left: 0;
        padding-left: toRem(20);
    }
}
