.pairing-management {
    table {
        &.expandable {
            .table__mark {
                @include media('<desktop') {
                    width: toRem(50);
                }
            }
            tr {
                td {
                    &:nth-child(2) {
                        @include media('<desktop') {
                            width: calc(100% - #{toRem(50)});
                            position: relative;
                            padding-right: toRem(55);
                        }
                    }
                }
            }
            .table__control__button {
                @include media('<desktop') {
                    border-left: toRem(1) solid $separators-color;
                    padding-left: toRem(18);
                    padding-top: toRem(12);
                    padding-bottom: toRem(12);
                    position: absolute;
                    right: 0;
                    top: toRem(11);
                    margin: 0;
                }
            }
        }
        th {
            color: $black;
            @include font-size(14);
            padding: toRem(20) toRem(14) toRem(20) 0;
        }
        td {
            font-weight: normal;
            @include font-size(14);
            padding: toRem(20) toRem(14) toRem(20) 0;
        }
    }
    h5 {
        @include font-size(22);
    }
    &__filter {
        border-bottom: none;
        > div {
            border-bottom: none;
        }
        .otherFillter {
            margin: 0;
        }
        .small-block {
            width: 100%;
            .input-group {
                vertical-align: middle;
                display: inline-block;
                width: 46%;
                margin-left: 4%;
                @include media('<=tablet') {
                    margin-left: 0;
                    width: 100%;
                }
                input {
                    width: 100%;
                    background: $white;
                }
                label {
                    display: block;
                    margin-bottom: toRem(8);
                    @include font-size(14);
                }
            }
        }
        .dateFilter {
            .calendar {
                top: auto;
                bottom: toRem(12);
            }
        }
    }
    &__form {
        background-color: $light-gray-color;
        border: toRem(1) solid $input-border;
        padding: toRem(25);
        padding-right: 10%;
        @include media('<=tablet') {
            padding-right: toRem(25);
        }
        .refresh-button {
            .btn {
                background: transparent;
            }
        }
    }
    &__actions {
        float: left;
        .withSelected {
            background: $secondary-lighten-color;
            border-radius: toRem(30);
            padding: toRem(11) toRem(16);
            font-weight: normal;
            color: $primary-color;
            display: block;
            &.js--open {
                border: 0;
                padding-bottom: toRem(11);
            }
        }
        .viewDetailsBtn {
            border: 0;
        }
        .dropBlock__holder {
            padding: toRem(15) 0;
            li {
                border-bottom: none;
                @include font-size(14);
                color: $primary-color;
                .viewDetailsBtn {
                    font-size: inherit;
                    color: inherit;
                }
            }
        }
    }
    &-modal {
        .modal {
            &__body {
                label {
                    display: block;
                    width: 100%;
                    @include font-size(14);
                }
                input {
                    width: 100%;
                }
                .expires-days {
                    input {
                        width: toRem(60);
                    }
                }
            }
        }
    }
    .permissionFilter,
    .emailFilter {
        .radio--primary {
            margin-bottom: toRem(10);
        }
    }
    .label-txt {
        @include font-size(14);
    }
    &__row {
        color: $primary-lighten-color;
        transition: color 0.5s, background 0.4s;
        cursor: pointer;
        padding: 0 toRem(20);
        &:hover {
            background: $light-gray-color;
            color: $primary-color;
        }
        &.active-row {
            color: $primary-color;
        }
        .table__mark {
            text-align: center;
            .radio--primary span:before {
                float: none;
            }
        }
    }
    .addExternalEmailButton {
        a {
            padding: toRem(12) toRem(16);
            margin-top: toRem(1);
        }
    }
    .actionButtonsPanel {
        @include media('<tablet') {
            margin-bottom: 0;
        }
        .link {
            font-weight: 600;
        }
    }
}
[class*='pairing'] {
    .modal {
        &__header {
            h1 {
                text-align: left;
            }
        }
        &__footer {
            .btn {
                clear: none;

                &--cancel {
                    color: $primary-color;
                }
            }
        }
    }
}
