.loginBar {
    .sign-in-label,
    .profile-text {
        color: lighten($blue-color, 25%);
        font-weight: 600;
        display: block;
        @include media('<tablet') {
            display: none;
        }
    }

    .icon-userprofile,
    .icon-person_outline,
    .icon-profile_empty,
    .icon-person {
        color: $text-onDark;
        @include font-size(28);
        padding: 0;
        @include media('>=tablet') {
            display: none;
        }
    }

    &.dropBlock {
        @include media('>=tablet') {
            margin: toRem(-22) 0;
        }
    }

    &__dropBlock__holder {
        background-color: $white;
        color: $primary-color;
        @include font-size(14);

        @include media('>=tablet') {
            min-width: toRem(233);
            left: -50%;
            top: 100%;

            &.js--open {
                &:before {
                    content: '';
                    width: 0;
                    height: 0;
                    border: toRem(5) solid transparent;
                    display: inline-block;
                    border-bottom-color: $white;
                    position: absolute;
                    top: toRem(-10);
                    left: calc(50% - #{toRem(10)});
                }
            }
        }
        .rlist.separator > {
            li ~ li {
                margin: 0;
                padding: 0;
            }
        }

        a {
            padding: toRem(15) toRem(20);
            display: block;
        }

        i,
        .icon-person {
            display: inline-block;
            vertical-align: middle;
            color: $primary-lighten-color;
            margin-right: toRem(12);
        }
        .icon-person {
            @include font-size(19);
        }
        .icon-export {
            margin-left: toRem(10);
            margin-right: 0;
        }

        @include media('<tablet') {
            top: toRem(63);
            left: 0;
            right: 0;
            background: $primary-bg-color;
            color: $text-onDark;

            i,
            .icon-person {
                color: $text-onDark;
            }

            li {
                @include font-size(16);
                display: block;
                border-top: toRem(1) solid $primary-border-color;
            }
        }
    }
    &--beta {
        @include media('<tablet') {
            .individualUser {
                text-align: center;
            }
            .icon-person {
                margin: 0 auto;
                display: block;
                color: $primary-lighten-color;
            }
            .profile-text {
                font-weight: normal;
                font-size: toRem(9);
                color: $primary-lighten-color;
                display: block;
            }
        }
    }
}

.register-link {
    a {
        margin: 0;
        @include font-size(12);
        background: $register-btn-bg;

        @include media('<tablet') {
            font-weight: 500;
            @include font-size(16);
            background: transparent;
        }

        @include media('>=tablet') {
            display: inline;
        }
        &.btn {
            color: $register-btn-text;
        }
    }

    @include media('>=tablet') {
        padding: toRem(23) 0 toRem(22) toRem(15);
        margin-left: toRem(12);
        border-left: toRem(1) solid $primary-border-color;
        font-weight: 600;
    }
}

.navigation-login-dropdown-container {
    @include font-size(14);
    left: auto;
    right: toRem(-14);
    top: toRem(40);
    position: absolute;
    text-align: left;
    color: $text-onLight;
    box-shadow: 0 toRem(3) toRem(7) rgba($black, 0.15);
    z-index: $index-10;
    background: $white;
    min-width: toRem(200);

    @include media('<tablet') {
        @include font-size(16);
        top: toRem(65);
        left: 0;
        right: 0;
        background-color: $primary-bg-color;
        color: $text-onDark;
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            margin: 0;
            border-top: toRem(1) solid $secondary-border-color;
            @include media('<tablet') {
                border-top: toRem(1) solid $primary-border-color;
            }

            &:last-child {
                color: $light-pink-color;
            }
        }

        a {
            padding: toRem(15) toRem(25);
            display: inline-block;
            width: 100%;

            &:hover {
                background: darken($white, 10%);
            }
        }
    }
}
