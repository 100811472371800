figure {
    .pill-figures__content & {
        margin: 0;
        max-width: toRem(450);
    }
}

figcaption {
    @include font-size(14);
    font-weight: 300;

    .w-slide & {
        border-bottom: none;
        padding-bottom: 0;
    }
}

.figure {
    &__title {
        font-weight: 500;

        .w-slide & {
            display: inline-block;
            text-transform: none;
            text-align: justify;
            font-weight: bold;
            margin-bottom: toRem(10);
        }
    }
    &__image {
        margin-bottom: toRem(15);

        &--default {
            background-color: $secondary-lighten-color;
            height: toRem(256);
            width: toRem(434);
            margin-bottom: toRem(18);
            max-width: 100%;
        }

        &--wrapper {
            position: relative;
            display: inline-block;
            width: 100%;
            max-width: toRem(434);

            &:after {
                font-family: icomoon, sans-serif !important;
                speak: none;
                font-style: normal;
                font-weight: 400;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;

                @include font-size(70);
                color: darken($secondary-color, 13);
                position: absolute;
                left: calc(50% - #{toRem(37)});
                top: calc(50% - #{toRem(37)});

                .audio & {
                    @extend .icon-audiotrack, :before;
                }
                .video & {
                    @extend .icon-play_circle_filled, :before;
                }
            }
        }
    }
}

.figure-viewer {
    &__hold__list {
        overflow: auto;
    }
}
