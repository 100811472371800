.colored-block-rss {
    .titleHR,
    .title,
    .publicationImage {
        display: none;
    }
    .listOfPublications {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            border-bottom: toRem(1) solid $secondary-color;
            padding-bottom: toRem(11);
            margin-bottom: toRem(11);
            &:last-child {
                border: none;
            }
            a {
                font-weight: 400;
                margin-bottom: toRem(10);
                color: $black;
                height: auto;
            }
            .published {
                @include font-size(14);
                font-style: italic;
                color: $primary-lighten-color;
                padding-top: toRem(6);
            }
        }
    }
}
