.acm-browse {
    margin-bottom: toRem(40);
    &__title {
        @include font-size(28);
        font-weight: 300;
        color: $primary-color;
        @include media('<tablet') {
            @include font-size(22);
        }
    }
    &__icon {
        padding-right: toRem(20);
        width: toRem(86);
        .browse-svg-icon {
            fill: $secondary-color;
        }
    }
    &__meta {
        @include font-size(18);
        color: $primary-color;
        font-weight: 300;
    }
    &__title-count {
        font-weight: 600;
    }
    &__publisher-title {
        @include font-size(18);
        font-weight: 300;
        color: $primary-color;
        margin-top: toRem(12);
    }
    &__item {
        margin-bottom: toRem(20);
        background: $white;

        @extend .card--rounded;
        @extend .card--bordered;

        &-body {
            display: flex;
            padding: toRem(20) toRem(17) toRem(20) toRem(28);
            align-items: center;
            height: toRem(130);
            .icon-arrow-right-bold {
                color: $secondary-color;
                @include font-size(30);
            }
        }

        &-footer {
            padding: toRem(10) toRem(22) toRem(10) toRem(28);
            border-top: toRem(1) solid $secondary-color;
            display: block;
            color: $primary-color;
            height: toRem(42);

            .icon-arrow-right-bold {
                color: $primary-lighten-color;
            }
            svg {
                height: auto;
                vertical-align: middle;

                &.smallSvg {
                    width: toRem(25);
                    margin-right: toRem(5);
                }
                &.bigSvg {
                    width: toRem(15);
                    margin-right: toRem(10);
                }
            }
            .browse-svg-icon {
                fill: $primary-lighten-color;
            }

            &.active-item {
                background-color: $primary-lighten-color;
                svg {
                    path {
                        fill: $white;
                    }
                    ellipse {
                        fill: $primary-lighten-color;

                        &:first-of-type {
                            fill: $white;
                        }
                    }
                }

                span {
                    color: $white;
                }
                .icon-arrow-right-bold {
                    display: none;
                }
            }
        }
    }
    &__content {
        flex-grow: 1;
    }
    &__nav {
        .flex-grid {
            flex-wrap: nowrap;
            overflow-x: auto;
            @include media('<desktop') {
                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                    width: toRem(3);
                    height: toRem(3);
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: toRem(4);
                    background-color: $secondary-color;
                }
            }
        }
    }
    &__nav-item {
        width: 100%;
        margin-right: toRem(14);
        min-width: toRem(200);
        &:last-child {
            margin-right: 0;
        }
        .acm-browse {
            &__item-body {
                padding: toRem(14) toRem(10) toRem(10) toRem(16);
                height: toRem(74);
                .icon-arrow-right-bold {
                    @include font-size(25);
                }
                &.active-item {
                    background-color: $primary-lighten-color;
                    .browse-svg-icon {
                        fill: $white;
                    }
                    .acm-browse__title-count,
                    .acm-browse__publisher-title {
                        color: $white;
                    }
                    .icon-arrow-right-bold {
                        display: none;
                    }
                }
            }
            &__icon {
                width: toRem(46);
                padding-right: toRem(10);
                min-width: auto;
                svg {
                    vertical-align: middle;
                }
                .browse-svg-icon {
                    fill: $primary-lighten-color;
                }
            }
            &__publisher-title {
                @include font-size(12);
                margin: 0;
            }
            &__item-footer {
                @include font-size(11);
                padding: toRem(10) toRem(3) toRem(10) toRem(14);

                i {
                    padding-top: toRem(5);
                }
            }
        }
    }
    .grid-item {
        @include media('>=large') {
            min-width: max-content;
        }
    }
}
