.advanced-search {
    $this: &;
    background: $white;
    @extend .profile-main-container;

    &.advanced-search {
        padding-top: 0;
    }

    .page__header {
        margin-bottom: 0;
    }

    .info--text {
        font-style: normal;
    }

    &__search-in__add:not(.hidden),
    &__search-in__remove:not(.hidden) {
        @include media('>=tablet') {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: toRem(6);
        }

        @include media('<phone') {
            display: inline-block;
        }
    }

    [class*='card'] ~ [class*='card'] {
        border-top: none;
    }

    .advanced-search__search-in__remove {
        i {
            color: $primary-lighten-color;
            @include font-size(10);
            border: solid toRem(2);
            border-radius: 50%;
            display: block;
            width: toRem(21);
            height: toRem(21);
            text-align: center;
            padding-top: toRem(4);
        }
    }

    .advanced-search__search-in__add {
        i {
            color: $blue-color;
            font-weight: 200;
            @include font-size(22);
        }
    }

    .search__required__icon {
        display: none;
    }

    &__search-in {
        &__term {
            select {
                font-weight: normal;
            }

            width: 100%;
            margin-bottom: 0;
            padding-bottom: toRem(25);
            vertical-align: top;

            &:last-child {
                border: 0;
                padding-bottom: 0;
            }

            .ms-sel-ctn {
                min-height: toRem(40);
                flex-wrap: wrap;
                height: auto;

                input {
                    min-width: 10%;
                    height: auto;
                    min-height: 0;
                }
            }
        }

        & > .advanced-search__search-in__term {
            border-top: toRem(1) solid $secondary-color;
            padding-top: toRem(30);
        }
    }

    .mainlabel {
        @include font-size(18);
        font-weight: normal;
        padding: 0;
        margin-bottom: toRem(24);
        display: inline-block;
        margin-right: toRem(5);
    }

    &__search-in,
    &--filters-fields {
        .advanced-search__search-in__term {
            @include media('>=tablet') {
                display: flex;
            }

            select {
                margin: 0;
                margin-right: 1.76%;

                &:first-of-type {
                    width: 30%;
                    min-width: toRem(214);
                }

                @include media('<tablet') {
                    width: 100%;
                    margin-right: 0;
                }
            }

            input,
            .ms-ctn {
                margin: 0;
                width: 63%;

                .quickSearchFormContainer & {
                    width: 60%;
                }

                border-color: $secondary-color;

                @include media('<tablet') {
                    width: 91%;
                    margin-right: toRem(5);
                    margin-top: toRem(12);
                }
            }
        }

        .ms-ctn {
            .ms-sel-ctn {
                border-color: $secondary-color;
                display: flex;
                align-items: center;

                input {
                    margin: 0;
                    @include font-size(14);
                }
            }

            @include media('<tablet') {
                display: inline-block;
            }
        }

        .input-group {
            &:last-child {
                @include media('>=tablet') {
                    margin-bottom: 0;
                }
            }
        }

        @include media('<phone') {
            border-bottom: toRem(1) solid $secondary-color;
            margin-bottom: 0;
            padding-bottom: toRem(20);
        }
    }

    .timeFrame-to {
        display: block;
        margin-top: toRem(16);
    }

    &--date {
        select {
            margin: 0;
            width: 25%;
            @include media('>=tablet', '<desktop') {
                width: 50%;
            }
            @include media('<tablet') {
                width: 100%;
            }
        }

        #publicationDate {
            width: 100%;
            margin: 0;
        }

        .radio--primary {
            display: block;
            margin-bottom: toRem(15);
            padding-block: toRem(1);
            outline: 0;
            @include media('<tablet') {
                margin-bottom: toRem(20);
            }

            .label-txt {
                font-weight: 300;
                font-size: toRem(14);

                &::before {
                    margin-right: 0.313rem;
                }
            }
        }

        .from-to-label {
            margin-left: toRem(24);
            padding-right: toRem(10);
            font-weight: 300;
            width: toRem(50);
            display: inline-block;
            text-align: right;
            @include font-size(12);

            @include media('<tablet') {
                margin-left: 0;
                padding-right: 0;
            }
        }

        #fromYear,
        #toYear {
            width: 17%;

            @include media('>=tablet', '<large') {
                width: 40%;
            }

            @include media('<tablet') {
                width: 100%;
            }
        }

        #fromMonth,
        #toMonth {
            width: 24%;
            margin-right: toRem(15);
            margin-bottom: 1rem;

            @include media('>=tablet', '<large') {
                width: 40%;
            }

            @include media('<tablet') {
                width: 100%;
                margin: toRem(12) 0;
            }
        }

        #staticRangeSelect {
            display: block;
            margin: 0 toRem(20);
            width: 24%;
            @include media('<tablet') {
                margin: 0;
                width: 100%;
            }
            @include media('>=desktop', '<large') {
                width: 26%;
            }
        }
    }

    &--expand {
        border-top: toRem(1) solid $secondary-color !important;

        .input-group {
            margin-bottom: 0;
        }

        .left-bordered-title {
            margin: toRem(30) 0;
        }

        select {
            width: 40%;
            margin-right: toRem(10);
            background-color: $white;

            @include media('>=tablet', '<desktop') {
                width: 94%;
            }
            @include media('<tablet') {
                width: 87%;
                float: left;

                ~ span {
                    line-height: toRem(39);
                }
            }
            @include media('>=desktop', '<large') {
                width: 50%;
            }
        }
    }

    &__bottom-btns {
        @extend .clearfix;
        @include media('<tablet') {
            padding-top: toRem(20);
        }
    }

    &--query {
        ~ .advanced-search__bottom-btns {
            padding-top: toRem(30);
            border-top: toRem(1) solid $secondary-color;
        }

        .simple-tooltip__block--t {
            margin: 0 toRem(10);
            vertical-align: middle;
        }
    }

    .search-within-label {
        display: block;
        @include font-size(12);
        padding: 0;
        margin-bottom: toRem(8);
    }

    &__link {
        @include font-size(14);
        color: $blue-color;

        &:hover,
        &:focus,
        &:active {
            color: $blue-color;
        }
    }

    .simple-tooltip__mobile {
        @include media('>=tablet') {
            min-width: toRem(20);
            min-height: toRem(20);
            cursor: pointer;
            text-align: center;
        }
    }

    select,
    input:not(.advanced-search__autocomplete),
    textarea {
        min-height: toRem(40);
        @include font-size(13);
        color: $black;
        border-color: $secondary-color;
        padding: toRem(8) toRem(17);
        line-height: toRem(23);
    }

    &.advanced-search select {
        max-height: toRem(40);
        background-image: url(/specs/products/acm/releasedAssets/images/down-arrow.png),
            url(/specs/products/acm/releasedAssets/images/input-line.png);
        background-position: right 0.75rem center, right 2.4rem center;
        padding-right: toRem(38);
    }

    input {
        &::-webkit-input-placeholder {
            //chrome
            color: $primary-lighten-color;
        }

        &::-moz-placeholder {
            //firefox
            color: $primary-lighten-color;
        }

        &:-ms-input-placeholder {
            //ie
            color: $primary-lighten-color;
        }
    }

    &--filters-fields {
        .advanced-search__search-in__term .ms-ctn {
            width: 42%;
            @include media('<tablet') {
                width: 92%;
            }

            input {
                padding: 0;
            }
        }

        .advanced-search__search-in__term.reproducible.input-group {
            @include media('>=desktop', '<large') {
                display: block;
                select {
                    width: 48%;
                }
                .advanced-search__autocomplete__wrapper {
                    width: 95%;
                    margin-top: toRem(15);
                    display: inline-block;
                }
                .advanced-search__search-in__add {
                    display: inline-block;
                    vertical-align: bottom;
                    margin-bottom: toRem(5);
                }
            }
        }

        .advanced-search__search-in__term:last-child {
            .advanced-search__search-in__add {
                align-items: center;
                -ms-flex-pack: center;
                justify-content: center;

                @include media('<tablet') {
                    display: inline-block;
                    margin-top: toRem(20);
                }
            }
        }

        .advanced-search__search-in__term:not(:first-child) {
            .advanced-search__search-in__add {
                display: none;
            }
        }

        .advanced-search__search-in__term:first-child {
            .advanced-search__search-in__remove {
                display: none;
            }
        }

        @include media('<tablet') {
            [id*='searchArea'],
            [id*='operator'] {
                width: 100% !important;
            }

            select[id*='operator'] {
                margin-top: toRem(10);
            }
        }
        @include media('>=tablet') {
            [id*='operator'] {
                width: 19%;
            }
        }
    }

    .accordion {
        &__control {
            font-weight: 400;
            border: none;
            padding: 0;
        }

        &__content {
            background: transparent;
            padding: 0;

            .form-control {
                flex: 1;
            }

            .input-group__field-container {
                @include media('>=tablet') {
                    display: flex;
                    align-items: flex-end;
                }

                @include media('<tablet') {
                    &:after,
                    &:before {
                        content: '';
                        clear: both;
                        display: block;
                    }
                }
            }

            .btn {
                margin: 0;
                width: toRem(135);
                text-align: center;

                @include media('>=tablet') {
                    margin-left: toRem(12);
                }

                @include media('<tablet') {
                    float: right;
                    margin-top: toRem(10);
                }

                i.icon-reset {
                    font-weight: 600;
                }
            }
        }
    }

    &.advanced-search:before {
        background: $secondary-color;
    }

    &__hints ol {
        padding-left: toRem(24);
    }

    &--refinement {
        margin-bottom: toRem(16);

        .input-group {
            margin: 0;
        }

        .facet__list--applied li:last-child {
            background-color: transparent;
            color: $blue-color;
            float: right;
        }
    }
    &__autocomplete__wrapper {
        width: 42%;
        position: relative;
        @include media('<tablet') {
            width: 91%;
            margin-top: toRem(10);
        }
        .autoComplete_wrapper {
            border-radius: $border-radius;
            overflow: visible;
            &:focus-within {
                box-shadow: 0 0 toRem(6) toRem(2) $focus-color;
            }

            #{$this}__autocomplete {
                border-radius: 0;
                outline: 0;

                &:focus {
                    box-shadow: none;
                }
            }
            border: 1px solid $secondary-color;
            min-height: toRem(40);
            padding: $advanced-search-autocomplete-wrapper-p;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: $advanced-search-autocomplete-wrapper-gap;

            input {
                width: auto;
                border: none;
                outline: none;
                margin: 0;
                padding: 0;
                font-size: toRem(14);
                color: $primary-color;
            }

            input ~ input {
                max-width: 15%;
            }
        }
        .no-suggestions {
            position: absolute;
            top: toRem(-15);
            font-size: toRem(10);
            right: 0;
            color: $lighter-silver;
        }
        .single_highlight_class {
            color: $white;
            background-color: $lighter-dark-color;
        }
        .autoComplete__link {
            font-size: 1rem;
            padding: toRem(5);
            color: $suggestions-color;
        }
        .advanced-search__autocomplete {
            border: none;
        }
    }
}

.ms-ctn {
    .ms-trigger {
        border-left: toRem(1) solid rgba(0, 0, 0, 0.12);
        border-top-right-radius: toRem(2);
        border-bottom-right-radius: toRem(2);
        width: toRem(44);
        display: flex;
        align-items: center;
        margin: 0;
        justify-content: center;
        background-color: $white;

        &:hover {
            background-color: $white;
            width: toRem(44);
            right: 0;
            border-radius: 0;
        }

        .ms-trigger-ico {
            @extend .icon-arrow-down-bold;
            font-family: icomoon, sans-serif;
            margin: 0;
            border: none;
            color: $secondary-text-color;
            width: auto;
            height: auto;
        }
    }

    .ms-sel-ctn {
        @include media('<tablet') {
            width: 100%;
        }
    }

    .ms-sel-item {
        display: inline-table;
        @include font-size(14);
    }
}

#editableQuery {
    background: $white;
}

#fullQuery {
    background: $secondary-lighten-color;
}

#exportQuery {
    td {
        font-size: toRem(12);
        font-weight: 300;

        &:first-child {
            font-weight: 400;
            width: toRem(145);
        }
    }

    .btn {
        color: $white;
        margin-top: toRem(30);
    }
}

.exportQuery__name {
    input {
        margin: 0;

        &::-webkit-input-placeholder {
            //chrome
            color: $primary-lighten-color;
            font-size: toRem(12);
            font-style: italic;
        }

        &::-moz-placeholder {
            //firefox
            color: $primary-lighten-color;
            font-size: toRem(12);
            font-style: italic;
        }

        &:-ms-input-placeholder {
            //ie
            color: $primary-lighten-color;
            font-size: toRem(12);
            font-style: italic;
        }
    }
}

[data-target='#exportQuery'] {
    position: relative;

    .dot-flashing {
        display: none;
    }

    &.load {
        .dot-flashing {
            display: inline-block;
            left: 47%;
            width: toRem(10);
            height: toRem(10);
            border-radius: toRem(5);
            background-color: $white;
            color: $white;
            position: absolute;

            //-webkit-animation-name: dotFlashing;
            //animation-name: dotFlashing;
            //animation-duration: 1s;

            animation: dotFlashing 1s infinite linear alternate;
            animation-delay: 0.5s;
            z-index: $index-1;

            &:before,
            &:after {
                content: '';
                display: inline-block;
                position: absolute;
                top: 0;
                border-radius: toRem(5);
                background-color: $white;
                color: $white;
                width: toRem(10);
                height: toRem(10);

                //-webkit-animation-name: dotFlashing;
                //animation-name: dotFlashing;
                //animation-duration: 1s;

                animation: dotFlashing 1s infinite alternate;
            }

            &:before {
                left: toRem(-15);
                animation-delay: 0s;
            }

            &:after {
                left: toRem(15);
                animation-delay: 1s;
            }
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.47);
        }
    }
}

.advanced-search__timeframe {
    .timeframe {
        &__static-range {
            margin-bottom: toRem(23);
            outline: 0;
        }
    }
    #publicationDate-label {
        margin-bottom: toRem(23);
    }
    @include media('<phone') {
        padding-top: 0;
        border-bottom: none;
        border-top: none;
    }
}
