.alerts {
    &__msg,
    .intro-text {
        color: $primary-color;
        @include font-size(14);
        font-weight: 300;

        &-info {
            @include font-size(12);
            display: flex;
        }

        &-bottom-border {
            border-bottom: toRem(1) solid $secondary-color;
            padding-bottom: toRem(14);
        }
    }

    &__info-icon {
        color: $primary-lighten-color;
        @include font-size(15);
        padding-right: toRem(8);
    }

    h5 {
        margin-top: toRem(20);
    }

    [class*='btn'] {
        font-weight: normal;
    }

    &__select__all__actions {
        padding-top: toRem(10);
    }

    @include media('<desktop', '>=tablet') {
        .account-tab__nav {
            a {
                height: 100%;
                line-height: toRem(40);
            }
        }
    }
}

.alert {
    &__freq {
        padding: 0 toRem(23);
        margin-top: toRem(26);

        select {
            margin-top: toRem(8);
            max-width: toRem(290);
        }
    }

    &__format {
        margin-top: toRem(26);
        padding: 0 toRem(23);

        &-label {
            margin-bottom: toRem(8);
        }

        .radio--primary span:before {
            width: toRem(15);
            height: toRem(15);
            margin: toRem(5);
        }

        .input-group {
            margin-bottom: toRem(12);

            @include media('<phone') {
                margin-bottom: 0;
            }

            .input-group.left-side-label {
                justify-content: flex-start;
                margin-left: 0;

                .radio--primary + .radio--primary {
                    margin-left: toRem(50);

                    @include media('<phone') {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    &__content {
        h5 {
            border-bottom-width: toRem(5);
        }

        .alert__list {
            .input-group {
                @include border-style(0, toRem(2), $separators-color);
            }
        }
    }

    &__list {
        li {
            &:first-child {
                .input-group {
                    @include border-style(0, toRem(2), $separators-color);
                }
            }
        }

        .input-group {
            padding-bottom: toRem(10);
            margin-bottom: toRem(15);
        }
        td {
            h6 {
                margin-bottom: 0;
            }
        }

        @include media('<tablet') {
            td:not(.hidden):first-child {
                float: left;
                border: 0;
            }
            td {
                border-bottom: 0 !important;
            }
        }
    }
}

#frmAlerts {
    .manage-alert-markall {
        padding-bottom: toRem(10);
        border-bottom: toRem(1) solid $secondary-color;
        margin-top: toRem(45);

        .label-txt {
            @include font-size(16);
            font-weight: 400;
        }
    }
}

@include media('<phone') {
    #topicAlertsForm {
        .form-btn {
            .btn.big {
                padding-left: toRem(18);
                padding-right: toRem(18);
            }
        }
    }
}

.profile-main-container .table-responsive td {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 400;
        @include font-size(12);
    }
}
