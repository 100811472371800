.card {
    &:not(header):not(main) {
        padding: toRem(20);
        @extend .clearfix;
    }

    &--dark {
        padding: toRem(20);
        background: $light-gray-color;
        @extend .clearfix;
    }

    &--shadow {
        box-shadow: 0 toRem(4) toRem(8) rgba(0, 0, 0, 0.08);
    }

    &--rounded {
        border-radius: toRem(10);
    }

    &--bordered {
        border: toRem(1) solid $secondary-color;
    }

    &--arrow {
        position: relative;
        padding-top: toRem(30) + 5;
        &:before {
            content: '';
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            border: toRem(30) solid transparent;
            position: absolute;
            border-top-color: $body-bg;
        }
    }
}

.colored-block {
    margin-bottom: toRem(20);
    &.loadAjax-auto {
        min-height: toRem(250);
        opacity: 0.7;
        .slideShow & {
            min-height: toRem(196);
        }
    }
    &.shadow {
        box-shadow: 0 toRem(4) toRem(2) rgba($black, 0.1);
    }

    &__title {
        background: $secondary-lighten-color;
        padding: toRem(14) 0;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 0;

            &:not(.left-bordered-title) {
                border-left: toRem(8) solid;
                padding-left: toRem(8);

                .conference-landing & {
                    border-color: $conference-primary-color;
                }
            }

            font-style: italic;
            line-height: 1.125rem;
        }

        &.light-title {
            h5,
            h3 {
                opacity: 0.6;
            }
        }
        .contribs-grid & {
            @extend .light-title;
        }
        .title-header {
            font-size: toRem(16);
        }
    }

    &__content {
        position: relative;
        padding: toRem(16);
        background-color: $light-gray-color;

        &.gutterless {
            padding: 0;
        }
        .tabbed-content--proceedings-tabs {
            .tab__pane {
                padding-bottom: toRem(36);
                .more-link {
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                    margin-bottom: toRem(15);
                    @include font-size(13);
                }
            }
        }
    }

    &__dash {
        width: toRem(18);
        height: toRem(4);
        position: absolute;
        bottom: 0;
        right: toRem(16);

        .conference-landing & {
            background: $conference-primary-color;
        }
    }

    &__control {
        background: $primary-lighten-color;
        color: $white;
        min-height: toRem(53);

        .rlist--inline.separator > {
            li {
                border-right: none;
                border-left: toRem(1) solid lighten($primary-lighten-color, 10%);
                float: left;
            }
        }

        a {
            @include font-size(20);
            display: inline-block;
            padding: toRem(14);
            line-height: toRem(14);
            height: toRem(48);
            width: toRem(48);

            &.tab-link:not(.primary-blue-color) {
                color: $white;
            }
        }

        .icon-fullscreen {
            @include font-size(22);
        }

        strong {
            margin: 0;
            padding: toRem(16);
            display: inline-block;
            @include font-size(14);
            line-height: toRem(16);
        }
    }

    .js--sticko & {
        &:not(:first-child) {
            margin-top: toRem(10);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &--white {
        .colored-block {
            &__content {
                background: $white;
            }
        }
    }

    &-grid {
        .contrib-metrics--inline {
            height: 100%;
        }
        @include media('<desktop') {
            display: block;
        }
        @include media('>=desktop') {
            padding-bottom: toRem(20);

            .colored-block {
                height: 100%;
                display: flex;
                flex-direction: column;
                margin-bottom: 0;
                &__content {
                    height: 100%;
                    overflow: hidden;
                    //-padding-bottom: toRem(52);
                    //> div {
                    //    height: 100%;
                    //} // this caused many issues
                }
            }
        }
    }

    &--light {
        border-radius: toRem(5);

        .colored-block__title {
            @include font-size(12);
            padding: toRem(10);

            .title {
                font-weight: 600;
                margin-right: toRem(5);
            }
        }

        .badge {
            float: right;
        }

        .colored-block {
            &__content {
                background: $white;
            }

            &__img-holder {
                padding-right: toRem(15);
                max-width: toRem(120);

                img {
                    height: auto;
                    border: toRem(1) solid $secondary-lighten-color;
                }
            }

            &__desc {
                .title {
                    color: $blue-color;
                    h5 {
                        font-weight: 500;
                    }
                }

                .desc {
                    font-weight: 300;
                    @include font-size(14);
                }
            }
        }
    }
    &__desc {
        .flex-container & {
            flex: 1;
        }
    }

    &__header {
        padding-top: toRem(14);
        position: relative;
        &:after {
            content: '';
            position: absolute;
            border-bottom: toRem(1) solid #e0e0e0;
            width: 30%;
            left: 35%;
            height: 0;
            bottom: -1rem;
            z-index: $index-1;
        }
    }

    .content--empty {
        height: 100%;
        text-align: center;
        color: $primary-lighten-color;
        font-style: italic;
        /// block center
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
            max-width: 31%;
        }
    }
}

.to-fit-inside-coloredBlock {
    margin-left: toRem(-16);
    margin-right: toRem(-16);
}
