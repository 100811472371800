#appliedFacet {
    padding: 0;

    .clear {
        display: block;
        border-top: toRem(1) solid $secondary-border-color;
        text-align: right;
        margin: toRem(10) toRem(-15) 0;
        padding: toRem(15) toRem(15) 0;
        color: $blue-color;
        text-transform: capitalize;
    }
}

.facet__list--applied {
    li {
        background-color: $light-yellow-color;
        border: none;
        color: $text-onLight;
        @include font-size(14);
        font-weight: 500;
        padding: toRem(5) toRem(10);
        text-transform: capitalize;

        i {
            @include font-size(12);
            color: $light-yellow-color;
            cursor: pointer;
            background: black;
            padding: toRem(2);
            border-radius: 50%;
        }

        &.pinned-filter {
            background-color: $secondary-lighten-color;
            border: toRem(1) solid $secondary-color;
        }
    }
}
