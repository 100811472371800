.saved-searches {
    th {
        color: $primary-color;
    }

    tr {
        .action {
            margin: 0;
            padding: 0;
            width: auto;
            @include media('<tablet') {
                border-bottom: 0;
            }

            .table__action {
                padding: 0;
                margin: 0;
                text-transform: capitalize;
                color: $primary-color;

                &.disabledBtn {
                    text-decoration: none;
                }
            }

            text-align: right;

            &:last-child {
                text-align: left;

                .table__action {
                    @extend .separator-left;
                    padding-left: toRem(8);
                    @include media('>=tablet') {
                        margin-left: toRem(8);
                    }
                    font-size: 0;
                    color: $primary-lighten-color;

                    &:before {
                        content: '\e984';
                        font-family: icomoon, sans-serif;
                        @include font-size(18);
                        display: inline-block;
                        padding-top: toRem(3);
                    }
                }
            }

            .table__action[data-action-type='saved-search--run'] {
                &:before {
                    font-family: icomoon, sans-serif;
                    content: '\e944';
                    display: inline-block;
                    color: $blue-color;
                    @include font-size(18);
                    vertical-align: middle;
                    margin-right: toRem(5);
                }
            }
        }

        .frequency-text {
            cursor: default;
            width: toRem(90);
            text-align: center;
            font-weight: 500;
            border-color: $secondary-color;

            &:hover,
            &:focus {
                background-color: $white;
            }
        }

        .frequency-change {
            color: $primary-color;
            font-weight: 500;
            width: toRem(90);
            text-align: center;
        }
    }

    .table-responsive {
        th {
            &:nth-child(5) {
                display: none !important;
            }
        }
        td {
            &.frequencyInfo-td {
                display: none !important;
            }
        }
    }

    @include media('<tablet') {
        .table-responsive {
            .expanded {
                &:first-child {
                    padding-top: 0;

                    td:not(.hidden):first-child {
                        padding-top: 0.5em;
                    }
                }
            }
        }
    }

    td {
        font-weight: 600;
    }
}

.query--list.saved-searches {
    position: sticky;
    top: toRem(124);
    @include media('<tablet') {
        position: static;
    }

    h2 {
        margin-bottom: 0;
    }
    th {
        @include font-size(12);
    }

    td {
        font-weight: 300;
        padding: toRem(5);
    }

    tr .action:last-child .table__action {
        border-left: none;
    }

    p {
        @include font-size(14);
        font-weight: 300;
    }
}

#modal-changeFrequency {
    .modal__body {
        margin-top: toRem(30);

        .input-group {
            .radio--primary {
                margin-bottom: toRem(10);
            }

            .label-txt {
                vertical-align: sub;
            }
        }
        .btn {
            width: toRem(130);
        }
    }
}
