.pill {
    &-section {
        position: sticky;
        transition: all 0.3s;
        top: toRem(30);

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            position: static;
            transition: none;
        }

        &.js--sticky {
            position: sticky;
        }

        &.js--fixed {
            position: fixed;
        }

        &.js--open {
            position: fixed !important;
            z-index: $index-8;
        }
    }

    &-list {
        color: $tertiary-bg-color;

        @include media('>=tablet') {
            box-shadow: 0 0 toRem(2) rgba(0, 0, 0, 0.16);
            display: inline-block;
            margin: toRem(2);
            text-align: center;
            border-radius: toRem(20);
            overflow: hidden;
            //max-width: toRem(38);
        }
        @include media('<tablet') {
            min-width: 100%;
        }

        a,
        button {
            padding: toRem(18) toRem(9);
            display: inline-block;
            min-width: toRem(40);
            border: none;
            background-color: transparent;
            color: $tertiary-bg-color;

            &.disable {
                color: lighten($tertiary-bg-color, 30%);
                pointer-events: none;
            }

            @include media('<tablet') {
                padding: toRem(16);
                vertical-align: middle;
            }
        }

        li {
            background: $white;
            padding: 0 toRem(2);

            &:hover {
                background-color: $secondary-lighten-color;
            }

            &.pill-active {
                background-color: $tertiary-bg-color;
                color: $white;
                button,
                a {
                    color: $white;
                }
            }

            @include media('<tablet') {
                display: inline-block;
            }

            &.disable {
                cursor: not-allowed;

                &:hover {
                    background-color: $white;
                }
            }
        }

        i {
            display: block;
        }

        .icon-metric {
            @include font-size(13);
        }
    }

    &-authors-list {
        .orcid-account {
            display: none;
        }
    }
}

.coolBar {
    margin: 0 toRem(-15);
    position: sticky;
    top: toRem(50);
    z-index: $index-9;
    transition: top 0.5s, transform 0.5s;

    @include media('<tablet') {
        top: 0;
    }

    &__wrapper {
        background: $white;
        box-shadow: 0 0 toRem(10) rgba(0, 0, 0, 0.16);
        margin-bottom: toRem(3);
    }

    &__section {
        float: left;
    }
}

#article_Ctrl {
    @include font-size(11);
    color: $primary-lighten-color;
    padding: toRem(18) toRem(14) toRem(15);
    border-right: toRem(1) solid $secondary-shadow-color;
    display: inline-block;

    &.js--open {
        color: $secondary-lighten-color;
        background-color: $primary-lighten-color;
    }
}

.w-slide__pill {
    &.w-slide {
        transition: all 250ms, width 1ms;
        z-index: $index-999;
    }

    @include media('>=desktop') {
        min-width: toRem(440);
    }

    .w-slide {
        &_head {
            background-color: $secondary-lighten-color;
            color: $primary-lighten-color;
            max-height: toRem(56);
            padding: toRem(18) toRem(20);
            position: static;
        }

        &__back {
            float: right;
            display: block;
            outline: none;

            i {
                color: $primary-lighten-color;
            }
        }

        &__title {
            @include font-size(18);
            line-height: toRem(23);
            font-style: italic;
            font-weight: 600;
            position: static;
            max-height: 100%;

            &.js--open {
                transform: translateX(0%);
                color: $primary-lighten-color;
            }

            i {
                padding-right: toRem(13);
            }
        }

        &__content {
            padding: 0;
            overflow: hidden;
            position: relative;
            height: 100%;
        }
    }

    .nested--1 {
        padding-top: 0;
    }

    @at-root .pill-content,
        & {
        .tab {
            &__nav {
                li {
                    margin-bottom: 0;
                }

                a {
                    border-bottom: none;
                    padding: toRem(14) toRem(14) toRem(16);
                    @include font-size(17);
                    font-weight: 400;
                    line-height: toRem(20);
                    margin: 0;
                    font-style: normal;
                }

                .active {
                    a {
                        font-weight: normal;
                        background-color: $tertiary-bg-color;
                        color: $white;
                    }
                }

                border-bottom: toRem(3) solid $tertiary-bg-color;
            }

            &__pane {
                position: relative;
            }
        }
    }
}

[class*='pill'][class*='__content']:not(.pill-media__content) {
    overflow-y: scroll;
    height: calc(100vh - #{toRem(200)});
    padding-bottom: toRem(30);

    #pb-editor & {
        height: auto;
    }

    &::-webkit-scrollbar {
        width: 0.5em;
    }

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
        overflow: visible;
        border-radius: toRem(10);
        transition: all 0.5s;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
    }

    &:after {
        position: absolute;
        background: $white;
        height: 100%;
        top: 0;
        right: 0;
        width: 0.7em;
        -webkit-transition: all 0.5s;
        opacity: 1;
        content: '';
    }

    &:hover {
        &:after {
            opacity: 0;
            z-index: $index-minus3;
        }
    }

    .section {
        &__separator {
            padding: toRem(25) toRem(20);
            margin: 0;
            list-style-type: none;

            @include media('<tablet') {
                padding: toRem(25) toRem(15);
            }
        }

        &__content {
            padding-left: 1rem;
        }
    }

    .extra-links {
        color: $blue-color;
        @include font-size(13);
        margin-top: toRem(15);

        @include media('>=tablet') {
            .back {
                &:after {
                    content: attr(title);
                    margin-left: toRem(5);
                }
            }
        }

        > a {
            text-decoration: underline;

            &.disabled {
                user-select: none;
            }
        }
    }

    .table {
        @include font-size(14);

        &__caption {
            font-weight: 300;
            margin-top: toRem(10);
        }
    }

    @include media('<phone') {
        .btn {
            width: 100%;
            margin: 0;
            text-align: center;
        }
    }
}

.metric {
    &s__list {
        color: $primary-text-color;

        li {
            padding: toRem(11) toRem(10) toRem(13);

            &:nth-child(even) {
                background-color: $secondary-bg-color;
            }
        }
    }

    &-name {
        max-width: 70%;
        display: inline-block;
    }

    &-value {
        float: right;
    }
}

#article {
    &_Pop {
        @include media('<tablet') {
            width: 100%;
            max-height: calc(100vh - #{toRem(64)});
            overflow: scroll;
        }
        background-color: $white;

        .sections-box {
            margin: 0;
        }

        .sections-block {
            padding: 0;

            li {
                padding-bottom: toRem(13);
                padding-top: toRem(13);
                border-bottom: toRem(1) solid $secondary-shadow-color;

                &:last-child {
                    border: none;
                }
            }
        }

        .content-navigation {
            border-top: toRem(1) solid $secondary-shadow-color;
            padding-top: toRem(15);
        }
    }
}

.tab-link.btn--inverse {
    color: $primary-text-color;
    font-weight: 400;
}

.stickybar__original__position {
    height: toRem(1);
    visibility: hidden;
}

.pill-references__content {
    li {
        margin-top: toRem(-16);
        padding-top: toRem(16);

        &:hover {
            background-color: $accessibility-bg;
        }
    }
}

.citedBy {
    li.references__item {
        margin: 0 toRem(-16) 0 toRem(-20);
        padding: toRem(8) toRem(16) toRem(16) toRem(16);

        &:hover {
            background-color: $accessibility-bg;
        }
    }
}

.video-meta {
    text-align: center;
    span {
        font-weight: 700;
        color: $primary-color;
        display: block;
    }
}
