.pill-figures__content {
    li:not(#pill-fig__content):focus {
        background: $light-yellow-color;
    }

    li#pill-fig__content {
        outline: 0;
    }

    .figure {
        &__image {
            max-width: 100%;
            max-height: toRem(300);
            margin-left: auto;
            margin-right: auto;
            display: block;
        }
        cursor: default;
    }

    .video {
        .figure__image--wrapper {
            .figure__image {
                cursor: pointer;
                width: toRem(333.88);
                height: toRem(187);
            }
            &:after {
                content: '\e957';
                color: $white;
                cursor: pointer;
                font-size: 3rem;
                margin-top: toRem(7);
                border-radius: 50%;
                background-color: rgba(0, 0, 0, 0.3);
                padding: toRem(7) toRem(6) toRem(7) toRem(12);
            }
        }
    }

    .figure__caption {
        display: inline-block;
        text-transform: none;
        text-align: justify;
        font-weight: 500;

        p {
            margin: 0;
        }
    }
}
