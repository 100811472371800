.citation {
    .issue-item {
        &__citation {
            width: 100%;
            line-height: toRem(15);

            @include media('<tablet') {
                margin-bottom: toRem(5);
            }

            .dot-separator {
                &:not(.float-none) {
                    &:before {
                        line-height: toRem(27);
                        float: left;
                    }
                }
            }
        }

        &__detail {
            @include font-size(14);
            margin: 0;

            .epub-section__pagerange {
                display: inline-block;
            }
            @include media('<tablet') {
                .label {
                    display: block;
                    margin-bottom: toRem(5);
                }
            }
            .comma-separator {
                span {
                    display: inline-block;
                }
            }
        }

        &__footer {
            @include media('<tablet') {
                border: none;
                padding-top: 0;
                z-index: $index-10;
                &-info {
                    float: none;
                    padding-bottom: toRem(15);
                    .rlist--inline {
                        line-height: toRem(22);
                        min-height: 0;
                    }

                    li {
                        &:not(:last-child) {
                            padding-right: toRem(10);
                        }
                    }

                    span {
                        display: inline-block;
                    }
                }

                &-links {
                    padding-top: toRem(6);
                    .rlist--inline:first-child {
                        margin-bottom: toRem(5);
                    }
                    .btn {
                        margin-bottom: 0;
                    }
                    .right {
                        float: right;
                    }

                    .saveToBinders {
                        + [data-db-target-of] {
                            @include media('<phone') {
                                top: toRem(40);
                            }
                        }
                    }
                }
            }
            @include media('<desktop') {
                &-links {
                    .rlist--inline:first-child {
                        margin-bottom: toRem(5);
                    }
                }
            }

            &-links {
                .rlist--inline {
                    li {
                        vertical-align: baseline;
                        .btn--icon {
                            border: none;
                        }
                    }
                }
            }
        }
    }

    .issue-heading {
        color: $tertiary-bg-color;
        margin-right: 0;
        font-weight: normal;
    }

    .dot-separator:before {
        color: $tertiary-bg-color;
    }

    .badges {
        vertical-align: middle;
        display: inline-block;

        .img-badget {
            margin-right: toRem(6);
            width: toRem(24);
        }
    }
    &__sub {
        &__label {
            @include font-size(18);
            font-weight: 600;
            margin-block: toRem(4);
        }
    }

    &__title {
        @include font-size(28);
        line-height: toRem(35);
        margin: 0;

        @include media('<tablet') {
            @include font-size(20);
            line-height: toRem(26);
        }
    }

    .label {
        color: $black;
        display: inline-block;
        @include font-size(14);
        margin-bottom: 0;
        margin-right: toRem(6);
    }

    .border-bottom {
        padding-bottom: toRem(15);
        margin-bottom: toRem(15);
    }

    .share__inline-links {
        margin: 0;
        float: right;

        @include media('<tablet') {
            float: right;
        }
    }

    .loa {
        margin: 0;
        &__item {
            position: relative;
            &.author-show-info-focus {
                .author-info {
                    opacity: 1;
                    visibility: visible;
                }
            }

            .icon-Email {
                font-size: toRem(10);
                margin-left: toRem(5);
            }
        }
        .label {
            font-weight: 400;
        }

        .accordion-tabbed {
            &__tab {
                border-top: none;
                display: inline-block;

                .icon-section_arrow_d {
                    display: none;
                }
            }
            &__control {
                padding: 0;
                color: $tertiary-bg-color;

                &:hover {
                    color: darken($primary-lighten-color, 10%);
                }
            }
        }

        &__link {
            color: $blue-color;
            text-decoration: underline;
            font-weight: 500;
            margin: 0 toRem(5);
            &:hover {
                color: darken($blue-color, 10%);
            }
        }

        button.removed-items-count {
            @include font-size(14);
            font-weight: normal;
        }
    }
    .loa_author_inst,
    .auth-metrics {
        display: none;
    }

    .auth-profile-link {
        @extend .blue;
        @extend .stretched;

        i {
            display: none;
        }
    }

    .author-info__body {
        .separated-block--dashed--top {
            background-image: none;
            padding-top: 0;
        }
    }
    .comma-separator {
        &:before {
            content: ',';
            padding-right: toRem(5);
            font-weight: 600;
            vertical-align: middle;
        }
    }
    .CitationCoverDate {
        font-size: 0.875rem;
        color: $primary-lighten-color;
    }
}

.author-name {
    display: inline-block;
}

.crossmark__logo {
    width: 7.813rem;
    display: inline-block;
    filter: drop-shadow(0 toRem(1) toRem(1) rgba($black, 0.3));
    vertical-align: bottom;
}

.inline-section {
    display: inline-block;
    width: 100%;

    .left-bordered-title {
        display: inline-block;
    }
    .crossmark__mini__logo {
        width: 2.3rem;
        vertical-align: bottom;
    }
}

.author-info {
    position: absolute;
    background: $white;
    border: toRem(1) solid $secondary-border-color;
    max-width: toRem(316);
    width: toRem(316);
    z-index: $index-7;
    padding: 0;
    box-shadow: 0 0 toRem(4) $secondary-shadow-color;
    border-radius: toRem(4);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    left: 0;
    top: toRem(8);

    @include media('<tablet') {
        display: none;
    }

    &__header {
        background: $secondary-bg-color;
        padding: toRem(10);
        border-bottom: toRem(1) solid $secondary-border-color;
        .icon-Icon_mail {
            display: inline-block;
            @include font-size(16);
            padding-left: toRem(8);
            color: $primary-lighten-color;
            vertical-align: middle;
        }
    }

    &__body {
        padding: toRem(12) toRem(16) toRem(23);

        p {
            margin-top: 0;
            line-height: toRem(19);

            ~ p {
                padding-top: toRem(14);
                border-top: toRem(1) solid $secondary-color;
            }
        }

        a {
            font-style: italic;
            line-height: toRem(19);
            color: $blue-color;
            @include font-size(13);
            text-decoration: underline;

            &.btn {
                text-decoration: none;
                font-style: normal;
            }
        }
    }
}

.formats__ctrl {
    @include media('<tablet') {
        text-transform: capitalize;

        .span {
            display: none;
        }
    }
}

#binder {
    padding-left: toRem(27);
    color: $primary-text-color;
    h4 {
        line-height: toRem(21);
        @include font-size(17);
        margin-bottom: toRem(20);
    }

    ul {
        max-height: toRem(210);
        overflow-y: scroll;
        margin-bottom: toRem(20);

        &::-webkit-scrollbar {
            width: toRem(5);
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background: $secondary-lighten-color;
            border-radius: toRem(4);
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $primary-color;
            border-radius: toRem(4);
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $black;
        }
    }

    .label-txt {
        @include font-size(14);
        color: $primary-text-color;
        text-transform: capitalize;
        font-weight: normal;
    }

    .input-group {
        margin-bottom: toRem(17);
    }

    .border-top {
        @include font-size(14);

        margin-left: toRem(-27);
        margin-right: toRem(-17);
        padding-left: toRem(27);
        padding-top: toRem(14);
        border-top-width: toRem(1);
        border-color: $secondary-border-color;

        i {
            @include font-size(13);
            margin-right: toRem(10);
        }
    }
}

.comma {
    .label {
        &:after {
            display: none;
        }
    }
}

.publisher-site.publisher-site {
    display: none;

    i {
        margin-right: 0;
        margin-left: toRem(10);
    }
}

.article-citations {
    .publication-history-link,
    .in-print {
        color: $blue-color;
        text-decoration: underline;
        font-weight: 500;
        margin: 0 toRem(5);
        font-size: 0.875rem;
    }
    .issue-item__citation {
        display: block;
    }
}

.issue-item {
    &__footer-links {
        .btn--icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            outline: none;
        }
    }
}
