// todo: move to variables file
$reddit-colors: #dc513d;

.reddit-widget {
    @include media('<phone') {
        margin-top: toRem(30);
    }

    &__header {
        border-radius: toRem(4) toRem(4) 0 0;
        background-color: $reddit-colors;
        color: $white;
        padding: toRem(23) toRem(40);

        @include media('<desktop') {
            padding: toRem(20);
        }

        i {
            @include font-size(35);
            margin-right: toRem(12);
        }

        span {
            @include font-size(24);
            vertical-align: bottom;
        }
    }

    &__content {
        border-radius: 0 0 toRem(4) toRem(4);
        background: linear-gradient(180deg, #a94436 0.23%, #dc513d 9.35%), #4aa1eb;
    }

    .reddit-content {
        width: 88%;
        margin: 0 auto;

        .reddit-item {
            background-color: $white;
            border-radius: toRem(4);
            display: block;
            margin-bottom: toRem(15);
            padding: toRem(10);

            > div {
                display: inline-block;
                vertical-align: top;
            }

            .thumb-section {
                max-width: toRem(70);
                margin-right: toRem(10);

                img {
                    border-radius: toRem(4);
                    width: 100%;
                    height: auto;
                }

                + .info-section {
                    max-width: calc(100% - #{toRem(80)});
                }
            }

            .info-section {
                > span {
                    display: block;
                }

                .title {
                    font-weight: 600;
                    @include font-size(14);
                    margin-bottom: toRem(6);
                }

                .post-date {
                    color: $primary-lighten-color;
                    @include font-size(13);
                    margin-bottom: toRem(6);
                }

                .options {
                    color: $primary-lighten-color;
                    @include font-size(12);
                    font-weight: 600;

                    a {
                        margin-right: toRem(8);

                        i {
                            margin-right: toRem(3);
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }

    .reddit-error {
        padding: toRem(12);
        background-color: $white;
        min-height: toRem(100);
        border-radius: 0 0 toRem(4) toRem(4);
    }

    .v-scroller {
        &__arrows {
            @include font-size(20);
            color: $white;
            padding: toRem(14);
        }
    }
}
