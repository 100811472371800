main.content {
    min-height: toRem(600);

    @include media('<tablet') {
        min-height: toRem(750);
    }

    a {
        &.link-nocolor-nounderline {
            color: inherit;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        &.link-blue-nounderline {
            color: $blue-color;
            text-decoration: none;

            &:hover {
                color: $dark-blue-link;
                text-decoration: underline;
            }
        }

        &.link-underline {
            color: $blue-color;
            text-decoration: underline;

            &:hover {
                color: $dark-blue-link;
                text-decoration: none;
            }
        }
    }
}

.footer {
    a:hover {
        opacity: 1;
        text-decoration: underline;
    }
}

.issue-item__title,
.chapter-heading,
.multi-search__item--title,
.box-item__title,
.creative-work__title {
    a {
        &:visited {
            color: $visted-link-color;

            &:hover {
                color: $visted-link-color;
            }
        }
    }
}
