//keyFrames
@keyframes right-arrow-anim {
    from,
    to {
        transform: translateX(0);
    }

    50% {
        transform: translateX(toRem(4));
    }
}

@keyframes left-arrow-anim {
    from,
    to {
        transform: translateX(0);
    }

    50% {
        transform: translateX(toRem(-4));
    }
}

@keyframes pulsate {
    from,
    to {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.2, 1.2);
    }
}

// Alerts fade up animation.
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInUp {
    animation-name: fadeInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
    display: block !important;
}

@keyframes pulseLocation {
    50% {
        box-shadow: 0 0 0 toRem(10) rgba(255, 255, 255, 0.1), 0 0 0 toRem(20) rgba(255, 255, 255, 0.2),
            0 0 0 toRem(40) rgba(238, 238, 238, 0.3);
    }
}

@-webkit-keyframes lazyPulse {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}
@keyframes lazyPulse {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}
@-webkit-keyframes fade-slide-in {
    from {
        opacity: 0;
        -webkit-transform: translateY(toRem(-10));
        transform: translateY(toRem(-10));
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@keyframes fade-slide-in {
    from {
        opacity: 0;
        -webkit-transform: translateY(toRem(-10));
        transform: translateY(toRem(-10));
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

// Hero Scroll down arrow.
@keyframes scrollIndicatorAnim {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }

    50% {
        transform: translate3d(0, toRem(8), 0);
    }
}

// Binder view animation.
@keyframes dotFlashing {
    0% {
        background-color: $primary-lighten-color;
    }
    50%,
    100% {
        background-color: $white;
    }
}

// Contrib profile animation.
@keyframes dial {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

// Share text on article page.
@-webkit-keyframes share-this-intro {
    0% {
        -webkit-transform: translateY(-3em);
        opacity: 0;
    }
    80% {
        -webkit-transform: translateY(0.5em);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(0);
    }
}

@keyframes share-this-intro {
    0% {
        transform: translateY(-3em);
        opacity: 0;
    }
    80% {
        transform: translateY(0.5em);
        opacity: 1;
    }
    100% {
        transform: translateY(0);
    }
}
