.submission-report {
    height: toRem(416);
    &--wrapper {
        margin-bottom: toRem(30);

        .sb-container-noscroll .sb-scrollbar-container {
            right: 0;
        }

        .icon-notice {
            color: $error-text-color;
            @include font-size(17);
        }

        .icon-checkmark {
            color: $success-text-color;
        }

        .icon-loop {
            color: $info-text-color;
        }

        .section {
            margin-bottom: toRem(20);
            padding: 0;

            .section-title {
                @include font-size(12);
                .separator-left {
                    margin-left: toRem(6);
                    padding-left: toRem(6);
                }

                i {
                    vertical-align: middle;
                }
            }

            ul {
                margin-left: toRem(20);
            }

            [class*='alert-msg'] {
                border: none;
                border-radius: toRem(4);
                margin-bottom: toRem(5);
            }

            span.length {
                margin-left: toRem(4);
            }
        }
    }
    .icon-pages {
        color: $blue-color;
    }
}
