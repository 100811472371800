.trusted-proxy {
    &-form {
        padding: 0;
        select {
            max-width: toRem(400);
        }
        [type='submit'] {
            margin-bottom: toRem(20);
            &:not(.btn--inverse) {
                background-color: $blue-color;
            }
            &.btn--inverse {
                background: transparent;
            }
            &.blue {
                &:hover {
                    @include hoverState($blue-color);
                }
            }
        }
        span {
            padding-top: toRem(6);
        }
        .minIP-field {
            @include media('>=large') {
                width: 53%;
                display: inline-block;
                input {
                    float: left;
                    max-width: 92%;
                }
                span {
                    float: left;
                }
                &:after {
                    content: '__';
                    display: inline-block;
                    vertical-align: middle;
                    color: $secondary-color;
                    margin-left: toRem(10);
                }
            }
        }
        .maxIP-field {
            @include media('>=large') {
                width: 46%;
                display: inline-block;
                vertical-align: top;
                float: right;
            }
        }
        &__delete-btn {
            margin-bottom: toRem(30);
        }
    }
}
