.share {
    &__inline-links {
        color: $tertiary-bg-color;
        @include font-size(19);
        line-height: toRem(14);

        a {
            padding: toRem(4) toRem(6) !important;

            &:hover {
                color: darken($tertiary-bg-color, 10%);
            }
        }

        .a2a li {
            margin-bottom: 0;
        }

        .a2a {
            display: flex;
            text-align: left;
            justify-content: center;

            & a {
                width: unset;
            }
        }
    }
}

.service-name {
    display: inline-block;

    .hidden-service-name & {
        @extend .visibility-hidden;
    }
}

.visible-service-name {
    [class*='a2a_button'] {
        &:after {
            content: attr(title);
            display: inline-block;

            @include media('<tablet') {
                content: '';
            }
        }
    }
}

.pill-share__content {
    .share__inline-links {
        .qr-container {
            display: inline-block;
            width: 100%;
            margin-top: toRem(20);

            .qr-title {
                color: $primary-color;
            }
        }
    }
}

.a2a .at-icon-wrapper {
    margin-right: 0;
}
