[class*='alert-msg'],
.notification {
    @include font-size(12);
    font-weight: 300;
    border-radius: toRem(25);
    padding: toRem(7) toRem(16);

    i {
        margin-right: toRem(10);
        @include font-size(16);
        vertical-align: middle;
    }
}

.alert-msg,
.notification {
    &--success {
        color: $success-text-color;
        background: $success-bg-color;
        border: toRem(1) solid $dark-success-bg-color;
    }

    &--error {
        color: $error-text-color;
        background: $error-bg-color;
        border: toRem(1) solid $dark-error-bg-color;
    }

    &--warning {
        color: $warning-text-color;
        background: $warning-bg-color;
        border: toRem(1) solid $dark-warning-bg-color;
    }

    &--info {
        color: $info-text-color;
        background: $info-bg-color;
        border: toRem(1) solid $dark-info-bg-color;
    }
    &--fixed {
        position: fixed;
        bottom: toRem(35);
        right: toRem(10);
        @include font-size(16);
        font-weight: 600;
        border-radius: toRem(5);
        padding: toRem(16);
        i {
            @include font-size(20);
        }
    }
    .my-accoutn__alerts__msg & {
        display: flex;
        align-items: center;
    }
}
.notification {
    &:before {
        content: '\e949';
        font-family: icomoon, sans-serif;
        @include font-size(14);
        vertical-align: middle;
        padding-right: toRem(6);
    }
}

.alert-popup {
    /// Position
    position: fixed;
    right: 0;
    bottom: 0;
    margin: toRem(24);
    max-width: toRem(330);
    z-index: $index-9010;
    overflow: hidden;
    transition: bottom 0.5s;

    /// small screen position
    @include media('<tablet') {
        width: 100%;
        margin: 0;
    }

    /// visibility
    display: none;

    /// Design
    background: $light-gray-color;
    border: toRem(1) solid $secondary-lighten-color;
    box-shadow: 0 toRem(6) toRem(10) rgba(0, 0, 0, 0.1);
    padding: toRem(24);
    /// Typography
    font-size: 0.75rem;
    line-height: 2;
    color: $primary-color;

    /// Title
    &__title {
        font-size: $h3-font-size;
        line-height: 1;
        /// Space for close button
        margin-right: toRem(40);

        // img in title
        img {
            max-width: toRem(40);
            height: auto;
            margin-right: 0.5ex;
            vertical-align: middle;
        }
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        margin: toRem(16);

        /// Design
        font-size: 1.125rem;
        font-weight: 600;
        color: $primary-lighten-color;
    }

    &__body {
        > :last-child {
            margin-bottom: 0;
        }
    }
}

.saveToBinders-list {
    .alert-msg--fixed {
        z-index: $index-1;
    }
}
