.login-page-container {
    @include media('>tablet') {
        padding-bottom: toRem(100);
    }
    .login-title {
        margin-bottom: toRem(30);
        @include font-size(22);
    }

    .sub-title {
        margin-bottom: toRem(10);
        @include font-size(22);
    }

    .small-desc {
        margin-bottom: toRem(12);
        font-weight: 300;
        @include font-size(14);
    }

    .login-head-titles {
        @include media('<tablet') {
            padding: 0 toRem(25);
        }
    }

    .border-right-side {
        @include media('>desktop') {
            border-right: solid toRem(1) $secondary-color;
        }
        @include media('<=desktop') {
            margin-bottom: toRem(20);
        }
    }

    .padding-0 {
        @include media('<tablet') {
            padding: 0;
        }
    }

    .btn {
        &.big {
            margin: 0;
        }
    }

    .tab__pane {
        .container {
            padding: 0;
        }
    }
}

.login-page-wrapper {
    .tab__nav {
        @extend .account-tab__nav;

        padding: 0 !important;
        border-bottom: solid toRem(2) $primary-lighten-color;

        a {
            @include font-size(12);
            font-weight: bold;
            border: toRem(2) solid $profile-primary-color;
            background: $default-bg-color;
            color: $primary-text-color;
            padding: toRem(15) toRem(8);

            @include media('<=phone') {
                padding: toRem(15) toRem(4) !important;
            }
        }

        span {
            @include media('<=phone') {
                font-size: toRem(10);
            }
        }

        li {
            margin-bottom: 0;

            i {
                display: inline-block;
                @include media('<tablet') {
                    display: block;
                }
                color: $secondary-color;
                @include font-size(25);
                margin-right: toRem(8);
                vertical-align: middle;
                font-weight: normal;

                &:before {
                    color: $secondary-color;
                }
            }

            &.active {
                a {
                    border-color: $primary-lighten-color !important;

                    i {
                        color: $white;
                        font-weight: normal;

                        &:before {
                            color: $white;
                        }
                    }
                }
            }
        }

        .institutions-list {
            margin: toRem(5) 0;
            a {
                border-radius: toRem(20);
                &:hover {
                    background: $secondary-lighten-color;
                }
            }
        }

        .active a {
            background: $profile-primary-color;
            color: $default-bg-color;
            border-color: $profile-primary-color;
        }
    }

    .tab__content {
        background: $white;
        box-sizing: border-box;
        padding: toRem(30);
        @include media('<=desktop') {
            padding: toRem(20);
        }
        @include media('>=tablet') {
            border: solid toRem(1) $secondary-color;
        }
    }
}
