.banner {
    $this: &;

    padding-top: toRem(176);

    @include media('<desktop') {
        padding-top: toRem(174);
    }

    @include media('<tablet') {
        padding-top: toRem(114);
    }

    &.banner__no-sub {
        padding-top: toRem(124);

        @include media('<desktop') {
            padding-top: toRem(124);
        }

        @include media('<tablet') {
            padding-top: toRem(64);
        }
    }

    &.banner__about-page {
        padding-top: toRem(164);

        @include media('<desktop') {
            padding-top: toRem(160);
        }

        @include media('<tablet') {
            padding-top: toRem(114);
        }
    }

    @include media('>=tablet') {
        margin-bottom: toRem(13);
    }
    &__text {
        color: $white;
        font-weight: 600;
        max-width: toRem(415);
        margin: 0;
        text-shadow: toRem(1) toRem(1) toRem(4) rgba($black, 0.4);

        @include media('<tablet') {
            @include font-size(13);
            font-weight: 400;
        }
        @include media('<phone') {
            @include font-size(12);
        }
    }
    &__holder {
        display: flex;
        align-items: center;
        height: toRem(175);
        position: relative;
        background: none;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            will-change: transform;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 50%;
            background-image: var(--banner);
            @include media('<tablet') {
                background-image: var(--banner-sm);
            }
            .conference-landing & {
                --banner: url(../../../images/conference-top-banner.jpg);
                background: var(--banner);
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0.5;
            }
        }
        @include media('<tablet') {
            padding: toRem(10) 0;
        }
        .browse-journals & {
            .title {
                @include font-size(80);
                line-height: 1em;
                @include media('<desktop') {
                    @include font-size(48);
                    margin: 0;
                    text-align: center;
                }
                @include media('<tablet') {
                    @include font-size(32);
                }
            }

            .quick-search {
                &-alt {
                    @include media('>=desktop') {
                        max-width: toRem(550);
                    }
                }

                &__input {
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }

        .advanced-search__link {
            color: $text-onDark;
            font-weight: 600;
            @include font-size(14);
            display: block;
            float: right;
            padding-top: toRem(5);
        }

        .container {
            @include media('<tablet') {
                width: 100%;
            }
        }

        .title {
            color: $text-onDark;
            margin: 0;
            text-shadow: toRem(1) toRem(1) toRem(4) rgba($black, 0.4);

            @include media('<desktop') {
                margin-top: toRem(15);
            }
            @include media('<tablet') {
                padding-top: toRem(10);
                text-align: center;
                margin: 0;
            }

            &.lines {
                &-3 {
                    font-size: 1.5vw;
                    line-height: 1.8vw;

                    @include media('<xlarge') {
                        font-size: 2.3vw;
                        line-height: 2.5vw;
                    }

                    @include media('<large') {
                        font-size: 2.5vw;
                        line-height: 3vw;
                    }

                    @include media('<tablet') {
                        font-size: 3.5vw;
                        line-height: 4vw;
                    }

                    @include media('<phone') {
                        font-size: 5.5vw;
                        line-height: 6vw;
                    }
                }

                &-2,
                &-1 {
                    font-size: 2vw;
                    line-height: 2.3vw;

                    @include media('<xlarge') {
                        font-size: 3vw;
                        line-height: 3.5vw;
                    }

                    @include media('<large') {
                        font-size: 4vw;
                        line-height: 4.5vw;
                    }

                    @include media('<tablet') {
                        font-size: 4.5vw;
                        line-height: 5vw;
                    }

                    @include media('<phone') {
                        font-size: 6.5vw;
                        line-height: 7vw;
                    }
                }
            }
        }
        .banner__text {
            & + .title {
                @include media('<tablet') {
                    text-align: left;
                }
                @include media('<tablet') {
                    padding: toRem(4) 0;
                }
            }
        }
        .col-lg-6 {
            @include media('>=desktop') {
                float: none;
                display: inline-block;
                vertical-align: middle;
            }

            &[class*='col-sm'] {
                @include media('>=tablet') {
                    float: none;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
    }
    &--conference {
        #{$this}__holder {
            @include media('<=phone') {
                height: toRem(210);
                .title {
                    text-align: left;
                }
            }
        }
    }

    .title {
        &.lines-1 {
            line-height: toRem(46);
        }
    }
}
.banner--books,
.banner--proceeding {
    .banner__holder {
        &::before {
            background-image: url(../../../images/books-banner-inner--default-colors-darker.svg);
            @include media('<=tablet') {
                background-image: url(../../../images/books-banner-mobile--default-colors.svg);
            }
        }
    }
}
.banner--sig {
    .banner__holder {
        &::before {
            background-image: url('/specs/products/acm/releasedAssets/images/sig-banner-inner--default-colors.svg');
            @include media('<=tablet') {
                background-image: url('/specs/products/acm/releasedAssets/images/sig-banner-mobile--default-colors.svg');
            }
        }
    }
}

.banner--artifacts {
    .banner__holder {
        &:before {
            background-image: url(../../../images/acm-artifacts.jpg);
        }
    }
}
.banner--artifacts-landing {
    .banner__holder {
        &:before {
            background-image: url(../../../images/artifacts-landing.jpg);
        }
    }
}

.dark-theme {
    .banner {
        background-color: $primary-color;
    }
}
.acmbooks {
    .banner {
        background-color: $csur-primary-color;
    }
}

.queue {
    .theme-banner--magazine {
        .banner__holder {
            @include media('<tablet') {
                height: toRem(300);
            }
            @include media('<=phone') {
                height: toRem(210);
            }
            &::before {
                background-image: url(../../../images/magazine-banner-inner--queue-colors.svg) !important;
                @include media('>=tablet') {
                    background-position: left;
                }
                @include media('<tablet') {
                    background-image: url(../../../images/magazine-banner-mobile--queue-colors.svg) !important;
                }
            }
        }
    }
    .banner {
        .quickSearchFormContainer {
            .quick-search {
                @include media('<tablet') {
                    margin-top: toRem(30);
                }
                @include media('<=phone') {
                    margin-top: toRem(50);
                }
            }
        }
    }
    .banner__holder {
        @include media('<tablet') {
            &::before {
                background-position: 50% 30%;
            }
        }
    }
}
