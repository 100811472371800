.article-media {
    &__item {
        margin-bottom: toRem(10);
    }
    h6 {
        font-style: italic;
        font-weight: 300;
        @include font-size(18);
        margin: toRem(20) 0 toRem(16);
    }
    &__title {
        @include font-size(14);
        font-family: $tertiary-font-family;
        color: $blue-color;
        font-weight: 400;

        @include media('>=tablet') {
            margin-bottom: toRem(10);
        }

        @include media('<tablet') {
            margin-top: toRem(16);
        }
    }

    .video {
        &__meta {
            @include media('<tablet') {
                clear: left;
            }

            .table__cell-view {
                width: auto;
            }

            .issue-downloads__item__details {
                width: 100%;
            }
            @media only screen and (max-device-width: toRem(767)) and (orientation: landscape) {
                width: toRem(415);
                display: inline-block;
                float: right;
            }
        }
        &__details p {
            margin: 0;
            @include font-size(12);
            line-height: toRem(18);

            &:not(.bold) {
                font-weight: 300;
            }
        }

        &__links {
            vertical-align: bottom;
            @include media('>=tablet') {
                text-align: right;
            }

            .btn {
                &:not(.blue) {
                    background-color: $light-gray-color;
                    color: $primary-text-color;

                    i {
                        @include font-size(16);
                        color: $blue-color;
                        vertical-align: middle;
                    }
                }
            }
        }
        &__image {
            @media only screen and (max-device-width: toRem(767)) and (orientation: landscape) {
                width: toRem(250);
                display: inline-block;
            }
        }
    }
    &__links {
        .separated-block {
            margin-bottom: toRem(8);
        }
        p {
            margin: toRem(8) 0;
        }
        a {
            color: $blue-color;
        }
    }

    .flowplayer {
        z-index: $index-1;
    }
    .issue-downloads__item {
        @include font-size(14);
        a {
            color: $primary-text-color;
        }
    }
    .cloudflare-stream-container {
        .cloudflare-stream-player {
            height: toRem(187);
        }
    }
}

@include media('<tablet') {
    .ios {
        .video__links,
        .issue-item__footer-links-video {
            .open-media-viewer {
                display: none;
            }
        }
    }
}
