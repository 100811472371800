.book-meta {
    .links {
        text-align: center;
        padding-bottom: toRem(15);

        @include media('<desktop') {
            border-bottom: toRem(1) solid $secondary-color;
            margin: 0 toRem(-15);
        }

        .link {
            display: inline-block;
            line-height: toRem(14);
            @include font-size(14);

            @include media('<desktop') {
                width: 50%;

                ~ .link {
                    border-left: toRem(1) solid $secondary-color;
                }
            }
        }
    }

    .appears-info {
        color: $primary-lighten-color;
        img {
            vertical-align: middle;
        }
    }

    .btn {
        .small {
            @include font-size(12);
            font-weight: 300;
            font-style: italic;
        }
    }
}
