.bold,
b,
strong {
    font-weight: 600;
}

.light-text {
    font-weight: 300;
}

.visibility-hidden {
    height: 0;
    display: inline-block;
}

.link {
    color: $blue-color;
    text-decoration: underline;

    &:hover {
        color: darken($blue-color, 10%);
    }
}

.text-desktop {
    display: inline;
    margin: 0 toRem(3);

    @include media('<desktop') {
        display: none;
    }
}

.text-mobile {
    display: none;

    @include media('<desktop') {
        display: inline;
    }
}

.text-jumbo {
    font-size: toRem(48);
}

.text-small {
    font-size: toRem(12);
}

.text-lead {
    font-size: $h1-font-size;
}

.text-muted {
    color: $secondary-color;
}

.more-link {
    border-top: toRem(1) solid $secondary-border-color;

    .colored-block__content & {
        margin-inline: toRem(-15);
    }

    margin-top: toRem(10);
    padding: toRem(16) toRem(8) 0;
    clear: both;
    @include font-size(14);
    text-align: right;

    a {
        color: $blue-color;

        .icon-Arrow-Stroke {
            display: inline-block;
        }
    }

    &--without-border {
        border: none;
    }

    &--align-left {
        text-align: left;
        padding: 0;
    }

    &:not(.no-animation) {
        a {
            &:hover {
                .icon-Arrow-Stroke {
                    animation: right-arrow-anim 0.8s ease-in-out 0s infinite;
                }
            }
        }
    }
}

.to-upper-case,
.uppercase {
    text-transform: uppercase;
}

.capitalized {
    text-transform: capitalize;
}

.centered-content {
    text-align: center;
}

.highlight {
    background-color: $highlight-color-new;
}

.vertical-center {
    vertical-align: middle;
}
