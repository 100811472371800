.pagination {
    margin: toRem(20) 0;

    &__list {
        li {
            padding: 0 toRem(18);
            @include media('<phone') {
                padding: 0;
            }

            &.dotsValue {
                &:after {
                    content: '...';
                }
            }

            &.hidden.hidden {
                padding: 0;
            }

            &:only-child {
                display: none;
            }
        }

        a {
            padding: 0 toRem(5);
            @include media('<phone') {
                padding: 0 toRem(10);
            }

            &.active {
                text-decoration: none;
            }
        }
    }
    &__btn--prev {
        &:before {
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            content: '\e624';
            @include font-size(14);
            @include ltr-rtl('pr', toRem(10));
        }
    }
    &__btn--next {
        &:after {
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            content: '\e625';
            @include font-size(14);
            @include ltr-rtl('pl', toRem(10));
        }
    }

    &__btn--prev,
    &__btn--next {
        vertical-align: sub;

        &::after,
        &::before {
            font-weight: 800;
        }
    }
}
