.quick-search {
    padding: toRem(14) 0 toRem(8);
    position: relative;

    .input-group {
        padding: 0;
    }

    &__input {
        @include font-size(14);
        height: toRem(32);
        padding: toRem(5);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &.auto-complete {
            border-color: darken($primary-lighten-color, 8);
            border-radius: toRem(2);
        }

        &::-webkit-input-placeholder {
            //chrome
            color: $text-onDark;
        }
        &::-moz-placeholder {
            //firefox
            color: $text-onDark;
        }
        &:-ms-input-placeholder {
            //ie
            color: $text-onDark;
        }
    }

    &__button {
        position: static;
        @include font-size(24);
        padding: 0 toRem(10);
        border-left: toRem(1) solid $primary-border-color;
        height: toRem(32);
        background: darken($primary-lighten-color, 8);
        cursor: pointer;
        margin: 0;

        &:before {
            transition: transform 0.25s ease-in-out;
            display: inline-block;
        }

        &:hover {
            background: darken($primary-lighten-color, 8);
            &:before {
                transform: scale(1.2);
            }
        }
    }

    &--input {
        position: relative;
        min-width: toRem(235);
        display: inline-block;
        vertical-align: top;
        @include media('<desktop') {
            min-width: toRem(140);
            width: toRem(140);
        }
    }
    &--button {
        display: inline-block;
    }

    &-alt {
        input {
            @include font-size(16);

            background-color: rgba($white, 0.92);
            color: $primary-color;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            transition: box-shadow 0.4s ease-in-out, background 0.4s ease-in-out;
        }

        .quick-search {
            &__input {
                padding: toRem(5) toRem(35) toRem(5) toRem(12);
                border: none;
                height: toRem(56);
                border-radius: toRem(4);
                max-height: 100%;

                @include media('<=tablet') {
                    height: toRem(46);
                }

                &::-webkit-input-placeholder {
                    //chrome
                    @include font-size(16);

                    color: $primary-lighten-color;
                    font-style: italic;

                    @include media('<=tablet') {
                        @include font-size(14);
                    }
                }

                &::-moz-placeholder {
                    //firefox
                    @include font-size(16);

                    color: $primary-lighten-color;
                    font-style: italic;

                    @include media('<=tablet') {
                        @include font-size(14);
                    }
                }

                &:-ms-input-placeholder {
                    //ie
                    @include font-size(16);

                    color: $primary-lighten-color;
                    font-style: italic;

                    @include media('<=tablet') {
                        @include font-size(14);
                    }
                }
            }

            &--input {
                min-width: 100%;
            }

            &__button {
                @include font-size(30);
                background: transparent;
                position: absolute;
                top: toRem(26);
                right: 0;
                border: none;
                @include media('<=tablet') {
                    @include font-size(20);
                    top: toRem(22);
                }

                &:hover {
                    background: transparent;
                }
            }

            &__extra-links {
                @include font-size(14);
                color: $tertiary-text-color;
                background: $primary-bg-color;
                padding: 0 toRem(12) 0 toRem(10);
                border-bottom-left-radius: toRem(4);
                border-bottom-right-radius: toRem(4);

                .separator > li:not(:last-of-type) {
                    border-right: toRem(1) solid $primary-border-color;
                    margin-right: toRem(10);
                    padding: toRem(5) toRem(10) toRem(5) 0;
                    @include media('<tablet') {
                        padding: toRem(5) toRem(5) toRem(5) 0;
                        margin-right: toRem(5);
                    }
                }
                .more {
                    float: right;
                    padding-top: toRem(5);
                }
            }
        }
    }
    .advanced-search__link {
        display: block;
    }
}

/* Auto complete block*/

.quickSearchAutocomplete {
    &.ui-widget.ui-widget-content {
        border: toRem(1) solid $secondary-lighten-color;
        border-bottom-left-radius: toRem(4);
        border-bottom-right-radius: toRem(4);
        @include font-size(12);
        line-height: toRem(20);
        //box-shadow: 0 0 toRem(1) rgba(0, 0, 0, 0.18);
        padding: 0;
        max-height: toRem(330);
        overflow-y: scroll;
        width: 100%;

        li {
            margin: 0;

            ~ li {
                border-top: toRem(1) solid $secondary-lighten-color;
            }

            a {
                display: block;
                padding: toRem(8) toRem(12);

                &.ui-state-focus {
                    border: none;
                    border-radius: 0;
                    margin: 0;
                }
            }
        }

        .type {
            font-style: italic;
            color: $primary-lighten-color;
            margin-right: toRem(22);
            display: inline-block;
            width: 20%;
            vertical-align: top;

            ~ .qsItem {
                display: inline-block;
                width: calc(80% - #{toRem(22)});
            }

            @include media('<tablet') {
                width: 100%;
                margin-right: 0;
                ~ .qsItem {
                    width: 100%;
                }
            }
        }

        .single_highlight_class {
            color: $green-color;
        }
    }
}

.ui-autocomplete:not(.quickSearchAutocomplete#ui-id-1),
.ms-res-ctn.dropdown-menu {
    z-index: $index-10;
}
