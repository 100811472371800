.expired__affiliations {
    display: none;
    width: toRem(448);
    padding: toRem(16) toRem(12) toRem(13) toRem(20);
    background-color: $affiliations-popup-bg;
    border-radius: toRem(5);
    position: fixed;
    z-index: $index-11;
    right: toRem(16);
    top: toRem(51);
    @include media('<desktop') {
        right: 0;
        width: 100%;
        border-radius: 0;
        top: toRem(65);
    }

    &__header {
        display: flex;
        &__title {
            font-size: toRem(18);
            font-weight: 700;
        }
    }

    &__close {
        &__button {
            margin-left: auto;
            order: 2;
            .icon-cancel-bold {
                font-size: toRem(14);
                color: $affiliations-popup-close-color;
                font-weight: bolder;
                vertical-align: super;
            }
        }
    }

    &__body {
        &__text {
            font-size: toRem(14);
            margin-top: toRem(9);
            line-height: toRem(20);
            font-weight: 400;
        }
        &__button {
            a {
                margin: toRem(10) 0 toRem(15) 0;
                width: 100%;
                font-weight: 700;
                font-size: toRem(18);
                text-align: center;
            }
        }
    }

    &__footer {
        .label-txt {
            font-weight: 500;
            &:before {
                background-color: $white;
                margin-right: toRem(6);
                border: toRem(1.6) solid $black;
                @include media('<desktop') {
                    border-radius: 0;
                }
            }
        }
    }
}
