.article__access-block {
    text-align: center;

    .info--text {
        font-family: $tertiary-font-family;
    }

    .logIn {
        margin-bottom: 0;
        @include font-size(12);
        font-weight: 300;
        color: $tertiary-bg-color;

        a {
            color: $blue-color;
            text-decoration: underline;
        }
    }

    .btn {
        i {
            @include font-size(17);
            line-height: 0.75rem;
            vertical-align: middle;
        }

        @include media('>=tablet') {
            min-width: toRem(190);
            @include media('<large') {
                margin: 0 toRem(3);
                padding: toRem(11) toRem(10);
            }
        }

        @include media('<desktop') {
            width: 100%;
            margin: 0;

            & ~ .btn {
                margin-top: toRem(14);
            }
        }
    }
}
