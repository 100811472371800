.generate-report {
    .card--dark {
        margin-bottom: toRem(10);
    }

    .find-box {
        @include media('>=tablet') {
            display: flex;
            align-items: flex-end;
        }

        label {
            display: block;
            @include font-size(12);
            font-weight: 600;
            color: $primary-color;
            margin-bottom: toRem(10);
            width: 100%;

            @include media('<tablet') {
                margin-bottom: 0;
            }
        }

        .input-group {
            @include media('>=tablet') {
                width: 40%;
                margin-right: toRem(25);
            }

            @include media('<tablet') {
                margin-bottom: toRem(20);
            }

            &__field-container {
                display: block;
            }
        }

        @include media('<tablet') {
            .btn {
                margin: 0;
                width: 100%;
            }
        }
    }

    &-table {
        table {
            table-layout: fixed;
        }
        thead {
            @include font-size(14);
            margin-bottom: toRem(20);
            padding-bottom: toRem(15);
            border-bottom: toRem(4) solid $secondary-color;
        }

        td,
        th {
            padding: toRem(26) toRem(24) toRem(26) 0;
        }

        th {
            padding-bottom: toRem(12);
        }

        tbody {
            @include font-size(12);

            td {
                font-weight: 500;
            }

            a {
                color: $blue-color;
            }
        }
    }

    select {
        margin-right: toRem(10);
        background-color: $white;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url(../../../images/down-arrow.png);
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        padding-right: toRem(28);
        cursor: pointer;
    }

    .empty-table-msg {
        @include font-size(14);
        padding: toRem(10) 0;

        i {
            margin-right: toRem(5);
        }
    }
}

.referrerUrl {
    word-break: break-all;
    display: block;
    width: toRem(130);
}
