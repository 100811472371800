.truncate {
    &-text,
    &-with-shadow {
        .ellipsis {
            color: $text-onLight;
        }
    }

    &-text,
    &-list,
    &-with-shadow {
        .read-more,
        .read-less {
            color: $blue-color;
            cursor: pointer;
            border: unset;
            background-color: unset;
        }
    }

    &-text {
        overflow: hidden;

        p:not(.allow-margin) {
            margin: 0;
        }

        p {
            &:first-child {
                margin: 0;
            }
        }

        .read-less {
            display: inline-block;
            margin-top: toRem(10);
        }
    }

    &-text,
    &-list {
        .icon-section_arrow_d,
        .icon-section_arrow_u,
        .icon-arrow_r {
            display: none;
        }
    }

    &-list {
        .count-list {
            display: inline;
        }
    }

    &-with-shadow {
        .read-more,
        .read-less {
            display: none;
            clear: both;
            text-align: right;

            i {
                @extend .icon-arrow-down-bold;
                @include font-size(8);
                font-weight: 600;
                vertical-align: middle;
                margin-left: toRem(10);
            }
        }
        &.trunc-done {
            .read-more,
            .read-less {
                display: block;
            }
        }
        .read-less {
            i:before {
                transform: rotate(180deg);
            }
        }
        .read-more {
            position: relative;

            &:before {
                content: '';
                width: 100%;
                display: inline-block;
                height: toRem(30);
                position: absolute;
                left: 0;
                bottom: 100%;
                background: linear-gradient(to bottom, rgba(250, 250, 250, 0) 0%, rgba(250, 250, 250, 1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fafafa', endColorstr='#fafafa', GradientType=0);
            }
        }
    }
}

.removed-items-count {
    background-color: $secondary-lighten-color;
    border: none;
    padding-bottom: toRem(5);
    cursor: pointer;
}
