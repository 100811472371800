.facet {
    border-color: $secondary-shadow-color;
    padding: 0 toRem(15);
    margin-right: toRem(-15);
    margin-left: toRem(-15);

    &__list {
        a {
            @include font-size(14);
            font-weight: 500;
            line-height: toRem(18);
        }

        li {
            padding: toRem(10) toRem(15);
            margin: 0 toRem(-15);
            border-top: toRem(1) solid $secondary-shadow-color;

            &:first-child {
                border: none;
                padding-top: 0;
            }
        }
    }

    &__title {
        text-transform: none;
        color: $black;
        font-weight: 500;
        @include font-size(14);
        padding-bottom: toRem(5);

        @include media('<tablet') {
            padding-right: 0;
            padding-left: 0;
        }

        .facet__content .js--open & {
            font-weight: 600;
        }
    }

    .show-more {
        font-weight: 500;
        text-transform: none;
        color: $blue-color;
        @include font-size(14);
        border-top: toRem(1) solid $secondary-shadow-color;
        padding: toRem(13) toRem(15);
        margin: 0 toRem(-15);
    }

    &__select--hidden {
        padding: 0 toRem(15) toRem(5);
        background: $white;
        margin: 0 toRem(-15);
    }

    &.accordion {
        .accordion {
            &__control {
                margin-bottom: toRem(8);
                margin-top: toRem(10);
            }

            &__content {
                padding: 0;

                li {
                    border: toRem(1) solid rgba(0, 0, 0, 0.07);
                    border-top-color: $secondary-shadow-color;
                    border-bottom: none;

                    @at-root .accordion__content .show-more,
                        & {
                        border: toRem(1) solid rgba(0, 0, 0, 0.07);
                        border-top-color: $secondary-shadow-color;
                        border-bottom: none;
                        margin: 0 toRem(-15);
                        background-color: $white;
                        padding: toRem(13) toRem(15);
                    }
                }
            }
        }
    }

    &:not(:last-child) {
        margin-bottom: 0;
    }

    &__counter {
        float: none;
    }

    &__label {
        vertical-align: middle;
    }

    &__content {
        .facet {
            &:first-child {
                .accordion__control {
                    margin-top: 0;
                }
            }
        }

        @include media('<tablet') {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &__icon {
        width: auto;
        height: auto;
        max-width: toRem(24);
        max-height: toRem(24);
        vertical-align: middle;
        margin-right: 1ex;
    }

    &__inline {
        li {
            @include font-size(14);
        }

        a {
            border: toRem(1) solid $secondary-border-color;
            background: $white;
            padding: toRem(5) toRem(13);
            margin-right: toRem(10);
            margin-bottom: toRem(10);
            display: inline-block;

            &:hover {
                background: $secondary-color;
            }
        }
    }

    ~ .facet:not(.accordion) {
        margin-top: toRem(20);
    }

    &s__title {
        font-weight: 600;
    }
}
.w-slide {
    .sticko__child,
    .colored-block {
        width: 100% !important;
    }
}
