.proceedings-browse {
    &--alphabetical {
        margin-top: toRem(35);
        margin-bottom: toRem(30);

        .accordion-tabbed {
            &__control {
                padding: 0;

                &:after {
                    padding: 0;
                    position: absolute;
                    right: toRem(20);
                    top: 37%;
                }

                &-title {
                    width: calc(100% - #{toRem(51)});
                    border-right: toRem(1) solid $primary-lighten-color;
                    padding: toRem(10) toRem(20) toRem(10) toRem(24);
                    display: inline-block;
                }
            }

            &__content {
                background-color: $secondary-lighten-color;
                padding: toRem(30);

                @include media('<desktop') {
                    padding: toRem(16);
                }
            }
        }

        .accordion-tabbed__tab:not(:first-child) {
            border-color: $primary-lighten-color;
        }

        .title {
            font-weight: 600;
        }

        .count {
            @include font-size(12);
            float: right;
            line-height: toRem(30);
            color: $secondary-color;
        }

        .link {
            text-decoration: none;
            @include font-size(13);
        }

        .dotted-list.dotted-list li {
            &.visibility-hidden {
                position: absolute;
            }

            a {
                //display: inline-block;

                &:after {
                    content: '';
                }
            }

            &.accordion-tabbed__tab:not(:first-child) {
                border: none;
            }

            .accordion-tabbed__content {
                padding: 0;
                border: none;
            }
        }

        .show-more-items__btn-holder {
            margin: 0;
            padding: 0;
            float: left;

            .btn {
                padding: 0;
                @extend .link;
                background: transparent;
                @include media('<tablet') {
                    line-height: 2rem;
                }
            }
        }
    }

    &__control {
        background-color: $primary-color;
        color: $white;

        &:active,
        &:focus,
        &:hover {
            color: $white;
        }

        .title {
            @include font-size(24);
            line-height: toRem(30);
        }

        &.accordion-tabbed__control {
            position: sticky;
            top: toRem(52);
            z-index: $index-8;
            transition: top 0.5s, transform 0.5s;

            @include media('<tablet') {
                top: toRem(50);
            }
        }
    }

    &--empty {
        margin-top: toRem(50);
        text-align: center;
    }
}

.accordion--proceedings {
    background-color: $white;

    .accordion-tabbed {
        &__content {
            background-color: $white;
            border-top: toRem(1) solid $secondary-lighten-color;
        }

        &__control {
            position: relative;

            &-title {
                border-color: $secondary-lighten-color;

                @include media('<desktop') {
                    padding-right: toRem(5);
                }
            }

            &:after {
                color: $blue-color;
            }
        }
    }

    .title {
        @include font-size(14);
        @include media('>=desktop') {
            max-width: calc(100% - #{toRem(100)});
            display: inline-block;
            @include font-size(16);
        }
    }

    .count {
        color: $primary-lighten-color;

        @include media('<desktop') {
            display: none;
        }
    }

    .accordion-tabbed__tab:not(:first-child) {
        border-color: $secondary-lighten-color;
    }

    .dotted-list,
    h4 {
        @include font-size(12);
        font-weight: 500;
    }

    //to check after design is final
    @include media('<tablet') {
        .link {
            display: block;
            float: none;
            text-align: left;

            &.pull-right {
                border-top: toRem(1) solid $secondary-lighten-color;
                margin-top: toRem(16);
                padding-top: toRem(16);
            }
        }
    }

    .dotted-list {
        .sponsored {
            margin-left: toRem(4);
        }
    }
}

.latest-proceeding__info {
    margin-bottom: toRem(40);
    @extend .clearfix;

    img {
        width: toRem(100);
    }

    p {
        max-width: toRem(800);
        font-weight: 300;
        line-height: toRem(23);
        margin-top: 0;
    }
}

.disabledLink {
    pointer-events: none;
}

// light proceedings styles start

.light__proceedings.proceedings-browse--alphabetical {
    .accordion-tabbed__control {
        &:after {
            color: $arrow-blue-color;
        }
        &-title {
            border-right: toRem(1) solid $secondary-color;
        }
        &__content {
            border-top: toRem(1) solid $secondary-color;
        }
    }
    .accordion-tabbed__tab {
        border: toRem(1) solid $secondary-color;
        border-bottom: none;
        &:last-child {
            border-bottom: toRem(1) solid $secondary-color;
        }
        a {
            background-color: $white;
        }
    }
    .count {
        color: $primary-lightest-color;
    }

    .js--open {
        .accordion-tabbed__content {
            border-top: none;
        }
        .proceedings-browse__control {
            border-bottom: toRem(1) solid $secondary-color;
        }
    }

    .proceedings-browse__control {
        background-color: $primary-color;
        .title {
            color: $primary-color;
        }
        &.accordion-tabbed__control {
            top: 0;
        }
    }
    .accordion--proceedings {
        background-color: transparent;
        .js--open {
            .accordion-tabbed__control {
                border-bottom: toRem(1) solid $secondary-color;
            }
        }
        .link {
            margin-top: toRem(15);
        }
        .accordion-tabbed__tab {
            background-color: $white;
            border-bottom: toRem(1) solid $secondary-color;
            &:not(:last-child) {
                margin-bottom: toRem(17);
            }
        }
    }
    .pull-right {
        &.link {
            display: none;
        }
    }
}

.proceedings-meta-light {
    display: inline-block;
    float: right;
    margin-top: 0;
    margin-bottom: 0;
    width: 45%;
    @include media('<tablet') {
        width: 100%;
        margin-top: toRem(15);
        float: none;
    }
    .quick-search--form {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .quick-search-alt {
        .quick-search--input {
            min-width: 84%;

            @media only screen and (min-device-width: toRem(768)) and (max-device-width: toRem(1294)) {
                min-width: 78%;
            }
        }
    }
}

.proceedings-title {
    &.border-title {
        display: inline-block;
        margin-top: toRem(15);
    }
}

// light proceedings styles end
