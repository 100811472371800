body {
    // Make a box shadow around the focus elments by Tap key.
    &.js--keyboard-on {
        button,
        a {
            &:focus {
                box-shadow: 0 0 toRem(6) toRem(2) $focus-color;
            }
        }
    }

    // Add background image to the body page.
    @include media('>=tablet') {
        &:not(.no-bg) {
            background-image: url(../../../images/BG1.svg), url(../../../images/BG2.svg);
            background-repeat: no-repeat, no-repeat;
            background-position: 161% 25%, -81% 64%;
            background-size: 55%, 62%;
        }
    }
}

// Styles for error pages.
.content {
    margin-top: toRem(20);
    padding-top: 0;

    &--padded {
        @include media('>=tablet') {
            margin-bottom: toRem(60);
            margin-top: toRem(60);
        }
    }
}

// Edit mode
#pb-editor {
    .header {
        height: auto;

        &--first-row {
            height: auto;
        }

        &__fixed-items {
            position: static;
            height: auto;
        }
    }

    .sub-nav {
        position: static;
    }

    .dynamic-sticko {
        position: static !important;
    }

    .sticko__child {
        height: auto !important;
    }

    .main-nav {
        .menubar__extra-mob-links {
            display: block;
        }
    }

    .c-hero-wrapper {
        .c-hero__hero-slider {
            height: 70vh;
        }
    }
}

.clearfix--sm {
    @include media('<=tablet') {
        clear: both;
    }
}

.description-box {
    margin: toRem(20) 0;

    .left-bordered-title {
        font-size: toRem(22);
    }
}

.mock-data {
    background-color: $lightgrey;
    background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent toRem(10),
        rgba(255, 255, 255, 0.5) toRem(10),
        rgba(255, 255, 255, 0.5) toRem(17)
    );
}

.loader {
    padding: toRem(10);
    text-align: center;
}

.icon-Arrow-Stroke {
    font-size: toRem(10);
    padding-left: toRem(10);
}

.section {
    margin-bottom: toRem(46);
    padding: toRem(30) 0;

    &--relative {
        position: relative;
    }

    &__title {
        margin-bottom: toRem(30);
    }

    &__title-light-gray {
        color: $primary-lighten-color;
        font-weight: normal;
        font-size: toRem(32);
    }

    &--gray {
        background-color: $light-gray-color;

        &-dark {
            background-color: $secondary-lighten-color;
        }
    }

    &--green-background-image {
        background-image: url(../../../images/green-dl-bg.jpg);
        background-size: cover;
        background-position: center;
    }

    &--green-background-image2 {
        background-image: url(../../../images/green-bg.jpg);
        background-size: cover;
        background-position: center;
    }

    &--bordered {
        border-top: toRem(1) solid $secondary-color;
    }

    &--no-margin {
        margin-bottom: 0;
    }

    &--no-padding-top {
        padding: 0 0 toRem(30) 0;
    }

    &--gutterless {
        margin: 0;
        padding: 0;
    }

    &--margin0-xs {
        @include media('<tablet') {
            margin: 0;
        }
    }
}

section {
    &.biography {
        figure {
            float: left;
            margin-right: toRem(10);

            img {
                max-width: toRem(100);
            }
        }
    }
}

.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;

    .main-nav.menu--res & {
        opacity: 0.5;
    }
}

.left-border {
    @include media('>=tablet') {
        border-left: toRem(1) solid $secondary-color;
        margin-left: toRem(50);
        padding-left: toRem(30);
    }
}

@include media('>=tablet') {
    .row-eq-height {
        display: flex;
    }
}

.inline-block {
    display: inline-block;
}

.page__header {
    font-weight: 400;
    display: inline-block;
    padding-right: toRem(14);
    margin-right: toRem(14);
}

.float-none {
    float: none;
}

.full-height-container {
    height: 100%;
}

.conf-banner {
    display: block;
    margin: toRem(15);
}

.relative-position {
    position: relative;
}

.notice-msg {
    color: $primary-lighten-color;
    display: flex;
    align-items: center;

    &__icon {
        padding-right: toRem(8);
    }
}

.responsive-embed {
    padding: toRem(20) 0;
}

.small-gutters {
    .row {
        margin-left: toRem(-7);
        margin-right: toRem(-7);
    }

    [class*='col-'] {
        padding-left: toRem(7);
        padding-right: toRem(7);
    }
}

.truncate-text-css {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */

    &[data-lines='1'],
    &[data-lines='2'],
    &[data-lines='3'],
    &[data-lines='4'],
    &[data-lines='5'] {
        -webkit-line-clamp: attr(data-lines);
    }

    > * {
        margin: 0;
    }

    &.desktop-only {
        @include media('<desktop') {
            display: block;
        }

        @media only screen and (min-device-width: toRem(768)) and (max-device-width: toRem(1024)) and (orientation: landscape) {
            display: block;
        }
    }

    &.mobile-only {
        @include media('>=desktop') {
            display: block;
        }
    }
}

.fixed-element {
    position: fixed;
}

.pb-ad {
    display: none;
    max-width: 64%;
    height: auto;
    align-items: center;
    position: fixed;
    bottom: toRem(20);
    left: 18%;
    background-color: $black;
    color: $white;
    padding: 0 toRem(12);
    border-radius: toRem(8);
    box-shadow: 0 0 toRem(16) rgba($black, 0.5);
    border: toRem(1) solid $primary-color;
    z-index: $index-9020;
    @include font-size(12);

    @include media('<desktop') {
        max-width: 80%;
        left: 10%;
    }

    @include media('<tablet') {
        align-items: baseline;
        max-width: 94%;
        left: 3%;
        bottom: toRem(10);
    }

    i.icon-Icon_Information {
        @include font-size(22);
    }

    i.icon-close_thin {
        @include font-size(15);
        cursor: pointer;
    }

    i {
        @include media('<tablet') {
            position: relative;
            top: toRem(10);
        }
    }

    p {
        margin: toRem(10);
    }
}

.hidden-tablet {
    @include media('<large') {
        display: none !important;
    }
}

.disable {
    color: $secondary-color !important;
    cursor: not-allowed !important;

    span,
    i {
        color: $secondary-color !important;
    }

    a {
        cursor: not-allowed !important;
    }

    &:hover,
    &:focus,
    &:active {
        background: $secondary-lighten-color !important;
    }
}

.disabledBtn {
    cursor: not-allowed !important;
    opacity: 0.5 !important;
}

.article__body {
    .link,
    .references {
        &:visited {
            color: $visted-link-color;

            &:hover {
                color: $visted-link-color;
            }
        }
    }
}

.warning-message {
    color: $dark-red-color;
}

// Backgrounds colors.
.light-gray-bg {
    background-color: $light-gray-color;
}

.primary-lighten-color {
    background-color: $primary-lighten-color;
}
