$share-this-vertical-offset: -0.25em !default;
$share-this-background: #333 !default;
$share-this-foreground: #fff !default;
$share-this-highlight-link: rgba(#fff, 0.25) !default;
$share-this-border-radius: 0.25em !default;
$share-this-arrow-size: 0.5em !default;

$share-this-sharer-width: 2em !default;
$share-this-sharer-height: 2em !default;
$share-this-sharer-padding: 0.35em !default;
$share-this-border-distance: 4em !default;
$share-this-animation-length: 0.3s !default;
$share-this-small-devices-max: toRem(575) !default;
@mixin share-this-transform($transformation...) {
    -webkit-transform: $transformation;
    -ms-transform: $transformation;
    transform: $transformation;
}
@mixin share-this-animation($animation...) {
    -webkit-animation: $animation;
    animation: $animation;
}
@mixin share-this-filter($filter...) {
    -webkit-filter: $filter;
    filter: $filter;
}

@mixin share-this-on-bottom() {
    &::before {
        bottom: auto;
        top: 100%;
        border-width: 0 $share-this-arrow-size $share-this-arrow-size;
        margin-top: 0;
    }

    > ul {
        top: 100%;
        transform: translateX(-50%);
        margin: $share-this-arrow-size 0 0;
    }
}

.share-this-popover {
    max-width: $share-this-border-distance * 2;
    pointer-events: none;
    @include share-this-filter(drop-shadow(0 toRem(1) toRem(3) rgba(0, 0, 0, 0.35)));
    @include share-this-animation(share-this-intro $share-this-animation-length);

    &::before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        width: 0;
        height: 0;
        margin: $share-this-vertical-offset + $share-this-arrow-size (-$share-this-arrow-size);
        border-width: $share-this-arrow-size $share-this-arrow-size 0;
        border-style: solid;
        border-color: $share-this-background transparent;
    }

    > ul {
        pointer-events: auto;
        list-style: none;
        padding: 0;
        margin: $share-this-vertical-offset - $share-this-arrow-size 0 0;
        white-space: nowrap;
        background: $share-this-background;
        color: $share-this-foreground;
        border-radius: $share-this-border-radius;
        position: absolute;
        left: 50%;
        @include share-this-transform(translate(-50%, -100%));

        > li {
            display: inline-block;
            width: $share-this-sharer-width;
            height: $share-this-sharer-height;
            line-height: $share-this-sharer-height;
            text-align: center;

            > a {
                display: inline-block;
                width: 100%;
                height: 100%;
                color: inherit;
                box-sizing: border-box;
                padding: $share-this-sharer-padding;

                &:hover,
                &:focus {
                    background: $share-this-highlight-link;
                }
            }
        }
    }

    @media (pointer: coarse) {
        font-size: 150%;
        @include share-this-on-bottom();
    }
    @media (max-width: $share-this-small-devices-max) {
        left: 0 !important;
        right: 0 !important;
        width: auto !important;
        max-width: none;
        @include share-this-on-bottom();

        > ul {
            left: 0;
            width: 100%;
            transform: none;
            border-radius: 0;
            text-align: center;
        }
    }
}
