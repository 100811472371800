.show-more-items {
    &__btn-holder {
        text-align: center;
        .btn {
            padding: toRem(12) toRem(54);
        }
        &.table-type {
            border: solid toRem(1) $secondary-color;
            background: $white;
            text-align: left;
            position: relative;

            .btn {
                padding: toRem(15) toRem(10);
                background: $white;
                border: none;
                margin: 0;
                width: 100%;
                text-align: left;
                color: $blue-color;

                i {
                    @include font-size(8);
                    vertical-align: middle;
                }
            }

            &.more {
                &:before {
                    content: '';
                    width: calc(100% - #{toRem(1)});
                    display: inline-block;
                    height: toRem(80);
                    position: absolute;
                    left: 0;
                    bottom: calc(100% + #{toRem(1)});
                    background: linear-gradient(to bottom, rgba(250, 250, 250, 0) 0%, rgba(250, 250, 250, 1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fafafa', endColorstr='#fafafa', GradientType=0);
                }
            }
        }
    }
}
