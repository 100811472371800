.loa {
    a {
        span {
            color: $loa-text-color;
        }
    }
    color: $tertiary-bg-color;

    li:not(.count-list) {
        line-height: 2rem;
    }

    a:not(.removed-items-count) {
        text-decoration: underline;
    }

    i {
        @include font-size(8);
        color: $blue-color;
        font-weight: 900;
    }
}

.item-meta__info {
    .loa {
        &.truncate-list {
            .label,
            label {
                margin-bottom: 0;
            }
            li:not(.count-list) {
                line-height: 1rem;
            }
        }
    }
}

.author-picture {
    margin-right: toRem(5);
    vertical-align: middle;
    height: toRem(24);
    box-sizing: content-box;
    @extend .img-rounded;

    &.author-picture {
        width: toRem(24);
        padding-right: 0;
    }
}

.dotted-list {
    @extend .rlist;

    li {
        position: relative;
        padding-left: toRem(25);

        &:before {
            content: '•';
            @include font-size(25);
            color: $secondary-color;
            line-height: toRem(18);
            position: absolute;
            left: 0;
        }
    }

    &--black-dots {
        li {
            &:before {
                color: $black;
            }
        }
    }

    &.rlist--inline {
        li {
            padding-left: 0;

            &:before {
                display: none;
            }

            &:not(:last-of-type) {
                padding-right: toRem(20);

                &:after {
                    content: '•';
                    @include font-size(25);
                    color: $secondary-color;
                    line-height: toRem(18);
                    position: absolute;
                    margin-left: toRem(5);
                }
            }
        }
    }
}

.ol--styled {
    font-weight: 300;
    font-size: 0.813rem;
    line-height: 1.93;
    padding-left: 1rem;

    > li {
        margin-bottom: 2rem;
    }
}

ol.separator {
    > li ~ li {
        border-top: toRem(1) solid $separators-color;
        margin-top: toRem(15);
        padding-top: toRem(15);
    }
}

.book-carousel__book-data,
.latest-issue-magazine,
.multi-search,
.multi-search--latest-issues,
.item-meta__info,
.issue-item--binder {
    .loa {
        li:not(.label) {
            margin-right: toRem(5);
        }
    }
}

.search-result,
.table-of-content-wrapper,
.classification-system__tabs {
    .issue-item__content-right {
        .loa {
            li {
                margin-right: toRem(5);
            }
            &.comma {
                > li:not(:last-child) {
                    &:after {
                        margin: 0;
                    }
                }
            }
        }
    }
}

ul {
    &.slide-item {
        list-style-type: none;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 0;
    }
}

.hidden-lg {
    &.hidden-md {
        @media only screen and (min-device-width: toRem(768)) and (max-device-width: toRem(992)) {
            display: none;
        }
    }
}
