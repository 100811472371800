.events-browse {
    .header-breadcrumb {
        margin-top: 0;
    }

    .article__breadcrumbs {
        margin-top: toRem(13);
    }

    &__meta {
        margin: toRem(30) 0 toRem(50);

        .left-bordered-title {
            @include font-size(22);
        }

        p {
            @include font-size(15);
            font-weight: 300;
        }

        .link {
            text-decoration: none;
        }
    }

    &__search {
        margin: toRem(50) 0;
    }

    &__list {
        margin-top: toRem(50);
    }

    &__gmap {
        position: sticky;
        top: toRem(176);
        width: 100%;
        transition: top 0.5s;

        #map {
            height: calc(100vh - #{toRem(52)}) !important;
        }
    }
}

.gm-style-iw-d {
    overflow: hidden !important;
    > div:not(.map-popup) {
        padding: toRem(16);
    }
}
.gm-style .gm-style-iw-c {
    border-radius: 0;
    padding: 0;
}

.gm-ui-hover-effect {
    top: 0 !important;
    right: 0 !important;
}
