.daterange {
    position: relative;
    .advanced-search--event & {
        height: toRem(40);
    }
}

.daterange * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.daterange div,
.daterange li,
.daterange span,
.daterange ul {
    margin: 0;
    padding: 0;
    border: 0;
}

.daterange ul {
    list-style: none;
}

.daterange.dr-active {
    z-index: $index-10;
}

.daterange .dr-input {
    display: flex;
    border: toRem(1) solid $dr-input-lighter-color;
    background-color: $white;
    position: relative;
    z-index: $index-5;
    overflow: hidden;
    height: toRem(40);
}

.daterange .dr-input:hover {
    border-color: $dr-input-light-color;
}

.daterange .dr-input.dr-active {
    -webkit-box-shadow: 0 0 0 toRem(3) rgba(38, 147, 213, 0.3);
    box-shadow: 0 0 0 toRem(3) rgba(38, 147, 213, 0.3);
    border-color: $dr-input-light-color;
}

.daterange .dr-input .dr-dates {
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 0 0.75rem;
    @include media('<=1410px') {
        .advanced-search--event & {
            padding: 0 0.5rem;
        }
    }
    min-width: calc(100% - #{toRem(35)});
}

.daterange .dr-input .dr-dates .dr-date {
    font-size: 0.938rem;
    @include media('<=1410px') {
        @include font-size(14);
    }
    padding: 0.656rem 0;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    outline: 0;
}

.daterange .dr-input .dr-dates .dr-date.dr-active,
.daterange .dr-input .dr-dates .dr-date:focus,
.daterange .dr-input .dr-dates .dr-date:hover {
    color: $dr-input-light-color;
}

.daterange .dr-input .dr-dates .dr-date:empty:after {
    content: attr(placeholder);
    color: $dr-input-grey-color;
}

.daterange .dr-input .dr-dates .dr-dates-dash {
    color: $dr-input-grey-color;
    padding: 0 toRem(5);
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    font-weight: 600;
}

.daterange .dr-input .dr-presets {
    width: 2.188rem;
    border-left: toRem(1) solid $dr-input-lighter-color;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.daterange .dr-input .dr-presets.dr-active,
.daterange .dr-input .dr-presets:hover {
    border-color: $dr-input-light-color;
    -webkit-box-shadow: inset 0 toRem(2) toRem(3) $dr-input-lightest-color;
    box-shadow: inset 0 toRem(2) toRem(3) $dr-input-lightest-color;
}

.daterange .dr-input .dr-presets.dr-active .dr-preset-bar,
.daterange .dr-input .dr-presets:hover .dr-preset-bar {
    background-color: $dr-input-light-color;
}

.daterange .dr-input .dr-presets .dr-preset-bar {
    height: toRem(2);
    background-color: $dr-input-lighter-color;
    margin: toRem(1) 0 toRem(1) 25%;
}

.daterange .dr-input .dr-presets .dr-preset-bar:nth-child(1) {
    width: 50%;
}

.daterange .dr-input .dr-presets .dr-preset-bar:nth-child(2) {
    width: 40%;
}

.daterange .dr-input .dr-presets .dr-preset-bar:nth-child(3) {
    width: 30%;
}

.daterange .dr-selections {
    position: absolute;
}

.daterange .dr-selections .dr-calendar {
    background-color: $white;
    font-size: 0.938rem;
    -webkit-box-shadow: 0 0 toRem(5) $dr-input-lighter-color;
    box-shadow: 0 0 toRem(5) $dr-input-lighter-color;
    -webkit-border-radius: toRem(5);
    border-radius: toRem(5);
    position: relative;
    overflow: hidden;
    z-index: $index-4;
    padding-top: toRem(5);
    top: toRem(-5);
    left: toRem(4);
    -webkit-transition: width 0.2s;
    -o-transition: width 0.2s;
    transition: width 0.2s;
    min-width: toRem(210);
}

.daterange .dr-selections .dr-calendar .dr-range-switcher {
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.375rem 0.5rem;
    font-size: 0.875rem;
}

.daterange .dr-selections .dr-calendar .dr-range-switcher .dr-switcher {
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: toRem(1) solid rgba(195, 202, 205, 0.5);
    -webkit-border-radius: toRem(5);
    border-radius: toRem(5);
    height: 1.563rem;
}

.daterange .dr-selections .dr-calendar .dr-range-switcher .dr-switcher i {
    color: $dr-input-lighter-color;
    position: relative;
    top: toRem(-1);
    cursor: pointer;
    font-size: 0.75rem;
    height: 100%;
    width: toRem(20);
}

.daterange .dr-selections .dr-calendar .dr-range-switcher .dr-switcher i:hover:after,
.daterange .dr-selections .dr-calendar .dr-range-switcher .dr-switcher i:hover:before {
    background-color: $dr-input-light-color;
}

.daterange .dr-selections .dr-calendar .dr-range-switcher .dr-switcher i.dr-disabled {
    pointer-events: none;
    opacity: 0;
}

.daterange .dr-selections .dr-calendar .dr-range-switcher .dr-switcher i:after,
.daterange .dr-selections .dr-calendar .dr-range-switcher .dr-switcher i:before {
    content: '';
    position: absolute;
    width: toRem(7);
    height: toRem(2);
    background-color: $dr-input-lighter-color;
    -webkit-border-radius: toRem(1);
    border-radius: toRem(1);
    left: 50%;
}

.daterange .dr-selections .dr-calendar .dr-range-switcher .dr-switcher i.dr-left:before {
    top: calc(50% - #{toRem(2)});
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    -ms-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
}

.daterange .dr-selections .dr-calendar .dr-range-switcher .dr-switcher i.dr-left:after {
    top: calc(50% + #{toRem(2)});
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
}

.daterange .dr-selections .dr-calendar .dr-range-switcher .dr-switcher i.dr-right:before {
    top: calc(50% - #{toRem(2)});
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
}

.daterange .dr-selections .dr-calendar .dr-range-switcher .dr-switcher i.dr-right:after {
    top: calc(50% + #{toRem(2)});
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    -ms-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
}

.daterange .dr-selections .dr-calendar .dr-range-switcher .dr-month-switcher {
    width: 100%;
    margin-right: 0.375rem;
}

.daterange .dr-selections .dr-calendar .dr-range-switcher .dr-year-switcher {
    min-width: toRem(80);
}

.daterange .dr-selections .dr-calendar .dr-days-of-week-list {
    display: flex;
    background-color: $dr-input-lightest-color;
    font-size: 0.625rem;
    color: $dr-input-grey-color;
    padding: 0.313rem 0;
    border: toRem(1) solid rgba(195, 202, 205, 0.5);
    border-left: none;
    border-right: none;
}

.daterange .dr-selections .dr-calendar .dr-days-of-week-list .dr-day-of-week {
    width: 14.28%;
    text-align: center;
}

.daterange .dr-selections .dr-calendar .dr-day-list {
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 0.938rem;
}

.daterange .dr-selections .dr-calendar .dr-day-list .dr-day {
    padding: 0.313rem;
    text-align: center;
    width: 14.28%;
    cursor: pointer;
    color: $dr-input-dark-color;
}

.daterange .dr-selections .dr-calendar .dr-day-list .dr-day.dr-hover:not(.dr-current) {
    background-color: $dr-input-lightest-color !important;
}

.daterange .dr-selections .dr-calendar .dr-day-list .dr-day.dr-hover-before {
    border-left: toRem(2) solid $dr-input-light-color !important;
    -webkit-border-radius: toRem(2) 0 0 toRem(2);
    border-radius: toRem(2) 0 0 toRem(2);
    padding-left: 0.188rem !important;
}

.daterange .dr-selections .dr-calendar .dr-day-list .dr-day.dr-hover-after {
    border-right: toRem(2) solid $dr-input-light-color !important;
    -webkit-border-radius: 0 toRem(2) toRem(2) 0;
    border-radius: 0 toRem(2) toRem(2) 0;
    padding-right: 0.188rem !important;
}

.daterange .dr-selections .dr-calendar .dr-day-list .dr-end,
.daterange .dr-selections .dr-calendar .dr-day-list .dr-selected,
.daterange .dr-selections .dr-calendar .dr-day-list .dr-start {
    background-color: $dr-input-lightest-color;
}

.daterange .dr-selections .dr-calendar .dr-day-list .dr-maybe {
    background-color: $dr-input-lightest-color !important;
}

.daterange .dr-selections .dr-calendar .dr-day-list .dr-fade {
    color: $dr-input-lighter-color;
}

.daterange .dr-selections .dr-calendar .dr-day-list .dr-start {
    border-left: toRem(2) solid $dr-input-light-color;
    -webkit-border-radius: toRem(2) 0 0 toRem(2);
    border-radius: toRem(2) 0 0 toRem(2);
    padding-left: 0.188rem;
}

.daterange .dr-selections .dr-calendar .dr-day-list .dr-end {
    border-right: toRem(2) solid $dr-input-light-color;
    -webkit-border-radius: 0 toRem(2) toRem(2) 0;
    border-radius: 0 toRem(2) toRem(2) 0;
    padding-right: 0.188rem;
}

.daterange .dr-selections .dr-calendar .dr-day-list .dr-current {
    color: $dr-input-light-color !important;
    background-color: rgba(38, 147, 213, 0.2) !important;
}

.daterange .dr-selections .dr-calendar .dr-day-list .dr-outside {
    pointer-events: none;
    cursor: default;
    color: rgba(195, 202, 205, 0.5);
}

.daterange .dr-selections .dr-preset-list {
    background-color: $white;
    color: $dr-input-light-color;
    font-size: 0.938rem;
    -webkit-box-shadow: 0 0 toRem(5) $dr-input-lighter-color;
    box-shadow: 0 0 toRem(5) $dr-input-lighter-color;
    -webkit-border-radius: toRem(5);
    border-radius: toRem(5);
    position: relative;
    overflow: hidden;
    z-index: $index-4;
    padding-top: toRem(5);
    top: toRem(-5);
    left: toRem(4);
    width: 100%;
}

.daterange .dr-selections .dr-list-item {
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding: 0.75rem 0.625rem;
    border-bottom: toRem(1) solid $dr-input-lightest-color;
    cursor: pointer;
    white-space: nowrap;
}

.daterange .dr-selections .dr-list-item:hover {
    background-color: $dr-input-lightest-color;
}

.daterange .dr-selections .dr-list-item .dr-item-aside {
    color: $dr-input-grey-color;
    font-size: 0.75rem;
    margin-left: 0.313rem;
    position: relative;
    top: toRem(-1);
}

.daterange--single .dr-input {
    cursor: text;
}

.daterange--single .dr-input .dr-dates {
    padding: 0;
    min-width: toRem(160);
    width: 100%;
}

.daterange--single .dr-input .dr-dates .dr-date {
    width: 100%;
    padding: 0.656rem 0.75rem;
    text-align: left;
    color: $dr-input-dark-color;
}
