.contrib-list {
    &__result-header {
        .sort-by-caption {
            display: inline-block;
            font-weight: 600;
        }

        .per-page__label {
            font-weight: 600;
        }
    }

    &__labels {
        padding-left: toRem(65);
        @include media('<tablet') {
            padding-left: toRem(45);
        }
        @include media('<phone') {
            padding-left: toRem(15);
        }

        .events-browse__list-info-labels {
            @include media('<tablet') {
                text-align: right;
            }
        }
    }

    &__list {
        @include media('<=tablet') {
            &:first-child {
                margin-top: toRem(8);
            }
        }

        display: flex;
        align-items: center;
        padding: toRem(8) toRem(15);
        @include media('<phone') {
            padding: toRem(10);
            display: block;
        }
        margin-bottom: toRem(8);
        background: $white;

        .list__img,
        .list__title {
            display: inline-block;
        }

        .list__img {
            margin-right: toRem(15);
            @include media('<phone') {
                margin-bottom: toRem(10);
            }

            div {
                width: toRem(50);
                height: toRem(50);
                margin: 0;
            }

            img {
                @extend .img-rounded;
                @extend .img-rounded--50;
            }
        }

        .list__title,
        .list__count {
            vertical-align: top;
            color: $primary-lighten-color;
            text-decoration: underline;
            @include media('<phone') {
                color: $primary-color;
                text-decoration: none;
                padding-top: 4%;
                max-width: 75%;
            }
        }

        .list__count {
            margin-left: auto;
            padding-right: 15%;
            @include media('<phone') {
                padding-right: 4%;
            }
        }

        .list__count_block {
            display: none;
            @include media('<phone') {
                display: block;
                color: $primary-lighten-color;
                border-top: solid toRem(1) $secondary-lighten-color;
                margin: toRem(0) toRem(-10);
                padding: toRem(12) toRem(12) toRem(2) toRem(74);
                font-style: italic;
                i {
                    float: right;
                    @include font-size(12);
                    padding-top: toRem(4);
                }
            }
        }
        .contrib-link {
            display: flex;
            align-items: center;
        }
    }
    &__empty-msg {
        color: $primary-lighten-color;
    }

    .search__bottom-left {
        display: none;
        @include media('<tablet') {
            display: inline-block;
        }

        .result__ctrl__filters {
            display: block;
            padding-top: toRem(5);
        }
    }

    .search-result__info {
        margin-top: 0;
    }

    @include media('<tablet') {
        .box-item {
            &__content {
                flex-direction: row;
                padding-bottom: toRem(10);
            }

            &__title-holder {
                margin-top: 0;
            }
        }
    }
    .box-item__title {
        color: $primary-lighten-color;
        text-decoration: underline;
    }
}

.custom-dropzone {
    [data-pb-dropzone='clearButtonZone'] {
        // this selector is temporary
        display: inline;
    }
}

.find_by {
    float: left;
    .inline-icon {
        padding-top: toRem(10);
    }
}
