.pill-citations__content {
    [data-target='#exportCitation'] {
        i {
            color: $primary-lighten-color;
        }
    }

    p:not(.info--text) {
        color: $primary-lighten-color;
        margin: toRem(10) 0;
    }
}

.w-slide__pill {
    .citation-count {
        line-height: toRem(16);
        margin-left: toRem(5);
    }
}

.count-bubble {
    @include font-size(10);
    min-width: toRem(18);
    height: toRem(18);
    color: $white;
    background: $count-bubble-blue;
    border-radius: toRem(20);
    border: toRem(1) solid $secondary-bg-color;
    display: inline-block;
    padding: 0 toRem(2);
    text-align: center;
    vertical-align: middle;
}

.article-citations {
    .mass-export {
        display: none;
    }
}

.citedBy-label {
    display: inline;
}

.header-container {
    .article-citations {
        float: right;
    }
    &:after {
        content: '';
        clear: both;
        display: table;
    }
}
