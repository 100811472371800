.flex-container {
    display: flex;

    &--wrap {
        flex-wrap: wrap;
    }
}

.flex-grid {
    @extend .flex-container;
    flex-wrap: wrap;
    float: none;

    &[class*='grid-']:not([class*='item']):not([class^='icon-']) {
        &:before,
        &:after {
            content: '';
            display: none;
        }
    }

    .flex-item {
        position: relative;
        min-height: toRem(1);
        padding-left: toRem(15);
        padding-right: toRem(15);
        float: none;
        flex: 0 33.33%;

        @include media('<=tablet') {
            flex: 0 100%;
        }

        margin-bottom: toRem(20);

        .creative-work {
            height: 100%;
            padding-bottom: 0;
        }
    }

    &.row {
        &:before,
        &:after {
            display: none;
        }
    }
}

.justify-between {
    justify-content: space-between;
}

.align-items-c {
    align-items: center;
}
