.advanced-search--event {
    margin-left: toRem(-15);
    margin-right: toRem(-15);

    input {
        padding: toRem(10) toRem(8);
        border-radius: toRem(2);
        margin: 0;
        @include font-size(14);
        line-height: toRem(18);
    }
    .input-group {
        .form-control {
            border: toRem(1) solid $secondary-shadow-color;
        }

        &:hover {
            .input-group-addon--right,
            .form-control {
                border-color: $blue-color;
            }
        }

        i {
            color: $primary-lighten-color;

            &.js--rotated {
                &:before {
                    transform: rotate(180deg);
                }
            }
        }

        &__field-container--right-icon {
            .form-control {
                border-right: none;
            }

            .ms-ctn {
                display: table-cell;
            }
        }
    }
    .input-group-addon--right {
        border-left: toRem(1) solid $secondary-shadow-color;
        border-top-right-radius: toRem(2);
        border-bottom-right-radius: toRem(2);
        padding: 0 toRem(15);
        max-width: toRem(44);
    }

    .current-location {
        cursor: pointer;
        padding: toRem(5) toRem(11);
        vertical-align: middle;

        i {
            font-size: toRem(20);
        }

        &.js-active i {
            color: $blue-color;
        }

        &:hover i {
            border-radius: 100%;
            animation: pulseLocation 1.1s infinite ease-in-out;
        }
    }

    .icon-arrow-down-bold {
        @include font-size(9);
    }
    .icon-Icon_Search {
        @include font-size(24);
    }

    .col-md-7 {
        position: relative;

        &:after {
            border-right: toRem(1) solid $secondary-shadow-color;
            content: '';
            position: absolute;
            height: calc(100% - #{toRem(25)});
            top: 0;
            right: 0;
        }
    }

    .dropBlock__holder {
        box-shadow: 0 0 toRem(10) rgba(82, 82, 82, 0.43);
        top: toRem(40);
        border-radius: toRem(2);
        padding: toRem(35) toRem(15) toRem(15) toRem(25);
        background-color: $white;
        margin-top: toRem(1);
    }

    .ms-ctn {
        padding: 0;

        .ms-sel-ctn {
            border: none;
            margin: 0;
            height: auto;
            padding: toRem(5) toRem(8);
            line-height: toRem(16);
            width: calc(100% - #{toRem(44)}) !important;
            overflow: hidden;

            input {
                float: none;
                line-height: toRem(28);
                padding: 0;
                //width: 100% !important;
            }
        }

        .ms-sel-item {
            @include font-size(14);
            font-size: toRem(14);
            color: $primary-color;
            background: $secondary-lighten-color;
            border: none;
            padding: toRem(5) toRem(13);
            border-radius: toRem(20);

            .ms-close-btn {
                background-image: none;
                font-family: icomoon, sans-serif !important;
                speak: none;
                font-style: normal;
                font-weight: 400;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;

                width: auto;
                height: auto;

                margin-top: toRem(3);
                @include font-size(10);

                &:after {
                    content: '\e970';
                }
            }

            &:hover {
                border: none;
            }
        }
    }

    .ms-ctn-focus {
        border: none;
        box-shadow: none;
    }
    .checkbox-wrapper {
        min-width: toRem(550);
        label {
            color: $primary-lighten-color;
            font-weight: 500;
        }

        .input-group {
            position: relative;
            padding: toRem(8) 0 toRem(4);
            margin-bottom: toRem(5);
        }

        .checkbox--primary input:not(.jcf):checked ~ span:after {
            content: '';
            background: $secondary-lighten-color;
            display: inline-block;
            position: absolute;
            top: 0;
            z-index: $index-minus;
            left: toRem(-11);
            right: 0;
            bottom: 0;
            border-radius: toRem(20);
        }
    }

    #advanced-search-btn {
        margin: 0;
        padding-left: toRem(40);
        padding-right: toRem(40);
    }

    #advanced-search-clear {
        &:disabled {
            border: none;
        }
    }

    .advanced-search__search-in__term input {
        width: 100% !important;
        margin: 0;
    }

    @include media('<=1520px', '>1310px') {
        .col-md-4 {
            width: 32%;
            &:nth-child(3) {
                width: 36%;
            }
        }
    }

    @include media('<=1310px', '>1250px') {
        .col-md-4 {
            width: 31%;
            &:nth-child(3) {
                width: 38%;
            }
        }
    }

    @include media('>1250px') {
        .col-md-4 {
            &:nth-child(4) {
                clear: left;
            }
        }
    }

    @include media('<=1250px', '>767px') {
        .col-md-4 {
            width: 50%;

            &:nth-child(2n + 1) {
                clear: left;
            }
        }
    }
    .ms-res-ctn.dropdown-menu {
        position: absolute;
        border: toRem(1) solid rgba(0, 0, 0, 0.15);
        top: 100%;
        @include ltr-rtl('left', 0);
        z-index: map-get($z-core, 'advanced-search-ms-res-ctn.dropdown-menu');
        display: none;
        padding: toRem(5) 0;
        margin: toRem(2) 0 0;
        list-style: none;
        background-color: $white;
        box-shadow: 0 toRem(6) toRem(12) rgba(0, 0, 0, 0.175);
        background-clip: padding-box;
    }
}

// old design for items inside sigs and conferences
//.gridView-wrapper {
//    min-width: toRem(550);
//    -webkit-column-width: toRem(120);
//    -moz-column-width: toRem(120);
//    -ms-column-width: toRem(120);
//    column-width: toRem(120);
//    -webkit-column-gap: toRem(10);
//    -moz-column-gap: toRem(10);
//    -ms-column-gap: toRem(10);
//    column-gap: toRem(20);
//
//    label {
//        color: $primary-lighten-color;
//        font-weight: 500;
//    }
//
//    .input-group {
//        position: relative;
//        padding: toRem(8) 0 toRem(4);
//        margin-bottom: toRem(5);
//    }
//
//    .checkbox--primary input:not(.jcf):checked ~ span:after {
//        content: '';
//        background: $secondary-lighten-color;
//        display: inline-block;
//        position: absolute;
//        top: 0;
//        z-index: $index-minus;
//        left: toRem(-11);
//        right: 0;
//        bottom: 0;
//        border-radius: toRem(20);
//    }
//}
