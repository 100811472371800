.slideshow-section {
    padding-top: toRem(30);
    padding-bottom: toRem(30);
    .slideShow {
        min-height: toRem(120);
        padding-top: toRem(40);
        .owl {
            &-carousel {
                padding: 0 toRem(50);
                @include media('>desktop') {
                    padding: 0;
                }
            }
            &-nav {
                .owl-prev,
                .owl-next {
                    color: $primary-lighten-color;
                    i {
                        color: $primary-lighten-color;
                    }
                }
                @include media('>desktop') {
                    .owl-prev {
                        left: toRem(-40);
                    }
                    .owl-next {
                        right: toRem(-40);
                    }
                }
                [class*='owl-'] {
                    top: 10%;
                }
            }
            &-dots {
                display: block;
                //margin: 0 toRem(-50);
                .owl-dot {
                    &.active,
                    &:hover,
                    &:focus {
                        span {
                            background-color: $blue-color;
                        }
                    }
                }
            }
        }
    }
    &.other-journals {
        .slideShow {
            .owl-nav {
                [class*='owl-'] {
                    top: 50%;
                }
            }
        }
    }
}

.award-winners-slide {
    @include media('>=1200px', '<=1380px') {
        width: 94%;
        margin: 0 auto;
    }

    margin-bottom: toRem(30);

    .owl-carousel {
        .owl-stage-outer {
            min-height: toRem(115);
        }
    }
}

.border-title {
    margin: 0;
    border-left: toRem(8) solid;
    padding-left: toRem(8);
    font-style: italic;
    line-height: 1.125rem;
}

.to-fit-inside-coloredBlock {
    .more-link {
        margin: 0;
        border: none;
        padding-top: 0;
    }
    .multi-search__item.multi-search__item {
        @include media('>=desktop') {
            &:nth-child(4n + 1) {
                padding-left: calc(#{toRem(44)} + #{toRem(16)});
            }
        }

        @include media('>=tablet') {
            &:nth-child(2n + 1) {
                padding-left: calc(#{toRem(44)} + #{toRem(16)});
            }
        }

        @include media('<tablet') {
            &.multi-search__item {
                padding-left: calc(#{toRem(44)} + #{toRem(16)});
            }
        }
    }
}
