.recent-issues-proceedings {
    background-color: $proceeding-issues-bg;
    padding: toRem(40) 0;

    @include media('>=tablet') {
        background-image: url(../../../images/dL-logo-big.png);
        background-repeat: no-repeat;
        background-position: center left;
    }

    .left-bordered-title:not(.custom-border-title) {
        display: inline;
        margin-right: toRem(15);
    }

    &__btn {
        @include media('<phone') {
            display: none;
        }
    }

    .hidden {
        display: none;
        color: $blue-color;
        @include media('<phone') {
            display: inline;
        }
    }

    .desc {
        padding-left: toRem(15);
        font-weight: 300;
    }

    &__item-group {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        @include media('>=phone') {
            padding-bottom: toRem(20);
            border-bottom: solid toRem(1) $secondary-color;
        }

        @include media('<phone') {
            display: none;

            &.loaded {
                display: block;
            }
        }
    }

    &__item {
        min-height: toRem(100);
        background: $white;
        padding: toRem(15) toRem(20);
        display: inline-block;
        width: 100%;
        border-radius: toRem(4);

        @include media('>=phone') {
            width: 48%;
            margin: 1%;
        }
        @include media('>=desktop') {
            width: 32%;
            margin: 0.666%;
        }
    }

    &__created {
        @include font-size(14);

        i {
            @include font-size(18);
            margin-right: toRem(5);
            vertical-align: sub;
        }
    }

    &__title {
        @include font-size(14);
        color: $primary-lighten-color;
        padding: toRem(6) 0;
    }

    &__volume {
        color: $blue-color;
        border-left: solid toRem(2) $secondary-color;
        padding-left: toRem(8);
        line-height: toRem(16);
    }

    .loadMoreBtn {
        display: none;
        width: 100%;
        margin-top: toRem(10);
        @include media('<phone') {
            display: block;
        }
    }

    .slideShow {
        .recent-issues-proceedings__carousel {
            padding: 0;

            &.owl-carousel {
                @include media('<tablet') {
                    display: block;
                    padding: 0 toRem(10);
                }

                @include media('<phone') {
                    padding: 0;
                }

                .owl-stage-outer:before,
                .owl-stage-outer:after {
                    display: none;
                }

                .owl-nav {
                    height: toRem(32);
                    position: absolute;
                    right: 50%;
                    bottom: toRem(-5);

                    .owl-prev,
                    .owl-next {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: toRem(32);
                        height: toRem(32);
                        padding: 0;

                        border: solid toRem(1) $secondary-color;
                        border-radius: 50%;
                        background: $white !important;

                        i {
                            @include font-size(10);
                            color: $blue-color;
                            margin: 0;
                        }
                    }

                    .owl-prev {
                        left: toRem(-73);
                    }

                    .owl-next {
                        right: toRem(-73);
                    }
                }
            }
        }
    }
}
