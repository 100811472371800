.loi {
    background: $white;
    padding: 0;

    .coverDate {
        border-right: none;
        margin-right: 0;
        padding-right: 0;
        display: initial;
    }

    .issue {
        font-weight: 600;
        color: $blue-color;
        white-space: nowrap;

        &__image {
            padding: toRem(15);
            text-align: center;

            @include media('<tablet') {
                display: none;
            }

            img {
                height: toRem(173);
                display: block;
                margin: 0 auto;
            }
        }

        &__info-container {
            display: inline-block;
            padding: toRem(15);

            @include media('>=tablet') {
                border-top: toRem(1) solid $secondary-border-color;
                display: block;
            }
        }
    }

    &__issue {
        @include font-size(14);
        margin-bottom: toRem(16);
        padding-bottom: toRem(16);

        @include media('<tablet') {
            border-bottom: toRem(1) solid $secondary-border-color;
            padding-left: toRem(30);
        }

        &:hover {
            .issue__image {
                background-color: $light-gray-color;
            }
        }
        .status {
            @include font-size(14);
            text-align: center;
            color: $secondary-text-color;
            font-weight: 500;
            font-style: italic;

            @include media('<tablet') {
                display: inline-block;
                margin-left: toRem(8);
            }
        }

        .separator {
            border-left-color: $secondary-text-color;
            padding-left: 0;
            height: toRem(10);

            @include media('>=tablet') {
                display: none;
            }
        }
    }
    &__vol-title {
        margin: toRem(10) toRem(15) toRem(30) 0;

        @include media('<tablet') {
            margin-left: 0;
        }
    }

    &__list {
        @include font-size(18);
        .active {
            border: none;
            background-color: $white;
            color: $text-onLight;
        }
        a {
            padding: toRem(16) toRem(20);
        }
    }

    .tab {
        &__content {
            border-top: none;
            box-shadow: inset 0 toRem(6) toRem(5) toRem(-5) rgba($black, 0.1);
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    .issues-list {
        > li {
            padding-left: toRem(1);
            padding-right: toRem(1);
        }

        @include media('>=tablet') {
            display: flex;
            flex-wrap: wrap;
            margin: 0 toRem(-17);

            > li {
                display: flex;
                padding-left: toRem(15);
                padding-right: toRem(15);
            }
        }

        .loi__issue {
            padding: 0;
            margin-bottom: toRem(36);
            display: block;
            border-top: toRem(4) solid $secondary-color;
            &:not(:focus) {
                box-shadow: 0 toRem(4) toRem(2) rgba($black, 0.1);
            }
            @include media('>=tablet') {
                width: toRem(189);
            }

            @include media('<tablet') {
                margin-bottom: toRem(10);
                border-top-width: toRem(2) !important;
            }

            h6 {
                display: block;
                margin: toRem(10) 0;

                &.truncate-text-css {
                    display: -webkit-box;
                }
            }
        }
    }

    .swipe__wrapper {
        background-color: $secondary-bg-color;
        color: $primary-lighten-color;
        &:before,
        &:after {
            background: none;
        }
    }

    &-tab-1 {
        > .swipe__wrapper {
            box-shadow: 0 toRem(4) toRem(2) toRem(-2) rgba($black, 0.1);
            background-color: $primary-bg-color;
            color: $text-onDark;
            z-index: $index-2;

            [class*='loi__btn'] {
                background: $primary-bg-color;
                color: $text-onDark;
                box-shadow: toRem(10) 0 toRem(15) toRem(-5) rgba($black, 0.8);
                padding: toRem(16) toRem(10) toRem(15);
            }
        }
    }
    &-tab-2 {
        > .swipe__list {
            background-color: $secondary-bg-color;
        }
    }

    &-list {
        &__wrapper {
            margin: 0;
            max-width: inherit;
        }

        &__controls {
            i {
                font-weight: 600;
            }
        }
    }

    &__banner {
        box-shadow: none;
        font-weight: 500;
        padding-bottom: toRem(25);
        background: $white;

        &--sticky {
            box-shadow: 0 toRem(4) toRem(4) toRem(-2) rgba($black, 0.3);
        }

        &.uncollapsible {
            .tab__content {
                max-height: 100%;
            }
        }

        &__wrapper {
            margin: auto;
            max-width: $container-large-screen;
            padding: 0 toRem(15);
            position: relative;
            @include media('<desktop') {
                padding: toRem(15);
            }
        }
    }

    &__jacm-title {
        display: inline-block;
        padding: 0 toRem(15);
        @include media('<desktop') {
            text-align: center;
            @include font-size(14);
            display: block;
            &::after {
                font-family: icomoon, sans-serif !important;
                content: '\e96b';
                color: rgba($black, 0.87);
                padding-left: toRem(15);
                @include font-size(10);
            }
        }
    }

    &__archive {
        &:after {
            content: '';
            width: 0;
            padding: 0;
        }
    }

    &--res {
        .loi__list a {
            padding: toRem(16) toRem(15);
        }
    }
}

#loi {
    &-banner {
        > a {
            padding: toRem(20) toRem(10);
            &:hover {
                background-color: rgba($white, 0.1);
            }
            ~ a {
                border-left: none;
            }
        }

        .loi__subscribe {
            @include media('>=desktop') {
                @include font-size(14);
                font-style: italic;
                font-weight: 600;
                padding: toRem(5) toRem(10);
                background-color: rgba($white, 0.6);
                text-transform: uppercase;
                &:hover,
                &:focus {
                    background-color: rgba($white, 0.3);
                }
            }
            @include font-size(16);
            background-color: transparent;
        }

        @include media('>=desktop') {
            text-align: center;
            display: block;
            position: static;
            box-shadow: none;
        }
        @include media('<desktop') {
            left: 0;
            right: 0;
            top: toRem(49);
            border-top: toRem(1) solid $black;

            > a {
                border-bottom: toRem(1) solid rgba($black, 0.12);
                display: block;
                @include font-size(16);
            }
        }
    }

    .dropzone {
        min-width: toRem(100);
    }
}

.default-loi-img {
    background-image: url(../../../images/journal-cover-default-colors.svg);

    &.acmType {
        background-image: url(../../../images/journal-cover--csur.svg);
    }

    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: toRem(173);
}
