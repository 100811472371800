.associatedUser {
    position: relative;

    &__edit {
        position: absolute;
        right: toRem(28);
        top: toRem(28);
        @include font-size(12);
        cursor: pointer;
        z-index: $index-1;

        @include media('<=tablet') {
            right: toRem(12);
            top: toRem(12);
        }

        i {
            margin-right: toRem(4);
        }
    }

    &__info {
        width: 100%;

        .messages {
            margin-bottom: toRem(22);

            p {
                position: relative;

                .icon-close_thin {
                    position: absolute;
                    right: 0;
                    font-size: toRem(12);
                    margin-top: toRem(2);
                    cursor: pointer;
                }
            }
        }

        .user-info {
            @include font-size(14);
            border-bottom: solid toRem(1) $secondary-color;
            padding-bottom: toRem(12);
            margin-bottom: toRem(22);

            .item {
                margin: toRem(10) 0;

                div {
                    display: inline-block;

                    &.labels {
                        width: toRem(120);
                    }
                }
            }
        }

        .email-update {
            display: inline-block;
            width: 100%;

            .update-check {
                > label {
                    font-weight: normal;

                    &:first-child {
                        margin-bottom: toRem(12);
                    }

                    a {
                        vertical-align: middle;
                    }
                }
            }
        }
    }

    .verifyEmail-label {
        i {
            color: $primary-lighten-color;
            @include font-size(16);
            margin-left: toRem(4);
        }
    }

    .input-group {
        margin-bottom: 0;

        &.input-group__field-container--error {
            .field-error-label {
                display: block;
                label {
                    @include font-size(12);
                    font-weight: 300;
                    font-style: italic;
                }
            }
        }
    }

    .user-email-input {
        background-color: $white;
    }

    .input-group__field-container {
        .input-group__left-icon {
            top: toRem(7);

            i {
                @include font-size(25);
                color: $secondary-color;
            }
        }

        .input-group__right-icon {
            top: toRem(7);

            i {
                cursor: default;
                @include font-size(24);

                &.valid {
                    color: $success-text-color;
                }

                &.invalid {
                    color: $error-text-color;
                }
            }
        }
    }
}

.InstitutionAdminForm {
    .previewPhoto {
        margin-top: toRem(10);
    }

    span.jcf-file {
        white-space: normal;
    }

    .jcf-file {
        display: inline;

        .jcf-fake-input,
        .jcf-button-content {
            cursor: pointer;
        }
        .jcf-real-element {
            top: 0;
        }
    }

    .deletePhotoBtn {
        display: none;
        border-left: toRem(1) solid rgba(217, 217, 217, 0.56);
        background: transparent;
        font-weight: normal;
        @include font-size(14);
        padding: toRem(5) toRem(10);
        vertical-align: middle;
        margin-top: toRem(1);

        .deletePhoto {
            background: transparent;
            border: toRem(1) solid $primary-color;
            color: $primary-color;
            padding: toRem(5) toRem(13);
            border-radius: toRem(30);
            cursor: pointer;
        }
    }

    .photoError {
        display: block;
        label {
            font-weight: 300;
            color: $error-text-color !important;
        }
    }
    .about {
        color: $contrib-about-color;
        margin-bottom: toRem(15);
        display: block;
    }
    .input-group-addon {
        width: 13%;
    }
}
.current-user {
    font-weight: normal;
}

#form-processing {
    text-align: center;

    .modal__dialog {
        margin: 15% auto;
        min-height: toRem(240);
        overflow: visible;
    }

    .modal__body {
        margin-top: toRem(-50);
    }

    .modal__header {
        .icon-container {
            position: relative;
            background: $white;
            bottom: toRem(65);
            width: toRem(80);
            height: toRem(80);
            margin: 0 auto;
            border-radius: 50%;

            .icon-clock-animate {
                top: toRem(15);
                left: toRem(15);
                position: relative;
                border-radius: 50%;
                border: toRem(4) solid $blue-color;
                width: toRem(50);
                height: toRem(50);
            }

            .icon-clock-animate:after {
                position: absolute;
                width: 0;
                height: toRem(18);
                display: block;
                border-left: toRem(3) solid $blue-color;
                content: '';
                left: toRem(20);
                top: toRem(3);
                animation-duration: 2s;
            }

            .icon-clock-animate:before {
                position: absolute;
                width: 0;
                height: toRem(18);
                display: block;
                border-left: toRem(3) solid $blue-color;
                content: '';
                left: toRem(20);
                top: toRem(3);
                animation-duration: 60s;
            }

            .icon-clock-animate:before,
            .icon-clock-animate:after {
                transform-origin: bottom;
                animation-name: dial;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
            }
        }
    }

    span.bold {
        margin-right: toRem(5);
    }
}

#form-confirm {
    .modal {
        &__dialog {
            min-height: toRem(180);
            margin: 15% auto;
        }
        &__actions {
            display: flex;
            justify-content: end;
            padding-top: toRem(30);
        }
    }
}

.contrib-profile {
    &__popup {
        position: fixed;
        bottom: toRem(20);
        left: toRem(20);
        max-width: toRem(600);
        background: $white;
        border-radius: toRem(6);
        z-index: $index-15;
        overflow: hidden;
        border: toRem(1) solid $secondary-lighten-color;
        display: none;

        @include media('<tablet') {
            width: 100%;
            padding: 0;
            left: 0;
            bottom: 0;
        }
        &__body {
            padding: toRem(16) toRem(50);
            font-size: toRem(12);
            color: $primary-color;
            padding-top: 0;
            @include media('<tablet') {
                padding: toRem(11) toRem(15);
                padding-top: 0;
            }
            .test {
                padding-left: toRem(4);
                &:not(:last-child)::after {
                    content: ',';
                }
            }
        }
        &__header {
            background: $secondary-lighten-color;
            display: flex;
            align-items: center;
            .icon-cancel-bold {
                padding: toRem(14);
                font-size: toRem(10);

                &:hover {
                    cursor: pointer;
                }
            }

            .icon-info {
                display: inline-block;
                vertical-align: middle;
                width: toRem(40);
                border-right: toRem(1) solid $white;
                padding: toRem(14) 0;
                text-align: center;
                margin-right: toRem(10);
                min-height: toRem(45);
            }
        }
        &__title {
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            font-weight: 600;
            flex: 1;
        }
        &__close {
            @include font-size(14);
        }
    }
}
