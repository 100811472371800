.tag-cloud {
    &__svg {
        max-width: 100%;
    }

    text {
        cursor: pointer;
    }
}
.organizational-chart {
    p {
        margin-block-start: 1em;
    }
}
