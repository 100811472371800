// todo: move to variables file
$index-3: 3;
$index-10010: 10010;

.classification-system {
    padding: toRem(20) 0;

    .left-section {
        @include media('>desktop') {
            border-right: solid toRem(1) $secondary-color;
        }

        @include media('>tablet') {
            padding-top: toRem(12);
        }
    }

    .right-section {
        @include media('<desktop') {
            position: fixed;
            background: white;
            left: 0;
            width: 100%;
            padding: 0;
            transition: top 0.6s;
            z-index: $index-10;
            top: calc(100% - #{toRem(44)});
        }

        .ccs-concept-toggle {
            @include media('>=desktop') {
                display: none;
            }

            background-color: $light-gray-color;
            box-shadow: 0 toRem(-2) toRem(10) 0 $secondary-shadow-color;
            border-top: solid toRem(1) $secondary-shadow-color;
            border-bottom: solid toRem(1) $secondary-shadow-color;
            padding: toRem(10);
            text-align: center;
            position: relative;

            span {
                font-size: $font-size-sm;

                &.count {
                    display: inline-block;
                    position: absolute;
                    right: toRem(8);
                    top: toRem(6);
                    background-color: $primary-lighten-color;
                    border-radius: 50%;
                    color: $white;
                    width: toRem(30);
                    height: toRem(30);
                    padding-top: toRem(4);
                }
            }

            i {
                @include font-size(12);
                padding-left: toRem(10);
            }
        }
    }

    .colored-block__content {
        border: solid toRem(1) $secondary-color;
        border-radius: toRem(2);
    }

    &__breadcrumb-section {
        @include media('>=desktop') {
            display: flex;
            align-items: center;
        }

        .icon-Icon_Information {
            @include font-size(20);
            color: $primary-lighten-color;
            vertical-align: middle;
            cursor: pointer;
        }
    }

    &__breadcrumb,
    &__assign-ccs {
        display: inline-block;
    }

    &__breadcrumb {
        width: calc(100% - #{toRem(270)});
        background: $primary-color;
        position: relative;
        border-radius: toRem(5) 0 0 toRem(5);
        padding: toRem(5) toRem(5) toRem(5) toRem(10);
        margin-right: toRem(30);

        @include media('<desktop') {
            width: 100%;
            padding: toRem(10) toRem(5);
            margin-bottom: toRem(28);
            border-radius: toRem(5);
        }

        svg.arrow-right {
            @include media('>=desktop') {
                display: inline-block;
                position: absolute;
                top: 0;
                left: 100%;
                fill: $primary-color;
            }

            @include media('<desktop') {
                display: none;
            }
        }

        &:before {
            @include media('<desktop') {
                content: '';
                position: absolute;
                height: 0;
                border: toRem(15) solid transparent;
                border-top-color: $primary-color;
                right: 48%;
                top: 100%;
            }
        }

        li {
            @include media('<desktop') {
                border-left: solid toRem(1) $white;
                display: block;
                padding-left: toRem(8);
                margin-left: toRem(12);

                &:first-child {
                    border: none;
                    padding: 0;
                    margin-left: 0;
                }
            }

            a {
                display: inline-block;
                color: $white;
                margin: 0 toRem(6);
                padding: toRem(2) 0;
                border-radius: toRem(5);

                &.first-level {
                    margin: 0 toRem(6) 0 0;
                }

                &.active {
                    font-weight: 600;
                    background: $black;
                    border: toRem(2) solid $blue-color;
                    padding: toRem(2) toRem(6);
                }
            }

            + li {
                &:before {
                    content: '\e931';
                    display: inline-block;
                    color: $white;
                    @include font-size(8);
                    font-family: 'icomoon', sans-serif;
                    vertical-align: middle;
                    @include media('<desktop') {
                        display: none;
                    }
                }
            }
        }
    }

    &__assign-ccs {
        margin-right: toRem(10);
        @include media('<desktop') {
            width: calc(100% - #{toRem(50)});
            margin-right: toRem(25);
        }

        .btn {
            padding: toRem(12) toRem(10);
            @include media('<desktop') {
                width: 100%;
            }

            &.disabled {
                background-color: $secondary-lighten-color;
                color: $primary-lighten-color;
            }
        }
    }

    &__tabs {
        margin-top: toRem(10);

        .tab__nav {
            border-bottom: solid toRem(1) $secondary-color;

            li {
                margin-bottom: 0;

                a {
                    border: 0;
                    font-size: $font-size-sm;
                    color: $blue-color;
                    padding: toRem(4) toRem(8);

                    &:hover,
                    &:focus {
                        text-decoration: none;
                        color: $blue-color;
                        outline: none;
                    }

                    @include media('<phone') {
                        @include font-size(12);
                        padding: toRem(4) toRem(6);
                    }
                }

                &.active {
                    a {
                        border-bottom: solid toRem(3) $blue-color;
                        font-weight: 600;
                    }
                }
            }

            .recent-papers-tab {
                display: none;
            }
        }

        .dropBlock {
            border-left: toRem(1) solid $secondary-color;

            > a {
                font-size: $font-size-sm;
                padding: toRem(12) 0 toRem(12) toRem(5);
                line-height: toRem(10);

                @include media('<phone') {
                    @include font-size(12);
                }

                i {
                    color: $primary-lighten-color;
                }
            }

            .search__sort__drop {
                a {
                    @include media('<phone') {
                        @include font-size(12);
                    }
                }
            }
        }

        .viewsLoader {
            padding: toRem(15) 0;
        }
    }

    &__ccs-interactive-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include media('<desktop') {
            margin-bottom: toRem(20);
        }

        > li {
            position: relative;
            display: flex;

            width: 100%;
            height: toRem(125);
            margin: toRem(8) 0;

            border: solid toRem(1) $secondary-color;
            border-radius: toRem(10);
            background: $white;

            @include media('>=tablet') {
                margin: toRem(8);
                width: 31%;
            }

            @include media('>tablet') {
                &:hover {
                    background: $light-gray-color;

                    .child-tags-popup {
                        display: block;
                    }
                }
            }

            a {
                display: flex;
                flex: 1 1 auto;
                justify-content: center;
                align-items: center;

                font-size: $font-size-base;
                text-align: center;
                padding: 0 toRem(25);
            }

            &.active {
                background: $light-gray-color;
            }
        }

        .child-tags-popup {
            display: none;
            width: toRem(350);
            max-width: toRem(350);
            padding: toRem(18);
            z-index: $index-3;
            position: absolute;
            top: 75%;
            left: 20%;
            background: $white;
            box-shadow: 0 toRem(2) toRem(11) $primary-lighten-color;
            margin-bottom: toRem(40);
            transition: 0.3s;

            li {
                span {
                    display: inline-block;
                    text-align: left;
                    padding: toRem(6);
                    font-size: $font-size-sm;
                }
            }
        }
    }

    &__flat-view {
        margin-top: toRem(15);

        li {
            list-style-type: none;
        }

        .accordion-tabbed__tab {
            border: none;
            margin-bottom: toRem(10);

            .accordion-tabbed__control {
                display: inline;

                &:after {
                    content: '\e930';
                    float: left;
                    color: $blue-color;
                    border: solid toRem(1) $secondary-color;
                    border-radius: 50%;
                    margin-right: toRem(10);
                    margin-top: toRem(2);
                    padding: toRem(3) toRem(6);
                    font-size: 0.625rem;
                    transition: 0.3s;
                }
            }

            &.js--open {
                .accordion-tabbed__control {
                    &:after {
                        content: '\e930';
                    }
                }
            }

            &:not(.js--open) {
                .accordion-tabbed__control {
                    &:after {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .main-parents-container {
            border-bottom: solid toRem(1) $secondary-color;
            padding: toRem(8) 0;
            margin-bottom: toRem(10);
            background-color: $white;
        }

        .main-parents {
            display: inline-block;
            font-size: 1.125rem;
            font-weight: 600;
        }
    }

    &__ccs-flat-list {
        @include media('>phone') {
            padding-left: toRem(50);
        }

        > li {
            padding: toRem(6) 0;
        }

        a {
            &:hover {
                background: $secondary-lighten-color;
                border-radius: toRem(20);
            }
        }

        ul {
            list-style-type: none;
            padding-left: toRem(30);

            li {
                a {
                    display: inline-block;
                    padding: toRem(8);
                }
            }
        }

        .list-second {
            font-size: 1rem;
            font-weight: 600;
            padding: toRem(8);
            display: inline-block;
        }
    }

    &__ccs-concept {
        @include media('>=desktop') {
            padding-top: toRem(12);
            max-height: 92vh;
            overflow-y: auto;
            overflow-x: hidden;
            padding-right: toRem(10);

            &::-webkit-scrollbar {
                width: toRem(4);
            }

            &::-webkit-scrollbar-track {
                background: $secondary-lighten-color;
            }

            &::-webkit-scrollbar-thumb {
                background: $primary-lighten-color;
                border-radius: toRem(4);
            }
        }

        @include media('<desktop') {
            padding: toRem(20) toRem(15);
            max-height: 80vh;
            overflow-y: auto;
            overflow-x: hidden;
        }

        h4 {
            @include media('<desktop') {
                display: none;
            }
        }

        span {
            font-weight: 200;

            &.empty-ccs {
                padding-bottom: toRem(20);
                display: inline-block;
            }
        }

        .ccs-concept-section {
            display: none;
        }

        button.btn {
            width: 100%;

            i {
                padding-right: toRem(8);
            }
        }

        .preview-ccs {
            display: block;
            font-size: $font-size-sm;
            color: $blue-color;
            text-align: center;
            padding: toRem(12) 0;
            text-decoration: underline;

            i {
                padding-right: toRem(8);
            }
        }

        .clear-all-ccs {
            padding: 0 toRem(5);
            text-decoration: underline;
            color: $blue-color;
        }
    }

    .ccs-concept-list {
        margin: toRem(20) 0;
        border-top: solid toRem(1) $secondary-color;
        border-bottom: solid toRem(1) $secondary-color;
    }

    .ccs-concept-list-item {
        border-bottom: solid toRem(1) $secondary-color;
        padding: toRem(10) 0;

        &:last-child {
            border: none;
        }

        .title {
            display: inline-block;
            padding-bottom: toRem(4);
            @include font-size(15);
        }

        .options {
            float: right;

            a {
                color: $primary-lighten-color;
                @include font-size(12);

                &:first-child {
                    border-right: solid toRem(1) $secondary-color;
                    padding-right: toRem(6);
                    margin-right: toRem(6);
                }
            }
        }

        .relevance {
            font-style: italic;
            color: $primary-lighten-color;
            font-size: $font-size-sm;
        }
    }

    .ccs-concept-child {
        border-left: solid toRem(1) $primary-color;
        margin: toRem(8) 0 0 toRem(14);
        padding: 0 toRem(10);

        li {
            padding-top: toRem(2);
            padding-bottom: toRem(2);

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
                color: $primary-color;
            }

            color: $primary-lighten-color;
            font-size: $font-size-sm;
        }
    }

    .ccs-views-container {
        margin-bottom: toRem(30);

        @include media('>=tablet') {
            min-height: 50vh;
        }
    }

    .recent-papers-container {
        margin-bottom: toRem(30);

        .textForNoResults {
            padding: toRem(10);
        }

        .more-link {
            border: 0;
        }
    }

    .sticky-ccs-items,
    .sticky-ccs-flat {
        @include media('>tablet') {
            position: sticky;
            transition: top 0.5s;
            top: toRem(52);
        }

        @include media('<=tablet') {
            position: static;
        }
    }

    @include media('>tablet') {
        .sticky-ccs-items {
            z-index: $index-2;
        }

        .sticky-ccs-flat {
            z-index: $index-1;
        }
    }

    .copy-notification-box {
        display: none;
        position: fixed;
        right: 0;
        bottom: 0;
        margin: toRem(20);
        z-index: $index-10010;
        background: $jetc-lighter-color;
        color: $jocch-primary-color;
        border-radius: toRem(5);
        padding: toRem(10) toRem(12);
        width: toRem(370);

        @include media('<tablet') {
            width: 90%;
        }

        i {
            margin-right: toRem(5);
        }
    }
}

// Classification System Modals CSS

#modal-breadcrumbInfo {
    .modal__body {
        overflow-y: auto;
        max-height: toRem(500);
        padding: 0 toRem(12);

        &::-webkit-scrollbar {
            width: toRem(6);
        }

        &::-webkit-scrollbar-track {
            background: $secondary-lighten-color;
        }

        &::-webkit-scrollbar-thumb {
            background: $black;
            border-radius: toRem(4);
        }
    }

    ul {
        padding: 0 toRem(10);

        li {
            list-style-type: disc;
            @include font-size(13);
        }
    }
}

#modal-assignCCS {
    .assign-ccs-concept-modal {
        .ccs-concept-list {
            margin: toRem(10) 0 toRem(20) 0;
            padding: 0 toRem(12);
            background-color: $secondary-lighten-color;
            border: solid toRem(1) $secondary-color;
        }

        .ccs-concept-list-item {
            .title {
                @include font-size(13);
            }
        }

        .ccs-concept-child {
            li {
                @include font-size(12);
                text-decoration: none;
            }
        }

        .input-group {
            margin-top: toRem(10);

            .radio--primary {
                display: inline-block;
                padding-right: toRem(20);
                margin: 0;

                @include media('<tablet') {
                    display: block;
                    padding: toRem(8) 0;
                }

                .label-txt {
                    &:before {
                        margin-top: 0;
                        margin-right: toRem(5);
                    }
                }
            }

            @include media('<tablet') {
                margin-bottom: toRem(15);
            }
        }

        .assign_buttons {
            .assign-this-ccs {
                @include media('>=tablet') {
                    float: right;
                }
            }

            @include media('<tablet') {
                width: 100%;
                border-top: solid toRem(1) $secondary-color;
                padding-top: toRem(10);

                button {
                    width: 100%;
                    margin: toRem(8) 0;
                }
            }
        }
    }
}

#modal-generateCode {
    .generate-code-modal {
        > span {
            font-size: $font-size-sm;
        }

        .input-group {
            margin-top: toRem(18);

            .label-txt {
                a {
                    font-weight: 300;
                    font-size: $font-size-sm;
                    vertical-align: middle;
                    text-decoration: none;
                }
            }
        }

        .generated-views {
            font-size: $font-size-sm;
            padding: toRem(12);
            background-color: $secondary-lighten-color;
            border: solid toRem(1) $secondary-color;
            overflow: auto;
            min-height: toRem(150);
            max-height: toRem(300);

            &::-webkit-scrollbar {
                width: toRem(6);
                height: toRem(6);
            }

            &::-webkit-scrollbar-track {
                background: $white;
            }

            &::-webkit-scrollbar-thumb {
                background: $secondary-color;
                border-radius: toRem(4);
            }
        }

        .generated-code {
            white-space: nowrap;

            .indent {
                padding-left: toRem(18);
            }
        }

        .generated-display {
            display: none;

            .classification-system__preview-flat-tab {
                .flat-item {
                    display: inline;
                    padding-right: toRem(8);

                    li {
                        display: inline-block;

                        &.dot {
                            @include font-size(20);
                            vertical-align: middle;
                            font-weight: 600;
                            padding: 0 toRem(4);
                        }

                        &.curly {
                            vertical-align: middle;
                        }
                    }

                    &.high {
                        font-weight: 600;
                    }
                    &.medium {
                        font-weight: 400;
                    }
                    &.low {
                        font-weight: 100;
                    }
                }
            }
        }

        .generate-desc-display {
            display: none;
        }

        .checkbox--primary {
            display: inline-block;
        }

        .copy__btn {
            color: $blue-color;
            @include font-size(19);
            padding: 0 toRem(8);
            border-left: toRem(1) solid $secondary-color;
            display: inline-block;
            margin-top: toRem(8);
            line-height: toRem(10);
            margin-right: toRem(-8);
            outline: none;

            i {
                vertical-align: middle;
            }
        }

        .generate-btn-container {
            display: inline-block;
            float: right;

            .generate-btn {
                @include font-size(12);
                background-color: $secondary-lighten-color;
                font-weight: normal;
                padding: toRem(8) toRem(12);

                &:hover,
                &:focus {
                    text-decoration: none;
                }

                &.generate-code-view {
                    display: none;
                }
            }
        }
    }
}

#modal-PreviewCCS {
    .modal__body {
        min-height: toRem(500);
    }

    .tree-preview-container,
    .flat-preview-container {
        overflow-y: auto;
        max-height: toRem(550);
        padding: 0 toRem(12);

        @include media('<phone') {
            padding: toRem(12);
            max-height: toRem(300);
        }

        &::-webkit-scrollbar {
            width: toRem(6);
            height: toRem(6);
        }

        &::-webkit-scrollbar-track {
            background: $secondary-lighten-color;
        }

        &::-webkit-scrollbar-thumb {
            background: $black;
            border-radius: toRem(4);
        }
    }

    .preview-ccs-modal {
        .legend {
            border-top: solid toRem(1) $secondary-color;
            margin-top: toRem(22);
            padding-top: toRem(22);

            div {
                display: inline-block;
            }
        }

        .classification {
            @include font-size(12);
            padding-bottom: toRem(10);
            padding-right: toRem(28);
            font-style: italic;
        }

        .legend-items {
            padding-right: toRem(18);
            @include media('<tablet') {
                margin-bottom: toRem(10);
            }

            span {
                @include font-size(11);
            }

            .relevance {
                width: toRem(20);
                height: toRem(20);
                border: solid toRem(3) $primary-lighten-color;
                border-radius: 50%;
                vertical-align: middle;
                margin-right: toRem(5);

                &.high {
                    background-color: $primary-lighten-color;

                    + span {
                        font-weight: 600;
                    }
                }

                &.medium {
                    background-color: $secondary-lighten-color;

                    + span {
                        font-weight: 500;
                    }
                }

                &.low {
                    background-color: $secondary-lighten-color;
                    border-color: $secondary-lighten-color;

                    + span {
                        font-weight: 300;
                    }
                }
            }
        }
    }

    .classification-system__preview-flat-tab {
        background-color: $white;

        .title-section {
            text-align: center;
            background-color: $light-gray-color;
            border-bottom: solid toRem(4) $secondary-shadow-color;
            margin-bottom: toRem(20);
            padding: toRem(12) 0;
            font-size: toRem(18);
            font-weight: 600;
        }

        .flat-item {
            margin: toRem(5) 0;
            padding: toRem(15);
            background-color: $white;

            &:nth-child(odd) {
                background-color: $light-gray-color;
            }

            li {
                + li {
                    &:not(.relevance) {
                        &:before {
                            content: '\e931';
                            display: inline-block;
                            margin: 0 toRem(10);
                            color: $primary-color;
                            @include font-size(8);
                            font-family: 'icomoon', sans-serif;
                            vertical-align: middle;
                        }
                    }
                }

                &:nth-last-child(2) {
                    span {
                        font-weight: 600;
                    }
                }
            }

            .relevance {
                display: block;
                margin-top: toRem(5);
                font-size: $font-size-sm;
                color: $primary-lighten-color;
                font-style: italic;
            }
        }
    }
}
