.pill-all-authors {
    a[href='javascript:void(0);'] {
        cursor: default;
    }
}
.pill-authors__content {
    .expandable-accordion {
        position: relative;

        .expand-all {
            @include font-size(13);
            text-decoration: underline;
            color: $blue-color;
            margin-bottom: toRem(14);
        }
    }

    .section__separator {
        border: none;
    }

    .auth-profile-link {
        @extend .btn--inverse;
    }

    .js--open > .accordion-tabbed__control:after {
        content: '\e60c' !important;
    }

    .accordion-tabbed__control:after {
        content: '\e60b' !important;
        display: inline-block !important;
        font-family: icomoon, sans-serif !important;
        padding-right: toRem(10);

        color: $blue-color;
        font-style: normal;
        font-size: 0.563rem;
    }

    .loa {
        visibility: hidden;

        .label {
            padding: 0;
            padding-left: toRem(14);
            color: $black;
            display: block;
            margin-bottom: toRem(20);
            @extend .left-bordered-title;

            b {
                font-weight: 600;
            }
        }

        button:not(.removed-items-count) {
            text-decoration: none;
        }
        .author-name {
            display: block;
            ~ span {
                display: none;
            }
        }
        .author-picture {
            width: toRem(45);
            height: toRem(45);
            float: left;
        }

        &__author-name {
            @extend .auth-name;
        }

        &_author_inst {
            @extend .info--text;
            @extend .auth-institution;
        }

        &__author-info {
            display: inline-block;
            width: calc(100% - #{toRem(50)});
            p {
                margin: 0;
            }
        }

        li {
            display: block;
            visibility: visible;

            .accordion-tabbed__control {
                @extend .clearfix;
            }
        }

        .loa__link {
            display: none;
        }
        &__item {
            + .label {
                margin-top: toRem(20);
            }

            padding: toRem(11) toRem(16);
            border-top: toRem(1) solid $secondary-color;
        }

        .auth-metrics li {
            display: flex;

            .metric-name {
                padding-right: toRem(5);
                max-width: 100%;
            }
            .metric-value {
                align-self: center;
                margin-left: auto;
                float: none;
            }
        }
    }

    .author-info {
        position: static;
        opacity: 1;
        visibility: visible;
        width: 100%;
        max-width: 100%;
        border: none;
        box-shadow: none;
        background: transparent;
        display: block;

        &__body {
            padding: 0;
        }
        .author-info__header,
        p {
            display: none;
        }
    }

    .contrib-metrics-bibliometrics {
        .view-profile {
            display: inline-block;
        }
    }
}

.acmbooks,
.toc {
    .colored-block__title {
        .left-bordered-title {
            line-height: toRem(23);
        }
    }
}
