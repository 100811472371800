.teaser-item {
    .issue-item {
        &__title {
            @include font-size(14);
            color: $primary-color;
            margin-bottom: toRem(10);
        }

        &__content {
            width: 100%;
            float: none;
        }
    }

    .loa button:not(.removed-items-count) {
        text-decoration: none;
    }
}

.creative-work {
    .meta {
        text-transform: capitalize;
        font-weight: 600;
    }

    .epub {
        font-weight: 600;
    }
}
