.publications-wrapper {
    .slide-item {
        margin: 0 toRem(12);
    }

    // to get equal slide items height
    .colored-block {
        height: auto;
        background: $white;
    }

    .colored-block__desc {
        height: toRem(171.8);
        overflow-y: hidden;
        .desc {
            height: toRem(75);
            @include media('<tablet') {
                height: toRem(77);
            }
            p {
                margin: 0;
            }
        }
    }
    .title {
        h5 {
            margin-bottom: toRem(8);
        }
    }

    .colored-block__img-holder {
        @include media('<desktop') {
            display: none;
        }
    }
}

.slide-item-no-slider {
    float: left;
    width: calc(50% - #{toRem(24)});
    height: auto !important;

    @include media('<=tablet') {
        width: 100%;
    }

    .colored-block__img-holder {
        width: 50%;
        @include media('>tablet', '<=desktop') {
            width: 100%;
        }
        @include media('<tablet') {
            width: 100%;
        }
    }
}
