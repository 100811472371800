img {
    &.img-rounded {
        border-radius: 50%;
        object-fit: cover;
        object-position: top;

        &--120 {
            width: toRem(120);
            height: toRem(120);
        }

        &--50 {
            width: toRem(50);
            height: toRem(50);
        }

        &--134 {
            width: toRem(134);
            height: toRem(134);
        }

        .left-side-image & {
            padding: 0;
            margin-right: toRem(20);
        }
    }
}

.spinner-container {
    img {
        height: toRem(100);
    }
}

img.loading {
    width: initial;
    height: initial;
    opacity: 1;
    animation: unset;
    -webkit-animation: unset;
    transform: unset;
    -webkit-transform: unset;
    background: none;
    border: 0;
    border-radius: 0;
}

.loading {
    opacity: 1;
    text-align: center;
    background: unset;
    overflow: hidden;
    width: auto;
    height: auto;
    animation: none;
    border: none;
    border-radius: 0;
    transform: none;

    &:after {
        content: '\e98b';
        font-family: icomoon, sans-serif !important;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: inline-block;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
        padding: toRem(5);
        transition: transform 0.5s, -webkit-transform 0.5s;
        font-size: 2.875rem;
        line-height: normal;
    }
}

img.lazy {
    width: 100%;
}
