/* stylelint-disable*/

// Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 532px !default;

// Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;

// Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px !default;

$screen-sm-min: $screen-sm !default;

// Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default;

$screen-md-min: $screen-md !default;

// Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

// Deprecated `$screen-md` as of v3.0.1
$screen-lg: 1200px !default;

$screen-lg-min: $screen-lg !default;

// Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

// Deprecated `$screen-md` as of v3.0.1
$screen-xlg: 1920px !default;

$screen-xlg-min: $screen-xlg !default;

// Deprecated `$screen-xlg-desktop` as of v3.0.1
$screen-xlg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;

$screen-sm-max: ($screen-md-min - 1) !default;

$screen-md-max: ($screen-lg-min - 1) !default;

// ** Number of columns in the grid
$grid-columns: 12 !default;

// ** Padding between columns. Gets divided in half for the left and right
$grid-gutter-width: 30px !default;

// ** Point at which the navbar becomes uncollapsed
$grid-float-breakpoint: $screen-sm-min !default;

// ** Point at which the navbar begins collapsing
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;

// Small screen / phone
$container-mobile: (500px) !default;

// For `$screen-sm-min` and up
$container-xs: $container-mobile !default;

// Small screen / tablet
$container-tablet: (736px) !default;

// For `$screen-sm-min` and up
$container-sm: $container-tablet !default;

// Medium screen / desktop
$container-desktop: (960px) !default;

// For `$screen-md-min` and up
$container-md: $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop: (1080px) !default;

// For `$screen-lg-min` and up
$container-lg: $container-large-desktop !default;

$container-large-screen: 1310px;

:export {
    screenXs: $screen-xs;
    screenSm: $screen-sm;
    screenMd: $screen-md;
    screenLg: $screen-lg;
    screenXlg: $screen-xlg;
    containerMobile: $container-mobile;
    containerXs: $container-xs;
    containerTablet: $container-tablet;
    containerSm: $container-sm;
    containerDesktop: $container-desktop;
    containerMd: $container-md;
    containerLargeDesktop: $container-large-desktop;
    containerLg: $container-lg;
}
/* stylelint-enable*/
