.article-table-content {
    table {
        @include font-size(14);
        font-weight: 500;
        border: toRem(1) solid #9e9e9e;
    }

    tr {
        font-weight: 500;
        border-bottom: toRem(1) solid $secondary-color;
    }

    th {
        color: $black;
        font-weight: 500;
        text-align: center;
        background: $secondary-lighten-color;
    }
}

.responsive-table {
    &__row {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        border-bottom: toRem(1) solid $secondary-border-color;
        .responsive-table--institutional-list & {
            align-items: center;
        }
        &.hidden-sm {
            @include media('<=tablet') {
                display: none;
            }
        }
        @include media('<tablet') {
            .upcoming-events-table & {
                border-bottom: 0;
            }
        }
    }

    &__header {
        .upcoming-events-table & {
            @include media('<tablet') {
                display: none;
            }
        }
        .responsive-table--institutional-list & {
            border-width: toRem(4);
            @include media('<tablet') {
                display: none;
            }
        }
    }

    &__cell {
        border-right: toRem(1) solid $secondary-border-color;
        padding: toRem(20);
        flex: 1;
        .responsive-table--institutional-list & {
            border-right: 0;
            padding-left: 0;
            @include media('<tablet') {
                flex-basis: 100%;
            }
        }
        @include media('<tablet') {
            padding: toRem(10) toRem(20);
            .upcoming-events-table & {
                border-right: 0;
            }
        }

        &:last-child {
            border: none;
            .responsive-table--institutional-list & {
                padding-right: 0;
                @include media('>=tablet') {
                    flex-basis: toRem(140);
                    flex-grow: 0;
                }
            }
        }

        &.stretched-cell {
            flex: 0 0 30%;
        }
    }
}

.user-profile-table {
    td,
    th {
        border-bottom: toRem(1) solid $secondary-color;
        padding: 1.8em 1em;
        @include media('>=desktop', '<large') {
            padding: 1.8em 0;
        }
    }
    th {
        padding-top: 0;
    }
    margin-top: toRem(10);

    thead {
        border-bottom: solid toRem(4) $secondary-color;
        @include media('<tablet') {
            border-bottom: 0;
        }
    }

    td {
        @include media('>=tablet') {
            color: $primary-color;
            @include font-size(12);
            font-weight: normal;
        }
        &[data-thead='Saved Search Name'] {
            @include media('>=desktop') {
                max-width: 0;
            }
        }
    }

    p {
        @include media('>=tablet') {
            margin: 0;
            font-weight: normal;
        }
    }
}

.table-striped {
    tbody tr:nth-of-type(odd) {
        background-color: $light-gray-color;
    }

    &--hover {
        tbody .clickable-row:hover {
            cursor: pointer;
            background-color: $light-gray-color;
        }
    }
}

.table-responsive {
    .hidden-tr {
        display: block;
        height: 0;
        td {
            border: none;
        }
    }
}

.table-view {
    display: table;
    width: 100%;

    &.row {
        width: calc(#{$grid-gutter-width} + 100%);
    }

    & .table__cell-view {
        display: table-cell;
        width: 50%;
        float: none;
    }

    /// SASS cannot extend code into different media query ...
    &--sm {
        @include media('>=tablet') {
            display: table;
            width: 100%;

            &.row {
                width: calc(#{$grid-gutter-width} + 100%);
            }

            [class*='col'] {
                display: table-cell;
                float: none;
            }
        }
    }
}
