// todo: move to variables file
$index-9: 9;
$biography-colors: dimgray;

article {
    @include media('>=tablet') {
        margin-top: toRem(20);
    }

    .article-citations {
        font-family: $quaternary-font-family;
        .dot-separator:first-child {
            &:before {
                content: '';
            }
        }
    }

    & + .figure-viewer {
        font-family: $quaternary-font-family;
    }
}

.article {
    &__body {
        .colored-block__title {
            background-color: $light-gray-color;
        }

        .hlFld-Fulltext > p,
        .bookPageContainer > p,
        .hlFld-Abstract,
        .bullet,
        .bookPageContainer section p,
        .hlFld-Fulltext section p,
        .hlFld-Fulltext ol li > p {
            @include font-size(17);
            line-height: toRem(34);
            font-weight: 300;

            @include media('<tablet') {
                @include font-size(15);
                line-height: toRem(30);
            }
        }

        .tab-link,
        .ref-link,
        .references {
            color: $blue-color;
        }

        .open-media-viewer {
            color: $white;
        }

        &.article__full {
            .article-table-content,
            .article-video-content,
            .article__inlineFigure {
                .colored-block__media-title {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

            .abstractSection {
                margin-bottom: toRem(30);
            }
        }
        .article-section__title {
            padding-top: toRem(30);
            span.label {
                display: inline;
                @include font-size(16);
                color: $primary-color;
                margin-right: toRem(2);
            }
        }

        table.listgroup {
            tr {
                font-weight: 300;
            }

            td {
                @include font-size(17);
            }
        }

        table.listgroup[list-type] {
            td {
                padding-block: 0;
            }
            p {
                margin: 0 0 toRem(16) 0;
            }
        }

        .math-formula {
            display: flex;
            align-items: center;

            .formulaLabel {
                .label {
                    margin-bottom: 0;
                }
            }
            img {
                margin: 1em auto;
            }
        }

        .hlFld-Abstract,
        .hlFld-Abstract .section__title,
        .bookPageContainer .section__title,
        .hlFld-Fulltext,
        .bookPageContainer,
        .hlFld-Fulltext section p,
        .bookPageContainer section p,
        .article__section,
        .article__index-terms,
        .article__comments,
        .article-media__title,
        .article-section__title,
        &.article__abstractView,
        .bullet,
        button,
        pre,
        .article__footNotes,
        .article__footNotes .references__article-title,
        .article__references,
        .article__references .references__article-title {
            font-family: $quaternary-font-family;
        }

        .hlFld-Fulltext,
        .bookPageContainer {
            section {
                .factoid {
                    border-bottom: toRem(1) solid;
                    margin: 0 15%;
                    padding: toRem(8);

                    img {
                        padding: 0 toRem(8);
                        height: toRem(64);
                        float: left;
                    }

                    img.pull-right {
                        float: right;
                    }

                    > p {
                        vertical-align: middle;
                        font-weight: bold;
                        font-size: 1em;
                        line-height: initial;
                    }
                }

                p.leading-para {
                    font-size: 1.2em;
                }

                p.closing-para {
                    font-size: toRem(14);
                }
            }

            section.biography {
                border-top: toRem(2) solid $biography-colors;
                color: $biography-colors;

                > h3 {
                    font-size: 1em;
                    margin-bottom: initial;
                }

                p {
                    font-size: 0.8em;
                    line-height: initial;
                }
            }
            .figure__caption {
                p {
                    font-size: 0.825rem;
                    line-height: 1.1;
                    font-weight: 300;
                }
            }
        }
        .pull-quote p {
            margin-bottom: 0;
        }
    }
    &__cited {
        margin-top: toRem(20);
        .citedBy-main {
            display: none;
        }
        .show-more-items__btn-holder {
            display: none;
        }
        .colored-block {
            &__title {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }

    &__abstract {
        background-color: $light-gray-color;
        padding: toRem(30);

        &:after,
        &:before {
            display: block;
            clear: both;
            content: '';
        }

        @include media('<tablet') {
            padding: toRem(15);
            margin: 0 toRem(-15);
        }

        .colored-block {
            &__title {
                font-family: $default-font-family;

                @include media('>=tablet') {
                    margin: toRem(-30) 0 0 toRem(-30);
                }
                @include media('<tablet') {
                    .left-bordered-title {
                        margin: 0;
                        border: none;
                    }
                }
            }
        }
        p {
            margin: 0;
        }

        .left-side-image {
            //display: inline-block;

            + .abstractSection {
                padding-top: toRem(17);
                //display: inline-block;
                vertical-align: top;
            }

            img {
                width: auto;
                height: auto;
                padding-right: 0;
                margin-right: toRem(20);
                margin-top: toRem(17);

                @include media('<=tablet') {
                    width: 50%;
                }
            }
        }
        .key-image {
            max-width: toRem(120);
            box-shadow: 0 0 toRem(10) $secondary-shadow-color;
        }
    }
    &-section__title {
        font-family: $default-font-family;
        font-weight: bold;
        font-style: normal;

        @at-root {
            h2#{&},
            h3#{&} {
                @include font-size(17);
            }
        }
    }

    &__inlineFigure {
        .figure__title {
            font-family: $tertiary-font-family;

            span:last-child {
                @include media('<tablet') {
                    display: none;
                }
            }
        }
    }

    &__inlineFigure,
    .video.colored-block {
        @extend .separated-block--dashed--bottom;

        .colored-block__content {
            text-align: center;
        }
    }
    &__index-terms {
        margin: toRem(45) 0 toRem(25);
    }

    &__table-viewer-opener {
        display: none;
    }
    &-table-content {
        .colored-block__content {
            width: 100%;
            overflow: hidden;
            overflow-x: auto;
        }
    }
    &-section__table {
        background: $white;
        color: $primary-color;
        tr {
            &:nth-child(odd) {
                background: $secondary-lighten-color;
            }
            img {
                padding: 0;
            }
            td,
            th {
                border: toRem(1) solid $primary-lighten-bg-color;
            }
        }
        .ref-link {
            color: $blue-color;
        }
    }
    .inline-fig {
        .captionLabel {
            font-weight: normal;
        }
    }
}

.colored-block {
    &__overlay {
        display: inline-block;
        overflow: hidden;
        transition: all 0.5s;
        &.relativeElement {
            position: relative;
        }
        .figure__image {
            opacity: 1;
            transition: all 0.5s;
            margin-bottom: 0;
            display: block;
        }
        .view-media {
            color: $white;
            border: toRem(1) solid;
            padding: toRem(6) toRem(15);
            position: absolute;
            top: 150%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.5s;
            opacity: 0;
            pointer-events: none;

            .figure-viewer & {
                line-height: toRem(16);
                @include font-size(14);
            }
        }
        &:hover {
            @include media('>tablet') {
                background: $black;
                .figure__image {
                    opacity: 0.2;
                }
                .view-media {
                    top: 50%;
                }
            }
        }
    }
    &__media-title {
        font-weight: 500;
        max-width: calc(100% - #{toRem(100)});
    }
}
.relativeElement {
    .view-media {
        opacity: 1;
        pointer-events: all;
    }
}

.index-terms__content {
    text-align: center;
}

.pill-information__content {
    .references {
        color: $blue-color;
    }
}

.figure-viewer {
    .media-item {
        .article-section__table-footnotes {
            display: block;
            color: $black;
            max-width: 80%;
            margin: 0 auto;
            text-align: left;
        }
    }
}

.media-item {
    .article-section__table-footnotes {
        display: none;
    }
}

/* JATS styles starts */
.group-fig {
    text-align: center;
    figure.inline-fig {
        display: inline-block;
    }
}

figure {
    > img {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
}

figcaption {
    font-weight: bold;
    text-align: center;
}

.quote {
    background: $quote-bg;
    margin: 1.5em 20%;
    padding: toRem(20) toRem(40);
    position: relative;
    max-height: none;
    font-style: normal;
    text-indent: 0;
    &:before {
        height: toRem(21);
        position: absolute;
        width: toRem(21);
        font-size: 3.45rem;
        font-family: 'Liberation serif', sans-serif;
        content: open-quote;
        left: toRem(8);
        top: 0;
    }
    &:after {
        content: close-quote;
        right: toRem(10);
        bottom: toRem(30);
        height: toRem(21);
        position: absolute;
        width: toRem(21);
        font-size: 3.45rem;
        font-family: 'Liberation serif', sans-serif;
    }
}

.pull-quote {
    &:before {
        content: '';
        width: toRem(36);
        height: toRem(8);
        background-color: var(--primary-color);
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
    }
    padding: toRem(4) 0;
    position: relative;
    background: initial;
    margin: 0 20%;
}

.left-bordered {
    border-left: toRem(8) solid;
    padding: 0 toRem(12);
    margin: 0 20%;
    overflow: auto;
}

.statement {
    margin: 2em;

    > .label {
        display: inline-block;
        margin: 0;
        padding-right: toRem(8);
    }

    > p {
        display: inline;
    }
}

.smallcaps {
    font-variant: small-caps;
    font-size: initial;
}

.drop-cap {
    font-size: 7em;
    display: block;
    font-weight: bold;
    float: left;
    line-height: 0.6em;
    margin: toRem(13) toRem(15) toRem(10) 0;
}

.boxed-text {
    border: toRem(1) solid $boxed-text-border;
    background: $boxed-text-bg;
}

.boxed-text-float {
    padding: 1em;
    margin: 1em 0;
    .description {
        font-size: toRem(19);
        font-weight: bold;
    }
}

.boxed-text-anchor.pull-right {
    float: right;
}

.boxed-text-anchor {
    padding: 0 1em;
    margin: 0;
    width: 30%;
    float: left;
    clear: both;
}

.inline-headings-section {
    margin-bottom: toRem(8);
    h3 {
        display: inline;
    }
}

p.inline-heading {
    display: inline;
    padding-left: toRem(8);
}

@media (max-width: toRem(600)) {
    .quote {
        margin: 0;
        padding: toRem(40) toRem(20);
    }

    .pull-quote {
        margin: 0;
    }

    .left-bordered {
        margin: 0;
    }

    .boxed-text-anchor {
        float: none;
        width: initial;
        p {
            padding: toRem(8) 0;
        }
    }
}

.badge-rounded-edgs {
    background-color: var(--primary-color);
}

/* JATS styles ends */

.article_highlights {
    margin-top: toRem(35);

    .accordion__control {
        display: flex;
        color: $primary-color;

        .icon-section_arrow_d {
            margin-left: auto;
            margin-right: toRem(15);
        }
    }

    .section__title {
        margin-bottom: 0;
    }

    .accordion__content {
        background-color: $white;
    }
}
