.article__supplemental-material {
    margin: toRem(45) 0;

    .article-media {
        margin-top: toRem(20);
    }
}

.overlay-video__trigger {
    position: absolute;
    background: rgba(0, 0, 0, 0.55);
    top: 0;
    bottom: 0;
    left: toRem(15);
    right: toRem(15);
    z-index: $index-2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
        @include font-size(70);
        @include media('<=tablet') {
            @include font-size(30);
        }
        @include media('<tablet') {
            @include font-size(50);
        }

        color: $white;
    }
}

.supplemental-material {
    &__links {
        .meta-panel__cc {
            i:first-of-type {
                padding-left: 0;
            }
        }
    }
}
