.people {
    .contrib-list__result-header {
        .search-result__info {
            .search-result__tabs {
                display: block;
            }
            .find-box {
                width: 100%;
            }
            .input-group__field-container {
                @include media('>tablet') {
                    width: 76%;
                }
            }
        }
    }

    &__people-list {
        padding: toRem(55) 0;

        @include media('<desktop') {
            flex-basis: 100%;
            width: 100%;

            &:first-child {
                margin-top: toRem(30);
            }
        }

        .people-list-container {
            position: relative;
            background-color: $white;
            border-radius: toRem(4);
            margin: 0 toRem(15);
            padding: toRem(10) toRem(15) 0 toRem(15);
            height: 100%;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
        }

        .people-img {
            position: relative;
            height: 0;
            bottom: toRem(65);
            text-align: center;

            img {
                width: toRem(110);
                height: toRem(110);
                @extend .img-rounded;
            }
        }

        .people-badges {
            display: inline-block;
            position: absolute;
            left: toRem(6);
            top: toRem(10);

            img {
                max-width: toRem(38);
            }
        }

        .people-info {
            text-align: center;
            padding-top: toRem(60);
            height: 100%;

            .name {
                @include font-size(17);
            }

            .location {
                @include font-size(14);
                color: $blue-color;
                padding: toRem(6) 0;
            }

            .publication {
                @include font-size(14);
                color: $primary-lighten-color;

                span {
                    color: $primary-color;
                    padding-right: toRem(5);
                }
            }
        }

        .people-actions {
            padding-top: toRem(15);
            padding-bottom: toRem(10);
            text-align: center;
            margin: 0 toRem(-15);

            .rlist--inline {
                display: inline-block;
                padding-right: toRem(5);

                .btn--icon {
                    height: toRem(30);
                    line-height: toRem(24);

                    &:not(.websites__ctrl) {
                        width: toRem(30);
                    }
                }

                &:first-child {
                    margin-bottom: toRem(8);
                }
            }

            [data-db-target-of] {
                min-width: toRem(290);
                padding: toRem(16);

                @media only screen and (max-device-width: toRem(425)) {
                    min-width: toRem(245);
                }
            }
        }

        .people-button-group {
            margin: 0 toRem(-15);
            max-height: toRem(62);

            a {
                display: inline-block;
                width: 50%;
                text-align: center;
                padding: toRem(12) toRem(10);
                @include font-size(14);

                &.view-profile {
                    border-top: solid toRem(1) $blue-color;
                    color: $white;
                    background-color: $blue-color;
                    border-bottom-left-radius: toRem(4);
                }

                &.quick-view {
                    position: relative;
                    border-top: solid toRem(1) $secondary-color;
                    color: $primary-lighten-color;
                    background-color: $white;
                    border-bottom-right-radius: toRem(4);

                    &.opened {
                        background-color: $secondary-lighten-color;

                        &:after {
                            content: '';
                            position: absolute;
                            height: 0;
                            border-style: solid;
                            border-color: $secondary-lighten-color transparent transparent;
                            border-width: toRem(15) toRem(15) 0;
                            right: calc(50% - #{toRem(15)});
                            bottom: toRem(-15);
                        }
                    }

                    .loadingIcon {
                        color: $blue-color;
                        position: absolute;
                        @include font-size(16);
                        margin-left: toRem(2);
                        display: none;
                    }

                    &.loading-data {
                        cursor: wait;

                        .loadingIcon {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }

    .people-details {
        display: inline-block;
        width: 100%;

        &__info {
            width: 63%;
            background-color: $primary-color;
            display: inline-block;
            padding: toRem(15);
            float: left;

            @include media('>=large') {
                height: toRem(132);
            }

            @include media('<large') {
                width: 100%;
            }

            .image {
                display: inline-block;
                margin-right: toRem(20);
                float: left;

                img {
                    width: toRem(110);
                    height: toRem(110);
                    @extend .img-rounded;
                }
            }

            .people-info {
                display: inline-block;
                float: left;

                .name {
                    @include font-size(22);
                    color: $white;
                    padding-bottom: toRem(6);

                    @include media('<phone') {
                        @include font-size(20);
                    }
                }

                .location {
                    @include font-size(13);
                    color: $secondary-color;
                    padding-bottom: toRem(8);
                }

                .view-profile {
                    @include media('<phone') {
                        padding-left: toRem(15);
                        padding-right: toRem(15);
                    }
                }
            }
        }

        &__bibliometrics {
            display: table;
            width: 37%;
            float: left;
            text-align: right;

            @include media('>=large') {
                height: toRem(132);
            }

            @include media('<large') {
                width: 100%;
            }

            .bib-item {
                display: table-cell;
                color: $white;
                height: 100%;
                padding: toRem(15) toRem(10);
                text-align: center;
                vertical-align: middle;
                width: 33.33%;

                &.bib-gray {
                    background-color: $primary-lighten-color;
                }

                &.bib-blue {
                    background-color: $dark-blue-color;
                }

                &.bib-skyblue {
                    background-color: $dark-blue-cyan;
                }

                .title {
                    @include font-size(16);
                }

                .count {
                    @include font-size(25);
                }
            }
        }

        &__data {
            display: inline-block;
            background-color: $secondary-lighten-color;
            width: 100%;
            padding: toRem(20);
            @include media('<desktop') {
                padding: toRem(9);
            }

            li {
                @include font-size(13);
                position: relative;

                .count {
                    position: absolute;
                    right: toRem(10);
                    top: 0;

                    @include media('>=tablet', '<large') {
                        right: toRem(-12);
                    }
                }

                .creative-work {
                    padding-bottom: toRem(12);
                    margin-bottom: toRem(12);
                }
            }

            .people-keywords {
                li {
                    display: inline-block;
                    background-color: $white;
                    margin-right: toRem(12);
                    margin-bottom: toRem(12);
                    padding: toRem(5);
                }
            }

            .no-data-container {
                text-align: center;
            }

            .no-data-img {
                width: toRem(100);
                height: auto;
                margin: toRem(25) 0;
            }

            .people-affilations {
                @include media('<tablet') {
                    margin: toRem(15) 0;
                }
            }
        }
    }

    .quick-view-slide-down {
        display: none;
        height: auto;
        clear: both;
        margin: 0 toRem(20) toRem(30);
        width: 100%;

        .slide-down-content {
            position: relative;
        }

        &__close-btn {
            position: absolute;
            right: toRem(-25);
            top: toRem(2);
            font-weight: 600;
            font-size: toRem(15);
            background: transparent;
            border: none;
        }
    }

    .search__bottom {
        @include media('<phone') {
            padding: toRem(15) toRem(8) toRem(20);
        }
    }

    #find-input {
        min-width: toRem(300);

        @include media('<phone') {
            min-width: auto;
        }
    }

    &-collaborations {
        .creative-work {
            @include media('<desktop') {
                max-width: toRem(90);
            }
        }
    }
}
