.pill-information__content {
    .cover-image {
        @include font-size(14);
        @extend .clearfix;

        &__image {
            img {
                max-width: toRem(168);
                @include media('<tablet') {
                    max-width: toRem(120);
                }
                width: auto;
                padding: 0;
                margin-right: toRem(20);

                @include media('<phone') {
                    margin-bottom: toRem(12);
                    max-width: toRem(80);
                }
            }
        }

        &__details {
            overflow: hidden;

            @include media('<phone') {
                clear: both;
            }

            &-extra {
                line-height: 1.125rem;
                padding-top: toRem(16);
                .flex-container {
                    padding: toRem(6) 0;

                    .label {
                        @include font-size(14);
                        color: $primary-color;
                    }
                }
            }

            &-extra-data {
                div {
                    padding-top: toRem(10);
                }
            }
        }

        .loa {
            display: inline-block;
            vertical-align: top;
            color: inherit;
            line-height: toRem(20);

            .label {
                margin-bottom: 0;
            }

            @include media('<desktop') {
                &.truncate-list {
                    span:not(.loa_author_inst) {
                        display: inline-block;
                        text-decoration: underline;
                    }
                }
            }
        }

        &-article {
            .cover-pages {
                display: none;
            }
        }

        .cover-pages,
        .cover-date,
        .article-no {
            display: inline-block;
        }

        .cover-date ~ .cover-pages {
            border-left: toRem(1.5) solid $secondary-color;
            margin-left: toRem(5);
            padding-left: toRem(5);
            line-height: toRem(12);
        }

        .bold {
            min-width: toRem(60);
            display: inline-block;
        }

        &-proceeding {
            .book-meta {
                @include font-size(18);
                font-weight: 600;
                display: block;
            }
        }

        & + .cover-multiple-parents {
            border-top: toRem(1) solid $secondary-color;
            margin-top: toRem(20);
            padding-top: toRem(20);
        }
    }

    .serial-title,
    .book-meta {
        padding-bottom: toRem(10);
    }

    .serial-title,
    .citation-line,
    .book-meta,
    .serial-info {
        @include font-size(18);
        font-weight: 600;
        display: block;
    }

    .citation-line,
    .cover-image-proceedingn .book-meta {
        font-style: italic;
    }

    .toc--link {
        color: $loa-text-color;
        text-decoration: underline;
        padding-top: toRem(10);
        display: block;
    }

    .badges {
        img {
            vertical-align: middle;
            margin-right: toRem(24);
            margin-top: toRem(10);
            display: inline-block;

            width: toRem(64);
        }

        .info--text {
            display: inline-block;
            vertical-align: middle;
            max-width: calc(100% - #{toRem(90)});
        }
    }

    .financial {
        p {
            @include font-size(14);
            text-align: justify;
        }
    }

    .publisher {
        p {
            margin: 0;
            @include font-size(14);
        }

        .inlineBlock {
            display: inline-block;
            vertical-align: middle;

            ~ .inlineBlock {
                margin-left: toRem(10);
            }
        }

        &__name {
            color: $primary-color;
            font-weight: 600;
        }
    }

    .history {
        @include font-size(14);

        a {
            color: $blue-color;
        }
    }

    .copyrights {
        clear: both;
        padding-top: toRem(25);
    }

    .funding-list,
    .proceedingInfo-sponsors__list,
    .proceedingInfo-inCoop__list,
    .qualifiers__list {
        list-style: disc;

        &__item {
            padding-bottom: toRem(10);

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    .acm-books img {
        vertical-align: middle;
        margin-right: toRem(17);
    }

    .loc {
        > li:not(:last-of-type) {
            border-bottom: toRem(1) solid $secondary-color;
            margin-bottom: toRem(20);
            padding-bottom: toRem(20);
        }
    }
    img.author-picture {
        margin: 0 toRem(4) 0 toRem(8);
    }
}
