.device-pairing {
    &__devices {
        .btn {
            margin: toRem(10);
            &__cancel {
                color: $primary-color;
            }
        }
    }
    table {
        th {
            color: $black;
            @include font-size(13);
            padding: toRem(20) toRem(14) toRem(20) 0;
        }
        td {
            color: $primary-color;
            font-weight: normal;
            @include font-size(12);
            padding: toRem(20) toRem(14) toRem(20) 0;
        }
    }
    .no-result {
        @include font-size(15);
        font-weight: 300;
    }
    .modal__dialog {
        min-height: toRem(1);
    }
}

[class^='device-pairing'] {
    .title {
        @include font-size(22);
        font-weight: normal;
    }
    label {
        color: $black;
        margin-bottom: toRem(7);
    }
    .buttonsPanel {
        width: 100%;
        background: $light-gray-color;
    }
    .formButtons {
        background: $light-gray-color;
        padding: toRem(10);
    }
    .side-content {
        width: 100%;
    }
    .btn {
        @extend .stretched;
        &[disabled] {
            border: none;
        }
        &:not(.btn--inverse) {
            &:not(.btn--cancel) {
                @extend .blue;
            }
        }
        &:not(:last-child) {
            &.btn--cancel {
                color: $primary-color;
            }
        }
    }
}
