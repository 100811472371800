// todo: move to the variales file
$slide-head-bg: antiquewhite;

.w-slide {
    &_head {
        @include font-size(16);
        background-color: $slide-head-bg;

        .use-black-slide-head & {
            background-color: $black;
            color: $white;
        }
    }

    &__back {
        font-weight: 500;
        text-transform: capitalize;
        color: $black;

        .use-black-slide-head & {
            color: $white;
            font-size: toRem(14);
        }

        &:focus,
        &:hover {
            color: $black;
            .use-black-slide-head & {
                color: $white;
            }
        }

        i {
            color: $black;
            .use-black-slide-head & {
                color: $white;
            }
            font-weight: 900;
            margin-right: toRem(7);
            @include font-size(12);
        }
    }

    &__title {
        font-weight: 500;

        &.js--open {
            .use-black-slide-head & {
                color: $white;
                font-weight: 600;
            }
        }
    }

    &__content {
        padding: toRem(40) toRem(10) 0;

        @include media('<tablet') {
            &[original-target='#filter'] {
                padding-bottom: toRem(70);
            }
        }
    }
}
