.publisher-browse__list {
    .more-link {
        float: right;
        border: none;
        margin: 0;
        padding: 0;
        @include font-size(14);

        @include media('<tablet') {
            float: none;
            display: block;
            text-align: right;
        }
    }

    .search__publishers {
        margin: toRem(8) 0;
        @include font-size(14);

        &--publications {
            @include font-size(12);
            line-height: toRem(25);

            .dotted-list.rlist {
                margin-left: toRem(12);

                li:before {
                    line-height: toRem(25);
                }
            }
        }
    }

    .publications-items {
        color: $blue-color;
        &-more {
            &:before {
                content: none;
            }
        }
    }

    .default-logo {
        padding-top: toRem(6);
    }

    .search-result__info {
        margin-top: 0;
    }
}

.publisher-browse__list,
.contrib-list {
    .search-result__tabs {
        @include media('>=tablet') {
            padding: 0 toRem(15);
        }

        @include media('>=desktop') {
            display: flex;
            align-items: center;
        }
    }

    .search-showing-results {
        margin-left: auto;
    }

    @include media('<tablet') {
        .search-showing-results,
        .search__bottom {
            margin: 0;
        }
        .pull-right.search-showing-results {
            border-top: 0;
        }

        .search__publishers--info {
            .counts {
                min-width: toRem(65);
            }
        }
    }

    @include media('<desktop') {
        .search-showing-results {
            float: none;
        }
    }
}

.find-box {
    @include media('<desktop') {
        border-bottom: toRem(1) solid $secondary-color;
    }

    .input-group {
        margin: 0;

        label {
            display: inline-block;
            min-width: toRem(100);
            @include font-size(14);
            color: $primary-lighten-color;
            font-style: normal;
            font-weight: 400;
            margin-right: toRem(8);
            @include media('<desktop') {
                padding-bottom: toRem(5);
            }

            @include media('>=phone') {
                width: auto;
            }
        }

        &__field-container {
            display: inline-block;

            @include media('<desktop') {
                width: 100%;
            }
        }
    }

    input {
        background: $white;

        &::-webkit-input-placeholder {
            //chrome
            font-weight: 300;
            font-style: italic;
        }

        &::-moz-placeholder {
            //firefox
            font-weight: 300;
            font-style: italic;
        }

        &:-ms-input-placeholder {
            //ie
            font-weight: 300;
            font-style: italic;
        }
    }

    button {
        background: none;
        padding: 0;
        border: none;
        cursor: pointer;

        i {
            @include font-size(20);
        }
    }

    .search-clear {
        margin-left: toRem(10);
        color: $blue-color;
        font-style: normal;
        padding-left: toRem(10);

        @include media('<desktop') {
            margin: 0;
            padding: 0;
            display: block;
            text-align: right;
            margin-top: toRem(10);
        }
        @media (max-width: toRem(1199)) {
            padding-left: 0;
            margin-left: 0;
        }
    }
}
