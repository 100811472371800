.alphanumeric-title {
    @include font-size(12);
    border: toRem(1) solid $secondary-color;
    width: toRem(32);
    height: toRem(32);
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 toRem(4) toRem(8);
    font-weight: 600;
    transition: all 0.3s;
    &:hover {
        transform: scale(1.2);
    }

    &.is--selected {
        background: $light-yellow-color;
    }

    &.is--disabled,
    &:not([href]) {
        color: $secondary-color;
        cursor: not-allowed;

        &:hover {
            transform: scale(1);
        }
    }
}
