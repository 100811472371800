.v-scroller {
    &__content-wrapper {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 50vh;
        -ms-overflow-style: none; //Hide scrollbar for IE
        scrollbar-width: none; // Hide scrollbar for FireFox

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__content {
        -ms-overflow-style: none; //Hide scrollbar for IE
        scrollbar-width: none; // Hide scrollbar for FireFox

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__arrows {
        text-align: center;
        padding: toRem(10);

        i {
            cursor: pointer;
        }
    }
}
