.toc-badge {
    border: toRem(1) solid $secondary-border-color;
    background: $white;
    padding: toRem(14) toRem(20);
    border-left: toRem(8) solid $secondary-border-color;

    @include media('>=tablet') {
        min-height: 92%;
    }

    @include media('<desktop') {
        margin-bottom: toRem(15);
    }

    border-left-color: var(--primary-color);

    &__row {
        padding-bottom: toRem(10);
    }

    &__label {
        padding-right: toRem(10);
    }

    &__view-all {
        color: $blue-color;
    }
}
