.metrics-toggle {
    display: flex;
    align-items: center;
    gap: toRem(6);
    flex-wrap: wrap;
    border: none;
    background-color: transparent;
    padding: 0;
    padding-bottom: 0.5rem;

    .citation,
    .metric {
        display: flex;
        gap: 0.4em;
    }

    span {
        display: inline-flex;
        align-items: center;
    }
}

.metric-update {
    text-align: center;
    margin-top: toRem(24);
    &__date {
        color: $metric-update-date;
        font-size: toRem(16);
        font-weight: 600;
        font-style: italic;
    }
}
