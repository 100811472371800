ol.organizational-chart {
    margin-top: toRem(15);
    text-align: center;
    .tree-preview-container & {
        z-index: $index-1;
    }

    ol,
    li,
    div,
    & {
        position: relative;
    }

    div:not(.organizational-chart__title) {
        background-color: $secondary-lighten-color;
        width: toRem(225);
        border-radius: toRem(20);
        height: toRem(40);
        text-align: center;
        display: inline-block;
        padding-inline: toRem(10);
    }

    ol {
        max-width: 100%;

        &.level-1 > li > div {
            width: toRem(300);
        }

        &.level-2 > li > div {
            width: toRem(275);
        }

        &.level-3 > li > div {
            width: toRem(250);
        }

        &.hasNodes {
            padding-top: 1rem;
            display: flex;
            flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-flow: row wrap;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex-direction: column;
            }
        }

        > li > div:before,
        &.hasNodes:before,
        > li > div:after {
            background-color: $primary-lighten-color;
            content: '';
            position: absolute;
            height: 1rem;
            width: toRem(1);
        }

        > li {
            padding: 1rem 0 0 0;
            flex: 1;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: 1 1 auto;
            }

            > div {
                &:before {
                    left: 50%;
                    bottom: 100%;
                }

                &:after {
                    background-color: transparent;
                    width: toRem(5);
                    height: toRem(5);
                    left: calc(50% - #{toRem(2)});
                    transform: rotate(46deg);
                    bottom: 100%;
                    border: toRem(1) solid $primary-lighten-color;
                    border-top: none;
                    border-left: none;
                }
            }
        }

        &.wrapped {
            > li {
                &:first-of-type:not(:only-of-type):after,
                &:last-of-type:not(:only-of-type):after,
                + li:not(:last-of-type):after {
                    display: none;
                }

                > div {
                    margin-left: toRem(34);

                    @include media('<phone') {
                        margin-left: 0;
                    }

                    .line {
                        background-color: $primary-lighten-color;
                        position: absolute;
                        height: 0;
                        bottom: 50%;
                        width: toRem(1);
                        right: calc(100% + 0.95rem);
                        z-index: $index-minus;
                    }

                    &:before {
                        height: toRem(1);
                        left: auto;
                        top: 50%;
                        width: 1rem;
                        right: 100%;
                    }

                    &:after {
                        left: auto;
                        top: calc(50% - #{toRem(2)});
                        right: 100%;
                        transform: rotate(-45deg);
                    }
                }
            }

            &.level-1 {
                margin-left: 1rem;
                @include media('<phone') {
                    margin-left: 0.5rem;
                }
            }

            &.level-2 {
                margin-left: 2rem;
                @include media('<phone') {
                    margin-left: 1rem;
                }
            }

            &.level-3 {
                margin-left: 3rem;
                @include media('<phone') {
                    margin-left: 1.5rem;
                }
            }

            &.level-4 {
                margin-left: 4rem;
                @include media('<phone') {
                    margin-left: 2rem;
                }
            }

            &.level-5 {
                margin-left: 5rem;
                @include media('<phone') {
                    margin-left: 2.5rem;
                }
            }

            &:before {
                display: none;
            }
        }

        &.hasNodes:before {
            top: 0;
            left: 50%;
        }
    }

    li {
        max-width: 100%;

        &:first-of-type:not(:only-of-type):after,
        &:last-of-type:not(:only-of-type):after,
        + li:not(:last-of-type):after {
            background-color: $primary-lighten-color;
            content: '';
            position: absolute;
            height: toRem(1);
            top: 0;
        }

        &:first-of-type:not(:only-of-type):after,
        &:last-of-type:not(:only-of-type):after {
            width: 50%;
            left: 50%;
        }

        + li:not(:last-of-type):after {
            width: 100%;
            left: 0;
        }
    }

    li:last-of-type:not(:only-of-type):after {
        left: 0;
    }

    p {
        @include font-size(12);
        font-weight: 300;
        max-width: 98%;
        max-height: 98%;
        position: relative;
        top: 20%;
        transform: translateY(-50%);
    }
    @include media('<phone') {
        li {
            width: 100%;
            display: inline-block;
        }
        ol {
            width: fit-content;
            display: inline-block;
            max-width: fit-content;
        }
    }
}

#organizational-chart__title {
    border: toRem(1) solid $secondary-shadow-color;
    padding: toRem(10);
    border-radius: toRem(10);
    margin-bottom: 0;
    background: $white;
    width: unset;
    display: block;
    font-size: toRem(14);
    font-weight: 600;
    height: unset;
}

.full-width-title {
    ol.organizational-chart {
        ol {
            &.level-1 > li > div {
                width: 100%;
            }
        }
    }
}
