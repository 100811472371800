.googleAnalyticsMapContainer {
    #googleAnalyticsMap {
        width: 100%;
        height: toRem(550);
        position: relative;
        padding: 0 !important;
        @include media('<=desktop') {
            height: toRem(350);
        }

        svg.datamap {
            background-color: $info-bg-color;
            border: solid toRem(2) $secondary-color;
            padding-top: toRem(20);
            height: toRem(550) !important;
            @include media('<=desktop') {
                height: toRem(350) !important;
            }
        }
    }

    .googleAnalyticsMapInfo {
        text-align: right;
        color: $primary-lighten-color;
        margin-top: toRem(6);
    }
}
