.cited-by {
    &__list {
        line-height: toRem(20);
        font-size: toRem(14);
    }

    &__article-body {
        .seeMoreWrapper {
            display: flex;
            justify-content: center;
        }

        .colored-block__title {
            padding: toRem(4) 0;
        }
    }

    &__title:is(h2) {
        line-height: unset;
        border-color: var(--primary-color);
        background: unset;
    }

    .extra-links {
        .link {
            color: $blue-color;
        }
    }

    .downloadAll {
        display: flex;
        align-items: center;
        gap: toRem(6);
        padding: 0.5rem 0.625rem;
        border-radius: 1.875rem;
        text-transform: unset;
        font-size: toRem(14);
        margin-top: toRem(-2);
        max-height: toRem(32);
    }

    .citedByEntry > span {
        &::after {
            content: '. ';
        }
    }
}

#tab-citations .cited-by {
    h4 {
        font-style: italic;
        font-weight: 600;
        text-transform: unset;
        color: $primary-color;

        &::before {
            height: toRem(18);
            width: toRem(8);
            border-radius: unset;
        }
    }

    .colored-block__title {
        background-color: unset;
    }
}
