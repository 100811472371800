.binder__issues {
    &__list {
        background: $light-gray-color;
        border-top: toRem(1) solid $secondary-color;
        padding-top: toRem(20);
        min-height: 100vh;
        padding-bottom: toRem(20);
        margin-top: toRem(18);

        .issue-item--binder {
            box-shadow: none;
            border: toRem(1) dashed $secondary-color;
            margin: 0;
            margin-top: toRem(-1);

            .issue-item__citation {
                display: inline-block;
            }

            .issue-item__content {
                @include media('>=desktop') {
                    width: calc(100% - #{toRem(185)});
                }
            }
        }

        .binder__issues__sort,
        .per-page {
            display: inline-block;
            position: relative;
            padding: 0 toRem(10);
            @include font-size(14);

            @include media('<phone') {
                padding: 0 toRem(5);
            }
        }

        .info--text {
            i {
                margin-right: toRem(5);
            }
        }

        .description-holder {
            .icon-add-desc,
            .icon-Icon_About-Article {
                color: darken($highlight-color, 12%);
            }

            .btn {
                color: $primary-lighten-color;
                padding: toRem(5) toRem(10);
            }

            .icon-add-desc.icon-add-desc {
                font-size: 1rem;
                font-weight: 400;
                vertical-align: text-top;
                margin: 0;
            }
        }

        .issue-item__footer-info {
            padding-bottom: toRem(10);
            > ul > li {
                vertical-align: middle;
            }
        }
    }

    &__sort {
        &-ctrl {
            @include font-size(14);
            padding-bottom: toRem(10);

            i {
                @include font-size(10);
                margin: 0 toRem(5);
                color: $primary-lighten-color;
                display: inline-block;
            }

            .icon-info {
                @include font-size(15);
                display: inline;
            }
        }

        &-drop {
            @extend .search__sort__drop;
            min-width: toRem(160);

            li {
                margin-bottom: toRem(5);
            }
        }
    }

    &__info {
        @include font-size(13);
        @include media('>=desktop') {
            margin-top: toRem(30);
        }

        @include media('<desktop') {
            display: flex;
            flex-direction: column;

            > .pull-left {
                order: 1;

                li {
                    display: block;
                }
            }

            > .accordion {
                order: 2;
            }

            > .pull-right {
                order: 3;
            }
        }

        .label {
            display: inline-block;
            font-weight: 400;
            color: $primary-color;
            margin: 0;
            margin-right: toRem(5);

            ~ span {
                color: $primary-lighten-color;
            }
        }

        .pull-left {
            @include media('>=desktop') {
                margin-bottom: toRem(12);
            }

            li:not(:last-child) {
                @include media('>=desktop') {
                    border-right: toRem(1) solid $secondary-color;
                    padding-right: toRem(10);
                    margin-right: toRem(10);
                }

                @include media('<desktop') {
                    margin-bottom: toRem(10);
                }
            }
        }

        .accordion {
            &__control {
                margin: 0;
                @include media('<desktop') {
                    text-align: center;

                    &.js--open {
                        background: $secondary-lighten-color;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                        border-bottom: toRem(1) solid $white;
                    }
                }
            }

            &__content {
                font-weight: 300;
                margin-bottom: toRem(15);

                @include media('<desktop') {
                    border-bottom-left-radius: toRem(20);
                    border-bottom-right-radius: toRem(20);
                    border-bottom: toRem(1) solid $white;

                    li.separator-left {
                        margin: 0;
                        padding: 0;
                        margin-top: toRem(20);
                        clear: both;
                        display: block;
                    }

                    .separator-left {
                        border-left: none;
                        margin-top: toRem(20);
                    }

                    .link {
                        padding: toRem(8);
                        text-align: center;
                        display: block;
                        width: 100%;
                        color: $primary-color;
                        border: toRem(1) solid $primary-color;
                        border-radius: toRem(2);
                        font-weight: 400;
                        text-decoration: none;
                    }
                }

                .icon-person {
                    @include font-size(14);
                    vertical-align: middle;
                    margin-right: toRem(5);
                }
            }
        }

        li.separator-left {
            padding-left: toRem(10);
            margin-left: toRem(10);
        }
    }

    .btn--icon {
        &.disable {
            color: $secondary-color;
            cursor: not-allowed;

            &:hover {
                @include media('>=desktop') {
                    background: $secondary-lighten-color;
                }
            }
        }
    }

    .pagination {
        li {
            padding: 0 toRem(5);
        }
    }
}

.pdfSpan {
    display: inline-block;
    padding: 0 toRem(3);
}

.binder {
    &--added-description {
        .accordion__content {
            margin-top: toRem(18);
            padding: 0;
            background: transparent;
        }

        textarea {
            resize: none;
            background: $light-gray-color;
            border-radius: toRem(4);

            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                @include font-size(13);
                font-style: italic;
                font-weight: 300;
                color: $primary-lighten-color;
            }

            &::-moz-placeholder {
                /* Firefox 19+ */
                @include font-size(13);
                font-style: italic;
                font-weight: 300;
                color: $primary-lighten-color;
            }

            &:-ms-input-placeholder {
                /* IE 10+ */
                @include font-size(13);
                font-style: italic;
                font-weight: 300;
                color: $primary-lighten-color;
            }

            &:-moz-placeholder {
                /* Firefox 18- */
                @include font-size(13);
                font-style: italic;
                font-weight: 300;
                color: $primary-lighten-color;
            }
        }

        .input-group__field-container--right-icon {
            width: 100%;

            textarea.editable__input {
                padding-right: toRem(70);
            }
        }

        .editable {
            &__wrapper {
                &:not(.js--edit):not(.editable--default) {
                    background-color: $secondary-lighten-color;
                    padding: toRem(15);
                }

                .input-group-addon--right {
                    width: toRem(66);
                    padding: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: toRem(5);
                    display: flex;
                    align-items: flex-end;

                    .charLimit {
                        margin: toRem(10) toRem(3);
                    }
                }

                .btn.blue {
                    background-color: $blue-color;
                    color: $white;
                    padding-top: toRem(11);
                    padding-bottom: toRem(11);
                    border-radius: toRem(2);
                    margin: 0;
                }

                .input-group {
                    margin-bottom: toRem(25);
                }

                h6 {
                    margin-bottom: toRem(5);
                }

                &.js--edit {
                    h6 {
                        display: none;
                    }
                }
            }

            &--default {
                h6 {
                    display: none;
                }
            }

            &__text {
                color: $primary-lighten-color;
            }
        }
    }

    &__sub-heading {
        color: $primary-lighten-color;
        @include font-size(22);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: toRem(13);
        @include media('>=desktop') {
            max-width: toRem(400);
        }
    }

    &__sub-title {
        color: $black;
    }

    @include media('<desktop') {
        &__issues__header,
        &__sub-heading {
            border-bottom: toRem(1) solid $secondary-color;
            padding-bottom: toRem(12);
            margin-bottom: toRem(12);
        }

        &__issues__info > .pull-right {
            border-top: toRem(1) solid $secondary-color;
            padding-top: toRem(12);
            margin-top: toRem(12);
        }
    }

    &__issues__header {
        margin-bottom: toRem(14);
    }
}

.pdf--create {
    &.disable {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .dot-flashing {
        display: none;
    }

    &.load {
        .dot-flashing {
            display: inline-block;
            left: 35%;
            position: relative;
            width: toRem(10);
            height: toRem(10);
            border-radius: toRem(5);
            background-color: $white;
            color: $white;

            animation: dotFlashing 1s infinite linear alternate;
            animation-delay: 0.5s;
            z-index: $index-1;

            &:before,
            &:after {
                content: '';
                display: inline-block;
                position: absolute;
                top: 0;
                border-radius: toRem(5);
                background-color: $white;
                color: $white;
                width: toRem(10);
                height: toRem(10);

                animation: dotFlashing 1s infinite alternate;
            }

            &:before {
                left: toRem(-15);
                animation-delay: 0s;
            }

            &:after {
                left: toRem(15);
                animation-delay: 1s;
            }
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.47);
        }
    }
}

.sortable__toggle {
    display: inline-block;
    padding-right: toRem(25);
    cursor: pointer;
    color: $secondary-color;

    &:hover {
        cursor: grab;

        .disable-sort & {
            cursor: not-allowed;
        }
    }
}

#createPDF {
    .modal__dialog {
        min-height: 0;
    }

    .btn {
        width: toRem(170);
        margin: 0;
    }
}
