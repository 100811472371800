.content-navigation {
    @include font-size(14);
    color: $blue-color;
    margin: toRem(15) 0;

    span:not(:first-child) {
        padding-left: toRem(5);
    }

    &__btn {
        &--next {
            float: right;
        }
        &--disabled,
        &--disabled:hover {
            color: $primary-lighten-color;
            cursor: auto;
            opacity: 0.5;
        }

        &--pre {
            .icon-Arrow-Stroke {
                &:before {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .icon-Arrow-Stroke {
        font-size: toRem(7);
        padding-left: 0;
    }

    .article__sections & {
        padding: 0 toRem(15);
        @include font-size(12);

        .type {
            display: none;
        }
    }
}
