// todo: move to variables file
$bibliometricsTitle-border: #261314;

.bibliometrics {
    position: relative;
    color: $text-onDark;
    @include font-size(18);
    margin: toRem(15) 0 toRem(25);
    overflow: hidden;
    font-family: Merriweather, sans-serif;
    @include media('<large', '>phone') {
        padding-bottom: toRem(20);
    }

    .slideShow {
        min-height: toRem(150);

        .owl {
            &-carousel {
                cursor: default;
                @include media('>=tablet') {
                    width: 100%;
                }

                @include media('>=1200px') {
                    pointer-events: none;
                }

                @include media('>phone', '<desktop') {
                    div.bibliometrics__count {
                        span {
                            font-size: toRem(24) !important;
                            display: inline-block;
                            margin: auto;
                        }
                    }
                    .bibliometrics__title {
                        font-size: toRem(16);
                    }
                }

                @media only screen and (min-device-width: toRem(768)) and (max-device-width: toRem(1024)) and (orientation: landscape) {
                    .bibliometrics__title {
                        font-size: toRem(16);
                    }
                    .bibliometrics__count {
                        span {
                            font-size: toRem(24) !important;
                            display: block;
                            width: toRem(145);
                            margin: auto;
                        }
                    }
                }

                .owl-item {
                    @include media('<tablet') {
                        height: toRem(183) !important;
                    }
                    @include media('>tablet') {
                        height: toRem(135) !important;
                    }
                    @media only screen and (min-device-width: toRem(768)) and (max-device-width: toRem(1024)) {
                        height: toRem(144) !important;
                        .bibliometrics__block {
                            padding: toRem(24) toRem(10);
                        }
                    }
                    @media only screen and (min-device-width: toRem(532)) and (max-device-width: toRem(991)) and (orientation: landscape) {
                        height: toRem(152) !important;
                    }
                    @media only screen and (min-device-width: toRem(992)) and (max-device-width: toRem(1024)) {
                        height: toRem(156) !important;
                    }
                }
                .owl-dot.active {
                    span {
                        background-color: $active-dot-color;
                        border-color: $owl-dot-outline-color;
                    }
                }
            }

            &-dots {
                display: none;

                &:not(.disabled) {
                    display: block;
                    position: absolute;
                    width: 100%;
                    left: 0;
                    @include media('<large') {
                        bottom: toRem(0);
                    }
                    @include media('<=phone') {
                        bottom: toRem(5);
                        right: 0;
                        width: toRem(90);
                    }
                }
            }
        }

        .slide-item {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__count {
        @include font-size(30);
        height: toRem(40);
        @include media('>=desktop') {
            @include font-size(20);
        }
    }

    &__block {
        text-align: center;
        padding: toRem(16) toRem(10);
        @include media('<phone') {
            height: 100%;
            padding: toRem(46);
        }
    }

    &__title {
        @include font-size(14);
        min-height: toRem(24);

        @include media('<phone') {
            @include font-size(16);
        }

        @include media('<tablet') {
            min-height: toRem(15);
        }

        @include media('<desktop') {
            min-height: toRem(44);
        }
    }

    &__main-title {
        position: absolute;
        z-index: $index-2;
        font-style: italic;
        font-weight: 600;

        &__tooltip {
            margin-right: toRem(5);
            .icon-Icon_Information {
                display: none;
            }

            &:hover {
                .icon-info {
                    display: none;
                }
                .icon-Icon_Information {
                    display: block;
                }
            }
        }

        @include media('<=phone') {
            border-left: toRem(8) solid $white;
            padding-left: toRem(8);
            margin-left: toRem(10);
            margin-top: toRem(11);
            @include font-size(18);
            line-height: toRem(20);
            height: auto !important;
        }

        @include media('>phone') {
            background: #251314;
            @include font-size(14);
            width: toRem(25);
            text-align: center;
            left: 0;
            top: 0;
            margin: 0;
            padding: 0;
            &__content {
                transform: rotate(-90deg);
                display: flex;
                flex-direction: row-reverse;
                gap: toRem(6);
                position: absolute;
                left: toRem(-31);
                text-align: center;
                top: 43%;
                &.shift-left {
                    left: toRem(-41);
                }
            }
            &__tooltip {
                margin-right: 0;
                transform: rotate(90deg);
            }
            &:after {
                content: '';
                display: inline-block;
                width: 0;
                height: 0;
                position: absolute;
                top: calc(50% - #{toRem(10)});
                left: 100%;
                border-style: solid;
                border-width: toRem(5) 0 toRem(10) toRem(9);
                border-color: transparent transparent transparent $bibliometricsTitle-border;
            }
        }
    }

    &__date__tooltip {
        background-color: $white;
        color: $info-text-color;
        box-shadow: toRem(-2) toRem(2) toRem(10) rgba(0, 0, 0, 0.5);
        border-radius: toRem(5);
        max-width: toRem(370);
        font-weight: 600;
        .icon-Icon_Information {
            font-weight: 400;
        }
        .simple-tooltip-text-top-arrow {
            border-color: $white;
        }
    }

    &--slider {
        .owl-carousel {
            padding: 0 0 0 toRem(24);
            @include media('<large') {
                padding: 0 toRem(30) 0 toRem(55);
            }
            @include media('<=phone') {
                padding: 0;
            }
            &:has(.owl-nav.disabled) {
                @include media('<large') {
                    padding: 0 0 0 toRem(24);
                }
            }
        }
    }

    &--not-slider {
        .owl-carousel {
            padding: 0;
            padding-left: toRem(25);
        }

        .owl-stage {
            transform: translate3d(0, 0, 0) !important;
        }
    }

    &-nomargin-container {
        .bibliometrics {
            margin: 0;
        }
    }
}

.owl-carousel {
    .owl-dots .owl-dot {
        background: transparent;
        border: none;
    }

    .owl-nav {
        [class*='owl-'] {
            border: none;

            .bibliometrics & {
                top: 0;
                margin: 0;
                height: 100%;
                transition: background 1s;
                padding: 0 toRem(10);

                i {
                    @include font-size(18);
                    color: rgba($white, 0.54);
                }
            }
        }

        .owl-prev {
            .bibliometrics & {
                @include media('>phone') {
                    left: toRem(25);
                }
            }
        }
    }
}

.contrib-metrics-wrapper {
    @include media('<tablet') {
        .accordion-tabbed__control {
            display: flex;
            align-items: center;

            .box-item__content {
                flex-direction: row;
            }

            &:after {
                content: '\e60b' !important;
                float: right;
                font-family: icomoon, sans-serif !important;
                padding-right: toRem(10);
                color: $blue-color;
                font-style: normal;
                font-size: 0.563rem;
                flex-grow: 1;
                text-align: right;
            }

            .box-item__title {
                a {
                    text-decoration: none;
                }
            }
        }
        .accordion-tabbed__tab {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        &.js--open {
            > .accordion-tabbed__control {
                &:after {
                    content: '\e60c' !important;
                }
            }
        }

        .authors-accordion {
            .accordion-tabbed__tab {
                &.js--open {
                    .accordion-tabbed__control {
                        &:after {
                            content: '\e60c' !important;
                            float: right;
                            font-family: icomoon, sans-serif !important;
                            padding-right: toRem(10);
                            color: $blue-color;
                            font-style: normal;
                            font-size: 0.563rem;
                            flex-grow: 1;
                            text-align: right;
                        }
                    }
                }
            }
        }

        .contrib-metrics-bibliometrics {
            background-image: linear-gradient(to right, #e0e0e0 30%, rgba(255, 255, 255, 0) 0);
            background-position: top;
            background-size: toRem(7) toRem(1);
            background-repeat: repeat-x;
        }
    }
}

.contrib-metrics-bibliometrics {
    .grid-md-2 & {
        li:nth-child(2n + 1) {
            @include media('>=tablet') {
                clear: left;
            }
        }
    }

    .authors-accordion {
        .loa {
            > li:not(.visibility-hidden) {
                border-top: toRem(1) solid $secondary-color;
            }
            &.border-bottom {
                border-bottom: toRem(1) solid $secondary-color;
            }
            &.odd-authors {
                > li:last-child {
                    width: 100%;
                }
            }
        }
        .accordion-tabbed__tab {
            @include media('<desktop') {
                width: 100%;
            }

            &.accordion__closed {
                &.grid-item {
                    &.visibility-hidden {
                        position: absolute;
                    }
                }
            }
        }

        .box-item__title {
            color: $primary-lighten-color;
            font-weight: 600;

            a {
                text-decoration: none;
            }
        }
    }

    .view-profile {
        display: none;

        a {
            text-decoration: none;
        }
        i {
            @include font-size(15);
            margin-left: toRem(5);
            color: $secondary-color;
        }
    }

    @include media('>large') {
        .accordion-tabbed__control {
            &:after {
                position: absolute;
                right: 0;
                padding-top: toRem(25);
            }
        }
    }
}

.auth-name {
    p {
        margin: 0;
        font-size: 0.875rem;
        font-weight: normal;
    }
}
