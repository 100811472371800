.more-issue__section {
    margin-top: toRem(60);

    &.colored-block__title {
        background-color: $black;
        color: $text-onDark;
        padding: toRem(22) 0;

        .left-bordered-title {
            line-height: toRem(20);
            &:before {
                background-color: $white;
            }
        }
    }
}
