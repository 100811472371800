.article__computing-reviews {
    p {
        margin: 0;
        @include font-size(14);
        line-height: toRem(23);
        font-weight: 300;
    }

    a {
        color: $blue-color;
        text-decoration: underline;
    }

    h4 {
        margin-bottom: toRem(5);
        margin-top: toRem(7);
    }

    img {
        @include media('<large') {
            width: 24%;
        }
        @include media('<desktop') {
            float: none;
            width: auto;
        }

        @include media('>=tablet', '<desktop') {
            float: left;
        }

        @include media('<phone') {
            width: 100%;
            height: auto;
            margin-bottom: toRem(10);
        }
    }

    .computing-text {
        @include media('>=tablet', '<large') {
            float: left;
        }
    }
}
