.split-request {
    margin-top: toRem(40);

    p {
        margin: 0;
    }

    .split-doi,
    &-form__header {
        @include media('>=tablet') {
            display: flex;
            align-items: center;

            .doi {
                width: 15%;
            }

            .title {
                width: 55%;
                padding-left: toRem(20);
            }

            .profileId {
                width: 30%;
                padding-left: toRem(20);
            }
        }
    }

    .doi {
        input {
            padding: 0;
            cursor: text !important;
            opacity: 1;
            border: none;
            background: transparent;
            @include font-size(12);
            color: $primary-color;
        }

        a {
            @include font-size(12);
            color: $blue-color;
            text-decoration: underline;
            font-weight: 400;
        }
    }

    .title {
        p {
            font-weight: 600;
        }

        ul.rlist--inline {
            margin-top: toRem(8);
            li {
                margin-right: toRem(8);
                margin-bottom: toRem(8);
            }
        }
    }

    .doi,
    .title,
    .profileId {
        @include media('<tablet') {
            margin-bottom: toRem(10);
        }

        label {
            @include media('>=tablet') {
                display: none;
            }
        }
        .field-error-label {
            label {
                @include media('>=tablet') {
                    display: block;
                }
            }
        }
    }

    .split-request-form {
        .input-group__right-icon {
            @include media('<tablet') {
                top: toRem(31);
            }
        }
    }

    .split-doi {
        border-bottom: toRem(1) solid $secondary-color;
        padding: toRem(15) 0;

        p,
        ul {
            @include font-size(12);
            //white-space: pre-wrap;
        }

        .title {
            font-weight: 300;
        }
    }

    input {
        width: 100%;
        background: $white;
        margin: 0;
    }

    &-action {
        margin-top: toRem(20);
    }

    &__publication-info {
        margin: toRem(40) 0 toRem(30);
        @include font-size(14);
        white-space: pre-wrap;

        label {
            @include font-size(14);
            display: inline-block;
            font-weight: 300;
            margin-bottom: 0;
        }

        span {
            font-weight: 600;
        }

        p {
            margin-bottom: toRem(10);
        }
    }

    .find-box {
        label {
            display: block;
            @include font-size(12);
            font-weight: 600;
            color: $primary-color;
            margin-bottom: toRem(10);
            width: 100%;
        }

        @include media('>=tablet') {
            input,
            .input-group__field-container {
                width: toRem(466);
            }
        }

        @include media('<tablet') {
            float: none;
            .btn {
                margin: 0;
                margin-top: toRem(10);
                float: right;
            }
        }

        .field-error-label.label {
            width: 100%;
            @include media('>=tablet') {
                float: left;
            }
        }
    }

    .info--text {
        @include font-size(12);
        color: $primary-lighten-color;
        margin-top: toRem(15);
    }

    &__search {
        .hidden {
            display: none !important;
        }

        .field-success-label {
            display: block;
            float: left;
            width: 100%;
            label {
                color: $success-text-color;
                font-weight: 300;
                font-style: italic;
            }
        }
        .error-submit-msg {
            position: absolute;
            right: toRem(40);
            font-size: toRem(12);
            color: $error-text-color;
            font-weight: 300;
            font-style: italic;
            padding-top: toRem(4);
        }
    }
}
