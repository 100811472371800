.search {
    &-result {
        &__xsl-body {
            .search__item {
                width: 100%;

                @include media('<tablet') {
                    margin: toRem(10) 0;
                }
            }

            .lazy-loaded {
                width: 100%;
            }

            .bookPubDate {
                display: block;
            }
        }

        &__info {
            font-weight: 300;
            font-style: italic;
            margin-bottom: toRem(35);

            @include media('<tablet') {
                margin-bottom: 0;
            }

            @include media('>=tablet') {
                margin-top: toRem(10);
            }

            .left-bordered-label {
                border-color: $highlight-color;
                display: inline-block;
                height: toRem(18);
                margin-top: toRem(4);
                position: absolute;
            }

            .hitsLength,
            .result__suffix {
                font-weight: 600;

                .pre_query {
                    font-weight: 100;
                }
            }

            .suffix__info {
                display: inline-block;
                max-width: toRem(550);

                @include media('<tablet') {
                    margin-bottom: toRem(20);
                }
            }

            .result__suffix {
                display: flex;

                @include media('<tablet') {
                    display: block;
                    //width: calc(100% - #{toRem(114)});
                }
            }

            &-details {
                display: inline-block;
                padding-left: toRem(16);
                position: relative;
            }

            .search-result--browse & {
                display: none;
            }
        }

        &__tabs {
            @include font-size(14);
            background-color: $light-gray-color;
            border-top: toRem(1) solid;
            border-bottom: toRem(1) solid;
            border-color: $secondary-border-color;

            @include media('<tablet') {
                background-color: transparent;
                border-top: none;
                padding: 0;
                border: 0;
            }
        }

        &__nav-container {
            position: relative;

            @include media('>=tablet') {
                display: inline-block;
            }
        }

        &__nav {
            display: inline-block;
            padding: toRem(12) 0;

            @include media('<desktop') {
                display: flex;
            }

            @include media('<phone') {
                display: flex;
                padding-bottom: 0;
            }

            @include media('<tablet') {
                padding-top: toRem(10);
                font-style: italic;
                overflow: auto;
                padding-left: toRem(15);
                padding-right: toRem(15);

                &:after,
                &:before {
                    content: ' ';
                    position: absolute;
                    top: 0;
                    width: toRem(20);
                    height: 100%;
                }

                &:after {
                    right: toRem(-10);
                    background: linear-gradient(to right, rgba(255, 255, 255, 0.4) 0, $white 100%);
                }

                &:before {
                    left: toRem(-10);
                    background: linear-gradient(to right, $white 0, rgba(255, 255, 255, 0.4) 100%);
                }
            }

            &__first-tab {
                font-weight: 400;
                font-size: inherit;
            }

            &__item {
                color: $blue-color;
                text-transform: uppercase;
                @include font-size(14);
                padding: toRem(12) toRem(15);

                @include media('<tablet') {
                    display: block;
                }

                @include media('<desktop') {
                    padding: toRem(12) toRem(5);
                }

                @include media('<phone') {
                    padding: toRem(12) toRem(7);
                    @include font-size(16);
                    line-height: toRem(15);
                }

                &.active {
                    font-weight: 800;
                    border-bottom: toRem(4) solid $blue-color;
                }
            }
        }

        .pagination {
            margin-bottom: toRem(30);
        }

        &__no-result {
            margin-top: toRem(10);
        }

        @include media('<phone') {
            .publications-wrapper {
                .colored-block__img-holder {
                    display: none;
                }
            }
        }

        &__profile-page {
            .search__acm-results {
                display: none;
            }
        }

        .issue-item {
            &__title {
                font-size: toRem(16);
            }

            .truncate-text {
                .read-more {
                    cursor: unset;
                }
            }

            .issue-item__footer-heading {
                font-size: toRem(14);
            }
        }

        @include media('<phone') {
            .issue-item__footer-info {
                ul.rlist--inline {
                    li {
                        display: none;

                        &:first-child {
                            display: inline-block;
                        }
                    }
                }
            }
        }

        .events-browse__list {
            &-labels {
                @include media('<desktop') {
                    display: none;
                }
            }
        }
        .saveBinder {
            .close {
                font-size: toRem(16);
            }
        }

        .save-search {
            &__alert-label {
                font-size: toRem(18);
            }
        }
    }

    &-showing-results {
        padding: toRem(12) toRem(12) toRem(12) 0;

        span {
            padding-right: toRem(4);
        }

        @include media('<tablet') {
            float: none;
            padding: toRem(20) toRem(20);
            margin: toRem(3) toRem(-15) 0;
            background-color: $light-gray-color;
            border-top: toRem(1) solid;
            border-bottom: toRem(1) solid;
            border-color: $secondary-border-color;

            .result__suffix {
                display: none;
            }
        }

        @include media('<phone') {
            margin-top: 0;
        }
    }

    &__follow,
    &__edit {
        @include font-size(14);
        font-weight: 500;
        font-style: normal;
        color: darken($blue-color, 10%);
        padding-left: toRem(15);

        &__label {
            padding-left: toRem(5);
        }

        &__icon {
            display: inline-block;
            padding: toRem(6) toRem(20);
            background-color: $blue-color;
            color: $white;
            border-radius: toRem(20);
            transition-duration: 0.3s;

            &:hover,
            &:focus {
                color: $white;
                background: darken($blue-color, 10%);
            }

            i {
                vertical-align: middle;
                font-weight: 600;
            }

            @include media('<348px') {
                padding: toRem(6) toRem(12);
            }
        }

        .saveSearchMsg {
            padding-left: toRem(10);
        }
    }

    &__edit {
        color: $primary-color;

        @include media('<phone') {
            &:before {
                content: ' ';
                display: table;
                margin-top: toRem(12);
            }
        }

        &__icon {
            padding: toRem(4) toRem(18);
            background: transparent;
            border: toRem(1) solid $primary-color;
            color: $primary-color;

            i {
                color: inherit;
            }

            &:hover,
            &:focus {
                color: $white;
                background-color: $primary-color;
            }

            @include media('<348px') {
                padding: toRem(4) toRem(12);
            }
        }
    }

    &__acm-full {
        @include font-size(14);
        padding-left: toRem(16);

        @include media('>=tablet') {
            margin-top: toRem(10);
        }

        a {
            padding-left: toRem(5);
            color: $blue-color;

            @include media('<tablet') {
                padding: 0;
                display: block;
            }
        }

        @include media('<tablet') {
            border-bottom: toRem(1) solid $secondary-border-color;
            border-top: toRem(1) solid $secondary-border-color;
            padding: toRem(20) toRem(15);
            margin: 0 toRem(-15);
        }
    }

    &__acm-results {
        @include font-size(18);

        @include media('<tablet') {
            padding: 0 0 toRem(20) 0;

            &:after {
                content: '';
                display: table;
                clear: both;
            }
        }
    }

    &__bottom {
        margin: toRem(15) 0 toRem(10);
        border-bottom: toRem(1) solid $secondary-border-color;
        @include font-size(14);

        @include media('<tablet') {
            padding: toRem(15) toRem(22) toRem(20);
            margin: toRem(3) toRem(-15) 0;
            position: relative;
            background-color: $light-gray-color;
            text-transform: uppercase;
            display: flex;
            flex: 1;
            align-items: center;
            @include font-size(12);
        }

        &.sticky_checkboxes {
            @include media('>=tablet') {
                position: sticky;
                background: $white;
                transition: top 0.5s;
                z-index: $index-5;
                top: toRem(124);
                margin: toRem(10) 0;
            }

            .item-results__msg {
                @include media('>=tablet', '<large') {
                    padding: toRem(11) 0;
                }
            }

            .search__bottom-right {
                @include media('>=tablet') {
                    padding: toRem(10) 0;
                }
            }

            @include media('<tablet') {
                position: static;
            }
        }

        &-left {
            display: inline-block;
            padding-top: toRem(5);
            width: 65%;

            @include media('>=tablet', '<large') {
                width: 40%;
            }

            @include media('<tablet') {
                display: inline-block;
                padding: 0;
                margin: 0;
                width: auto;
            }

            &__options {
                display: inline-block;
                width: 100%;
                position: relative;

                @include media('<tablet') {
                    display: none;
                }
            }

            .applied-facets-count {
                display: none;
                background: $primary-lighten-color;
                border-radius: 50%;
                width: toRem(18);
                height: toRem(18);
                text-align: center;
                color: $white;
                margin-left: toRem(4);
                vertical-align: bottom;
            }
        }

        &-right {
            display: inline-block;

            @include media('<tablet') {
                float: none;

                .sort-by {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    max-width: 80%;
                    display: inline-block;
                    vertical-align: bottom;
                    padding-left: toRem(5);

                    @include media('<355px') {
                        max-width: 60%;
                    }

                    @include media('<340px') {
                        max-width: 55%;
                    }

                    @include media('<325px') {
                        max-width: 40%;
                    }
                }
            }
        }

        .result__ctrl__filters {
            padding-top: toRem(5);
            width: 50%;
            border-right: toRem(1) solid $secondary-border-color;
            min-width: toRem(100);

            @include media('<tablet') {
                width: auto;
                padding-top: 0;
                padding-right: toRem(10);
                margin-right: toRem(6);

                .icon-filter_list {
                    padding-right: toRem(8);
                }
            }
        }
    }

    &__sort {
        float: right;
        position: relative;
        padding: 0 toRem(8);

        @include media('<tablet') {
            padding: 0;
        }

        > button[data-db-target-for='sort'] {
            @include media('<tablet') {
                padding: toRem(5) 0 0;
            }
        }

        i {
            @include font-size(8);
            margin-left: toRem(5);
            vertical-align: middle;
            padding-right: 0;
        }

        button,
        a {
            padding: toRem(5) 0 toRem(20);
            display: inline-block;
            background-color: transparent;
            border: none;
            font-style: italic;
            font-weight: 300;

            &:has(i) {
                cursor: pointer;
            }
        }

        .sort-by-caption {
            @include media('<tablet') {
                display: inline-block;
            }
        }

        &__drop {
            background: $default-bg-color;
            border: toRem(1) solid $secondary-border-color;
            border-top: toRem(3) solid;
            padding: 0;
            @include font-size(14);
            min-width: toRem(150);
            right: 0;
            left: initial !important;

            a {
                padding: toRem(6) toRem(15);
                display: block;
                text-transform: capitalize;

                &:hover {
                    background: $secondary-lighten-color;
                }
            }

            li {
                &:first-child {
                    a {
                        padding-top: toRem(12);
                    }
                }

                &:last-child {
                    a {
                        padding-bottom: toRem(12);
                    }
                }
            }
        }

        &.hide-dropdown {
            .search__accordion-ctrl {
                cursor: default;
                pointer-events: none;

                i {
                    display: none;
                }
            }

            .search__sort__drop {
                display: none;
            }
        }

        .sort-by-caption,
        .sort-by,
        .icon-section_arrow_d {
            color: $black;
        }
    }

    &__publishers {
        background: $white;
        margin-bottom: toRem(15);

        &--info {
            display: flex;
            align-items: center;
            padding: toRem(10) toRem(15);
            border-bottom: solid toRem(1) $secondary-color;

            img,
            .default-logo {
                margin-right: toRem(18);
                border-radius: 50%;
            }

            img {
                background: $white;
                border: solid toRem(1) $secondary-lighten-color;
                padding: toRem(6);
            }

            .default-logo {
                background-color: $secondary-lighten-color;
                width: toRem(35);
                height: toRem(35);
                padding-top: toRem(4);
                text-align: center;

                i {
                    @include font-size(20);
                    color: $secondary-color;

                    &:before {
                        color: $secondary-color;
                    }
                }
            }

            .title,
            .counts {
                color: $primary-lighten-color;
            }

            .counts {
                margin-left: auto;
            }
        }

        &--publications {
            padding: toRem(15);
        }
    }
}

.searchRss {
    margin-left: toRem(15);
    font-weight: 100;
}

.per-page {
    display: inline-block;

    @include media('<tablet') {
        display: none;
    }

    &.separator-end {
        border-right: toRem(1) solid $secondary-border-color;
        padding-right: toRem(5);
    }

    &__label {
        padding: toRem(5) 0;
        display: inline-block;
    }

    a {
        padding: toRem(5);
        display: block;
    }

    ul {
        float: right;
        font-weight: 400;
    }

    .js--selected {
        font-weight: 600;
    }
}

#newSearchSaveButton {
    background-color: $blue-color;
}

.bookPubDate,
.journalDate {
    &.simple-tooltip__block--b {
        display: inline-block;
        margin-bottom: toRem(4);

        @include media('<desktop') {
            margin-bottom: toRem(2);
        }
    }
}

.issue-item--search,
.search-result__xsl-body {
    .issue-heading {
        margin-bottom: toRem(4);

        @include media('<desktop') {
            display: block;
            margin-bottom: toRem(2);
        }
    }
}

span.single_highlight_class {
    background: $highlight-color-new;
}

.search-result__body.hidden {
    display: none;
}

.search-result--browse {
    .issue-item {
        &__citation {
            @include media('<desktop') {
                display: block;
            }
        }
    }
}

.author-publications-js,
.search-result__profile-page {
    .search__acm-full {
        border-top: none;
    }
}

.issueDate {
    color: $primary-lighten-color;
    text-transform: capitalize;
}

.facet__content {
    span {
        &.sr-only-focusable:focus {
            text-decoration: none !important; // overriding an important
        }
    }
}

.edit__options__Buttons {
    @include media('>=tablet') {
        float: right;
    }
}

.sticko__side-content {
    .search-result__info-details {
        width: 100%;
    }
}
