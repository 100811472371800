.subscribe-banner {
    text-align: center;
    padding: toRem(30) 0;
    &__subtitle {
        @include font-size(26);
        color: $white;
        font-weight: 300;
    }
    &__title {
        @include font-size(45);
        color: $white;
        margin-bottom: toRem(40);
        @include media('<tablet') {
            @include font-size(30);
        }
    }
    &__btns {
        li {
            @include media('>=tablet') {
                margin-right: toRem(10);
            }
            @include media('<tablet') {
                width: 100%;
                margin-bottom: toRem(10);
                overflow: hidden;
                a {
                    width: 100%;
                }
            }
        }
    }
    &__btn {
        display: flex;
        align-items: center;
        height: toRem(54);
        width: toRem(220);
        &-icon {
            @include font-size(24);
        }
        &-text {
            flex-grow: 1;
            @include font-size(18);
            padding-right: toRem(4);
            &-slim {
                @include font-size(12);
            }
        }
        .icon-export {
            color: $secondary-color;
            @include font-size(14);
        }
    }
}
