.section {
    &--most-popular {
        background-image: url(../../../images/most-popular-articles-bg-clear-v.jpg);
        background-position: bottom, top;
        background-repeat: repeat, no-repeat;
        background-size: contain;
        position: relative;
        overflow: hidden;
        z-index: $index-0;

        .container {
            position: relative;

            &:before,
            &:after {
                content: '';
                width: toRem(441);
                height: toRem(542);
                display: block;
                position: absolute;
                bottom: toRem(-136);
                z-index: $index-minus;
            }

            &:before {
                background-image: url(../../../images/Proceedings_Cover.png);
                right: 81%;
                transform: rotate(-11deg);

                @include media('<desktop') {
                    display: none;
                }
            }

            &:after {
                background-image: url(../../../images/CSUR_Cover.png);
                left: 74%;
                transform: rotate(11deg);
                position: absolute;
                bottom: toRem(-115);

                @include media('<desktop') {
                    bottom: toRem(-141);
                    left: 45%;
                }

                @include media('<tablet') {
                    display: none;
                }
            }

            .bg {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                &:after {
                    content: '';
                    width: toRem(441);
                    height: toRem(542);
                    display: block;
                    position: absolute;
                    bottom: toRem(-180);
                    z-index: $index-minus;
                    background-image: url(../../../images/JACM_Cover.png);
                    left: 82%;
                    transform: rotate(27deg);

                    @include media('<desktop') {
                        left: 59%;
                        bottom: toRem(-200);
                    }

                    @include media('<tablet') {
                        display: none;
                    }
                }
            }
        }
    }
}

.conferences-home {
    &__item {
        margin-bottom: toRem(30);
        @include media('<phone') {
            margin-bottom: toRem(15);
        }
        padding-bottom: toRem(15);
        border-bottom: toRem(1) solid rgba(0, 0, 0, 0.12);
    }
    .owl-carousel {
        display: block;
    }
}

.conference-banner {
    @include media('<tablet') {
        margin-top: toRem(20);
    }

    a {
        display: block;
    }
}

//styles from acm-custom.css

.tabbed-content--proceedings-tabs .parent-item {
    display: none;
}

.tabbed-content--proceedings-tabs time {
    text-transform: none;
}

.books-section {
    .owl-carousel {
        .owl-dot {
            position: relative;
            left: toRem(36);
        }
    }
}
