.authors-accordion {
    .accordion-tabbed {
        margin: 0;

        &__arrow {
            display: inline-block;
            width: auto;
            float: right;
            padding-top: toRem(13);
            i {
                display: inline-block;
            }
        }

        &__tab.js--open {
            .accordion-tabbed__arrow {
                i {
                    transform: rotate(180deg);
                }
            }
        }

        > li {
            padding: toRem(11) toRem(16);
            transition: 0.2s;
            color: $primary-lighten-color;
            &:not(.js--open) {
                &:hover {
                    background: $secondary-lighten-color;
                    color: $primary-color;
                }
            }

            @include media('<tablet') {
                padding: toRem(11) 0;
            }
        }

        li {
            line-height: inherit;
        }

        &__tab:first-child,
        &__tab:last-child {
            border-color: $secondary-border-color;
        }

        &__content {
            @include media('>=phone') {
                padding-left: toRem(45);
            }
        }

        &__control {
            padding: 0;
            color: $primary-lighten-color;
        }
    }

    .auth {
        &-image,
        &-info {
            vertical-align: middle;
            display: inline-block;
        }

        &-image {
            margin-right: toRem(11);
            img {
                width: toRem(56);
                height: toRem(56);
                @extend .img-rounded;
            }
        }

        &-name {
            font-weight: 600;
            font-style: italic;
            @include font-size(18);
            cursor: pointer;
        }

        &-institution {
            display: block;
            font-weight: 400;
        }
        &-profile-link {
            padding: toRem(8) toRem(35);
            display: inline-block;
            a {
                text-decoration: none;
            }
            i {
                @include font-size(14);
                font-weight: normal;
                padding-left: toRem(8);
                color: $primary-lighten-bg-color;
                display: inline-block;
            }
        }
    }

    div {
        > .metric-name,
        > .metric-value {
            font-weight: 600;
            color: $primary-color;
        }
    }
}

.holdings-wrapper {
    margin-top: toRem(10);

    .authors-accordion {
        padding-right: toRem(20);
        margin-bottom: toRem(30);
        .accordion-tabbed__content {
            padding-left: 0;
        }
        .accordion-tabbed {
            h4 {
                color: $primary-color;
            }
            > li {
                padding: 0;
                border: 0;
            }
        }
    }
}
