.upcoming-conf {
    &--upcoming-events {
        .search-result & {
            @extend .separated-block;
        }
        padding: toRem(25) 0;
        margin-top: toRem(30);
        margin-left: toRem(30);
        @include media('<phone') {
            margin-left: 0;
        }

        .owl-carousel {
            padding: 0;
        }

        .slideShow {
            margin: 0 toRem(40);
            @include media('<phone') {
                margin: 0 toRem(15);
            }

            .owl-carousel {
                .owl-dots {
                    @include media('<desktop') {
                        width: toRem(100);
                    }
                }

                .owl-dot {
                    span {
                        padding: toRem(5);
                    }

                    &.active {
                        span {
                            background-color: $blue-color;
                            border-color: $blue-color;
                        }
                    }
                }
            }
        }

        .slide-item {
            margin: 0 toRem(10);
        }

        .owl-nav {
            i,
            .owl-nav-active i {
                color: lighten($primary-lighten-color, 10%) !important;
            }

            .owl-prev {
                margin-left: toRem(-40);
                @include media('<phone') {
                    margin-left: toRem(-25);
                }
            }

            .owl-next {
                margin-right: toRem(-40);
                @include media('<phone') {
                    margin-right: toRem(-25);
                }
            }

            .owl-carousel .owl-nav [class*='owl-']:hover {
                background: transparent !important;
            }
        }
    }
    &--slider {
        .card--shadow {
            height: calc(100% - #{toRem(5)});
        }
        .colored-block {
            height: 100%;
            background: $white;
            &__dash {
                bottom: toRem(5);
            }
            .event {
                .event__content {
                    position: static;
                }
            }
        }
    }
}
.upcoming-conf-table-list {
    .events-browse__list {
        margin: 0;
    }
}
.upcoming-events-table {
    .slideShow {
        @include media('<=tablet') {
            min-height: toRem(150);
            padding: 0 toRem(20);
            .owl-prev,
            .owl-next {
                background-color: transparent !important;
            }
        }
    }
    .responsive-table {
        &__cell {
            @include media('<desktop') {
                border: none;
                padding: toRem(10) toRem(20);
            }
        }
        &__row {
            @include media('<desktop') {
                border: none;
            }
        }
    }
}
