.item-meta {
    margin-top: toRem(15);

    &-row {
        margin-top: toRem(12);
        &__label {
            display: table-cell;
            padding-right: toRem(10);
            min-width: toRem(116);
            @include media('>=tablet') {
                text-align: right;
            }

            img {
                vertical-align: middle;
            }
        }
        &__value {
            display: table-cell;
            .published-info {
                margin-bottom: toRem(10);

                img.badge {
                    vertical-align: middle;
                    padding-right: toRem(6);
                }
                .space {
                    word-break: break-word;
                }
            }
            &.vertical-top {
                vertical-align: top;
            }

            .magazine-name {
                border: toRem(1) solid #b8b8b8;
                border-radius: toRem(5);
                padding: toRem(4);
                color: $primary-lighten-color;
                text-shadow: toRem(2) toRem(2) toRem(3) rgba($white, 0.5);
                -webkit-background-clip: text;
                -moz-background-clip: text;
                background-clip: text;
                letter-spacing: toRem(1);
                text-transform: uppercase;
                @include font-size(12);
            }
        }

        .rlist--inline {
            li.label {
                @include media('<tablet') {
                    margin-right: toRem(20);
                }
                &:first-child {
                    @include media('>=tablet') {
                        margin-left: 0;
                        min-width: toRem(116);
                        text-align: right;
                    }
                }
            }

            .label {
                padding-right: toRem(10);
                color: $primary-color;
                @include font-size(14);
                @include media('>=tablet') {
                    margin-left: toRem(8);
                }
            }
        }

        .truncate-text {
            margin-top: 1rem;
        }
    }
    .colored-block {
        &__content {
            padding: toRem(16) 0;
            @include media('>=desktop') {
                display: flex;
            }

            .relatedDo {
                p {
                    display: inline;
                }
            }
        }

        &__title {
            line-height: toRem(23);

            .volume {
                font-weight: 600;
            }
            .date {
                @include media('<tablet') {
                    display: block;
                }
            }

            .in-progress {
                color: $secondary-text-color;
                &:before {
                    color: $text-onLight;
                }
                @include media('<tablet') {
                    display: none;
                }
            }
        }
    }

    .tags-info {
        .removed-items-count {
            padding: toRem(8) toRem(10);
            vertical-align: top;
        }
    }

    &__buttons {
        @include media('>=desktop') {
            display: flex;
        }

        .col-md-12 {
            @extend .separator-left;
            @include media('<desktop') {
                border: none;
                border-top: toRem(1) solid $secondary-border-color;

                margin-left: toRem(-15);
                margin-right: toRem(-15);
                padding: toRem(15);
                padding-bottom: 0;
            }
        }
    }

    .cover-image {
        @include media('<desktop') {
            display: none;
        }
    }

    &__subscriber {
        margin: toRem(30) toRem(-15) 0;
        @include font-size(12);
        color: $secondary-text-color;

        @include media('<desktop') {
            margin: toRem(10) 0;
        }
    }
    &__info {
        @include font-size(14);

        .published-info {
            > div {
                margin-bottom: toRem(12);
            }
        }

        &-table {
            td,
            th {
                padding: toRem(12) 0 0;
            }
            th {
                padding-right: toRem(8);
                text-align: right;
                vertical-align: top;
            }
        }
    }
    @include media('>=desktop') {
        .left-side-meta {
            display: table;
            &:not(.col-md-12) {
                padding-right: 0;
            }
        }

        .left-side-image {
            width: toRem(160);
            padding-right: toRem(26);

            img {
                width: 100%;
                float: none;
                padding: 0;
            }
        }

        .left-side-image,
        &__info {
            display: table-cell !important;
            vertical-align: top;
        }
    }

    .actions {
        @include media('<desktop') {
            display: flex;

            .btn {
                width: 100%;
            }
        }
    }
    .goto-sig {
        @include font-size(12);
        .icon-export {
            padding-left: toRem(5);
            vertical-align: middle;
        }
    }

    .rfc-status {
        margin-top: toRem(2);

        > div {
            padding-top: toRem(8);
        }

        ul {
            display: table-cell;

            a {
                text-decoration: underline;
                color: $blue-color;
            }
        }
    }

    &.profile-meta {
        @include media('>=tablet') {
            .left-side-meta {
                display: table;
                width: 100%;
                &:not(.col-md-12) {
                    padding-right: 0;
                }
            }
        }
    }
    &.proceedings-meta {
        .quick-search--button {
            .quick-search__button {
                position: absolute;
                top: 0;
            }
        }
    }
}

.static-height-for-truncate,
.truncate_container {
    [data-lines='4']:not(.trunc-done) {
        height: toRem(76);
        overflow: hidden;
    }
}
