// todo: move to variables file
$index-3: 3;
$index-4: 4;

.search {
    &__item {
        @include font-size(14);

        .browse-item-body {
            position: relative;
            .loa {
                li {
                    &:not(:last-child) {
                        &:after {
                            content: ' , ';
                        }
                    }
                }
            }
        }

        .item-overlay-bg {
            display: none;
        }
        .browse-item-cover {
            display: none;
        }
        .meta {
            margin-left: toRem(16);
            border-bottom: toRem(1) solid $secondary-border-color;
            padding-bottom: toRem(15);
            margin-bottom: toRem(20);

            &__abstract {
                color: $primary-lighten-color;

                @at-root {
                    .search__item-browse#{&} {
                        .search-result--tile & {
                            display: none;
                        }

                        &--dynamic {
                            display: none;

                            #pb-editor & {
                                display: block;
                            }

                            .search-result--tile & {
                                display: block;

                                @media (hover: none) {
                                    display: none;
                                }

                                @include media('<desktop') {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            &__editor {
                .loa {
                    color: $primary-lighten-color;
                    padding-left: toRem(10);
                    display: inline-block;
                }
            }
        }

        &.search__item-browse {
            .meta {
                padding-bottom: toRem(40);
            }

            .more-link {
                color: $blue-color;
                border-top: none;
                bottom: toRem(15);

                @include media('<=phone') {
                    padding: toRem(16) 0 0;
                    //right: 0;
                    @include font-size(12);
                }

                &__visit-website {
                    &:after {
                        content: '|';
                        padding: 0 toRem(8);
                        color: $black;
                    }
                }
                .icon-export {
                    padding-left: toRem(10);
                }
            }

            .author {
                text-decoration: none;
            }

            .author-name {
                text-decoration: underline;
            }

            a:focus {
                outline: none;

                .author-picture__wrapper {
                    box-shadow: 0 0 toRem(6) toRem(4) $focus-color !important;
                }
            }
        }

        .search-result__browse & {
            .author-picture {
                position: absolute;
                top: 0;
                left: 50%;
                display: block;
                width: 100%;
                max-width: 150%;
                max-height: 150%;
                transform: translateX(-50%);
                padding: 0;
                border-radius: 0;
                transition: filter 0.6s ease-in-out;

                &__wrapper {
                    position: relative;
                    border-radius: 50%;
                    overflow: hidden;
                    display: inline-block;
                    width: toRem(24);
                    height: toRem(24);
                    vertical-align: middle;
                    margin-right: toRem(5);
                }
            }
        }

        &-title {
            .browse-code {
                text-transform: uppercase;
                padding-right: toRem(5);
                font-style: italic;
                color: $journal-code-color;
                border-left: toRem(8) solid $text-onLight;
                padding-left: toRem(8);
                border-color: var(--primary-color);
            }
        }

        &-logo {
            display: none;
        }
    }

    &-result {
        .layout-wrapper {
            padding-bottom: toRem(30);

            .left-bordered-title {
                @include font-size(14);
                font-style: normal;
                padding-right: toRem(10);
            }
        }

        &--tile {
            margin: 0;
            max-width: 100%;
            width: 100%;
            padding: 0;
            display: block;

            &::before {
                width: 100%; //safari fix
            }

            .author-picture {
                width: auto;
                height: auto;
            }

            img.browse-image {
                display: block;
                position: absolute;
                width: 100%;
                top: 0;
                bottom: 0;
                filter: brightness(180%);
                transition: all 0.4s ease-out;
            }

            .item-overlay-bg {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;

                &::before,
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                }
            }

            .search__item {
                &-browse {
                    float: left;
                    z-index: $index-1;
                }

                &-journal {
                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        bottom: 0;
                        left: 0;
                        transform-origin: right bottom;
                        -webkit-transform-origin: right bottom;
                        box-shadow: toRem(4) toRem(-4) toRem(4) rgba($black, 0.34);
                        transform: rotate(-45deg) translate3d(3%, -12%, 0);
                        -webkit-transform: rotate(-45deg) translate3d(3%, -12%, 0);
                    }

                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 200%;
                        height: 100%;
                        bottom: 0;
                        left: 0;
                        transform-origin: left bottom;
                        -webkit-transform-origin: left bottom;
                        box-shadow: 0 toRem(4) toRem(6) rgba(0, 0, 0, 0.25);
                        transform: rotate(-45deg) translate3d(-3%, -3%, 0);
                        -webkit-transform: rotate(-45deg) translate3d(-3%, -3%, 0);
                    }

                    .item-overlay-bg {
                        background-color: rgba($black, 0.54);

                        &::before {
                            width: 32%;
                            height: 32%;
                            bottom: -4%;
                            left: -5%;
                            z-index: $index-minus;
                            transform-origin: left bottom;
                            -webkit-transform-origin: left bottom;
                            box-shadow: toRem(2) toRem(-2) toRem(8) #827f7f;
                            border-radius: 50%;
                        }

                        &::after {
                            width: 200%;
                            height: 100%;
                            bottom: 0;
                            left: 0;
                            opacity: 0;
                            transform-origin: left bottom;
                            transform: rotate(-45deg) translate3d(-3%, -3%, 0);
                            transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1),
                                opacity 0.15s ease-in-out 0.1s;

                            -webkit-transform-origin: left bottom;
                            -webkit-transform: rotate(-45deg) translate3d(-3%, -3%, 0);
                            -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1),
                                opacity 0.15s ease-in-out 0.1s;

                            @include media('<desktop') {
                                display: none;
                            }

                            @media (hover: none) {
                                display: none;
                            }
                        }
                    }

                    &:hover,
                    &:focus,
                    &:focus-within {
                        .item-overlay-bg {
                            &::after {
                                @include media('>=desktop') {
                                    transform: scale3d(1.5, 1.5, 1.5);
                                    -webkit-transform: scale3d(1.5, 1.5, 1.5);
                                    opacity: 1;
                                    transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.15s,
                                        border-radius 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.15s,
                                        opacity 0.25s ease-in-out;

                                    -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1)
                                            0.15s,
                                        border-radius 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.15s,
                                        opacity 0.25s ease-in-out;
                                }
                            }
                        }
                    }
                }

                &-sig {
                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        bottom: 0;
                        left: 0;
                        transform-origin: left top;
                        -webkit-transform-origin: left top;
                    }

                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 120%;
                        height: 120%;
                        bottom: 6%;
                        left: -10%;
                        transform-origin: center bottom;
                        -webkit-transform-origin: center bottom;
                        box-shadow: toRem(2) 0 toRem(19) #565656;
                        border-radius: 50%;
                        //transform: translate3d(0, -6%, 0);

                        @include media('<desktop') {
                            transform: none;
                            bottom: 6%;
                        }

                        @media (hover: none) {
                            transform: none;
                            bottom: 6%;
                        }
                    }

                    .item-overlay-bg {
                        background-color: rgba($black, 0.45);

                        &::before {
                            display: none;
                        }

                        &::after {
                            width: 120%;
                            height: 120%;
                            bottom: 0;
                            left: -10%;
                            opacity: 0;
                            transform-origin: center bottom;
                            -webkit-transform-origin: center bottom;
                            border-radius: 50%;
                            transform: translate3d(0, -6%, 0);
                            -webkit-transform: translate3d(0, -6%, 0);
                            transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1),
                                border-radius 0.25s ease-in-out, opacity 0.15s ease-in-out 0.1s;

                            -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1),
                                border-radius 0.25s ease-in-out, opacity 0.15s ease-in-out 0.1s;

                            @include media('<desktop') {
                                display: none;
                            }

                            @media (hover: none) {
                                display: none;
                            }
                        }
                    }

                    &:hover,
                    &:focus,
                    &:focus-within {
                        .item-overlay-bg {
                            &::after {
                                @include media('>=desktop') {
                                    transform: translate3d(0, 0, 0);
                                    -webkit-transform: translate3d(0, 0, 0);
                                    border-radius: 0;
                                    opacity: 1;
                                    transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.15s,
                                        border-radius 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.15s,
                                        opacity 0.25s ease-in-out;
                                    -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1)
                                            0.15s,
                                        border-radius 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.15s,
                                        opacity 0.25s ease-in-out;
                                }
                            }
                        }
                    }
                }

                &-conf {
                    background-color: var(--primary-color);

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        bottom: 0;
                        left: 0;
                        transform-origin: left bottom;
                        -webkit-transform-origin: left bottom;
                        box-shadow: toRem(-2) toRem(1) toRem(19) #3c3c3c;
                        transform: rotate(-45deg) translate3d(66%, 20%, 0);
                        -webkit-transform: rotate(-45deg) translate3d(66%, 20%, 0);
                        background-color: var(--lighter-color);
                    }

                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        bottom: 0;
                        left: 0;
                        transform-origin: left bottom;
                        -webkit-transform-origin: left bottom;
                        box-shadow: toRem(-2) toRem(1) toRem(19) #3c3c3c;
                        transform: rotate(-45deg) translate3d(-34%, 20%, 0);
                        -webkit-transform: rotate(-45deg) translate3d(-34%, 20%, 0);
                        background-color: var(--light-color);
                    }

                    .item-overlay-bg {
                        background-color: rgba($black, 0.4);
                        background-image: linear-gradient(to top, rgba(255, 255, 255, 0), var(--primary-color));

                        &::before {
                            background: var(--primary-color);
                            background-color: $csur-primary-color;
                            width: 200%;
                            height: 200%;
                            bottom: 0;
                            left: 0;
                            z-index: $index-minus;
                            opacity: 0;
                            transform-origin: left bottom;
                            transform: rotate(-45deg) translate3d(-12.5%, 110%, 0);
                            -webkit-transform-origin: left bottom;
                            -webkit-transform: rotate(-45deg) translate3d(-12.5%, 110%, 0);
                            transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1),
                                opacity 0.15s ease-in-out 0.1s;
                            -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1),
                                opacity 0.15s ease-in-out 0.1s;

                            @include media('<desktop') {
                                display: none;
                            }

                            @media (hover: none) {
                                display: none;
                            }
                        }

                        &::after {
                            background: var(--lighter-color);
                            background-color: rgba($csur-lighter-color, 1);
                            width: 32%;
                            height: 32%;
                            bottom: -11%;
                            right: -2%;
                            z-index: $index-minus;
                            transform-origin: left top;
                            -webkit-transform-origin: left top;
                            box-shadow: toRem(2) toRem(-2) toRem(8) #827f7f;
                            border-radius: 50%;
                        }
                    }

                    &:hover,
                    &:focus,
                    &:focus-within {
                        .item-overlay-bg {
                            &::before {
                                @include media('>=desktop') {
                                    transform: rotate(-90deg) translate3d(0, 100%, 0);
                                    -webkit-transform: rotate(-90deg) translate3d(0, 100%, 0);
                                    opacity: 1;
                                    transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.15s,
                                        border-radius 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.15s,
                                        opacity 0.25s ease-in-out;

                                    -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1)
                                            0.15s,
                                        border-radius 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.15s,
                                        opacity 0.25s ease-in-out;
                                }
                            }
                        }
                    }
                    .search-result--tile & {
                        background-color: var(--primary-color);

                        &::before {
                            background-color: var(--lighter-color);
                        }

                        &::after {
                            background-color: var(--light-color);
                        }

                        .item-overlay-bg {
                            background-image: linear-gradient(to top, rgba(255, 255, 255, 0), var(--primary-color));

                            &::before {
                                background: var(--primary-color);
                                background-color: $csur-primary-color;
                            }

                            &::after {
                                background-color: rgba($csur-lighter-color, 1);
                                background: var(--lighter-color);
                            }
                        }
                    }
                }
                .browse-link {
                    display: block;

                    @include media('<desktop') {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        padding: toRem(16);
                        z-index: $index-3;
                    }

                    @media (hover: none) {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        padding: toRem(16);
                        z-index: $index-3;
                    }
                }

                .meta {
                    margin: 0;
                    border: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    padding: 0;

                    //@include media('<desktop') {
                    //    display: none;
                    //}
                    //
                    //@media (hover: none) {
                    //    display: none;
                    //}
                }

                .loa {
                    width: 0;
                    height: 0;
                    overflow: hidden;

                    li {
                        &:nth-child(-n + 2) {
                            .author-picture {
                                &__wrapper {
                                    visibility: hidden;
                                    opacity: 0;
                                    padding: 0;
                                    width: toRem(56);
                                    height: toRem(56);
                                    margin: 0;
                                    border: toRem(2) solid rgba($white, 0.4);
                                    z-index: $index-4;
                                    position: absolute;
                                    left: 0;
                                    bottom: toRem(40);
                                    box-shadow: toRem(2) toRem(-2) toRem(8) #827f7f;
                                    transform: translateX(-200%);
                                    transition: opacity 0.4s ease-in-out, filter 0.4s ease-in-out,
                                        transform 0.25s ease-in-out;

                                    @include media('<' + $screen-mlg) {
                                        width: toRem(40);
                                        height: toRem(40);
                                    }

                                    @include media('<desktop') {
                                        visibility: visible;
                                        opacity: 1;
                                        transform: translate3d(0, 0, 0);
                                        left: toRem(16);
                                        bottom: toRem(16);
                                    }
                                    @media (hover: none) {
                                        visibility: visible;
                                        opacity: 1;
                                        transform: translate3d(0, 0, 0);
                                        left: toRem(16);
                                        bottom: toRem(16);
                                    }
                                }
                            }
                        }

                        &:nth-child(2) {
                            .author-picture {
                                &__wrapper {
                                    left: toRem(48);
                                    z-index: $index-3;
                                    transition: opacity 0.4s ease-in-outs, filter 0.4s ease-in-out,
                                        transform 0.25s ease-in-out;

                                    @include media('<' + $screen-mlg, '>=desktop') {
                                        left: toRem(48);
                                    }

                                    @include media('<desktop') {
                                        left: toRem(64);
                                    }
                                }
                            }
                        }

                        &:after {
                            content: ' ';
                            margin: 0;
                        }
                    }
                }

                &:hover,
                &:focus,
                &:focus-within {
                    .browse-item-body {
                        @include media('>=desktop') {
                            display: block;
                        }

                        @media (hover: none) {
                            display: flex;
                        }
                    }

                    .meta__abstract,
                    .more-link {
                        @include media('>=desktop') {
                            visibility: visible;
                            opacity: 1;
                        }
                    }

                    .search__item-logo {
                        @include media('>=desktop') {
                            display: none;
                        }

                        @media (hover: none) {
                            display: flex;
                        }
                    }

                    .meta {
                        @include media('>=tablet') {
                            position: relative;
                        }

                        @media (hover: none) {
                            position: absolute;
                        }

                        &__abstract {
                            @include media('>=desktop') {
                                background-image: linear-gradient(to top, rgba($white, 0), $white, $white, $white);
                                color: transparent;
                                -webkit-background-clip: text;
                                margin-top: toRem(40);
                                overflow: hidden;
                                height: calc(100% - #{toRem(120)});
                            }

                            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                                color: $white;
                                background-image: none;
                            }

                            @include media('<' + $screen-mlg, '>=tablet') {
                                height: calc(100% - #{toRem(104)});
                            }
                        }
                    }

                    .more-link {
                        @include media('>=desktop') {
                            position: absolute;
                            bottom: toRem(18);
                            padding-right: 0;
                            right: toRem(16);
                            transition: opacity 0.25s ease-in-out 0.3s;

                            a {
                                color: $white;
                            }

                            span {
                                display: none;
                            }
                        }

                        @media (hover: none) {
                            bottom: toRem(15);
                        }
                    }

                    .browse {
                        &-image {
                            filter: none;
                            transition: all 0.4s ease-in;
                        }

                        &-code {
                            @include media('>=desktop') {
                                visibility: visible;
                                opacity: 1;
                            }

                            &::before {
                                @include media('>=desktop') {
                                    width: toRem(8);
                                }

                                @media (hover: none) {
                                    width: 0;
                                }
                            }
                        }

                        &-title {
                            @include media('>=desktop') {
                                opacity: 0;
                            }

                            @media (hover: none) {
                                opacity: 1;
                            }
                        }

                        &-link {
                            @include media('>=desktop') {
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                z-index: $index-3;
                                padding: toRem(16);
                            }
                        }
                    }

                    .loa {
                        li {
                            &:nth-child(-n + 2) {
                                .author-picture {
                                    transition: filter 0.6s ease-in-out;

                                    &__wrapper {
                                        @include media('>=desktop') {
                                            visibility: visible;
                                            opacity: 1;
                                            transform: translate3d(0, 0, 0);
                                            transition: opacity 0.4s ease-in-out 0.1s, filter 0.6s ease-in-out,
                                                transform 0.4s ease-in-out 0.1s;
                                        }
                                    }
                                }
                            }

                            &:nth-child(2) {
                                .author-picture {
                                    &__wrapper {
                                        @include media('>=desktop') {
                                            transition: opacity 0.4s ease-in-out 0.1s, filter 0.6s ease-in-out,
                                                transform 0.4s ease-in-out 0.2s;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &:focus,
                &:focus-within {
                    box-shadow: 0 0 toRem(6) toRem(2) $focus-color;
                }
            }

            .more-link__visit-website {
                display: none;
            }

            .search__item-logo {
                flex: 1;
                display: flex;
                flex-flow: row;
                align-items: flex-end;
                justify-content: center;
                position: relative;
                opacity: 0.8;
            }

            .search__item-logo-image {
                display: block;
                flex-basis: 0;
                max-width: 60%;
                margin: 0 auto;
                max-height: toRem(120);
                padding: 0 0 toRem(16);
                width: auto;
                height: auto;
                position: absolute;
                bottom: 40%;
                left: 50%;
                transform: translate3d(-50%, 50%, 0);

                @include media('<large') {
                    bottom: 30%;
                }

                @include media('<tablet') {
                    bottom: 25%;
                }
            }

            .browse-item-body {
                display: flex;
                flex-flow: column nowrap;
                padding: toRem(16);
                color: $text-onDark;
                position: absolute;
                height: 100%;
                top: 0;
                width: 100%;
                z-index: $index-2;

                .browse {
                    &-title {
                        @include font-size(16);

                        position: relative;
                        font-weight: 600;
                        font-style: normal;
                        display: block;
                        bottom: unset;
                        top: 0;
                        padding: toRem(4) 0 0;
                        transition: opacity 0.25s ease-in-out;
                    }

                    &-code {
                        @include font-size(24);

                        position: relative;
                        color: $text-onDark;
                        border: none;
                        display: block;
                        font-style: italic;
                        font-weight: 600;
                        height: toRem(32);
                        margin: 0 0 0 toRem(-16);
                        padding: 0 0 0 toRem(16);
                        visibility: visible;
                        overflow: hidden;
                        opacity: 1;
                        transition: opacity 0.25s ease-in-out;

                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: toRem(8);
                            width: 0;
                            height: toRem(16);
                            background: $text-onDark;
                            transition: width 0.25s ease-in-out 0.25s;

                            @include media('<desktop') {
                                display: none;
                            }

                            @media (hover: none) {
                                display: none;
                            }
                        }
                    }
                }

                .colored-block__dash {
                    background-color: $white;
                    height: toRem(3);
                }
            }

            .editor-label,
            .author-name {
                @include sr-only();
            }

            .meta__abstract,
            .more-link,
            .author-picture__wrapper {
                visibility: hidden;
                opacity: 0.1;
            }

            .meta__abstract {
                visibility: visible;
                opacity: 0;
                position: relative;
                height: 0;
                z-index: $index-2;
                overflow: hidden;
                transition: height 0.4s ease-in-out;
            }

            .more-link {
                position: absolute;
                bottom: toRem(18);
                padding-right: 0;
                right: toRem(16);
                transition: opacity 0.25s ease-in-out;

                @include media('<desktop') {
                    visibility: visible;
                    opacity: 1;
                }

                @media (hover: none) {
                    visibility: visible;
                    opacity: 1;
                }

                a {
                    color: $white;
                }

                span {
                    display: none;
                }
            }

            &.search-result__browse {
                .search__item {
                    position: relative;
                    padding-top: 20%;
                    width: 20%;
                    overflow: hidden;
                    border-bottom: toRem(1) solid $white;
                    border-left: toRem(1) solid $white;
                    border-radius: toRem(2);

                    @include media('>=' + $screen-xxlg) {
                        width: 12.5%;
                        padding-top: 12.5%;

                        &:nth-child(8n) {
                            border-right: 0;
                        }
                    }

                    @include media('<' + $screen-xxlg, '>=' + $screen-xlg) {
                        width: 14.28%;
                        padding-top: 14.28%;

                        &:nth-child(7n) {
                            border-right: 0;
                        }
                    }

                    @include media('<' + $screen-xlg, '>=' + $screen-mlg) {
                        width: 16.66%;
                        padding-top: 16.66%;

                        &:nth-child(6n) {
                            border-right: 0;
                        }
                    }

                    @include media('<' + $screen-mlg, '>=' + $screen-lg) {
                        &:nth-child(5n) {
                            border-right: 0;
                        }
                    }

                    @include media('<large', '>=desktop') {
                        width: 25%;
                        padding-top: 25%;

                        &:nth-child(4n) {
                            border-right: 0;
                        }
                    }

                    @include media('<desktop', '>=tablet') {
                        width: 33.33%;
                        padding-top: 33.33%;

                        &:nth-child(3n) {
                            border-right: 0;
                        }
                    }
                    @include media('<tablet') {
                        width: 50%;
                        padding-top: 50%;

                        &:nth-child(2n) {
                            border-right: 0;
                        }
                    }
                    @include media('<phone') {
                        width: 100%;
                        padding-top: 100%;
                        border-right: 0;
                    }

                    .author-picture {
                        height: 100%;
                        border-radius: 50%;
                    }
                }
            }

            .owl-carousel {
                .slide-item {
                    @include media('>=desktop', '<1400px') {
                        .search__item-title {
                            .browse-title {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                            }
                        }
                        .search__item-logo {
                            img {
                                max-height: toRem(75);
                            }
                        }
                    }
                }
            }
        }

        &__browse {
            list-style: none;
            .browse-image {
                display: none;
            }
            &:not(.search-result--tile) {
                .search__item-browse {
                    display: flex;
                    border-bottom: toRem(1) solid $secondary-border-color;
                    padding-bottom: toRem(20);
                    margin-bottom: toRem(20);
                    position: relative;

                    &.fadeInUp {
                        display: flex !important;
                    }

                    .meta {
                        padding: 0;
                        margin: 0;
                        border: 0;
                    }
                    .browse-item {
                        &-body {
                            position: static;
                            width: 100%;
                        }
                        &-cover {
                            @include media('>phone') {
                                display: block;
                            }
                            flex-shrink: 0;
                            width: toRem(130);
                            margin-right: toRem(20);

                            img {
                                border: toRem(1) solid $secondary-lighten-color;
                            }
                        }
                    }
                    .browse-link {
                        .browse-code {
                            &:after {
                                content: '-';
                                padding-left: toRem(6);
                                padding-right: toRem(2);
                            }
                        }
                    }
                }
            }
        }
    }

    &__layout {
        @include font-size(14);
        margin: toRem(20) 0 toRem(10);
        display: inline-block;

        i {
            color: $blue-color;
            @include font-size(14);
            vertical-align: middle;
            padding-right: toRem(6);
        }
        span {
            vertical-align: middle;
        }
        .icon-Icon_Mobile-menu {
            @include font-size(22);
        }

        &-tile {
            margin-right: toRem(10);
        }

        [class^='search__layout-'] {
            cursor: pointer;
            background: $light-grey;
            padding: toRem(11) toRem(13);
            border-radius: toRem(30);
            transition: background 0.4s;
            &:hover {
                background: $grey;
            }
            &.active {
                background: $blue-lighten-10;
                color: $white;
                i {
                    color: inherit;
                }
            }
        }
    }
}

.search__item-journal,
.search__item-sig {
    .search-result--tile & {
        background-color: var(--lighter-color);

        &::before {
            background-color: var(--light-color);
        }

        &::after {
            background-color: var(--primary-color);
        }

        .item-overlay-bg {
            background-image: linear-gradient(to top, rgba(255, 255, 255, 0), var(--primary-color));

            &::before {
                background: var(--light-color);
            }

            &::after {
                background: var(--primary-color);
            }
        }
    }
}

.journalBrowse--lazyLoad {
    > li {
        display: none;
    }
}

.families-tab {
    margin-left: toRem(10);
    display: inline-block;
}
