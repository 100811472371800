.sb-container {
    position: relative;
    overflow: hidden;
    padding-right: toRem(10);
}
.sb-content {
    height: 100%;
    width: 120%;
    padding-right: 20%;
    overflow-y: scroll;
    box-sizing: border-box;
    list-style-type: none;
    padding-left: 0;
    margin: 1rem 0;
}
ul.sb-content {
    margin: 0;
}
.sb-scrollbar-container {
    position: absolute;
    right: toRem(5);
    bottom: toRem(5);
    top: toRem(5);
    width: toRem(7);
    background: #f0f0f0;
    border-radius: toRem(10);
    padding-left: 0;
    list-style-type: none;
    margin: 1rem 0;
    .sb-scrollbar {
        position: absolute;
        right: 0;
        height: toRem(30);
        width: toRem(6);
        border-radius: toRem(4);
        background: #000;
        padding: 0;
    }
}
ul.sb-scrollbar-container {
    margin: 0;
}
.sb-container-noscroll .sb-scrollbar-container {
    right: toRem(-20) !important;
}

.sb-scrollbar:hover {
    background: rgba(0, 0, 0, 0.5);
}

.sb-list {
    list-style-type: none;
    padding: 0;
    display: contents;
}
