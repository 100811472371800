.my-claimed-profiles {
    background: $white;

    .responsive-table {
        .responsive-table__cell {
            border: 0;
            font-weight: 300;

            @include media('<phone') {
                flex: 100%;
                padding: toRem(5);
            }

            &:first-child {
                font-weight: 400;
                @include media('>=desktop') {
                    flex: 15%;
                }
                @include media('>=phone', '<desktop') {
                    flex: 20%;
                }

                a {
                    text-decoration: underline;
                }

                a,
                img {
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            img {
                margin-right: toRem(15);
                @extend .img-rounded;
                width: toRem(56);
                height: toRem(56);
                @include media('<tablet') {
                    margin-bottom: toRem(10);
                }
            }

            &.btns_cell {
                text-align: right;
                a {
                    text-align: center;

                    @include media('<phone') {
                        width: 100%;
                    }
                }
            }
        }

        .responsive-table__row {
            align-items: center;

            @include media('<phone') {
                padding: toRem(20) 0;
            }

            @include media('>tablet') {
                &:not(.headings-d):hover {
                    background: $light-gray-color;
                }
            }

            &.headings-d {
                @include media('<phone') {
                    display: none;
                }
            }

            span {
                &.headings-m {
                    @include media('<phone') {
                        display: block;
                        padding-bottom: toRem(8);
                    }

                    @include media('>=phone') {
                        display: none;
                    }
                }
            }
        }
    }
}
