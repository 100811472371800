.space {
    margin-left: toRem(5);
}

// Remove any margrin on the element.
.no-margin {
    margin: 0 !important;
}

// Remove any padding on the element.
.no-padding {
    padding: 0 !important;
}
