.recentAwardAjaxContainer {
    .no-border {
        border: none;
    }

    .meta {
        font-weight: 500;
        font-style: normal;
    }

    .slide-item {
        .creative-work {
            @include media('<tablet') {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                flex-direction: column;
                width: auto;
                .creative-work__title {
                    margin-top: toRem(10);
                }
            }
        }
    }
    .creative-work__title {
        margin-bottom: toRem(4);
    }
}

.recentAwardAjax {
    .creative-work {
        time {
            &:before {
                content: '';
            }
        }
    }
}
