.lazy-loaded {
    margin-bottom: toRem(12);
    border-radius: toRem(4);
    opacity: 0;
    -webkit-transform: translateY(toRem(-10));
    transform: translateY(toRem(-10));
    -webkit-animation: fade-slide-in 334ms cubic-bezier(0, 0, 0.2, 1) forwards;
    animation: fade-slide-in 334ms cubic-bezier(0, 0, 0.2, 1) forwards 260ms;

    @extend .container;

    &:after,
    &:before {
        display: table;
        clear: both;
        content: '';
    }
    &:after {
        color: $primary-lighten-color;
        content: 'Loading ...';
    }
    .lazy-load__img {
        -webkit-animation: lazypulse 0.65s infinite alternate;
        animation: lazyPulse 0.65s infinite alternate;
    }
    [class*='lazy-load__line'] {
        height: toRem(10);
        margin: toRem(10) 0;
        width: 100%;
        background: $secondary-color;
        -webkit-animation: lazypulse 0.65s infinite alternate;
        animation: lazyPulse 0.65s infinite alternate;
    }

    .lazy-load__line {
        &--80 {
            width: 80%;
        }
        &--70 {
            width: 70%;
        }
        &--50 {
            width: 50%;
        }
    }
}

.delayLoad {
    white-space: normal;
}
