.toc {
    &__section {
        padding-top: toRem(8);
        margin-bottom: toRem(20);

        &.empty {
            display: none;
        }

        .authors-accordion {
            .auth-info {
                @include media('>=desktop') {
                    padding-left: toRem(60);
                    padding-top: toRem(15);
                }
            }
        }
    }

    .basic-info {
        .vol-issue {
            font-weight: 600;
            font-style: italic;
            @include font-size(18);
        }

        .coverDate {
            @include font-size(14);
        }
    }

    .loi__currentIssue {
        background-color: rgba($white, 0.4);
        font-weight: 600;
    }
}
