.favoriteShortlist {
    .favoritePublicationsOptions {
        @extend .select--boarded-gray;
        min-width: toRem(126);
    }

    .sortOptions {
        color: $primary-color;
        @include font-size(12);
        vertical-align: middle;
        padding-top: toRem(4);
    }

    .selectMenuLabel {
        .label-txt {
            @include font-size(12);
            font-weight: normal;
            color: $primary-color;
        }
    }

    .checkbox--primary {
        .label-txt {
            padding: 0;
            border: none;
        }
    }

    @include media('<phone') {
        .table-responsive {
            td.table__mark {
                float: left;
                border-bottom: 0;
            }

            td {
                border-bottom: 0;
            }
        }
    }
}
