.charLimit {
    border-left: toRem(1) solid $secondary-color;
    padding: 0 toRem(10);
    min-width: toRem(50);
    display: inline-block;

    &.yellow {
        color: $highlight-color;
    }

    &.red {
        color: $error-text-color;
    }
}
