.article-tool {
    &s {
        &__block {
            padding: toRem(15);
            min-width: toRem(250);
            background: $white;
        }
    }

    margin-bottom: toRem(5);
    @include font-size(12);

    a {
        display: block;
    }

    i {
        min-width: toRem(20);
        display: inline-block;
        text-align: center;
    }

    span {
        margin-left: toRem(10);
    }
}
