.multi-search {
    &.alt {
        @extend %multi-search-alt;
    }

    .slide-cover {
        border: toRem(2) solid $white;
    }

    .slideShow {
        position: relative;

        .more-link {
            @include media('>tablet') {
                border-top: none;
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: $index-1;
            }
        }
    }

    .issue-item {
        box-shadow: none;

        .access {
            text-transform: capitalize;
        }

        .issue-item__content {
            float: none;
        }
    }

    .creative-work {
        time {
            &:before {
                content: '';
            }
        }
    }

    &--latest-issues {
        .more-link {
            @include media('<tablet') {
                border: none;
                margin: 0;
            }
        }

        .issue-item {
            background: transparent;

            &__title {
                @include media('>tablet') {
                    min-height: toRem(44);
                }
            }

            .rlist--inline {
                &.truncate-list {
                    @include media('>tablet') {
                        min-height: toRem(64);
                    }
                }
            }
        }
    }

    &__item--conference {
        font-weight: 400;
        text-transform: none;
    }

    .list {
        .title,
        .number {
            display: inline-block;
        }

        .title {
            max-width: 90%;
        }

        .number {
            float: right;
        }
    }

    h3.creative-work__title,
    .issue-item__title {
        @include font-size(16);
    }

    &--custom--header {
        @include font-size(18);
    }
}

%multi-search-alt {
    .creative-work {
        border-bottom: toRem(1) solid $secondary-border-color;
        padding-bottom: toRem(11);
        margin-bottom: toRem(11);

        &__title {
            font-weight: 400;
            margin-bottom: toRem(10);
            color: $black;
            height: auto;
        }

        &__img-holder {
            padding-right: toRem(10);
            display: inline-block;
            vertical-align: text-top;

            + .creative-work__content {
                display: inline-block;
                width: calc(100% - #{toRem(76)});
                vertical-align: text-top;
            }

            img {
                @extend .img-rounded;
                width: toRem(56);
                height: toRem(56);
            }
        }
    }

    li {
        &:last-child {
            .creative-work {
                border: 0;
                padding: 0;
            }
        }
    }

    .slide-item {
        .creative-work {
            border: 0;
        }
    }

    .meta {
        @include font-size(14);
        font-style: italic;
        color: $primary-lighten-color;
    }
}
