.about-banner {
    background-color: $black;
    padding: toRem(70) 0 toRem(86) 0;
    background-image: url(../../../images/about-banner.jpg);
    @include media('>=tablet') {
        background-size: cover;
    }
    @include media('<tablet') {
        padding: toRem(43) 0 toRem(60);
        background-image: url(../../../images/about-banner-mob.jpg);
    }
    &__title {
        @include font-size(32);
        font-weight: normal;
        color: $white;
        margin-bottom: toRem(50);
    }
    &__box {
        background: $white;
        padding: toRem(39) toRem(46);
        padding-right: toRem(265);
        @include media('>tablet') {
            opacity: 0.9;
        }
        @include media('<1600px') {
            width: calc(100% - #{toRem(130)});
            padding-right: toRem(200);
        }
        @include media('<=tablet') {
            order: 2;
            width: 100%;
            padding: toRem(12) toRem(20);
        }
    }
    &__list {
        color: $primary-color;
        font-weight: 300;
        padding-left: toRem(20);
        &-item {
            margin-bottom: toRem(20);
        }
    }
    &__content {
        position: relative;
        display: flex;
        align-items: center;
        @include media('<=tablet') {
            flex-wrap: wrap;
        }
    }
    &__dl-logo {
        position: absolute;
        right: toRem(-130);
        width: toRem(260);
        background: $secondary-lighten-color;
        padding: toRem(101) toRem(19);
        border-radius: 50%;
        @include media('>tablet') {
            height: toRem(260);
            top: 0;
            bottom: 0;
            margin: auto;
        }
        @include media('<1600px') {
            right: 0;
        }
        @include media('<=tablet') {
            order: 1;
            position: static;
            width: 100%;
            text-align: center;
            padding: toRem(10) 0;
            border-radius: toRem(10) toRem(10) 0 0;
            img {
                max-width: toRem(180);
            }
        }
    }
}
