.sub-nav {
    width: 100%;
    text-align: center;
    z-index: $index-9;
    min-height: toRem(50);
    box-shadow: 0 toRem(4) toRem(4) toRem(-2) rgba($black, 0.3);
    transition: top 0.3s;
    top: toRem(124);
    @include media('<tablet') {
        top: toRem(64);
    }

    .dark-theme & {
        background-color: $primary-color;
    }

    .conference-landing {
        .banner__holder:before {
            background-color: var(--primary-color);
        }
    }

    .conference-landing & {
        background-color: $conference-primary-color;
    }

    .sponsors & {
        background-color: $conference-primary-color;
    }

    .green-theme & {
        background: $dark-green-color;
    }

    .responsive-menu-container {
        background-color: transparent;

        @include media('<desktop') {
            .menu-section {
                display: block;
            }
        }
    }

    .dropdown__toggle {
        .green-theme & {
            color: $white;
        }

        padding: toRem(19) toRem(10);
        display: block;
        line-height: 0.875rem;

        i {
            vertical-align: middle;
            display: inline-block;

            .green-theme & {
                color: $white;
            }
        }

        &.js--open {
            i.icon-section_arrow_d {
                &:before {
                    content: '\e61d';
                }
            }
        }
    }

    .dropdown-more {
        &:hover,
        &:active,
        &:focus {
            background: transparent;
        }
    }

    .dropdown__menu {
        .dark-theme & {
            background-color: $primary-color;
        }

        .green-theme & {
            background: $dark-green-color;
        }

        li ~ li {
            border-top: toRem(1) solid rgba(33, 33, 33, 0.17);
        }

        .sub-nav__item {
            padding: toRem(13) toRem(14);
            line-height: toRem(20);
            border-bottom: toRem(1) solid;

            .dark-theme & {
                border-color: lighten($primary-lighten-color, 5);
            }
        }
    }

    @include media('<desktop') {
        [role='menuitem'] {
            display: block;
        }
    }

    &__toggle {
        display: inline-block;

        .dark-theme &,
        .green-theme & {
            color: $white;
        }

        @include media('<desktop') {
            text-align: center;
            @include font-size(14);
            display: block;
            padding: toRem(14);
            &::after {
                font-family: icomoon, sans-serif !important;
                content: '\e96b';
                padding-left: toRem(15);
                @include font-size(10);
            }

            span {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 90%;
                display: inline-block;
                vertical-align: bottom;
            }
        }
    }

    &__holder {
        left: 0;
        width: 100%;
        z-index: $index-10;
        display: none;

        .dark-theme & {
            background-color: $primary-color;
        }

        .green-theme & {
            background: $dark-green-color;
        }

        .conference-landing & {
            background-color: $conference-primary-color;
        }

        .sponsors & {
            background-color: $conference-primary-color;
        }

        @include media('>=desktop') {
            text-align: center;
            display: block;
            position: static;
            box-shadow: none;
        }

        .menu-parent {
            .sub-items-list {
                @include media('<desktop') {
                    position: inherit;
                    float: none;
                    box-shadow: none;
                }

                .dropdown__menu {
                    display: block;

                    @include media('<desktop') {
                        width: 100%;
                        position: inherit;
                        float: none;
                        box-shadow: none;
                    }
                }
            }

            .dropdown__toggle {
                i {
                    @include media('<desktop') {
                        @include font-size(8);
                        float: right;
                    }
                }
            }
            &.active-parent {
                .dropdown__toggle {
                    background-color: $primary-lighten-color;
                    color: $white;
                }
            }
        }

        .menubar {
            @include media('<tablet') {
                overflow: auto;
                max-height: calc(100vh - #{toRem(47)});
            }
        }
    }

    &__item {
        padding: toRem(19) toRem(14);
        display: block;
        line-height: 0.875rem;
        color: $primary-color;

        &.active-item {
            @extend .sub-nav__item--highlighted;
            &:hover,
            &:active,
            &:focus {
                color: $black;
            }
        }

        .dark-theme &,
        .green-theme & {
            color: $white;
        }

        @include font-size(14);
        @include media('<desktop') {
            border-bottom: toRem(1) solid rgba(0, 0, 0, 0.12);
        }

        .icon-export {
            padding-left: toRem(10);
        }

        &:hover,
        &:active,
        &:focus {
            background-color: $primary-lighten-color;
            color: $white;
        }

        &--btn {
            @include font-size(14);
            font-style: italic;
            padding: toRem(5) toRem(10);
            text-transform: uppercase;
            margin-left: toRem(10);
        }

        &--btn,
        &--highlighted {
            font-weight: 600;
            background-color: rgba($white, 0.1);

            &:hover,
            &:active,
            &:focus {
                background-color: rgba($white, 0.15);
            }
        }

        &.external_link {
            &:after {
                content: '\e908';
                font-family: icomoon, sans-serif !important;
                speak: none;
                font-style: normal;
                font-weight: 400;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                margin: toRem(10);
            }
        }
    }

    &__drop-down-icon {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding-right: toRem(10);
        @include media('<=tablet') {
            display: none;
        }
    }

    .dropdown {
        &:hover,
        &:active,
        &:focus {
            background-color: transparent;
        }
    }
}
