/* merriweather-300 - latin */
@font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 300;
    src: url('./merriweather-v21-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Merriweather Light'), local('Merriweather-Light'),
    url('./merriweather-v21-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./merriweather-v21-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./merriweather-v21-latin-300.woff') format('woff'), /* Modern Browsers */
    url('./merriweather-v21-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./merriweather-v21-latin-300.svg#Merriweather') format('svg'); /* Legacy iOS */
}
/* merriweather-300italic - latin */
@font-face {
    font-family: 'Merriweather';
    font-style: italic;
    font-weight: 300;
    src: url('./merriweather-v21-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local('Merriweather Light Italic'), local('Merriweather-LightItalic'),
    url('./merriweather-v21-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./merriweather-v21-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./merriweather-v21-latin-300italic.woff') format('woff'), /* Modern Browsers */
    url('./merriweather-v21-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./merriweather-v21-latin-300italic.svg#Merriweather') format('svg'); /* Legacy iOS */
}
/* merriweather-regular - latin */
@font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    src: url('./merriweather-v21-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Merriweather Regular'), local('Merriweather-Regular'),
    url('./merriweather-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./merriweather-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('./merriweather-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('./merriweather-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./merriweather-v21-latin-regular.svg#Merriweather') format('svg'); /* Legacy iOS */
}
/* merriweather-italic - latin */
@font-face {
    font-family: 'Merriweather';
    font-style: italic;
    font-weight: 400;
    src: url('./merriweather-v21-latin-italic.eot'); /* IE9 Compat Modes */
    src: local('Merriweather Italic'), local('Merriweather-Italic'),
    url('./merriweather-v21-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./merriweather-v21-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./merriweather-v21-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('./merriweather-v21-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./merriweather-v21-latin-italic.svg#Merriweather') format('svg'); /* Legacy iOS */
}
/* merriweather-700 - latin */
@font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 600;
    src: url('./merriweather-v21-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Merriweather Bold'), local('Merriweather-Bold'),
    url('./merriweather-v21-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./merriweather-v21-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./merriweather-v21-latin-700.woff') format('woff'), /* Modern Browsers */
    url('./merriweather-v21-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./merriweather-v21-latin-700.svg#Merriweather') format('svg'); /* Legacy iOS */
}
/* merriweather-700italic - latin */
@font-face {
    font-family: 'Merriweather';
    font-style: italic;
    font-weight: 600;
    src: url('./merriweather-v21-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local('Merriweather Bold Italic'), local('Merriweather-BoldItalic'),
    url('./merriweather-v21-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./merriweather-v21-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./merriweather-v21-latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('./merriweather-v21-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./merriweather-v21-latin-700italic.svg#Merriweather') format('svg'); /* Legacy iOS */
}