.pill-access__content,
.get__access {
    .btn {
        text-align: center;
        font-weight: 400;
        justify-content: center;

        &.blue {
            min-width: toRem(240);
        }

        &.btn--inverse {
            @include media('>=large') {
                max-width: toRem(175);
            }

            @include media('>=desktop', '<large') {
                max-width: toRem(165);
            }

            @include media('>=phone', '<desktop') {
                max-width: toRem(195);
            }
            @include media('>=phone') {
                width: 50%;
            }

            @include media('<phone') {
                width: 100%;
                margin: 0;
                margin-bottom: toRem(10);
            }
        }
    }
    &__login {
        @include border('bottom', $secondary-lighten-color);
    }
}
