.facetDateChart {
    margin: toRem(30) toRem(40) toRem(50);
    min-height: toRem(155);

    &__chart {
        border-bottom: toRem(1) solid $tertiary-text-color;
    }

    .bin {
        &-color,
        &-color-optimal {
            background-color: $lighter-purple-color;
        }

        &-color-selected,
        &-color-optimal-selected {
            background-color: $purple-color;
        }
    }

    &__slider {
        &.ui-slider {
            .ui-slider {
                &-handle {
                    border-top: toRem(15) solid $tertiary-text-color;
                    &__label {
                        background-color: $tertiary-text-color;
                        color: $primary-darken-text-color;
                    }
                    &__indicator {
                        background-color: $tertiary-text-color;
                    }
                }
            }
        }
    }

    &__list {
        li {
            @include font-size(14);
            color: $blue-color;
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}

.jcf-range-frequencies__slice {
    background: $purple-color;
}
