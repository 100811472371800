@mixin hoverState($color) {
    &:hover,
    &:focus {
        background-color: darken($color, 10%);
    }
}

button {
    cursor: pointer;
    font-family: $default-font-family;
}

[class*='btn'] {
    cursor: pointer;
}

.btn {
    background-color: $primary-color;
    color: $white;
    border-radius: toRem(2);
    display: inline-block;
    clear: both;
    @include font-size(14);
    line-height: 1rem;
    padding: toRem(8) toRem(10);
    border: none;
    margin: 0 toRem(3);
    cursor: pointer;

    .bs & {
        clear: none;
    }

    @include media('<=tablet') {
        padding: toRem(8) toRem(5);
    }

    i {
        padding-right: toRem(5);

        &.icon-after-text {
            padding-left: toRem(5);
        }
    }

    @include hoverState($primary-color);

    &.light {
        background-color: $secondary-bg-color;
        @include hoverState($secondary-bg-color);
    }

    &.transparent {
        background-color: transparent;
        border: toRem(1) solid $primary-color;
        color: $primary-color;
        padding: toRem(7) toRem(9);
        @include media('<=tablet') {
            padding: toRem(7) toRem(4);
        }

        i {
            color: $tertiary-bg-color;
        }

        &:hover,
        &:focus {
            background-color: $secondary-bg-color;
        }
    }

    &.orange {
        color: $text-onDark;
        background-color: $orange-color;
        @include hoverState($orange-color);

        &:hover,
        &:focus {
            color: $white;
        }
    }

    &.blue {
        color: $text-onDark;
        background-color: $blue-color;
        @include hoverState($blue-color);

        &:hover,
        &:focus {
            color: $white;
        }
    }

    &.green-text {
        color: $dark-green-color;
        background-color: $white;
    }

    &--shadow {
        box-shadow: 0 toRem(15) toRem(6) toRem(-6) $green-shadow-color;
        transition: 0.25s;

        &:hover {
            box-shadow: 0 toRem(10) toRem(6) toRem(-6) $green-shadow-color;
            transform: translateY(toRem(1));
        }
    }

    &--bigger-icons {
        i {
            @include font-size(16);
        }
    }

    &.red {
        color: $text-onDark;
        background: $dark-red-color;

        &:hover,
        &:focus {
            background-color: $red;
            color: $white;
        }
    }

    &.yellow {
        background: $light-yellow-color;
        @include hoverState($light-yellow-color);
    }

    &.green {
        background-color: $green-color;
        color: $text-onDark;
        @include hoverState($green-color);
    }

    &.stretched.stretched {
        padding-left: toRem(30);
        padding-right: toRem(30);
        @include media('<tablet') {
            &.unstretched-mob {
                padding-left: toRem(10);
                padding-right: toRem(10);
            }
        }
    }

    &.full-text {
        i {
            @include font-size(11);
        }

        color: $text-onDark;
        background: $blue-color;
        @include hoverState($blue-color);
    }

    &--icon {
        margin: 0 toRem(3);
        color: $tertiary-bg-color;
        @include font-size(17);
        background: $secondary-bg-color;
        border: none;
        border-radius: toRem(2);
        display: inline-block;
        white-space: nowrap;
        padding: 0;

        width: toRem(32);
        height: toRem(32);
        text-align: center;
        vertical-align: middle;
        line-height: toRem(28);

        @include hoverState($secondary-bg-color);

        &:hover,
        &:focus {
            color: $tertiary-bg-color;
        }

        i {
            @include font-size(14);
            vertical-align: middle;
            padding-right: 0;
        }

        &.big {
            width: toRem(40);
            height: toRem(40);
            line-height: toRem(34);

            i {
                @include font-size(15);
            }
        }

        &.transparent {
            background-color: transparent;
            border: toRem(1) solid $primary-color;

            &:hover,
            &:focus {
                background-color: $secondary-bg-color;
            }
        }

        &.circle {
            border-radius: toRem(20);
        }

        &--dark {
            @extend .btn--icon;
            background: $primary-lighten-color;
            color: $white;
        }
    }

    &--inverse,
    &--cancel {
        background: $white;
        color: $primary-text-color;
        border: toRem(1) solid $primary-text-color;
        border-radius: toRem(2);

        &:hover,
        &:focus {
            background-color: $secondary-bg-color;
        }
    }

    &.big {
        padding: toRem(11) toRem(18);

        &:not(.btn--no-margin) {
            margin: 0 toRem(6);
        }
    }

    &[disabled] {
        cursor: not-allowed;
        background-color: $secondary-bg-color;
        color: darken($secondary-color, 10%);

        &:hover,
        &:focus {
            background-color: $secondary-bg-color;
        }
    }

    &--rounded {
        border-radius: toRem(30);
    }

    &--link {
        border: none;
        padding: 0;
        background: transparent;
        width: auto;
    }

    &--no-margin {
        margin: 0;
    }

    &--mob-full {
        @include media('<tablet') {
            width: 100%;
            margin-top: toRem(6);
            margin-bottom: toRem(6);
        }
    }

    &--full-width {
        width: 100%;
        text-align: center;
        @include media('<tablet') {
            margin-bottom: toRem(6);
        }
    }
}

.get-access {
    background-color: $get-access;
    color: $text-onDark;
    @include font-size(14);
    padding: toRem(7) toRem(22) toRem(8) toRem(23);
    margin: 0 toRem(5);
    border-radius: toRem(2);

    &:hover,
    &:focus {
        background-color: $get-access-hover;
        color: $text-onDark;
    }

    i {
        padding-right: toRem(5);
        vertical-align: sub;
        @include font-size(19);
    }
}

.vertical-buttons {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media('<desktop') {
        padding: toRem(15) 0 toRem(5);
        margin: toRem(10) 0;
    }

    &.mobile-inline {
        @include media('<desktop') {
            display: block;

            .btn {
                display: inline-block;
                width: auto;
                margin-right: toRem(8);
                padding: toRem(11) toRem(3);

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    .btn {
        margin: 0 auto toRem(10);
        padding: toRem(11);
        display: block;
    }

    .centered {
        width: 100%;
        margin: auto;
    }

    &.borders {
        border-right: toRem(1) solid $secondary-color;
        @extend .separator-left;
        @include media('<desktop') {
            border: none;
            border-top: toRem(1) solid $secondary-color;
            border-bottom: toRem(1) solid $secondary-color;
            margin: toRem(10) toRem(-15);
            padding: toRem(15);
        }
    }
}

.info--text {
    @include font-size(14);
    font-style: italic;
    font-weight: 300;

    &.big {
        @include font-size(18);
    }

    ~ .btn {
        margin-left: 0;
    }
}

.disableLoaAnchors .loa {
    button:not(.removed-items-count):not(.read-less) {
        pointer-events: none;
        cursor: default;
        text-decoration: none;
    }
}
