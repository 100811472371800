.recommend-to-librarian {
    margin-bottom: toRem(140);

    .left-bordered-title {
        @include font-size(22);

        margin-bottom: toRem(18);
        margin-top: toRem(15);

        @include media('<tablet') {
            margin-top: 0;
        }
    }
}

.recommendationForm {
    i {
        color: $secondary-color;
    }

    .info--text {
        font-style: normal;
    }

    h3 {
        font-weight: normal;
        margin: toRem(45) 0 toRem(30);

        @include media('<tablet') {
            margin: toRem(20) 0;
        }
    }

    label {
        color: $primary-color;
    }

    input {
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        border-radius: 0;

        &::-webkit-input-placeholder {
            //chrome
            font-style: italic;
            @include font-size(13);
            color: $primary-lighten-color;
        }
        &::-moz-placeholder {
            //firefox
            font-style: italic;
            @include font-size(13);
            color: $primary-lighten-color;
        }
        &:-ms-input-placeholder {
            //ie
            font-style: italic;
            @include font-size(13);
            color: $primary-lighten-color;
        }
    }

    .meta {
        &__title {
            @extend .card--dark;
            @include font-size(12);
            font-weight: 500;
            font-style: italic;
            padding: toRem(10) toRem(15);
            color: $primary-lighten-color;
            border-bottom: toRem(1) solid $secondary-color;
        }

        &__image {
            text-align: center;

            @include media('>=large') {
                padding: toRem(50) toRem(40);
            }

            padding: toRem(40) toRem(20);

            img {
                max-width: toRem(229);
            }
        }

        &__slogan {
            display: flex;
            align-items: center;
            border-top: toRem(1) solid $secondary-lighten-color;
            padding-top: toRem(26);
            margin-top: toRem(26);

            h3 {
                margin: 0;
            }
            i {
                @include font-size(40);
                margin-right: toRem(14);
            }
        }

        &__details {
            p {
                @include font-size(14);
                font-weight: 300;
            }

            .btn {
                margin: 0;
                i {
                    margin-left: toRem(7);
                    vertical-align: middle;
                }
            }
        }
    }

    &__reasons {
        .input-group {
            margin-bottom: toRem(14);
        }
        .label-txt {
            font-weight: 300;
        }

        textarea {
            margin-left: toRem(23);
            margin-top: toRem(20);
            background: $white;
            min-width: toRem(364);
            min-height: toRem(95);
            max-width: calc(100% - #{toRem(23)});
            @include media('<phone') {
                width: 100%;
                margin-left: 0;
                min-width: initial;
                max-width: initial;
            }
        }

        ~ .separator {
            display: none;
        }
    }

    &__privacy {
        &,
        h6 {
            @include font-size(12);
        }

        h6 {
            margin-bottom: toRem(10);
        }

        p {
            font-weight: 300;
        }
    }

    .form-btn {
        margin-bottom: toRem(20);

        .info--text {
            font-style: italic;
            @include font-size(12);
        }
        .stretched {
            padding-left: toRem(60);
            padding-right: toRem(60);
        }

        .icon-Arrow-Stroke {
            transform: rotate(180deg);
            display: inline-block;
            color: $primary-color;
        }
        .btn--link {
            padding: toRem(11) 0;
            margin-bottom: 0;
        }
    }

    hr.separator {
        margin-bottom: toRem(26);
        margin-top: toRem(26);
    }

    .publication-cover-info {
        ~ .separator {
            display: none;
        }

        .card--bordered {
            @include media('<tablet') {
                display: none;
            }
        }
    }

    .icon-person_outline {
        @include font-size(17);
    }
}
