.binder__create {
    .binder__header & {
        display: inline-block;
    }

    &-btn {
        @include media('<desktop') {
            margin: 0 !important;
            padding: toRem(11) toRem(18) !important;
        }
    }

    .input-group--gray-bg .input-group-addon {
        width: 10%;
    }
}

#createBinder {
    p {
        font-weight: 300;
    }

    input {
        &::-webkit-input-placeholder {
            //chrome
            font-style: italic;
            font-weight: 300;
            color: $primary-lighten-color;
        }

        &::-moz-placeholder {
            //firefox
            font-style: italic;
            font-weight: 300;
            color: $primary-lighten-color;
        }

        &:-ms-input-placeholder {
            //ie
            font-style: italic;
            font-weight: 300;
            color: $primary-lighten-color;
        }
    }
}
