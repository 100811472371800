.pill {
    &-references__content {
        .references {
            &__item {
                margin-bottom: toRem(16);
                padding-left: toRem(20);
                padding-right: toRem(20);

                &:first-child {
                    margin-top: toRem(16);
                }
            }
            &__suffix {
                &s {
                    text-align: right;
                    display: block;
                    padding-top: toRem(15);
                    &:before,
                    &:after {
                        content: ' ';
                        display: table;
                    }
                    &:after {
                        clear: both;
                    }
                }
            }

            &__item:focus {
                background: $light-yellow-color;
                margin-top: toRem(-16);
                padding-top: toRem(16);
            }
            &__go-to-ref {
                .back {
                    &:after {
                        content: '';
                    }
                }
            }
        }
    }
}

[data-slide-target='#pill-references'] {
    position: relative;

    .refNum {
        position: absolute;
        top: toRem(7);
        right: toRem(4);
    }

    &.disable {
        .refNum {
            opacity: 0.5;
        }
    }
}
