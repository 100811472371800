.owl-carousel_with-gradient-sides {
    .owl-stage-outer {
        &:before,
        &:after {
            content: '';
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            bottom: 0;
            width: toRem(50);
            z-index: $index-2;
        }

        &:before {
            background: linear-gradient(
                to right,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 0.8) 10%,
                rgba(255, 255, 255, 0) 100%
            );
            left: 0;
        }

        &:after {
            background: linear-gradient(
                to right,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0.8) 90%,
                rgba(255, 255, 255, 1) 100%
            );
            right: 0;
        }
    }
}

.slideShow {
    .owl-next,
    .owl-prev {
        span {
            display: none;
        }

        i {
            display: inline-block;
            @include font-size(24);
            font-weight: 900;

            .slideshow-gray-arrows & {
                color: $primary-lighten-color;
                @include font-size(20);
            }
        }
    }

    .owl-stage {
        &:focus {
            outline: none;
        }
    }

    .owl-item {
        .js--keyboard-on & {
            &:focus-within {
                z-index: $index-1;
                box-shadow: toRem(-4) 0 toRem(6) toRem(2) $focus-color, toRem(4) 0 toRem(6) toRem(2) $focus-color;
            }
            .overlay-cover {
                &:focus {
                    outline: none;
                }
            }
        }
    }

    .owl-carousel {
        .owl-dots {
            display: block;
            width: toRem(90);
            margin: 0 auto;
            margin-top: toRem(20);
            text-align: center;
            z-index: $index-1;
            overflow: hidden;
            font-size: 0;
            white-space: nowrap;

            .owl-dot {
                position: relative;
                left: toRem(36);
                -webkit-transform: scale(0.6);
                -moz-transform: scale(0.6);
                -ms-transform: scale(0.6);
                -o-transform: scale(0.6);
                transform: scale(0.6);
                -webkit-transition: transform 0.25s ease-in-out, left 0.4s ease-in-out;
                -moz-transition: transform 0.25s ease-in-out, left 0.4s ease-in-out;
                -o-transition: transform 0.25s ease-in-out, left 0.4s ease-in-out;
                transition: transform 0.25s ease-in-out, left 0.4s ease-in-out;

                &.active,
                &.owl-dot-prev,
                &.owl-dot-next {
                    -webkit-transform: scale(1);
                    -moz-transform: scale(1);
                    -ms-transform: scale(1);
                    -o-transform: scale(1);
                    transform: scale(1);
                    opacity: 1;
                }

                &.owl-dot-next-next,
                &.owl-dot-prev-prev {
                    -webkit-transform: scale(0.6);
                    -moz-transform: scale(0.6);
                    -ms-transform: scale(0.6);
                    -o-transform: scale(0.6);
                    transform: scale(0.6);
                    opacity: 0.8;
                }

                span {
                    border: toRem(1) solid $owl-dot-border;
                    background-color: transparent;
                    margin: toRem(5) toRem(4);
                    -webkit-transition: transform 0.25s ease-in-out, left 0.4s ease-in-out, background 0.25s ease-in-out,
                        border 0.25s ease-in-out;
                    -moz-transition: transform 0.25s ease-in-out, left 0.4s ease-in-out, background 0.25s ease-in-out,
                        border 0.25s ease-in-out;
                    -o-transition: transform 0.25s ease-in-out, left 0.4s ease-in-out, background 0.25s ease-in-out,
                        border 0.25s ease-in-out;
                    transition: transform 0.25s ease-in-out, left 0.4s ease-in-out, background 0.25s ease-in-out,
                        border 0.25s ease-in-out;
                }

                &.active,
                &:hover,
                &:focus {
                    span {
                        background-color: $blue-color;
                        border-color: $blue-color;
                    }
                }
            }
        }

        .owl-nav {
            [class*='owl-'] {
                background-color: transparent;
            }

            .owl-nav-active {
                i {
                    color: $primary-lighten-color;
                }
            }

            .owl-prev,
            .owl-next {
                i {
                    color: $primary-lighten-color;
                }
            }
        }

        .owl-item .owl-lazy {
            opacity: 1;
        }
    }

    #pb-editor & {
        .slide-item {
            float: left;
        }

        img {
            max-height: toRem(300);
            width: auto;
        }
    }

    .owl-equal & {
        .owl-stage {
            display: flex;

            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }

        .slide-item {
            width: 100%;
        }

        .owl-item {
            display: flex;
            height: auto !important;
        }
    }
}

.circular-arrows {
    .owl-carousel {
        .owl-nav {
            [class*='owl-'] {
                border-radius: 50%;
                color: $black;
                padding: toRem(15) toRem(20);
                box-shadow: toRem(2) toRem(2) toRem(2) toRem(2) rgba($black, 0.2);
                background: $white;

                &:hover,
                &:focus {
                    background-color: $white !important;
                }

                &:focus {
                    .js--keyboard-on & {
                        box-shadow: 0 0 toRem(6) toRem(2) $focus-color;
                    }
                }

                @include media('<tablet') {
                    display: none;
                }
            }

            i {
                font-size: 1rem;
            }

            .owl-nav-active {
                i {
                    color: $black;
                }
            }

            .owl-prev {
                margin-left: toRem(-20);
            }

            .owl-next {
                margin-right: toRem(-20);
            }
        }
    }

    .owl-next,
    .owl-prev {
        i {
            color: $black;
            @include font-size(12);
        }
    }
}

.fullwidth-slider {
    @extend .owl-carousel_with-gradient-sides;

    .owl-dots {
        display: none;
    }

    &.fullwidth-slider-withDots {
        .owl-dots {
            display: block;
            margin-top: toRem(20);
        }

        .owl-carousel {
            .owl-nav {
                .owl-prev,
                .owl-next {
                    height: calc(100% - #{toRem(40)});
                }
            }
        }
    }

    .owl-stage-outer {
        &:before,
        &:after {
            width: toRem(40);
            @include media('>=tablet') {
                width: toRem(100);
            }
            @include media('>=desktop') {
                width: toRem(150);
            }
        }
        @include media('>=xlarge') {
            padding-left: toRem(255);
        }
    }

    .owl-carousel {
        padding: 0;

        .owl-nav {
            margin-top: 0;

            .owl-prev,
            .owl-next {
                top: 0;
                margin: 0;
                padding: 0;
                width: toRem(40);
                background: transparent !important;
                @include media('>=tablet') {
                    width: toRem(100);
                }
                @include media('>=desktop') {
                    width: toRem(150);
                }
                height: 100%;
                border-radius: 0;

                i {
                    @include font-size(18);
                    color: $primary-lighten-color;
                    @include media('>=desktop') {
                        display: none;
                    }
                }

                &.disabled {
                    pointer-events: initial;
                }
            }

            .owl-prev {
                i {
                    @extend .icon-arrow-left-bold;

                    float: left;
                    margin-left: toRem(15);
                    @include media('>=tablet') {
                        margin-left: toRem(30);
                    }
                }
            }

            .owl-next {
                i {
                    @extend .icon-arrow-right-bold;

                    float: right;
                    margin-right: toRem(15);
                    @include media('>=tablet') {
                        margin-right: toRem(30);
                    }
                }
            }
        }

        &:hover {
            .owl-prev,
            .owl-next {
                i {
                    display: block;
                }
            }
        }
    }

    @include media('>tablet') {
        .more-link {
            border: none;
        }
    }
    @include media('>tablet') {
        &.fullwidth-slider-withDots {
            .more-link {
                padding: 0;
                margin: 0;

                &-container {
                    position: relative;
                    top: toRem(-25);
                }
            }
        }
    }

    .owl-dot {
        &.active,
        &:hover,
        &:focus {
            span {
                background-color: $blue-color !important;
                border-color: $blue-color !important;
            }
        }
    }

    .slide-item {
        img {
            width: 100%;
        }
    }

    &--with-dots {
        .owl-carousel {
            .owl-dots {
                margin-top: toRem(40);
            }
        }

        .more-link {
            a {
                @include media('>tablet') {
                    position: absolute;
                    right: 0;
                    bottom: toRem(23);
                    z-index: $index-1;
                }
            }
        }
    }
}

.book-carousel {
    .owl-carousel {
        .owl-nav {
            [class*='owl-'] {
                box-shadow: 0 toRem(1) toRem(6) rgba(0, 0, 0, 0.16);
                top: calc(50% - #{toRem(50)});
            }

            .owl-prev {
                @include media('>large') {
                    margin-left: toRem(-60);
                }
                @include media('<=large') {
                    margin-left: toRem(-30);
                }
                @include media('<=tablet') {
                    margin-left: toRem(-20);
                }
            }

            .owl-next {
                @include media('>large') {
                    margin-right: toRem(-60);
                }
                @include media('<=large') {
                    margin-right: toRem(-30);
                }
                @include media('<=tablet') {
                    margin-right: toRem(-20);
                }
            }
        }

        .owl-dots:not(.disabled) {
            display: block;
            position: absolute;
            top: toRem(-50);
            right: 0;
            @include media('<tablet') {
                display: none;
            }

            .owl-dot {
                &.active,
                &:hover,
                &:focus {
                    span {
                        background-color: $blue-color;
                    }
                }
            }
        }
    }
}

.equal-height-slides {
    .search__item,
    .overlay-cover,
    img {
        height: 100%;
        object-fit: cover;
    }
}

.owl {
    &-carousel {
        &.flex-grid {
            .viewport-slider & {
                display: flex;

                &:not(.owl-loaded) {
                    padding: 0;
                }
            }
        }

        .blue-dots & {
            .owl-dots {
                margin: 0 auto;

                .owl-dot {
                    span {
                        border: toRem(1) solid $tertiary-darken-color;
                        background-color: transparent;
                    }

                    &.active,
                    &:hover,
                    &:focus {
                        span {
                            background-color: $blue-color;
                        }
                    }
                }
            }
        }
    }
}

.collection-carousel {
    .slideShow {
        min-height: 0;

        .owl-carousel {
            padding: 0;
        }
    }
}

.flex-slideshow {
    .slideshow {
        @extend .flex-grid;
    }

    .grid-item {
        clear: none !important;
        float: none;
    }
}

.viewport-slider {
    .slide-removal {
        padding: 0;
    }

    &.owl-carousel {
        &.slide-removal {
            display: block;

            &.flex-grid {
                display: flex;
            }
        }
    }

    &.contribs-grid {
        .owl-carousel {
            @include media('<large') {
                padding: 0 toRem(30);
            }

            .owl-stage-outer {
                @include media('<phone') {
                    min-height: toRem(244);
                }
            }
        }
    }
}

.slider-disable-on {
    .slide-removal {
        display: block;
    }
}

.grid-md-view {
    @include media('>=desktop') {
        .owl-carousel {
            display: flex;
        }

        .slide-item {
            &:only-child {
                width: 100%;
            }

            &:first-child {
                &:nth-last-child(2) {
                    width: 50%;
                }

                &:nth-last-child(3) {
                    width: 33.333%;
                }
            }

            &:last-child {
                &:nth-child(2) {
                    width: 50%;
                }

                &:nth-child(3) {
                    width: 33.333%;
                }
            }

            &:nth-child(2) {
                &:nth-last-child(2) {
                    width: 33.333%;
                }
            }
        }
    }
}

.transVal {
    transition: all 0.25s ease 0s !important;
}

.people-carousel {
    @extend .owl-carousel_with-gradient-sides;

    .owl-prev,
    .owl-next {
        i {
            display: initial !important;
        }

        @include media('<phone') {
            display: none;
        }
    }

    .people-card {
        margin-right: toRem(24);
        height: 94%;

        @include media('<phone') {
            margin: toRem(4) toRem(40);
        }
    }

    .owl-stage-outer {
        min-height: toRem(144);
    }

    .slideShow {
        min-height: auto;
    }
}

//people card
.people-card {
    $this: &;

    position: relative;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    background: white;
    border-radius: toRem(4);
    box-shadow: 0 toRem(2) toRem(6) rgba($black, 0.15);
    margin: toRem(4) 0;
    padding: toRem(16) toRem(16) toRem(8);

    @include media('>=phone') {
        padding: toRem(8) toRem(8) toRem(8) toRem(64);
    }

    @include media('>=large') {
        padding: toRem(16) toRem(16) toRem(8) toRem(96);
    }

    &:hover {
        #{$this}__subject {
            text-decoration: none;
            background: darken($secondary-lighten-color, 4);

            .people-card__subject-icon {
                transform: rotate(360deg);
            }
        }

        #{$this}__image {
            filter: grayscale(0);
        }
    }

    &__item {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        padding: 0 0 toRem(8);

        &:last-child {
            padding: 0;
        }

        &--sm-border-btm {
            @include media('<phone') {
                border-bottom: toRem(1) solid $secondary-color;
                margin-bottom: toRem(8);
            }
        }
    }

    &__name {
        @include font-size(14);
        vertical-align: middle;
        display: inline-block;
        text-decoration: none;
        margin: 0 0 0 toRem(12);

        @include media('>=phone') {
            margin: 0;
        }

        &:hover {
            text-decoration: none;
        }
    }

    &__institution {
        margin: 0;
        flex: 0 1 100%;
        padding: 0;
        @include font-size(13);
        color: $blue-color;
    }

    &__subject {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        @include font-size(12);
        background: $secondary-lighten-color;
        padding: toRem(8) toRem(12);
        border-radius: toRem(18);
        text-decoration: none;
        margin: 0 toRem(4) toRem(8) 0;
        transition: background 0.25s ease-in-out;

        &:last-child {
            margin-right: 0;
        }

        &-icon {
            flex: 0 0 toRem(16);
            display: flex;
            width: toRem(16);
            height: toRem(16);
            line-height: toRem(16);
            margin: 0 toRem(8) 0 0;
            transition: transform 0.8s ease-in-out;
            @include font-size(16);
        }
    }

    &__image-link {
        @include media('>=phone') {
            position: absolute;
            border-radius: 50%;
            overflow: hidden;
            left: toRem(8);
            top: 50%;
            transform: translateY(-50%);
        }

        @include media('>=large') {
            left: toRem(16);
        }
    }

    &__image-wrapper {
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        display: inline-block;
        width: toRem(48);
        height: toRem(48);
        vertical-align: middle;

        @include media('>=large') {
            width: toRem(64);
            height: toRem(64);
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        width: auto;
        height: auto;
        max-width: 150%;
        max-height: 150%;
        min-width: 100%;
        min-height: 100%;
        transform: translateX(-50%);
        padding: 0;
        border-radius: 50%;
        transition: filter 0.6s ease-in-out;
        filter: grayscale(1);

        &:hover {
            filter: grayscale(0);
            border-radius: 50%;
        }
    }
}

.circle-carousel {
    max-width: toRem(1048);
    margin: 0 auto;

    &-imgs,
    &-content {
        margin: 0 auto;
    }

    @include media('>=phone', '<tablet') {
        max-width: toRem(500);
    }

    &-imgs {
        .owl-stage-outer {
            padding: toRem(40) 0;
        }
    }

    .owl-item {
        margin-right: toRem(-32);
        padding: 0 toRem(4);
        background: transparent;
        position: relative;
        z-index: $index-1;
        transition: z-index 0s linear 0.1s;

        .circle-content-slide__content {
            display: -webkit-box;
            -webkit-line-clamp: 8;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        &.active.center {
            z-index: $index-2;
            transition: z-index 0.3s linear 0s;
        }

        &:not(.active) .circle-slide__inner {
            opacity: 0;
        }

        &.active {
            .circle-content-slide__inner {
                opacity: 1;
            }
        }
    }

    .owl-next,
    .owl-prev {
        background: transparent !important;
    }
}

.circle-slide {
    $this: &;

    .owl-item.active.center & {
        z-index: $index-2;
        transition: z-index 0s linear 0.6s;

        &:before {
            opacity: 1;
            transition: opacity 0.4s ease-in-out 0.6s;
        }
    }

    &__inner {
        position: relative;
        display: flex;
        //background: $secondary-color;
        box-shadow: toRem(-3) 0 toRem(6) rgba($black, 0.15);
        width: 100%;
        padding-bottom: 100%;
        height: 0;
        border-radius: 50%;
        z-index: $index-1;
        transform-origin: center center;
        overflow: hidden;
        transition: opacity 0.4s ease-in-out;

        //Active Slide
        .active.center & {
            transform: scale(1.3) translateX(11%);
            margin-left: toRem(-24);
            z-index: $index-2;
            opacity: 1;
            //transition: transform 0.6s ease-in-out 0.2s,
            //            margin-left 0.6s ease-in-out 0.2s,
            //            z-index 0s linear 0.2s!important;

            transition: transform 0.2s ease-in-out 0.6s,
                //opacity 0.25s ease-in-out,
                margin-left 0.2s ease-in-out 0.6s,
                z-index 0s linear 0.6s !important;
            #{$this}__image {
                filter: grayscale(0);
            }

            &:hover {
                #{$this}__image-link {
                    z-index: $index-2;

                    &-label {
                        opacity: 1;
                        z-index: $index-2;
                        transition: opacity 0.4s ease-in-out 0.25s, background 0.4s ease-in-out;
                    }
                }
            }
        }

        //.active.middle--prev & {
        //    transform: scale(1.2) translateX(9%);
        //    margin-left: toRem(-8);
        //
        //    transition: transform 0.6s ease-in-out 0.2s,
        //                margin-left 0.6s ease-in-out 0.2s !important;
        //}
        //
        //.active.middle--next & {
        //    transform: scale(1.2) translateX(9%);
        //    margin-left: toRem(-8);
        //
        //    transition: transform 0.6s ease-in-out 0.2s,
        //                margin-left 0.6s ease-in-out 0.2s !important;
        //}
    }

    &__image-link {
        position: relative;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        @include media('<large') {
            display: none;
        }

        &:hover,
        &:focus {
            text-decoration: none;
            cursor: pointer;
            color: $white;
        }

        &-label {
            position: relative;
            color: $white;
            display: block;
            opacity: 0;
            width: calc(100% - #{toRem(32)});
            @include font-size(11);
            text-align: center;
            margin: 0 toRem(16);
            border: toRem(1) solid $white;
            padding: toRem(8) toRem(4);

            &:hover {
                background: rgba($white, 0.1);
            }
        }
    }

    &__image-wrapper {
        position: absolute;
        border-radius: 50%;
        overflow: hidden;
        display: inline-block;
        width: 100%;
        height: 100%;
        vertical-align: middle;
        opacity: 1;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($primary-lighten-color, 0.8);
            transition: opacity 0.4s ease-in-out;
            z-index: $index-1;
            border-radius: 50%;
        }

        &:hover {
            &::before {
                opacity: 0;
            }
        }

        .active.center & {
            &::before {
                opacity: 0;
            }

            &:hover {
                &::before {
                    background-image: linear-gradient(to bottom, #156794 0%, #156794 100%);
                    background-repeat: repeat-x;
                    opacity: 0.95;
                    transition: opacity 0.4s ease-in-out 0.2s;

                    @include media('<large') {
                        display: none;
                    }
                }
            }
        }
    }

    &__image {
        position: absolute;
        color: rgba($white, 0);
        top: 0;
        left: 50%;
        display: block;
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        transform: translateX(-50%);
        padding: 0;
        border-radius: 0;
        @include media('>=tablet') {
            transition: filter 0.6s ease-in-out;
        }
        filter: grayscale(1);

        &:hover {
            filter: grayscale(0);
        }
    }

    &:before {
        content: '';
        position: absolute;
        bottom: -17.5%;
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgyIiBoZWlnaHQ9IjM4IiB2aWV3Qm94PSIwIDAgMTgyIDM4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBmaWx0ZXI9InVybCgjZmlsdGVyMF9mKSI+CjxlbGxpcHNlIGN4PSI5MSIgY3k9IjE5IiByeD0iODciIHJ5PSIxNSIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyKSIvPgo8L2c+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2YiIHg9IjAiIHk9IjAiIHdpZHRoPSIxODIiIGhlaWdodD0iMzgiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJzUkdCIj4KPGZlRmxvb2QgZmxvb2Qtb3BhY2l0eT0iMCIgcmVzdWx0PSJCYWNrZ3JvdW5kSW1hZ2VGaXgiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0ic2hhcGUiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMiIgcmVzdWx0PSJlZmZlY3QxX2ZvcmVncm91bmRCbHVyIi8+CjwvZmlsdGVyPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXIiIHgxPSI5MSIgeTE9Ii0yIiB4Mj0iOTEiIHkyPSIzNCIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSJ3aGl0ZSIgc3RvcC1vcGFjaXR5PSIwIi8+CjxzdG9wIG9mZnNldD0iMC42NDczNDYiIHN0b3AtY29sb3I9IndoaXRlIiBzdG9wLW9wYWNpdHk9IjAuNDk3MjM4Ii8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzZBNkE2QSIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=')
            no-repeat center center;
        background-position: center bottom;
        background-size: 100% auto;
        z-index: $index-0;
        height: 100%;
        left: 4%;
        width: 92%;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }
}

.circle-content-slide {
    &__inner {
        padding: 0 toRem(48);
        text-align: center;
        opacity: 0;
        transition: opacity 0.8s linear 0s;
    }

    &__name {
        display: block;
        text-align: center;
        color: $black;
        @include font-size(18);
        margin-bottom: toRem(24);
        line-height: 1.4em;
        font-weight: 400;
    }
    &__award {
        display: block;
        color: $primary-lighten-color;
        @include font-size(16);
        font-style: italic;
    }

    &__content {
        color: $black;
        text-align: center;
        font-weight: 300;
        font-style: italic;
    }

    &__link {
        display: inline-block;
        color: $black;
        font-weight: 400;
        line-height: 1.4em;
        border: toRem(1) solid $black;
        width: toRem(200);
        max-width: 100%;
        text-align: center;
        padding: toRem(8) toRem(12);
        border-radius: toRem(2);
        margin: toRem(32) 0 0;
        transition: background 0.25s ease-in-out, color 0.25s ease-in-out;
        @include font-size(14);
        &:hover,
        &:focus {
            text-decoration: none;
        }

        &:hover {
            color: $white;
            background: $black;
        }
    }
}

#pb-editor {
    .circle-carousel {
        max-height: toRem(400);
        .circle-slide {
            display: inline-block;
            width: 18%;
        }
    }
}

.bibliometrics--slider {
    .owl-carousel {
        .owl-dots {
            .owl-dot {
                span {
                    margin-bottom: toRem(16);
                }
            }
        }
    }
}
