@use 'sass:math';

input,
optgroup,
select,
textarea {
    &:focus {
        box-shadow: 0 0 toRem(6) toRem(2) $focus-color;
        border: toRem(1) solid rgba($focus-color, 0.5);
        outline: none;
    }
    a:focus {
        outline: none;
    }
}

input {
    &[type='text'],
    &[type='search'] {
        &::-webkit-input-placeholder {
            //chrome
            font-weight: 500;
            @include font-size(14);
        }
        &::-moz-placeholder {
            //firefox
            font-weight: 500;
            @include font-size(14);
        }
        &:-ms-input-placeholder {
            //ie
            font-weight: 500;
            @include font-size(14);
        }
    }

    &[type='number'] {
        -moz-appearance: textfield;
    }
}

.radio--primary {
    font-weight: normal;
    color: $primary-color;
    input {
        &:checked {
            ~ span:before {
                background-color: $blue-color;
                box-shadow: inset 0 0 0 0 $blue-color, inset 0 0 0 toRem(2) $white;
            }
        }
    }
    span {
        &:before {
            border: toRem(1) solid $secondary-color;
        }
    }
}

.left-bordered {
    &-label {
        border-left: toRem(8) solid $text-onLight;
        padding-left: toRem(6);
    }
}

.checkbox--primary {
    span {
        &:before {
            border-radius: toRem(3);
            border: toRem(2) solid $tertiary-darken-color;

            @at-root .disabled#{&} {
                border-color: $secondary-lighten-color;
                background: $light-gray-color;
            }
        }
    }

    input {
        &:not(.jcf):checked {
            ~ span:before {
                background-color: $blue-color;
                border-color: $blue-color;
            }
        }
        &[disabled='disabled'] {
            pointer-events: none;
        }
    }

    &.disabled {
        pointer-events: auto;
        opacity: 1;
    }
}

.input-group {
    position: relative;
    .form-control {
        position: relative;
        //z-index: 2;
        width: 100%;
        margin-bottom: 0;
        border-color: $secondary-color;
    }
    &--gray-bg {
        .input-group__field-container {
            background: $light-gray-color;
        }
        .input-group-addon {
            background: inherit;
        }
    }
    &__field-container {
        position: relative;

        &--left-icon,
        &--right-icon {
            position: relative;
            display: table;
            border-collapse: separate;
            width: 100%;
        }

        &--left-icon {
            .form-control {
                border-left: 0;
                padding-left: 0;
            }
        }

        &--right-icon {
            .form-control {
                border-right: 0;
                padding-right: 0;
            }
        }
        &--error,
        &--success {
            transition: box-shadow 0.6s ease-in-out 0s;

            .input-group-addon,
            .form-control {
                border-color: $error-text-color;
            }
            .icon-notice {
                color: $error-text-color;
                @include font-size(14);
            }
            .field-error-icon {
                display: block;
            }
            + .field-error-label.field-error-label {
                display: block;
                margin: 0;
                label {
                    color: $error-text-color;
                    @include font-size(12);
                    font-weight: 300;
                    font-style: italic;
                }
            }
        }

        &--success {
            .input-group-addon,
            .form-control {
                border-color: $success-text-color;
            }
            .icon-checkmark.icon-checkmark {
                color: $success-text-color;
                @include font-size(14);
                font-weight: 600;
                border: none;
                padding: 0;
            }
            .field-success-icon {
                display: block;
            }
            + .field-success-label {
                display: block;
                padding-top: toRem(5);
                margin: 0;
                label {
                    color: $success-text-color;
                    @include font-size(12);
                    font-weight: 300;
                    font-style: italic;
                }
            }
        }
    }

    &--has-right-icon {
        position: relative;
        .form-control {
            padding-right: toRem(35);
        }
    }
    &--has-left-icon {
        position: relative;
        .form-control {
            padding-left: toRem(35);
        }
    }
    &--dropdown {
        .jcf-select {
            background: none;
            border-color: $secondary-color;
            &:before,
            &:after {
                content: '\e60b';
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                position: absolute;
                right: 0;
                top: 50%;
                height: 100%;
                padding: 0 toRem(10);
                font-family: icomoon, sans-serif !important;
                @include font-size(7);
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                color: $secondary-text-color;
                @extend .separator-left;
            }
        }
    }
    &__right-icon {
        position: absolute;
        right: toRem(10);
        z-index: $index-2;
        top: toRem(10);
        @include font-size(18);
        color: $secondary-text-color;
        .icon-checkmark {
            color: $green-color;
            border: toRem(1) solid;
            border-radius: 50%;
            padding: toRem(3);
            @include font-size(14);
        }
    }
    &__left-icon {
        position: absolute;
        left: toRem(10);
        z-index: $index-2;
        top: toRem(10);
        @include font-size(18);
        color: $secondary-text-color;
        .icon-checkmark {
            color: $green-color;
            border: toRem(1) solid;
            border-radius: 50%;
            padding: toRem(3);
            @include font-size(14);
        }
    }

    &-addon {
        padding: toRem(6) toRem(12);
        @include font-size(14);
        font-weight: 400;
        border: toRem(1) solid $secondary-color;
        width: 13%;
        border-right: 0;
        color: $secondary-text-color;
        background: $white;
        @include media('<phone') {
            width: 16%;
        }
        &:after {
            content: '';
            border-right: toRem(1) solid $secondary-color;
            padding: toRem(2) toRem(5);
        }
        &--right {
            border-left: 0;
            padding: 0 toRem(10);
            border-right: toRem(1) solid $secondary-color;
            &:after {
                display: none;
            }
        }
        + input {
            height: toRem(36);
        }
    }

    .password-toggle {
        cursor: pointer;
    }
    .error {
        padding-top: toRem(5);
    }
    .shibboleth & {
        max-width: toRem(400);
    }
    &__instructions {
        @include font-size(12);
        color: $primary-lighten-color;
    }
}

label {
    color: $primary-color;
    margin-bottom: toRem(6);
}

.checkbox--primary,
.checkbox--secondary,
.radio--secondary {
    & input:focus ~ span:before {
        box-shadow: 0 0 toRem(6) toRem(2) $focus-color;
    }
}

.radio--primary input:focus:checked ~ span:before {
    box-shadow: inset 0 0 0 toRem(2) white, 0 0 toRem(6) toRem(2) $focus-color;
}

.login-form {
    .input-group-addon {
        width: 9%;
        @include media('<phone') {
            width: 16%;
        }
    }
}

.select--boarded-gray {
    background: $secondary-lighten-color;
    border-radius: toRem(25);
    height: toRem(34);
    padding: 0 toRem(15);
    -webkit-appearance: none;
    margin: 0;
    @include font-size(12);
    background-image: url(../../../images/down-arrow.png);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    cursor: pointer;
}

.jcf-file {
    border: 0;
    padding-left: 0;
    .jcf-fake-input {
        width: auto;
        border: none;
        padding: 0;
        color: $primary-lighten-color;
    }
    .jcf-upload-button {
        margin-left: toRem(10);
        background: transparent;
        border: none;
        font-weight: normal;
        font-size: toRem(14);
        @extend .separator-left;
        padding: 0 toRem(10);

        .input-group__field-container--error & {
            overflow: visible;
        }
    }
    .jcf-button-content {
        background: $blue-color;
        padding: toRem(5) toRem(13);
        border-radius: toRem(30);

        .input-group__field-container--error & {
            box-shadow: 0 0 toRem(5) $error-text-color;
        }
    }
}

.form {
    &__section {
        @include media('>tablet') {
            max-width: toRem(800);
        }
    }
    &__section,
    &__group,
    &__fieldset {
        @include media('>tablet') {
            display: flex;
            flex-flow: row wrap;
            align-items: stretch;
            justify-content: stretch;
            margin-left: math.div($grid-gutter-width, -2);
            margin-right: math.div($grid-gutter-width, -2);

            .form__section {
                flex: 1 100%;
                max-width: none;
            }

            .form__group {
                flex: 1 100%;
                .input-group + .input-group {
                    &:before {
                        $form-group__dash-padding: 0.313 !default;
                        $grid-gutter-width: 1.875rem;
                        content: '';
                        position: absolute;
                        left: -($grid-gutter-width * 0.5 - $form-group__dash-padding);
                        top: calc(1rem + #{toRem(6)} + #{toRem(19)});
                        border-top: toRem(1) solid $input-border;
                        width: $grid-gutter-width - $form-group__dash-padding * 2;
                        height: 0;
                    }
                }
            }
            .input-group,
            .button-group {
                flex: 1 calc(50% - #{$grid-gutter-width});
            }
            .form__group,
            .input-group,
            .form__section {
                padding-left: $grid-gutter-width * 0.5;
                padding-right: $grid-gutter-width * 0.5;
            }
            .form__fieldset {
                flex: 1 100%;
                margin: $grid-gutter-width * 0.5;

                .input-group {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
            .button-group {
                padding-top: calc(1rem + #{toRem(6)});
                padding-left: $grid-gutter-width * 0.5;
                padding-right: $grid-gutter-width * 0.5;
            }
        }

        .messages {
            width: 100%;
        }
    }

    &__fieldset {
        .form__section & {
            padding: 1rem toRem(20);
        }

        &--dark {
            border: toRem(1) solid $secondary-lighten-color;
            background: $light-gray-color;
        }

        &--input-lg {
            margin-left: math.div($grid-gutter-width, -8);
            margin-right: math.div($grid-gutter-width, -8);

            .form__group,
            .input-group,
            .form__section,
            .button-group {
                padding-left: $grid-gutter-width * 0.125;
                padding-right: $grid-gutter-width * 0.125;
            }

            .button-group {
                flex: 0 1;
            }
        }
    }

    &__actions {
        background: $light-gray-color;
        text-align: right;
        padding: toRem(14) toRem(18);
        margin-bottom: 1rem;

        &--panel {
            border: toRem(1) solid $secondary-lighten-color;
            margin-bottom: 1rem;

            &__header,
            &__body {
                padding: toRem(14) toRem(18);
            }
            &__header {
                background: $light-gray-color;
                border-bottom: toRem(1) solid $secondary-lighten-color;
            }

            .btn--link {
                margin-top: 0.5rem;
            }
        }
    }
}

.field-error-icon {
    display: none;
}
.field-error-label {
    display: none;
}
