.overlay-cover {
    position: relative;
    display: block;

    &-wrapper {
        display: inline-block;
    }

    &__box {
        position: absolute;
        top: 0;
        background: rgba(0, 0, 0, 0.7);
        height: 100%;
        color: $white;
        text-align: center;
        opacity: 0;
        transition: 0.5s;
        left: 0;
        right: 0;
    }
    &__more {
        border: toRem(1) solid;
        padding: toRem(6) toRem(14);
        display: inline-block;
        margin-top: toRem(15);
        @include font-size(13);
    }
    &__content {
        height: 100%;
        width: 100%;
        display: table;

        &-middle {
            display: table-cell;
            vertical-align: middle;
            padding: 0 toRem(10);
        }

        .ellipsis {
            color: $white;
        }
    }
    &__latest-heading {
        margin-bottom: toRem(10);
        @include font-size(13);
    }

    &:hover {
        .overlay-cover__box {
            opacity: 1;
        }
    }

    .meta {
        &__vol,
        &__date {
            margin: 0;
        }

        &__vol {
            font-weight: 600;
        }
    }
}

.cover-image__image {
    img {
        border: toRem(1) solid $secondary-lighten-color;

        .left-side-image & {
            border: none;
        }
    }
}
