.administratorsWidget {
    button[type='submit'] {
        background: $blue-color;
        color: $white;
        @include media('<tablet') {
            width: 100%;
        }
    }
    &__delete-admin-btn {
        @include media('>=tablet') {
            float: right;
            width: auto;
        }
    }
    &__admin-list {
        .input-group {
            border-bottom: toRem(1) solid $separators-color;
        }
    }
    .email-addresses {
        @include media('>=tablet') {
            float: right;
            width: auto;
            clear: none;
        }
        &.big {
            @include media('<tablet') {
                margin-top: toRem(16);
                width: 100%;
                text-align: center;
            }
        }
        font-weight: normal;
        @include font-size(14);
    }
    .btn,
    button[type='submit'] {
        &.big {
            @include media('<tablet') {
                margin-left: 0;
            }
        }
    }
}
