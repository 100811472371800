.quick-search {
    padding-top: toRem(15);
    padding-bottom: toRem(15);

    @include media('<desktop') {
        .gutterless {
            padding: 0 toRem(15);
        }
    }

    input {
        background: transparent;
        border-color: $primary-bg-color;
        appearance: none;
        border-radius: 0;
    }

    .tab__nav a {
        border-bottom: none;
    }

    @include media('<desktop') {
        padding-top: toRem(10);
    }

    &:after {
        content: '';
        display: block;
        clear: both;
    }

    .js--open[data-db-target-for] {
        i:before {
            display: inline-block;
            transform: rotate(180deg);
        }
    }

    .dropBlock {
        @include font-size(14);

        > a {
            border: toRem(1) solid $primary-bg-color;
        }
        a {
            display: block;
            padding: toRem(12) 0 toRem(12) toRem(12);
            background: transparent;
            font-weight: 600;
            line-height: toRem(11);
        }

        i {
            @include font-size(8);
            padding: toRem(3) toRem(15);
            margin: 0;
            color: $primary-bg-color;
        }
    }

    .dropblock--tab {
        @include media('<tablet') {
            display: none;
        }
        position: absolute;
        width: 100% !important;
        box-shadow: 0 0 toRem(5) $secondary-text-color;

        li {
            display: block;
            margin: 0;
            &:not(.active) {
                margin-bottom: 0;
            }
        }

        a {
            @include font-size(14);
            padding: toRem(13) toRem(12);
            border-top: none;
        }
    }

    .tab__pane {
        padding: 0;
    }

    .input-group {
        margin-bottom: 0;
        @include media('<desktop') {
            padding: toRem(10) 0;
        }
        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }

    :-ms-input-placeholder,
    :-moz-placeholder,
    ::-webkit-input-placeholder {
        color: $secondary-lighten-text-color;
        @include font-size(14);
        font-weight: 600;
    }

    &__button {
        position: absolute;
        @include ltr-rtl('right', toRem(16));
        background: transparent;
        padding: toRem(9);
        color: $search-button;
        height: toRem(36);
        max-height: toRem(36);

        span {
            display: none;
        }

        @include media('>=desktop') {
            top: toRem(1);
        }

        @include media('<desktop') {
            bottom: toRem(11);
        }
    }

    &__ctrl__refine,
    &__ctrl__refine--hide {
        @include font-size(14);
    }

    .container {
        @include media('<desktop') {
            padding: 0 toRem(14);
        }
    }

    .quick-search-pane__anywhere {
        input[type='search'] {
            @include ltr-rtl('pr', toRem(40));
            @include media('<desktop') {
                max-height: toRem(38);
            }
        }
    }

    /*magicSuggest*/

    .ms-ctn,
    .ms-ctn-focus,
    .ms-sel-ctn {
        padding: 0;
    }
    .ms-sel-ctn {
        overflow: hidden;
    }
    .ms-ctn-focus {
        box-shadow: none;
        border: none;
    }

    .ms-ctn .ms-sel-ctn {
        margin: 0;
    }

    .ms-res-ctn.dropdown-menu {
        //background: transparent;
    }
    :-moz-placeholder {
        color: $secondary-lighten-text-color;
    }
    :-ms-input-placeholder {
        color: $secondary-lighten-text-color;
    }
    ::-webkit-input-placeholder {
        color: $secondary-lighten-text-color;
    }

    @if ($enable-quicksearch-as-menu) {
        &__dropBlock {
            background: #e0e0e0;

            .quick-search__button {
                background: $search-bg-color;
            }
        }
    }
    .swipe__wrapper {
        .scroll {
            padding: 0;
            position: relative;
        }
        &:after,
        :before {
            background: transparent;
        }
    }

    .dropzone {
        min-height: toRem(38);
    }

    .quick-search-advanced-link {
        line-height: toRem(38);
        @include font-size(15);

        &:hover,
        &:focus {
            font-weight: bold;
        }
    }
    .icon-close_thin {
        &:before {
            content: ' ';
        }
    }
}

@if ($enable-quicksearch-citation-option) {
    .option-citation {
        @include media('<desktop') {
            border-bottom: toRem(1) solid $primary-bg-color;
        }

        input {
            @include float(left);
            @include ltr-rtl('mr', 1.33%);
            margin-bottom: 0;

            @include media('<desktop') {
                width: 32%;
                margin-bottom: toRem(10);

                &:first-of-type {
                    width: 100%;
                    @include ltr-rtl('mr', 0);
                }

                &:nth-of-type(4) {
                    @include ltr-rtl('mr', 0);
                }
            }

            @include media('>=desktop') {
                width: 10%;

                &:first-of-type {
                    width: 48%;
                }
            }
        }
        &.single-journal {
            input {
                @include media('>=desktop') {
                    width: 20%;
                }
            }
        }
        .ms-ctn {
            @include float(left);
            width: 100%;

            @include media('>=desktop') {
                max-width: 48%;
                @include ltr-rtl('mr', 1.33%);
            }
            .ms-sel-ctn {
                @include media('<desktop') {
                    margin-bottom: toRem(10);
                }
            }
        }
    }
}

.quickSearchRegion.dark {
    background: $search-bg-color;

    .quick-search__button {
        background: $search-bg-color;
    }
}

// jquery ui-auto complete related css
@if ($enable-quicksearch-auto-complete) {
    .ui-autocomplete {
        z-index: map-get($z-core, 'ui-autocomplete');
        background: $white;
        list-style: none;
        padding: toRem(10);
        outline: 0;
        .ui-menu-item {
            margin-bottom: toRem(5);
        }
        .single_highlight_class {
            font-weight: bold;
        }
    }

    .ui-menu .ui-menu-item a.ui-state-focus,
    .ui-menu .ui-menu-item a.ui-state-active {
        font-weight: normal;
        margin: toRem(-1);
    }
}
