.list-of-issues-detailed {
    .loi {
        .tab__content {
            max-height: 100%;
        }

        .tab__pane {
            &.active {
                .tab__pane {
                    &.active {
                        padding-top: toRem(35);
                    }
                }
            }
        }

        &__list {
            a {
                line-height: toRem(18);
            }
        }

        &-tab-1 {
            > .swipe__wrapper {
                .scroll {
                    background-color: $black;
                    .tab__nav {
                        background-color: $black;
                    }
                }
            }
        }
    }
}
