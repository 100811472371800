@font-face {
  font-family: 'icomoon';
  src:  url('./icomoon.eot?f2m0wf');
  src:  url('./icomoon.eot?f2m0wf#iefix') format('embedded-opentype'),
    url('./icomoon.ttf?f2m0wf') format('truetype'),
    url('./icomoon.woff?f2m0wf') format('woff'),
    url('./icomoon.svg?f2m0wf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-go-to-original:before {
  content: "\e996";
}
.icon-creative-commons-zero:before {
  content: "\e95f";
}
.icon-creative-commons-share:before {
  content: "\e964";
}
.icon-creative-commons-sampling:before {
  content: "\e965";
}
.icon-creative-commons-samplingplus:before {
  content: "\e967";
}
.icon-creative-commons-sa:before {
  content: "\e98e";
}
.icon-creative-commons-remix:before {
  content: "\e969";
}
.icon-creative-commons-pd:before {
  content: "\e98f";
}
.icon-creative-commons-nd:before {
  content: "\e991";
}
.icon-creative-commons-nc-jp:before {
  content: "\e990";
}
.icon-creative-commons-nc-eu:before {
  content: "\e992";
}
.icon-creative-commons-nc:before {
  content: "\e993";
}
.icon-creative-commons:before {
  content: "\e994";
}
.icon-creative-commons-by:before {
  content: "\e995";
}
.icon-dblp:before {
  content: "\e955";
  color: #004f9f;
}
.icon-googlescholar:before {
  content: "\e953";
  color: #4885ed;
}
.icon-reset:before {
  content: "\e951";
}
.icon-eReader:before {
  content: "\e94d";
}
.icon-Book:before {
  content: "\e942";
}
.icon-journal:before {
  content: "\e943";
}
.icon-sigs:before {
  content: "\e945";
}
.icon-Vector:before {
  content: "\e94b";
}
.icon-share-social:before {
  content: "\e933";
}
.icon-save-social:before {
  content: "\e93f";
}
.icon-comment-social:before {
  content: "\e940";
}
.icon-reddit-filled:before {
  content: "\e932";
}
.icon-tree-preview:before {
  content: "\e92e";
}
.icon-code:before {
  content: "\e92f";
}
.icon-arrow-top:before {
  content: "\e930";
}
.icon-arrow-right:before {
  content: "\e931";
}
.icon-add-desc:before {
  content: "\e92d";
}
.icon-html:before {
  content: "\e92b";
}
.icon-rss:before {
  content: "\e95e";
}
.icon-clock:before {
  content: "\e922";
}
.icon-upload:before {
  content: "\e921";
}
.icon-pending:before {
  content: "\e91e";
}
.icon-movable:before {
  content: "\e918";
}
.icon-moving:before {
  content: "\e919";
}
.icon-plus-light:before {
  content: "\e916";
}
.icon-corporate:before {
  content: "\e910";
}
.icon-double-file:before {
  content: "\e90c";
}
.icon-institution-light:before {
  content: "\e905";
}
.icon-cookie:before {
  content: "\e98d";
  color: #757576;
}
.icon-star:before {
  content: "\e98c";
}
.icon-add-calendar:before {
  content: "\e988";
}
.icon-table-2:before {
  content: "\e989";
}
.icon-institution-add:before {
  content: "\e987";
}
.icon-Arrow-Stroke:before {
  content: "\e986";
}
.icon-share-folder:before {
  content: "\e981";
}
.icon-persons:before {
  content: "\e982";
}
.icon-folder:before {
  content: "\e983";
}
.icon-grid-3:before {
  content: "\e97f";
}
.icon-rhombus:before {
  content: "\e974";
}
.icon-researchGate:before {
  content: "\e975";
}
.icon-ePub:before {
  content: "\e973";
}
.icon-cancel-bold:before {
  content: "\e970";
}
.icon-burger:before {
  content: "\e96f";
}
.icon-arrow-left-bold:before {
  content: "\e96c";
}
.icon-arrow-right-bold:before {
  content: "\e96d";
}
.icon-arrow-down-bold:before {
  content: "\e96b";
}
.icon-pages:before {
  content: "\e963";
}
.icon-riddit-filled:before {
  content: "\e961";
}
.icon-get-access:before {
  content: "\e960";
}
.icon-navigator:before {
  content: "\e95a";
}
.icon-calender3:before {
  content: "\e95d";
}
.icon-full-text:before {
  content: "\e935";
}
.icon-open-book:before {
  content: "\e937";
}
.icon-add-folder:before {
  content: "\e938";
}
.icon-export:before {
  content: "\e908";
}
.icon-metric:before {
  content: "\e90b";
}
.icon-pdf-file:before {
  content: "\e906";
}
.icon-like:before {
  content: "\e920";
}
.icon-Icon_Twitter:before {
  content: "\e93c";
}
.icon-Icon_Track-citations:before {
  content: "\e93d";
}
.icon-Icon_Share:before {
  content: "\e941";
}
.icon-Icon_Search:before {
  content: "\e944";
}
.icon-Icon_Mobile-menu:before {
  content: "\e946";
}
.icon-Icon_mail:before {
  content: "\e947";
}
.icon-Icon_Links-References:before {
  content: "\e948";
}
.icon-Icon_Information:before {
  content: "\e949";
}
.icon-Icon_Google-plus:before {
  content: "\e94c";
}
.icon-Icon_Facebook:before {
  content: "\e94e";
}
.icon-Icon_Edit:before {
  content: "\e94f";
}
.icon-Icon_Download:before {
  content: "\e950";
}
.icon-Icon_Cart:before {
  content: "\e952";
}
.icon-Icon_Alerts:before {
  content: "\e954";
}
.icon-Icon_Account-LoggedIn:before {
  content: "\e956";
}
.icon-Icon_About-Article:before {
  content: "\e958";
}
.icon-zoom:before {
  content: "\e92a";
}
.icon-Email:before {
  content: "\e90e";
}
.icon-x_btnclose:before {
  content: "\e90d";
}
.icon-success:before {
  content: "\e90a";
}
.icon-orcid:before {
  content: "\e636";
}
.icon-plus_n:before {
  content: "\e606";
}
.icon-checkmark:before {
  content: "\e607";
}
.icon-arrow_d_n:before {
  content: "\e60b";
}
.icon-arrow_u_p:before {
  content: "\e60c";
}
.icon-search:before {
  content: "\e60e";
}
.icon-abstract:before {
  content: "\e611";
}
.icon-info:before {
  content: "\e616";
}
.icon-section_arrow_d:before {
  content: "\e61c";
}
.icon-section_arrow_u:before {
  content: "\e61d";
}
.icon-section_expand:before {
  content: "\e621";
}
.icon-arrow_l:before {
  content: "\e624";
}
.icon-arrow_r:before {
  content: "\e625";
}
.icon-close_thin:before {
  content: "\e626";
}
.icon-Icon_Images:before {
  content: "\e96a";
}
.icon-x:before {
  content: "\e900";
}
.icon-eye-blocked:before {
  content: "\e91d";
}
.icon-cancel:before {
  content: "\e971";
}
.icon-notice:before {
  content: "\e959";
}
.icon-warning:before {
  content: "\e959";
}
.icon-explanation-mark:before {
  content: "\e959";
}
.icon-notification:before {
  content: "\e959";
}
.icon-trash-can:before {
  content: "\e90f";
}
.icon-warning-triangle:before {
  content: "\e915";
}
.icon-my_location:before {
  content: "\e98a";
}
.icon-delete:before {
  content: "\e984";
}
.icon-fullscreen:before {
  content: "\e980";
}
.icon-person_add:before {
  content: "\e979";
}
.icon-arrow_drop_down:before {
  content: "\e97a";
}
.icon-audiotrack:before {
  content: "\e972";
}
.icon-person:before {
  content: "\e95b";
}
.icon-person_outline:before {
  content: "\e95c";
}
.icon-attach_file:before {
  content: "\e907";
}
.icon-format_color_text:before {
  content: "\e934";
}
.icon-filter_list:before {
  content: "\e93a";
}
.icon-circle-plus:before {
  content: "\e93b";
}
.icon-play_circle_filled:before {
  content: "\e94a";
}
.icon-subdirectory_arrow_left:before {
  content: "\e96e";
}
.icon-lock_open:before {
  content: "\e966";
}
.icon-lock_outline:before {
  content: "\e97c";
}
.icon-question:before {
  content: "\e91f";
}
.icon-minus-light:before {
  content: "\e917";
}
.icon-dots-three-vertical:before {
  content: "\e985";
}
.icon-dots-three-horizontal:before {
  content: "\e978";
}
.icon-Icons_Sorting:before {
  content: "\e968";
}
.icon-play1:before {
  content: "\e957";
}
.icon-account_balance:before {
  content: "\e923";
}
.icon-Block:before {
  content: "\e924";
}
.icon-Error:before {
  content: "\e925";
}
.icon-insert_drive_file:before {
  content: "\e926";
}
.icon-MailError:before {
  content: "\e927";
}
.icon-Sever:before {
  content: "\e928";
}
.icon-View:before {
  content: "\e929";
}
.icon-play:before {
  content: "\e909";
}
.icon-loop:before {
  content: "\e97d";
}
.icon-youtube:before {
  content: "\e976";
}
.icon-github:before {
  content: "\e977";
}
.icon-brand:before {
  content: "\e977";
}
.icon-octacat:before {
  content: "\e977";
}
.icon-social:before {
  content: "\e977";
}
.icon-quote:before {
  content: "\e902";
}
.icon-eye:before {
  content: "\e903";
}
.icon-views:before {
  content: "\e903";
}
.icon-bookmark:before {
  content: "\e936";
}
.icon-ribbon:before {
  content: "\e936";
}
.icon-twitter:before {
  content: "\e62c";
}
.icon-facebook:before {
  content: "\e62d";
}
.icon-linkedin:before {
  content: "\e62e";
}
.icon-zoom-in:before {
  content: "\e632";
}
.icon-zoom-out:before {
  content: "\e633";
}
.icon-reddit:before {
  content: "\eac6";
}
.icon-earth:before {
  content: "\e911";
}
.icon-globe:before {
  content: "\e911";
}
.icon-language:before {
  content: "\e911";
}
.icon-web:before {
  content: "\e911";
}
.icon-internet:before {
  content: "\e911";
}
.icon-sphere:before {
  content: "\e911";
}
.icon-planet:before {
  content: "\e911";
}
.icon-bookmark1:before {
  content: "\e93e";
}
.icon-ribbon1:before {
  content: "\e93e";
}
.icon-connection:before {
  content: "\e91c";
}
.icon-spinner8:before {
  content: "\e98b";
}
.icon-sort:before {
  content: "\e92c";
}
.icon-id-badge:before {
  content: "\e91b";
}
.icon-file:before {
  content: "\e97e";
}
.icon-institution:before {
  content: "\e97b";
}
.icon-calendar2:before {
  content: "\e901";
}
.icon-google-plus-square:before {
  content: "\e91a";
}
.icon-facebook-square:before {
  content: "\e912";
}
.icon-twitter-square:before {
  content: "\e913";
}
.icon-linkedin-square:before {
  content: "\e914";
}
.icon-table:before {
  content: "\e904";
}
.icon-heart-o:before {
  content: "\e939";
}
.icon-stack-overflow:before {
  content: "\e962";
}
