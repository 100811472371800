.conference {
    &__proceedings {
        margin-bottom: toRem(30);
        list-style: none;

        &__container {
            color: $primary-color;
            padding-left: 0;
        }

        &__header {
            &.border-title {
                margin-top: toRem(34);
                margin-bottom: toRem(32);
            }
        }
        a {
            &:hover {
                color: $blue-color;
                text-decoration: underline;
            }
        }
    }

    &__title {
        background-color: $light-gray-color;
        border-top: toRem(1) solid $secondary-color;
        border-bottom: toRem(1) solid $secondary-color;
        padding: toRem(17) toRem(16);
        font-size: toRem(16);
        font-weight: 700;

        a {
            display: block;
            &:not(:first-child) {
                margin-top: toRem(10);
            }
        }

        &.left-bordered-title {
            font-style: normal;

            &:before {
                height: 100%;
                width: toRem(4);
            }
        }
    }
    &__workshops {
        padding-left: toRem(16);
        font-size: toRem(14);
        width: 58%;
        @include media('<tablet') {
            width: 100%;
        }
        span {
            font-weight: 700;
            @include media('<tablet') {
                display: block;
                margin-bottom: toRem(10);
            }
        }

        ul {
            display: inline !important; // this is to override the truncation Js that sets the display to "inline-block" which breaks the truncated text into a new line
            a {
                text-decoration: underline;
            }
        }
    }
}

.proceedings__all {
    .btn {
        color: $blue-color;
        font-size: toRem(12);
        background: none;
        padding: 0;
    }

    i {
        font-size: toRem(12);
        margin-top: toRem(7);
        margin-left: toRem(5);
    }
}

.collapsed-proceedings {
    color: $arrow-blue-color;
    font-size: toRem(11);
    margin-left: toRem(7);
}
