.loa {
    //.label {
    //    margin:0;
    //}
    .icon-Icon_mail {
        color: $primary-lighten-color;
        @include font-size(14);
        display: inline-block;
        vertical-align: middle;
        padding-left: toRem(6);
    }
    img:not(.orcidid) {
        filter: grayscale(100%);
        transition: all 200ms ease-in-out;
    }
    a {
        &:hover {
            color: darken($primary-lighten-color, 10%);
            img {
                filter: grayscale(0);
            }
        }
    }
}

.orcidid {
    vertical-align: middle;
    margin-right: toRem(5);
}
