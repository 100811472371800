$primary-color: #333;

$primary-lighten-color: #6b6b6b;

$primary-lightest-color: #3c3c3c;

$secondary-color: #d9d9d9;

$secondary-lighten-color: #f0f0f0;

$light-gray-color: #fafafa;

$lighter-dark-color: #555;

$highlight-color-new: #565656;

$secondary-shadow-color: rgba(0, 0, 0, 0.12);

$orange-color: #c1450b;

$visted-link-color: #8347a1;

$suggestions-color: #666;

$facet-color: #454545;

$lighter-silver: #aaa;

$red-color: #c92626;

$light-pink-color: #ff8a80;

$dark-pink-color: #eb5757;

$dark-red-color: #d40c03;

$purple-color: #651fff;

$light-purple-color: #864efd;

$lighter-purple-color: #c1a7fc;

$journal-code-color: #2c5fad;

$light-yellow-color: #ffefbf;

$focus-color: #9e8d10;

$highlight-color: #ebc34a;

$highlight-color-darker: #816a24;

$highlight-color-new: #fffdbd;

$green-color: #5aab00;

$bibliometric-label-color: #251314;

$conference-primary-color: #76a49b;

$top-rated-color: #cb6d1c;

$top-rated-light-color: #e09e51;

$dark-green-color: #3b6f24;

$green-shadow-color: #3a6420;

$orange-open-access: #b95709;

$success-text-color: #368739;

$success-bg-color: #def3de;

$dark-success-bg-color: #afe981;

$error-text-color: #ad4542;

$error-bg-color: #ffebeb;

$dark-error-bg-color: #ffb9b7;

$warning-text-color: #856404;

$warning-bg-color: #fff3cd;

$dark-warning-bg-color: #ebd490;

$info-text-color: #307b9e;

$info-bg-color: #e3f4fd;

$dark-info-bg-color: #95cee8;

$primary-bg-color: $black;

$primary-text-color: $primary-color;

$primary-lighten-bg-color: $secondary-color;

$primary-border-color: rgba(255, 255, 255, 0.34);

$secondary-border-color: $secondary-color;

$footer-bg-color: $black;

$tertiary-bg-color: $primary-lighten-color;

$secondary-lighten-bg-color: $secondary-lighten-color;

$secondary-bg-color: $secondary-lighten-color;

$contrib-about-color: #9a9a9a;

$csur-primary-color: #1975ae;

$csur-light-color: #79c6e2;

$csur-lighter-color: #b2d9e8;

$csur-diff-color: #f3a34c;

$jetc-lighter-color: #d9ebd3;

$jocch-primary-color: #007d4a;

$interactions-primary-color: #4c4c4c;

$xrds-primary-color: #bd3a44;

$xrds-lighter-color: #f5babf;

$active-dot-color: #fff;

$owl-dot-outline-color: #fff;

$pub-contribs-border-color: #dfdfdf;

$accessibility-bg: #ffeeba;

$white: #fff;

$quote-bg: #f9f9f9;

$quote-dash: #bd3a44;

$bordered-quote: #bd3a44;

$boxed-text-border: #bdbdbd;

$boxed-text-bg: #e1e1e1;

$yellow-container: #ebebda;

$register-btn-bg: #525252;

$register-btn-text: #a3c8ff;

$loa-text-color: #595959;

$lightgrey: lightgrey;

$red: #d44848;

$background-trigger-bg: #80bfff;

$free-access: green;

$no-access: red;

$dr-input-lighter-color: #c3cacd;

$dr-input-lightest-color: #ebf1f4;

$dr-input-light-color: #2693d5;

$dr-input-dark-color: #4f565c;

$dr-input-grey-color: #9ba3a7;

$proceeding-issues-bg: #f3f3f3;

$affiliations-popup-bg: #e0e0e0;

$affiliations-popup-close-color: #707070;

$secondary-text-color: $primary-lighten-color;

$institutional-affs-red: #cf102d;

$black: #000;

$owl-dot-border: #8a8a8a;

$autoComplete-tag-border: #bbb;

$active-tab-onblack: #43b6ff;

$arrow-blue-color: #2088c9;

$accountTab-button: #63666a;

$claim-request-border: #e0e0e0;

$yellow-block: #f2c94c;

$light-silver: #f5f5f5;

$sub-nav: #ffffde;

$recommendation-title: #454545;

$grey-lighten-20: #eef1f3;

$blue-color: #0077c2;
$blue-lighten-10: #4774b8;
$dark-blue-link: #00588f;
$light-blue-color: #7c9ccc;
$lighter-blue-color: #73cef2;
$dark-blue-color: #002171;
$dark-blue-cyan: #2f8098;
$dim-blue-color: #506fbe;
$count-bubble-blue: #0077c2;
$blue-gray-color: #6b778c;

$metric-update-date: #747474;

$get-access: #448500;
$get-access-hover: #2d7130;
