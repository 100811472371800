.loi-page {
    .title-gray-wrapper {
        padding-top: toRem(10);
        background-color: $secondary-bg-color;
    }

    .loi__archive {
        background-color: rgba($white, 0.4);
        font-weight: 600;
    }

    &__title {
        @include font-size(22);
        margin: toRem(25) 0;
    }
}
