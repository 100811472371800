@mixin red-ribbon-display() {
    @for $i from 1 through 8 {
        .red-ribbon-#{$i} & {
            &:nth-child(-n + #{$i}) {
                .book-carousel__new-tag {
                    display: block;
                }
            }
        }
    }
}

.book-carousel {
    .owl-carousel {
        padding: 0;
    }
    &__image-holder {
        position: relative;
        float: left;
        margin-bottom: toRem(20);
        min-height: toRem(150);
        a {
            display: block;
        }
        img {
            width: 100%;
            max-width: toRem(120);
        }
        @include media('<large') {
            float: none;
            text-align: center;
            a {
                position: relative;
                display: inline-block;
                margin: 0 auto;
                text-align: center;
            }
            img {
                margin: 0 auto;
            }
        }
    }
    &__title {
        border-bottom: toRem(2) solid;
        margin-bottom: toRem(10);
        padding-bottom: toRem(10);
    }
    .owl-item {
        .author-picture {
            display: inline-block;
        }
    }
    &__info {
        color: $primary-lighten-color;
        margin-top: toRem(7);
    }
    &__category {
        margin-top: toRem(20);
    }
    &__new-tag {
        background-color: $red-color;
        position: absolute;
        left: toRem(-6);
        color: $white;
        font-weight: 600;
        @include font-size(13);
        padding: toRem(2) toRem(6);
        top: toRem(12);
        display: none;
    }
    &__brief {
        font-weight: 300;
        font-style: italic;
        clear: both;
        //min-height: toRem(110);
    }
    &__book-data {
        padding-left: toRem(10);
        overflow: hidden;
        @include media('<large') {
            clear: left;
            margin-top: toRem(20);
            padding-left: 0;
        }
    }
    .grid-item {
        margin-bottom: toRem(60);
        @include media('<tablet') {
            border-bottom: toRem(1) solid $secondary-color;
            margin-bottom: toRem(30);
            padding-bottom: toRem(30);
            &:last-child {
                border: none;
            }
        }
    }
    &__tags {
        color: $tertiary-bg-color;
    }
    @include media('<1420px', '>large') {
        padding: 0 toRem(50);
    }
}
.books-section {
    .grid-item {
        margin-bottom: toRem(50);
        padding: 0;
        @include media('<=phone') {
            border-bottom: toRem(1) solid $secondary-color;
            margin-bottom: toRem(25);
            padding-bottom: toRem(25);
        }
        &:last-child {
            border: none;
        }
        .owl-item & {
            border: none;
        }
    }

    .slide-item {
        margin-top: 0;
    }

    .owl-carousel {
        .icon-arrow_r,
        .icon-arrow_l {
            color: $primary-lighten-color;
            @include font-size(25);
        }
        .owl-prev,
        .owl-next {
            @include media('<desktop') {
                background: none !important;
            }

            @include media('<=tablet') {
                top: 15% !important;
            }

            @include media('<large') {
                span {
                    display: none;
                }
                .icon-arrow_l,
                .icon-arrow_r {
                    display: block;
                }
            }
        }

        .book-carousel__book-data {
            .truncate-list {
                .author-picture {
                    display: inline-block;
                }
            }
        }
    }
}
.grid-item {
    @include red-ribbon-display();
}
