.access-icon {
    @include font-size(12);
    text-transform: uppercase;
    display: inline-block;
}
.free-access {
    color: $free-access;
}
.open-access {
    color: $orange-open-access;
}
.no-access {
    color: $no-access;
}

.chorus-access {
    color: $journal-code-color;
}
