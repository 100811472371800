.personal-information {
    margin-bottom: toRem(30);
    &__text {
        color: $primary-color;
        font-weight: 300;
        margin-top: 0;
        margin-bottom: toRem(16);
    }
    &__label {
        @include font-size(14);
    }
    &__row {
        padding: toRem(20);
    }
    &__info {
        .personal-information__row {
            &:nth-child(odd) {
                background: $light-gray-color;
            }
        }
    }
}
