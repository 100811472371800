@font-face {
  font-family: 'fpicons';
  src:url('./fpicons.eot?yg5dv7');
  src:url('./fpicons.eot?#iefixyg5dv7') format('embedded-opentype'),
  url('./fpicons.woff?yg5dv7') format('woff'),
  url('./fpicons.ttf?yg5dv7') format('truetype'),
  url('./fpicons.svg?yg5dv7#fpicons') format('svg');
  font-weight: normal;
  font-style: normal;
}
