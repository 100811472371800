.profile-meta {
    .left-bordered-title {
        height: toRem(18);
    }

    h2 {
        font-family: $tertiary-font-family;
    }

    .link {
        text-decoration: none;
    }

    .item-meta-row {
        &:first-child {
            @include media('>=tablet') {
                margin-top: 0;
            }
        }
    }

    &.institution-profile {
        .item-meta-row {
            &:first-child {
                @include media('>=tablet') {
                    min-height: toRem(120);
                }
            }
        }
    }

    .icon-institution-add {
        font-size: toRem(19);
        line-height: toRem(15);
        float: left;
    }

    @include media('<tablet') {
        text-align: center;

        .edit-account {
            float: none;
        }

        .left-side-image {
            img {
                width: toRem(90);
                height: toRem(90);
                float: none;
                padding-right: 0;
                margin-right: 0;
            }
        }
    }

    .left-side-image,
    .item-meta__info {
        @include media('>=tablet') {
            display: table-cell !important;
            vertical-align: top;
        }
    }

    .left-side-image {
        @include media('>=tablet') {
            width: toRem(160);
            padding-right: toRem(26);
        }
        img {
            @extend .img-rounded;

            @include media('>=tablet') {
                padding: 0;
                width: toRem(134);
                height: toRem(134);
            }
        }
    }

    .actions {
        @include media('<tablet') {
            display: block;

            ul {
                margin-bottom: toRem(10);
            }

            .btn ~ .btn {
                margin-top: toRem(10);
            }
        }

        @include media('>=tablet') {
            ul {
                display: inline-block;
            }
        }

        .rlist--inline:last-child {
            &:before {
                color: $secondary-color;
                @include media('<tablet') {
                    content: '';
                }
            }
        }

        @include media('<large') {
            float: none;
            margin-top: toRem(10);
        }
    }

    .transparent {
        border-color: $secondary-color;

        &.btn {
            i {
                @include font-size(20);
                vertical-align: middle;
            }
        }
    }

    [data-db-target-of] {
        padding: toRem(22);
        background: $white;
        padding-right: 0;
        min-width: toRem(215);
        @include media('<desktop') {
            right: toRem(-41);
            left: auto !important;
        }
        li ~ li {
            margin-top: toRem(18);
        }

        i {
            color: $primary-lighten-color;
            @include font-size(18);
            padding-right: toRem(10);
            vertical-align: middle;
        }
    }

    .icon-person_add {
        @include font-size(18);
        line-height: toRem(11);
    }

    .absolute-tip {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }
}

.websites__ctrl {
    &.websites__ctrl {
        // increase specificity
        width: auto;
    }

    padding: 0 toRem(10);

    .separator {
        height: toRem(20);
        vertical-align: middle;
        padding-left: toRem(5);
        border-color: $secondary-color;
        display: inline;
    }
    .icon-arrow_drop_down {
        @include font-size(18);
        display: inline-block;
    }
    &.js--open {
        .icon-arrow_drop_down {
            transform: rotate(-180deg);
        }
    }
}

.author-websits {
    counter-reset: li;

    i {
        color: $primary-lighten-color;
        &:after {
            counter-increment: li;
            content: counter(li);
            @include font-size(10);
            font-weight: 600;
            padding: toRem(2);
        }
    }
}

.institutions-logo {
    li {
        margin-right: toRem(15);
    }
}

.list-of-institutions {
    li ~ li {
        .link {
            @extend .dot-separator;
            &:before {
                color: $secondary-color;
            }
        }
    }
}
