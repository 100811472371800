.publication-contribs {
    display: flex;
    padding: toRem(9) toRem(0);
    .icon-info {
        margin-left: toRem(8);
        margin-right: toRem(18);
        vertical-align: text-bottom;
    }
    .claimPublication {
        float: right;
        margin-right: toRem(16);
        &:hover {
            text-decoration: underline;
        }
    }
    .author-picture {
        margin-left: toRem(8);
        width: toRem(18);
        height: toRem(18);
        margin-top: toRem(3);
    }
    .claim,
    .pending,
    .unclaim {
        line-height: 2;
        width: 100%;
    }
}

.ro-mode {
    .publication-contribs {
        a {
            cursor: not-allowed;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.btn {
    &.claimButton,
    &.notMe {
        height: toRem(40);
        vertical-align: bottom;
        margin-top: toRem(13);

        .icon-person_add {
            color: $white;
            font-size: toRem(17);
            margin-right: toRem(8);
            vertical-align: text-top;
        }
    }
}

.cancelClaim {
    float: right;
    display: none;
    margin-right: toRem(16);
    &:hover {
        text-decoration: underline;
    }
}

.editClaim {
    float: right;
    margin-right: toRem(16);
    &:hover {
        text-decoration: underline;
    }
}

#tab-contributors {
    .pubContribs {
        .accordion-tabbed {
            padding: 0;
            > li {
                display: block;
                visibility: visible;
                padding: toRem(13) toRem(16);
                transition: 0.2s;
                color: $primary-lighten-color;
                border-top: toRem(1) solid $pub-contribs-border-color;
            }
            a {
                text-decoration: none;
            }
        }
        .auth-image,
        .auth-info {
            vertical-align: middle;
            display: inline-block;
            margin-right: toRem(11);
            @include media('<tablet') {
                width: 80%;
            }

            img {
                width: toRem(56);
                height: toRem(56);
                border-radius: 50%;
                -o-object-fit: cover;
                object-fit: cover;
                -o-object-position: top;
                object-position: top;
            }
        }
        .auth-name {
            font-weight: 600;
            font-style: italic;
            font-size: 1.125rem;
            cursor: pointer;
            a {
                text-decoration: none;
            }

            span {
                font-size: toRem(14);
                display: block;
            }
        }
        .auth-institution {
            display: block;
            font-weight: 400;
        }

        .accordion-tabbed__control {
            display: flex;
            &:after {
                content: '' !important;
            }
        }
        .icon-export {
            font-size: 0.938rem;
            margin-left: toRem(5);
            color: $secondary-color;
        }

        .icon-checkmark {
            color: $success-text-color;
        }

        .icon-warning-triangle {
            color: $warning-text-color;
        }

        .icon-notice {
            color: $error-text-color;
        }

        .alert-msg--warning {
            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .claimButton,
        .notMe {
            display: none;
        }
    }
}

.unnormalized {
    display: block;
}
.searchAuthor {
    button {
        margin-top: toRem(10);
        height: toRem(40);
    }
}

.authInfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    &:after {
        content: '\e60b' !important;
        display: inline-block !important;
        font-family: icomoon, sans-serif !important;
        padding-right: toRem(10);
        color: $blue-color;
        font-style: normal;
        font-size: 0.563rem;
    }
}

.accordion-tabbed__tab.js--open {
    .authInfo {
        &:after {
            content: '\e60c' !important;
        }
    }
}

.article-citations {
    .loa__link {
        font-size: 0.875rem;
    }
    .noTruncation {
        display: inline;
    }
}
