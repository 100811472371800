.ux-modal-container {
    .modal {
        z-index: $index-10001;
        &__dialog {
            min-height: toRem(318);
            padding: toRem(17) toRem(35) toRem(30);
            border-radius: toRem(2);
            box-shadow: toRem(2) toRem(4) rgba($black, 0.1);
            width: toRem(545);

            @include media('<tablet') {
                max-height: 95%;
                width: 95%;
                margin: toRem(30) auto;
                height: auto;
            }
        }

        &__header {
            img {
                vertical-align: middle;
            }

            h2 {
                @include font-size(22);
                text-align: left;
                font-weight: 600;
                margin-bottom: toRem(20);
            }
        }

        &-backdrop {
            background-color: rgba(0, 0, 0, 0.46);
            z-index: $index-10000;
        }
    }

    .close {
        color: $secondary-text-color;
        @include font-size(18);
    }

    .modal__footer {
        border: none;
        padding: 0;
        margin: 0;
        margin-top: toRem(15);
    }

    &.meduim {
        @include media('>=tablet') {
            .modal__dialog {
                width: toRem(727);
            }
        }
    }

    &.big {
        @include media('>=desktop') {
            .modal__dialog {
                width: toRem(893);
            }
        }
    }
}

.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.46);
    z-index: $index-10000;
}
