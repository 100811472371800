.authorizer {
    font-weight: 300;
    font-size: 0.813rem;

    .p {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    &__heading {
        margin-top: 2rem;
        font-weight: normal;
        font-size: 1.25rem;
    }

    .checkbox--primary {
        font-weight: inherit;
        font-style: italic;
        display: inline-block;
        margin-right: 5ex;

        .label-txt:before {
            position: relative;
            top: toRem(-2);
        }
    }

    .input-group,
    input {
        margin-bottom: 0;
    }

    [readonly] {
        border-color: $secondary-color;
        background: $light-gray-color;
    }

    &__response {
        resize: vertical;
        height: toRem(100);
        overflow: auto;
        white-space: pre;
        font-style: normal;
        line-height: 1.75;
        background: inherit;
        border-width: 0;
        padding: 0 toRem(10);
    }

    $authorizer__panel--border: #e0e0e0 !default; // didn't find it set anywhere else ...
    .panel {
        display: none;
        border: toRem(1) solid $authorizer__panel--border;
        border-radius: toRem(2);

        &__heading {
            font-size: inherit;
            margin: toRem(10);
        }

        &__header,
        &__body {
            padding: toRem(8) toRem(14);
        }

        &__header {
            background: $secondary-lighten-color;
        }

        &__body + .panel__body {
            border-top: toRem(1) solid $authorizer__panel--border;
        }

        .copy__btn {
            color: $blue-color;
            font-size: 1.2rem;
            padding: 0 toRem(8);
            border-left: toRem(1) solid $authorizer__panel--border;
            display: inline-block;
            margin-top: toRem(5);
            margin-right: toRem(-8);

            i {
                vertical-align: middle;
            }
        }
    }

    .authorizer-panel-loader {
        display: none;
        text-align: center;
        font-size: toRem(40);
        margin: toRem(40) 0 0 0;
        color: $blue-color;
    }

    .issue-item {
        box-shadow: none;
        margin-top: 0;

        &__citation {
            display: none;
        }

        &__content {
            width: auto;
        }

        a {
            color: inherit;
        }

        .read-more {
            color: $blue-color;
        }

        .btn--icon.blue,
        .btn--icon.red {
            color: $white;
        }
    }

    .multi-search--issue-item {
        .separated-block--dashed {
            display: none;
        }
    }

    .multi-search {
        padding: 0;
    }

    .re-authorize-message {
        display: none;
        padding: toRem(5) toRem(10);
        border-radius: toRem(30);
        margin-left: toRem(6);
        background: $success-bg-color;
        color: $success-text-color;

        i {
            margin-right: toRem(5);
        }
    }

    .urls-container {
        .col-sm-6 {
            margin: toRem(10) 0;
        }
        .error-url {
            display: none;
            color: $error-text-color;
            position: absolute;
            left: 0;
        }
    }
    .input-group-addon {
        width: 13%;
    }
}

#modal-authorizer {
    .issue-item {
        &__title {
            a {
                &:hover {
                    color: $blue-color;
                }
            }
        }
    }

    a {
        text-decoration: none;
    }

    .no-eligible-publications {
        display: none;
        text-align: center;
        margin-top: toRem(70);
    }
}

.bibliometrics-iframe {
    position: absolute;
    border: 0;
    z-index: $index-10;
    width: 100% !important;
    height: toRem(45) !important;
}
