.accordion {
    &-tabbed {
        &__tab {
            &:not(:first-child) {
                border-color: $secondary-border-color;
            }

            &.toc__section {
                border: none;
            }
        }

        &__content {
            border: none;
            display: block;
        }
        &__control {
            color: $text-onLight;
            @include font-size(14);
            font-weight: 500;
        }
    }

    &__control {
        color: $primary-lighten-color;
        @include font-size(14);
        font-weight: 500;
        margin-bottom: toRem(18);

        i[class*='icon-'] {
            @include font-size(8);
            font-weight: 900;
        }

        + .accordion__content {
            display: none;
        }
    }

    &__content {
        background-color: $secondary-lighten-bg-color;
        padding: toRem(15);
        p {
            margin: 0;
        }
        h6 {
            margin: 0;
        }
    }
}
