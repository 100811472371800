.search__magazine-item {
    line-height: toRem(20);
    width: 100%;

    .meta__ {
        &vol {
            @include font-size(18);
            margin-bottom: toRem(10);
            display: inline-block;
        }

        &vol,
        &links {
            color: $blue-color;
        }

        &vol,
        &status {
            font-style: italic;
            font-weight: 600;
        }

        &status {
            color: $primary-lighten-color;
        }

        &date,
        &status,
        &isbn {
            margin: 0;
        }

        &isbn {
            line-height: toRem(25);
        }

        &info {
            width: calc(100% - #{toRem(110)});
        }

        &links {
            margin-top: toRem(40);

            li ~ li {
                margin: 0;
                padding: 0;
                border-color: $secondary-color;
            }

            @include media('<desktop') {
                li:last-child {
                    border-bottom: toRem(1) solid $secondary-color;
                }
            }

            a {
                padding: toRem(10) 0;
                display: block;
            }

            @include media('<desktop') {
                clear: both;
            }

            .external {
                &:after {
                    content: '\e908';
                    font-family: icomoon, sans-serif !important;
                    speak: none;
                    font-style: normal;
                    font-weight: 400;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    vertical-align: middle;
                    margin-left: toRem(5);
                }
            }
        }

        &description {
            padding-right: toRem(23);
            margin: toRem(14) 0;

            @include media('<tablet') {
                display: none;
            }
        }

        &image {
            display: inline-block;
            margin-right: toRem(10);
            max-width: toRem(100);

            img {
                width: toRem(97);
            }
        }
    }

    @include media('>=tablet') {
        .more-link,
        .lot {
            margin-top: toRem(30);
        }
    }

    [class*='grid-']:not([class*='item']):not([class^='icon-']) .grid-item {
        //increasing the specificity to apply the style
        &:not(:first-child) {
            border-top: toRem(1) solid $secondary-color;
        }

        padding-top: toRem(10);

        @include media('>=tablet') {
            &:nth-child(2n + 1) {
                clear: left;
                padding-left: 0;
            }

            &:nth-child(2n + 2) {
                padding-right: 0;
            }

            &:nth-child(2),
            &:first-child {
                border-top: none;
                padding-top: 0;
            }
        }

        @include media('<tablet') {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .loa {
        padding-right: 0;
    }

    .issue-item__title {
        margin: 0;
    }

    .grid-md-2 {
        @include media('<tablet') {
            .left-bordered-title {
                margin-top: toRem(20);
                margin-bottom: 0;
            }

            .grid-item:first-child {
                padding-top: toRem(20) !important;
            }
        }
    }
    .left-bordered-title:before {
        background-color: var(--primary-color);
    }
}
