// todo: move to variables file
$index-15: 15;

.cookiePolicy-popup {
    position: fixed;
    bottom: toRem(20);
    left: toRem(20);
    max-width: toRem(600);
    background: $white;
    border-radius: toRem(6);
    z-index: $index-15;
    overflow: hidden;
    border: toRem(1) solid $secondary-lighten-color;
    display: none;

    @include media('<tablet') {
        width: 100%;
        padding: 0;
        left: 0;
        bottom: 0;
    }
    &__body {
        padding: toRem(16) toRem(50);
        font-size: toRem(12);
        color: $primary-color;
        padding-top: 0;
        @include media('<tablet') {
            padding: toRem(11) toRem(15);
            padding-top: 0;
        }
    }
    &__header {
        background: $secondary-lighten-color;
        .icon-cookie {
            display: inline-block;
            vertical-align: middle;
            width: toRem(40);
            border-right: toRem(1) solid $white;
            padding: toRem(13) 0;
            text-align: center;
            margin-right: toRem(10);
            min-height: toRem(45);
        }
    }
    &__title {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        font-weight: 600;
    }
    &__close {
        @include font-size(14);
    }
}
