// todo: move to variables file
$index-9: 9;

.claim-request {
    .pending-count {
        padding: toRem(10);
        @include font-size(14);
        border-bottom: toRem(1) solid $secondary-color;

        .count {
            @include font-size(16);
            float: right;
        }
    }

    .card--dark {
        padding: toRem(10);
        @include font-size(12);
    }

    .row-eq-height {
        margin-top: toRem(40);
    }

    [class*='alert-msg'] {
        i {
            @include font-size(14);
        }
    }

    .daterange {
        &--trigger {
            position: absolute;
            z-index: $index-9;
            width: toRem(50);
            height: toRem(38);
            padding-top: toRem(11);
            top: toRem(1);
            left: toRem(1);
            bottom: toRem(1);
            border: none;
        }

        .dr-input {
            padding-left: toRem(38);
        }
    }

    &-list {
        @include font-size(14);
    }

    thead {
        th {
            color: $primary-color;
        }

        @include media('<tablet') {
            display: none;
        }
    }

    tbody {
        td {
            font-weight: 300;
            @include media('>=tablet') {
                font-size: toRem(12);
            }

            &:first-child {
                font-weight: 400;
                font-size: toRem(14);

                a {
                    @include media('>=phone') {
                        text-decoration: underline;
                    }

                    @include media('>=large') {
                        margin-left: toRem(15);
                    }

                    @include media('<large') {
                        margin-top: toRem(10);
                    }
                }

                a,
                img {
                    display: inline-block;
                    vertical-align: middle;

                    @include media('<tablet') {
                        margin-right: toRem(12);
                    }
                }
                @include media('>=desktop') {
                    display: flex;
                    align-items: center;
                }
            }

            &:last-child {
                @include media('>=desktop') {
                    max-width: toRem(146);
                }
            }

            img {
                @extend .img-rounded;
                width: toRem(56);
                height: toRem(56);
            }

            @include media('<tablet') {
                display: block;
            }
            @include media('>=tablet', '<desktop') {
                max-width: toRem(150);
            }
        }

        [class*='alert-msg'] {
            font-size: toRem(11);

            @include media('<tablet') {
                width: 100%;
                display: block;
                text-align: center;
            }
        }
        .hidden-md {
            color: $primary-color;
            margin-bottom: toRem(8);
            font-weight: 700;
            font-size: toRem(12);
        }
    }

    tr {
        border-bottom: toRem(1) solid $secondary-color;
    }

    @include media('<desktop') {
        .row-eq-height {
            display: block;
        }

        .left-border {
            border: none;
            margin-left: 0;
        }
    }

    .claim__filter {
        border-top: toRem(1) solid $secondary-border-color;
        padding: toRem(12) toRem(12) toRem(12) toRem(6);
        font-size: toRem(12);
    }

    #filter {
        .colored-block:first-child {
            margin-top: toRem(24);
        }
    }

    .icon-calender3 {
        color: $secondary-color;
    }

    .icon-full-text {
        color: $black;
    }

    &__date {
        font-size: toRem(22);
        color: $black;

        @include media('<tablet') {
            margin-top: toRem(27);
        }
    }

    .left-border {
        margin-left: 0;

        @include media('>=tablet') {
            border-left: toRem(1) solid $claim-request-border;
        }
    }

    .claim-request__result {
        border-left: toRem(8) solid $yellow-block;
        padding-left: toRem(8);
        line-height: toRem(16);
        margin: toRem(10) 0 toRem(18) 0;
        font-size: toRem(18);
        font-weight: 300;
    }

    .table-responsive {
        padding-bottom: toRem(28);

        @include media('<tablet') {
            border-top: toRem(1) solid $secondary-color;
        }
    }
    .applied-status-count {
        display: inline-block;
        background: $primary-lighten-color;
        border-radius: 50%;
        width: toRem(18);
        height: toRem(18);
        text-align: center;
        color: $white;
        margin-left: toRem(4);
        vertical-align: bottom;
    }
    .w-slide__btn {
        border-right: toRem(1) solid $secondary-border-color;
        padding: toRem(8);
    }
    .sticky-mobile {
        position: sticky;
        top: toRem(65);
        background: $white;
        padding: 0;
        z-index: $index-1;
    }
    .daterange--single {
        .dr-input .dr-dates {
            min-width: unset;
        }
    }
    .dates-list {
        position: absolute;
        display: none;
        flex-direction: column;
        padding: 0;
        margin: 0;
        top: toRem(38);
        left: 0;
        border: toRem(1) solid $secondary-border-color;
        list-style: none;
        z-index: $index-999;
        width: 100%;
        box-shadow: 0 toRem(2) toRem(4) rgba(0, 0, 0, 0.2);
        @include media('>=tablet') {
            width: unset;
        }
        li {
            display: contents;
        }
        &__item {
            padding: toRem(12) toRem(84) toRem(12) toRem(10);
            border-bottom: toRem(1) solid $secondary-border-color;
            white-space: nowrap;
            background-color: $white;
            font-size: toRem(12);
            &:hover {
                background-color: $light-silver;
            }
        }
        &.js--show {
            display: flex;
        }
    }
    .input-group-addon--right {
        @include media('>=tablet') {
            position: relative;
        }
        cursor: pointer;
    }
    @include media('>=tablet') {
        &__item-status {
            min-width: toRem(150);
        }
    }
}
