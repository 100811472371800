.profile-menu {
    background: $white;
    font-weight: normal;
    padding: 0;

    li {
        border-top: solid toRem(1) $secondary-lighten-color;

        a {
            color: $primary-color;

            &.active {
                color: $primary-color;
                font-weight: 600;
                border-left: 0;
                border-bottom: solid toRem(3) $dark-green-color;
            }
        }

        &:last-child {
            border-bottom: solid toRem(1) $secondary-lighten-color;
        }
    }

    &--dropBlock {
        margin-bottom: toRem(40);
        > a {
            border-bottom: solid toRem(3) $dark-green-color;
        }
        a {
            background: $white;
            color: $primary-color;
            font-weight: 600;
            border-left: 0;
            border-top: 0;
            @include media('<desktop') {
                @include font-size(14);
            }

            &:hover {
                background: $white;
                color: $primary-color;
            }

            &.js--open {
                border-bottom: solid toRem(3) $secondary-color;
            }
        }
    }

    &--res {
        li {
            &:first-child {
                border-top: 0;
            }
            a {
                background: $white;
                color: $primary-color;
                border-top: 0;
            }
        }
    }
    &__institutional:empty {
        padding-top: 0;
        margin-top: 0;
        border-top: 0;
    }
}
