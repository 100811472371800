// todo: move to variables file
$index-5: 5;

.award-winners {
    &__prize-main-title {
        margin: toRem(25) 0;

        &.left-bordered-title {
            &:before {
                background: $black;
            }
        }

        @include media('<tablet') {
            &.accordion-tabbed__control {
                &:after {
                    @include font-size(12);
                    font-style: normal;
                    color: $blue-color;
                }
            }
        }
    }

    &__prize-image {
        img {
            display: inline-block;
            @extend .img-rounded;
            background: $white;
            padding: toRem(12);
            border: solid toRem(4) $secondary-lighten-color;
        }
    }

    &__prize {
        background: $light-gray-color;
        text-align: center;
        padding: toRem(20);
        border-left: solid toRem(8) $black;

        @include media('>=tablet') {
            &.sticky_prize {
                position: sticky;
                transition: top 0.5s;
                z-index: $index-5;
                top: toRem(124);
            }
        }
        @include media('<tablet') {
            padding: toRem(20);
            position: inherit;
        }
        margin-bottom: toRem(15);
    }

    &__prize-title {
        @include font-size(16);
        margin-top: toRem(10);
    }

    &__list {
        padding: 0;
        @include media('>=tablet') {
            ul {
                display: flex;
                flex-wrap: wrap;
            }
        }

        ul {
            width: 100%;
        }
    }

    &__item {
        padding: toRem(20) toRem(40);
        text-align: center;
        @include media('<desktop') {
            padding: toRem(20);
        }
        @include media('<tablet') {
            padding: toRem(10) 0;
            text-align: left;
        }
    }

    &__link {
        display: block;
        border-bottom: solid toRem(1) $secondary-color;
        padding-bottom: toRem(30);
        @include media('<tablet') {
            padding-bottom: toRem(10);
        }

        .award-winners__img {
            img {
                width: toRem(110);
                height: toRem(110);
                filter: grayscale(1);
                transition: filter 0.3s;
                @extend .img-rounded;
                max-width: toRem(110);
                @include media('<=tablet') {
                    filter: none;
                    width: toRem(70);
                    height: toRem(70);
                }
            }

            @include media('<tablet') {
                float: left;
                width: toRem(70);
                max-width: toRem(70);
                margin-right: toRem(15);
            }
        }

        &:hover {
            .award-winners__img {
                img {
                    filter: none;
                }
            }
        }

        @include media('<tablet') {
            display: inline-block;
            vertical-align: middle;
            text-align: left;
            width: 100%;
        }

        @include media('>=tablet') {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    &__name {
        @include media('>=tablet') {
            padding-top: toRem(5);
        }
        @include media('<tablet') {
            float: left;
            width: 82%;
            padding-top: toRem(25);
        }
        @include media('<phone') {
            width: 70%;
            padding-top: toRem(8);
        }
    }

    &__award {
        font-size: toRem(14);
        margin-top: toRem(6);
        color: $primary-lighten-color;
        @include media('<tablet') {
            float: left;
            width: 82%;
        }
        @include media('<phone') {
            width: 70%;
        }
    }

    .accordion-tabbed__tab {
        border: none;
        width: 100%;
        @include media('>=tablet') {
            display: inline-block;
            padding-bottom: toRem(10);
        }
        @include media('<tablet') {
            margin-bottom: toRem(40);
        }
    }
}
