.c-hero {
    $this: &;

    top: 0;
    left: 0;
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    transform: translate3d(0, 0, 0);

    @include media('<large') {
        background-image: url(../../../images/hero-mobile.jpg);
    }

    @include media('>=large') {
        background-image: url(../../../images/hero-desktop-poster.jpg);
    }

    #background-trigger {
        display: none; /* show only when needed */
        position: fixed;
        top: toRem(10);
        right: toRem(5);
        background-color: $background-trigger-bg;
        color: $white;
        font-size: 90%;
        padding: toRem(6);
        -webkit-border-radius: toRem(3);
        -moz-border-radius: toRem(3);
        border-radius: toRem(3);
        cursor: pointer;
    }

    @include media('>=tablet') {
        background-color: $black;
    }

    &::before {
        background-image: linear-gradient(#000, rgba(0, 0, 0, 0.17) 58%, rgba(0, 0, 0, 0));
        background-repeat: no-repeat;
        content: '';
        position: absolute;
        top: 0;
        z-index: $index-minus49;
        transform: translate3d(0, 0, 0);
        height: toRem(262);
        width: 100%;
        display: block;
        left: 0;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 100%;
        width: 100%;
        z-index: $index-minus50;
        transform: translate3d(0, 0, 0);
        background: rgba($black, 0.6) url(../../../images/video-overlay.png) repeat center center;

        @include media('<large') {
            display: none;
        }
    }

    &__content {
        padding-bottom: toRem(150);
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        width: 100%;

        @media screen and (max-height: toRem(920)) {
            padding-bottom: 0;
        }

        @include media('<large') {
            padding-bottom: 0;
        }

        @include media('>=tablet', '<desktop') {
            padding-bottom: toRem(150);
        }
    }

    &__logo-img {
        display: block;
        width: toRem(264);
        height: auto;
        max-width: 100%;
        margin: 0 auto toRem(32);

        @include media('>=phone') {
            width: toRem(320);
        }

        @include media('>=tablet') {
            width: toRem(440);
        }

        @include media('>=large') {
            width: toRem(580);
            margin-bottom: toRem(64);
        }

        @media screen and (max-height: toRem(420)) {
            margin-top: toRem(20);
            max-width: toRem(200);
        }

        @media screen and (max-height: toRem(547)) {
            visibility: hidden;
        }

        @media screen and (max-height: toRem(780)) and (min-width: toRem(768)) {
            margin-bottom: toRem(20);
            margin-top: 0;
            width: toRem(400);
        }

        @media screen and (max-height: toRem(680)) and (min-width: toRem(768)) {
            width: toRem(320);
            margin-top: toRem(50);
        }

        @include media('>tablet', '<desktop') {
            margin-top: toRem(120);
        }
    }

    &__dots-navbar {
        position: absolute;
        bottom: toRem(150);
        left: 50%;
        transform: translateX(-50%);
        z-index: $index-2;

        @include media('<tablet') {
            display: none;
        }

        @media screen and (max-height: toRem(920)) {
            bottom: toRem(80);
        }

        @include media('<large') {
            bottom: toRem(100);
        }

        @include media('>=tablet', '<desktop') {
            bottom: toRem(200);
        }

        @media screen and (max-height: toRem(900)) {
            display: none;
        }

        @media screen and (orientation: landscape) and (max-device-width: toRem(840)) {
            display: none;
        }

        @media screen and (max-height: toRem(840)) {
            bottom: toRem(80);
        }

        @media screen and (min-height: toRem(1100)) {
            bottom: 25%;
        }

        @media screen and (min-height: toRem(1650)) {
            bottom: 34%;
        }
    }

    &__foot {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;

        background: red;

        @include media('>=tablet') {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    &__scroll-indicator {
        position: absolute;
        bottom: toRem(16);
        background: transparent
            url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAyNCAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIxLjE3IDcuNjI5MzllLTA4TDEyIDkuMTdMMi44MyA3LjYyOTM5ZS0wOEwwIDIuODNMMTIgMTQuODNMMjQgMi44M0wyMS4xNyA3LjYyOTM5ZS0wOFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=')
            no-repeat center center;
        display: block;
        height: toRem(44);
        width: toRem(44);
        margin: 0 auto;
        opacity: 0.6;
        animation: scrollIndicatorAnim 1.2s ease-in-out 0s infinite;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        transition: opacity 0.4s ease-in-out, background 0.4s ease-in-out;

        &:hover {
            animation-play-state: paused;
            opacity: 1;
            cursor: pointer;
            background-color: rgba($white, 0.02);
        }
    }

    &__yt-link {
        position: absolute;
        display: none;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding: toRem(14) toRem(16);
        border: toRem(1) solid $white;
        border-radius: toRem(2);
        font-size: toRem(14);
        line-height: 1em;
        color: $white;
        transition: background 0.25s ease-in-out, color 0.25s ease-in-out;

        @include media('>=tablet') {
            display: inline-flex;
            right: toRem(24);
            bottom: toRem(32);
        }

        &:hover,
        &:focus {
            color: $white;
            background: rgba($white, 0.1);
        }
    }

    &__yt-icon {
        @include font-size(20);
        margin-right: toRem(8);
        line-height: toRem(14);
    }

    &__container {
        max-width: 100%;

        .hero-slideshow-loader {
            background: rgba($white, 0.2);
            border-radius: toRem(5);
            margin: toRem(50) auto;
            max-width: toRem(720);
            padding: toRem(12) toRem(50);

            @media screen and (max-height: toRem(900)) {
                margin: toRem(30) auto toRem(80);
            }

            @media screen and (max-height: toRem(780)) {
                margin: toRem(20) auto toRem(40);
            }

            @media screen and (max-height: toRem(700)) {
                margin: toRem(20) auto;
                margin-bottom: 0;
            }

            @media screen and (max-height: toRem(430)) {
                margin-bottom: toRem(60);
            }

            .lazy-loaded [class*='lazy-load__line'] {
                background: $white;
            }

            &:after {
                color: $white;
            }
        }
    }

    &__hero-slider {
        @media screen and (max-height: toRem(430)) {
            margin-bottom: toRem(60);
        }

        .slideShow {
            min-height: 0;

            .owl-carousel {
                background: rgba($white, 0.2);
                border-radius: toRem(5);
                margin: toRem(50) auto;
                max-width: toRem(720);
                padding: toRem(12) toRem(50);

                @media screen and (max-height: toRem(900)) {
                    margin: toRem(30) auto toRem(80);
                }

                @media screen and (max-height: toRem(780)) {
                    margin: toRem(20) auto toRem(40);
                }

                @media screen and (max-height: toRem(700)) {
                    margin: toRem(20) auto;
                    margin-bottom: 0;
                }

                @media screen and (max-height: toRem(430)) {
                    margin-bottom: toRem(60);
                }

                .owl-nav {
                    .owl-next,
                    .owl-prev {
                        @include media('<large') {
                            background: none !important;
                        }

                        i {
                            color: $white;
                        }
                    }

                    .owl-next {
                        padding-right: toRem(20);
                    }

                    .owl-prev {
                        padding-left: toRem(20);
                    }
                }

                .owl-dots {
                    margin-top: toRem(2);
                    .owl-dot {
                        &.active,
                        &:hover,
                        &:focus {
                            span {
                                background-color: $white;
                                border-color: $white;
                            }
                        }
                    }
                }

                .owl-item {
                    text-align: center;
                    color: $white;
                    @include font-size(14);

                    span {
                        display: block;
                        font-weight: 600;
                        padding-top: toRem(6);
                    }

                    p {
                        margin: toRem(6) 0;
                    }
                }
            }
        }
    }

    &__messages {
        color: $white;
        text-align: center;
        background: rgba($white, 0.2);
        border-radius: toRem(5);
        margin: toRem(50) auto;
        max-width: toRem(720);
        padding: toRem(12) toRem(50);

        @include font-size(14);

        @media screen and (max-height: toRem(900)) {
            margin: toRem(30) auto toRem(80);
        }

        @media screen and (max-height: toRem(780)) {
            margin: toRem(20) auto toRem(40);
        }

        @media screen and (max-height: toRem(700)) {
            margin: toRem(20) auto;
            margin-bottom: 0;
        }

        @media screen and (max-height: toRem(430)) {
            margin-bottom: toRem(60);
        }
    }

    .quick-search {
        &__advancedHeader {
            color: $white;
            @include font-size(14);
            display: block;
            margin: auto;
            text-align: right;
            margin-top: toRem(10);

            width: toRem(420);

            @include media('>=tablet') {
                width: toRem(520);
            }

            @include media('>=desktop') {
                width: toRem(620);
            }

            @include media('>=large') {
                width: toRem(720);
            }

            @media screen and (max-height: toRem(680)) {
                width: toRem(420);
            }

            @include media('<phone') {
                width: 100%;
            }
        }
        &__input.auto-complete {
            border-radius: 0.5rem;
        }
    }
}

.c-hero__form {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin: 0 auto;

    .quick-search {
        &--input {
            max-width: 100%;
            width: toRem(420);

            @include media('>=tablet') {
                width: toRem(520);
            }

            @include media('>=desktop') {
                width: toRem(620);
            }

            @include media('>=large') {
                width: toRem(720);
            }

            @media screen and (max-height: toRem(680)) {
                width: toRem(420);
            }

            .input-group {
                margin: 0;

                @media screen and (max-height: toRem(420)) and (min-width: toRem(768)) {
                    margin-top: toRem(60);
                }
            }
        }

        &__input,
        &__input.autocomplete {
            position: relative;
            height: auto;
            line-height: toRem(20);
            font-size: toRem(14);
            padding: toRem(16) toRem(16) toRem(16) toRem(16);
            border-radius: toRem(8);
            border: none;
            overflow: hidden;
            color: $primary-color;
            width: 100%;
            max-height: inherit;
            background: rgba($white, 0.87);
            transition: background 0.25s ease-in-out, box-shadow 0.4s ease-in-out;

            @include media('>=desktop') {
                font-size: toRem(18);
            }

            &::-webkit-input-placeholder {
                font-size: inherit;
                color: $primary-color;
                font-style: normal;

                @include media('>=tablet') {
                    font-size: inherit;
                }
            }

            &::-moz-placeholder {
                font-size: inherit;
                color: $primary-color;
                font-style: normal;

                @include media('>=tablet') {
                    font-size: inherit;
                }
            }

            &:-ms-input-placeholder {
                font-size: inherit;
                color: $primary-color;
                font-style: normal;

                @include media('>=tablet') {
                    font-size: inherit;
                }
            }

            &:focus {
                outline: 0;
                background-color: $white;
            }
        }

        &__button {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            display: block;
            border: none;
            max-height: unset;
            line-height: 1;
            border-radius: 0;
            border-top-right-radius: toRem(8);
            border-bottom-right-radius: toRem(8);
            padding: toRem(12);
            background: transparent;
            transition: background 0.25s ease-in-out;
            width: auto;

            &:focus {
                box-shadow: none;
            }

            &:hover {
                background: transparent;
            }
        }

        &__advancedHeader {
            color: $white;
            @include font-size(14);
        }
    }
}

.c-dots-nav {
    $this: &;

    display: flex;
    flex-flow: row nowrap;
    padding: 0;
    margin: 0;
    list-style: none;

    &__item {
        margin: toRem(6);
        width: toRem(96);
        height: toRem(96);
        display: flex;
        flex-flow: row nowrap;
        justify-content: stretch;

        @include media('>=desktop') {
            margin: toRem(8);
            width: toRem(112);
            height: toRem(112);
        }
    }

    &__icon {
        @include font-size(29);
        margin-bottom: toRem(6);
        transform-origin: center bottom;
        transition: transform 0.4s ease-in-out, filter 0.1s ease-in-out;
    }

    &__link {
        position: relative;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        color: $text-onDark;
        text-align: center;
        width: 100%;
        background: rgba($white, 0.2);
        border-radius: 50%;
        font-size: toRem(13);
        transition: transform 0.25s ease-in-out, background 0.25s ease-in-out, box-shadow 0.25s ease-in-out,
            color 0.25s ease-in-out;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            opacity: 0;
            border-radius: 50%;
            transform-origin: center bottom;
            z-index: $index-minus;
            box-shadow: 0 toRem(20) toRem(15) rgba($black, 0.4);
            height: toRem(10);
            left: 5%;
            width: 90%;
            transition: opacity 0.25s ease-in-out, bottom 0.25s ease-in-out;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: $black;
            opacity: 0;
            border-radius: 50%;
            transform-origin: center bottom;
            z-index: $index-1;
        }

        &:focus {
            color: $white;
        }

        &:hover {
            color: $white;
            text-decoration: none;
            background: rgba($blue-color, 1);
            transform: translateY(toRem(-16));
            box-shadow: 0 0 0 toRem(4) rgba(lighten($blue-color, 10%), 0.6),
                0 0 0 toRem(8) rgba(darken($blue-color, 20%), 0.6);

            &::before {
                opacity: 1;
                bottom: toRem(-16);
            }

            &::after {
                bottom: toRem(-16);
            }

            #{$this}__icon {
                filter: drop-shadow(toRem(2) toRem(2) toRem(2) rgba($primary-color, 0.4));
                transform: translateY(toRem(-2));
            }
        }
    }
}

.c-hero-wrapper {
    display: flex;
}
