[data-db-target-of] {
    box-shadow: 0 toRem(2) toRem(10) rgba(82, 82, 82, 0.43);
}
.base {
    > [data-db-target-of] {
        @include card(toRem(17), $default-bg-color);
        min-width: toRem(225);
    }
}

[data-db-direction='right'] {
    left: auto;
    right: 0;
}
